import type { FC } from 'react';

import { CallbackAction } from './Actions/CallbackAction';
import { CheckAction } from './Actions/CheckAction';
import { DropdownAction } from './Actions/DropdownAction';
import { SpinnerAction } from './Actions/SpinnerAction';
import { ToggleAction } from './Actions/ToggleAction';
import { UrlAction } from './Actions/UrlAction';
import { ListActionType } from './IList';
import type { IListAction } from './IList';

interface IListActionRenderProps {
	item: any;
	render?: (item: any) => IListAction;
	action?: IListAction;
}

export const ListActionsRender: FC<IListActionRenderProps> = ({ item, render, action }) => {
	let actionToRender: IListAction | undefined = action;
	if (!action && !render) {
		return null;
	}

	if (action && action.showIf && !action.showIf(item)) {
		return null;
	}

	if (render) {
		actionToRender = render(item);
	}

	if (actionToRender && actionToRender.type === ListActionType.action) {
		return <CallbackAction action={actionToRender} item={item} />;
	}

	if (actionToRender && actionToRender.type === ListActionType.spinner) {
		return <SpinnerAction action={actionToRender} item={item} />;
	}

	if (actionToRender && actionToRender.type === ListActionType.toggle) {
		return <ToggleAction action={actionToRender} item={item} />;
	}

	if (actionToRender && actionToRender.type === ListActionType.url) {
		return <UrlAction action={actionToRender} item={item} />;
	}

	if (actionToRender && actionToRender.type === ListActionType.check) {
		return <CheckAction action={actionToRender} item={item} />;
	}

	if (actionToRender && actionToRender.type === ListActionType.dropdown) {
		return <DropdownAction action={actionToRender} item={item} />;
	}

	return null;
};
