import type { ICouponConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/couponConfiguration/entities/CouponConfiguration';
import { CouponConfigurationEndpoints } from '@crac/core/modules/pricing/partnerConfiguration/couponConfiguration/services/CouponConfigurationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

/**
 * Deactivate a service coupon configuration params
 */
export interface ICouponConfigurationDeactivateParams extends IServiceBaseParams {
	ids: number[];
}

/**
 * Deactivate coupon configuration
 * @param params `ICouponConfigurationDeactivateParams`
 * @returns `Promise<ServiceResponse<ICouponConfiguration[]>>`
 */
export const couponConfigurationDeactivateService = (params: ICouponConfigurationDeactivateParams) => {
	return Api.post<ICouponConfiguration[], ICouponConfigurationDeactivateParams>(
		CouponConfigurationEndpoints.DEACTIVATE,
		params,
	);
};
