export enum ServiceRateTypeEndpoints {
	/**
	 * @host `/serviceRateType/search/`
	 */
	SEARCH = '/serviceRateType/search/',

	/**
	 * @host `/serviceRateType/insert/`
	 */
	INSERT = '/serviceRateType/insert/',

	/**
	 * @host `/serviceRateType/modify/`
	 */
	MODIFY = '/serviceRateType/modify/',
}
