import { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import type { ICustomerSearchParams } from './CustomerSearchService';
import { customerSearchService } from './CustomerSearchService';
import type { MessageType } from '../../../models/types/MessageType';
import type { ICustomer } from '../entities/Customer';

export const customerSearchByFieldService = async (
	params: ICustomerSearchParams,
): Promise<ServiceResponse<ICustomer[]>> => {
	const tasks = [];
	if (params.taxIdentificationNumber) {
		tasks.push(
			customerSearchService({
				closedBookings: params.closedBookings,
				taxIdentificationNumber: params.taxIdentificationNumber,
			}),
		);
	}

	if (params.driverLicense) {
		tasks.push(
			customerSearchService({ closedBookings: params.closedBookings, driverLicense: params.driverLicense }),
		);
	}

	if (params.email) {
		tasks.push(customerSearchService({ closedBookings: params.closedBookings, email: params.email }));
	}

	const requestResponses = await Promise.all(tasks);

	const responseData: ICustomer[] = [];
	const responseErrors: MessageType[] = [];

	const updateResponse = (customers: ICustomer[]): void => {
		customers.forEach((customer) => {
			if (!responseData.some((item) => item.code === customer.code)) {
				responseData.push(customer);
			}
		});
	};

	requestResponses.forEach((response) => {
		if (response.ok && response.data) {
			updateResponse(response.data);
		} else {
			responseErrors.push(...response.errors);
		}
	});

	if (responseErrors.length > 0) {
		return new ServiceResponse<ICustomer[]>(false, null, responseErrors);
	}

	return new ServiceResponse<ICustomer[]>(true, responseData, []);
};
