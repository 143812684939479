import type { IPackageConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/packageConfiguration/entities/PackageConfiguration';
import { PackageConfigurationEndpoints } from '@crac/core/modules/pricing/partnerConfiguration/packageConfiguration/services/PackageConfigurationEndpoints';
import type { PackageType } from '@crac/core/modules/pricing/partnerConfiguration/packageConfiguration/types/PackageType';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IPackageConfigurationModifyParams extends IServiceBaseParams, Partial<IPackageConfiguration> {
	id: number;
	service: string;
	packageType: PackageType;
}

/**
 * Modify package configuration
 * @param model IPackageConfigurationModifyParams
 * @returns IPackageConfiguration
 */
export const packageConfigurationModifyService = (
	model: IPackageConfigurationModifyParams,
): Promise<ServiceResponse<IPackageConfiguration>> => {
	return Api.post<IPackageConfiguration, IPackageConfigurationModifyParams>(
		PackageConfigurationEndpoints.MODIFY,
		model,
	);
};
