import type { INotAllowedMovement } from '@crac/core/modules/pricing/notAllowedMovement/entities/NotAllowedMovement';
import type { INotAllowedMovementDeleteParams } from '@crac/core/modules/pricing/notAllowedMovement/services/NotAllowedMovementDeleteService';
import { notAllowedMovementDeleteService } from '@crac/core/modules/pricing/notAllowedMovement/services/NotAllowedMovementDeleteService';
import type { INotAllowedMovementInsertParams } from '@crac/core/modules/pricing/notAllowedMovement/services/NotAllowedMovementInsertService';
import { notAllowedMovementInsertService } from '@crac/core/modules/pricing/notAllowedMovement/services/NotAllowedMovementInsertService';
import type { INotAllowedMovementModifyParams } from '@crac/core/modules/pricing/notAllowedMovement/services/NotAllowedMovementModifyService';
import { notAllowedMovementModifyService } from '@crac/core/modules/pricing/notAllowedMovement/services/NotAllowedMovementModifyService';
import { notAllowedMovementSearchService } from '@crac/core/modules/pricing/notAllowedMovement/services/NotAllowedMovementSearchService';
import type { INotAllowedMovementSearchParams } from '@crac/core/modules/pricing/notAllowedMovement/services/NotAllowedMovementSearchService';
import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

/**
 * Searches for not allowed movements based on specified parameters.
 *
 * @param {INotAllowedMovement[]} payload - The not allowed movements to be returned.
 * @param {INotAllowedMovementSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of not allowed movements.
 */
export const notAllowedMovementSearch = createAsyncAction<INotAllowedMovement[], INotAllowedMovementSearchParams>(
	'notAllowedMovement/search',
	notAllowedMovementSearchService,
);

/**
 * Inserts new not allowed movements.
 *
 * @param {INotAllowedMovement[]} payload - The not allowed movements to be inserted.
 * @param {INotAllowedMovementInsertParams} params - Parameters for the insertion.
 * @returns {Promise<void>} - A promise that resolves after the not allowed movements are inserted.
 */
export const notAllowedMovementInsert = createAsyncAction<INotAllowedMovement[], INotAllowedMovementInsertParams>(
	'notAllowedMovement/insert',
	notAllowedMovementInsertService,
);

/**
 * Modifies an existing not allowed movement.
 *
 * @param {INotAllowedMovement} payload - The not allowed movement to be modified.
 * @param {INotAllowedMovementModifyParams} params - Parameters for the modification.
 * @returns {Promise<void>} - A promise that resolves after the not allowed movement is modified.
 */
export const notAllowedMovementModify = createAsyncAction<INotAllowedMovement, INotAllowedMovementModifyParams>(
	'notAllowedMovement/modify',
	notAllowedMovementModifyService,
);

/**
 * Deletes a specific not allowed movement.
 *
 * @param {INotAllowedMovement} payload - The not allowed movement to be deleted.
 * @param {INotAllowedMovementDeleteParams} params - Parameters for the deletion.
 * @returns {Promise<void>} - A promise that resolves after the not allowed movement is deleted.
 */
export const notAllowedMovementDelete = createAsyncAction<INotAllowedMovement, INotAllowedMovementDeleteParams>(
	'notAllowedMovement/delete',
	notAllowedMovementDeleteService,
);

/**
 * Searches for not allowed movements by zone based on specified parameters.
 *
 * @param {INotAllowedMovement[]} payload - The not allowed movements to be returned for a specific zone.
 * @param {INotAllowedMovementSearchParams} params - Parameters for the search by zone.
 * @returns {Promise<void>} - A promise that resolves to an array of not allowed movements for a specific zone.
 */
export const notAllowedMovementSearchByZone = createAsyncAction<INotAllowedMovement[], INotAllowedMovementSearchParams>(
	'notAllowedMovement/searchByZone',
	notAllowedMovementSearchService,
);

/**
 * Clears the not allowed movement state.
 */
export const notAllowedMovementClear = createSyncAction('notAllowedMovement/clear');
