import { useEffect } from 'react';

import {
	supplierLocationClear,
	supplierLocationSearch,
} from '@crac/core/modules/supplier/location/state/actions/SupplierLocationActions';

import { useAlertMessage } from '~/features/shared/hooks/useAlertMessage';
import { useBindAndMemoizeActions } from '~/features/shared/hooks/useBindAndMemoizeActions';
import { useLocationSelector } from '~/features/workshop/location/state/selector';

export const useSupplierLocationLoad = (code: string) => {
	const { locations, supplierLocationSearchRequest } = useLocationSelector();
	const actions = useBindAndMemoizeActions({ supplierLocationSearch, supplierLocationClear });
	const { showAlert } = useAlertMessage();

	useEffect(() => {
		if (code) {
			actions.supplierLocationSearch({ supplier: code });
		}

		return () => {
			actions.supplierLocationClear();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [code]);

	useEffect(() => {
		if (!supplierLocationSearchRequest.inProgress && !supplierLocationSearchRequest.ok) {
			const [{ message }] = supplierLocationSearchRequest.messages;
			showAlert({ message });
		}
	}, [showAlert, supplierLocationSearchRequest]);

	const isLoading = supplierLocationSearchRequest.inProgress;

	return { locations, isLoading };
};
