import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { PaymentEndPoints } from './PaymentEntpoints';
import type { IPaymentLine } from '../entities/PaymentLine';

export interface IPaymentLineGetByCustomerParams extends IServiceBaseParams {
	customerCode: string;
}

/**
 * GET payment line from customer
 *  {string} customerCode
 * @returns {Promise<Array>} `Array<PaymentLine>` Payment line list
 */
export const paymentLineGetByCustomerService = (
	model: IPaymentLineGetByCustomerParams,
): Promise<ServiceResponse<IPaymentLine[]>> => {
	return Api.get<IPaymentLine[], IPaymentLineGetByCustomerParams>(
		PaymentEndPoints.PAYMENT_LINE_GET_BY_CUSTOMER,
		model,
	);
};
