import type { IDiscountCoupon } from '@crac/core/modules/pricing/discountCoupon/entities/DiscountCoupon';
import { DiscountCouponEndpoints } from '@crac/core/modules/pricing/discountCoupon/services/DiscountCouponEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IDiscountCouponInsertParams extends IServiceBaseParams {
	discountCoupons: IDiscountCoupon[];
}

/**
 * Insert discount coupons
 */
export const discountCouponInsertService = (
	model: IDiscountCouponInsertParams,
): Promise<ServiceResponse<IDiscountCoupon[]>> => {
	return Api.post<IDiscountCoupon[], IDiscountCouponInsertParams>(DiscountCouponEndpoints.INSERT, model);
};
