import { useRef, useState } from 'react';
import type { FC, ReactNode } from 'react';

import type { IActionProps, IListAction } from './IList';
import { Tooltip } from '../../../external/reactstrap/tooltip';

const getTooltipText = (action: IListAction, item: any) => {
	if (action.hideTooltip) {
		return null;
	}
	if (action.tooltip) {
		return action.tooltip(item);
	}
	if (!action.text && action.icon) {
		switch (action.icon) {
			case 'eye':
				return 'View';
			case 'pencil':
				return 'Edit';
			case 'trash':
				return 'Remove';
			case 'power-off':
				if (!action.color) {
					return null;
				}
				if (action.color === 'danger') {
					return 'Deactivate';
				}
				return 'Activate';
			default:
				return null;
		}
	}
	return null;
};

type TooltipWrapperType = {
	children: ReactNode;
} & IActionProps;

export const TooltipWrapper: FC<TooltipWrapperType> = ({ action, children, item }) => {
	const tooltipRef = useRef(null);
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const toggle = () => setTooltipOpen(!tooltipOpen);

	const tooltipText = getTooltipText(action, item);
	const txtKeys = tooltipText ? tooltipText.split('\n').map((txt, idx) => `${txt}-${idx}`) : [];

	return (
		<>
			<div ref={tooltipRef} style={{ display: 'inline-block' }}>
				{children}
			</div>
			{tooltipText ? (
				<Tooltip
					autohide
					className="m-3"
					isOpen={tooltipOpen}
					target={tooltipRef}
					toggle={toggle}
					trigger="hover"
				>
					{tooltipText.split('\n').map((txt, idx) => (
						<p className="p-0 m-0" key={txtKeys[idx]}>
							{txt}
						</p>
					))}
				</Tooltip>
			) : null}
		</>
	);
};
