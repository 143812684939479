import { createSlice } from '@reduxjs/toolkit';

import {
	packageConfigurationClear,
	packageConfigurationDeactivate,
	packageConfigurationInsert,
	packageConfigurationModify,
	packageConfigurationRemoveDeactivated,
	packageConfigurationSearch,
} from '@crac/core/modules/pricing/partnerConfiguration/packageConfiguration/state/actions/PackageConfigurationActions';
import type { IPackageConfigurationReducerState } from '@crac/core/modules/pricing/partnerConfiguration/packageConfiguration/state/stateType/PackageConfigurationReducerState';
const initialState: IPackageConfigurationReducerState = {
	packageConfigurationInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	packageConfigurationModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	packageConfigurations: [],
	packageConfigurationSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	packageConfigurationDeactivateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const packageConfigurationSlice = createSlice({
	name: 'PackageConfigurationSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR
		builder.addCase(packageConfigurationClear, (state) => {
			return {
				...state,
				packageConfigurations: [],
			};
		});
		// SEARCH
		builder
			.addCase(packageConfigurationSearch.pending, (state) => {
				return {
					...state,
					packageConfigurationSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(packageConfigurationSearch.fulfilled, (state, action) => {
				return {
					...state,
					packageConfigurationSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					packageConfigurations: action.payload,
				};
			})
			.addCase(packageConfigurationSearch.rejected, (state, action) => {
				return {
					...state,
					packageConfigurationSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(packageConfigurationInsert.pending, (state) => {
				return {
					...state,
					packageConfigurationInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(packageConfigurationInsert.fulfilled, (state, action) => {
				return {
					...state,
					packageConfigurationInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					packageConfigurations: [...state.packageConfigurations, ...action.payload],
				};
			})
			.addCase(packageConfigurationInsert.rejected, (state, action) => {
				return {
					...state,
					packageConfigurationInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(packageConfigurationModify.pending, (state) => {
				return {
					...state,
					packageConfigurationModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(packageConfigurationModify.fulfilled, (state, action) => {
				return {
					...state,
					packageConfigurationModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					packageConfigurations: [...state.packageConfigurations, action.payload],
				};
			})
			.addCase(packageConfigurationModify.rejected, (state, action) => {
				return {
					...state,
					packageConfigurationModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// REMOVE DEACTIVATED
		builder.addCase(packageConfigurationRemoveDeactivated, (state, action) => {
			return {
				...state,
				packageConfigurations: state.packageConfigurations.filter(({ id }) => id !== action.payload),
			};
		});
		// DEACTIVATE
		builder
			.addCase(packageConfigurationDeactivate.pending, (state) => {
				return {
					...state,
					packageConfigurationDeactivateRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(packageConfigurationDeactivate.fulfilled, (state, action) => {
				return {
					...state,
					packageConfigurationDeactivateRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					packageConfigurations: [
						...state.packageConfigurations.filter(
							({ id }) => !action.payload.map((item) => item.id).includes(id),
						),
						...action.payload,
					],
				};
			})
			.addCase(packageConfigurationDeactivate.rejected, (state, action) => {
				return {
					...state,
					packageConfigurationDeactivateRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const packageConfigurationReducer = packageConfigurationSlice.reducer;
