import { PickUpEndpoints } from './PickUpEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IBooking } from '../../entities/Booking';

export interface IBookingStartContractParams extends IServiceBaseParams {
	bookingNumber: string;
	customerCode: string;
	turn?: string;
}

/**
 * Start contract
 * @param {string} bookingNumber
 * @returns {Promise<BookingPropType>} `BookingPropType`
 */
export const bookingPickUpStartContractService = (
	model: IBookingStartContractParams,
): Promise<ServiceResponse<IBooking>> => {
	return Api.post<IBooking, IBookingStartContractParams>(PickUpEndpoints.START_CONTRACT, model);
};
