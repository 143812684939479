import type { IBranchSchedule } from '@crac/core/modules/pricing/branchSchedule/entities/BranchSchedule';
import { BranchScheduleEndpoints } from '@crac/core/modules/pricing/branchSchedule/services/BranchScheduleEndpoints';
import type { FromTo } from '@crac/core/modules/pricing/types/FromToType';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IBranchScheduleModifyParams extends IServiceBaseParams {
	id: number;
	hour?: FromTo<string>;
	weekDay?: FromTo<number>;
	shift: number;
	hoursBeforeOpening?: number;
	hoursAfterClosing?: number;
}

export const branchScheduleModifyService = (
	model: IBranchScheduleModifyParams,
): Promise<ServiceResponse<IBranchSchedule>> => {
	return Api.post<IBranchSchedule, IBranchScheduleModifyParams>(BranchScheduleEndpoints.MODIFY, model);
};
