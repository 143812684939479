import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CommentEndPoints } from './CommentsEndpoints';
import type { IComment } from '../entities/Comment';

export interface ICommentRemoveFromBookingParams extends IServiceBaseParams {
	code: string;
	lineNumber: number;
	table?: number;
}

/**
 * Remove comment from booking
 *  {string} lineNumber Line number
 *  {string} code Booking code
 */
export const commentRemoveFromBookingService = (
	model: ICommentRemoveFromBookingParams,
): Promise<ServiceResponse<IComment>> => {
	return Api.post<IComment, ICommentRemoveFromBookingParams>(CommentEndPoints.REMOVE_FROM_BOOKING, model);
};
