import { createSlice } from '@reduxjs/toolkit';

import {
	rentRateTypeRelationDelete,
	rentRateTypeRelationInsert,
	rentRateTypeRelationModify,
	rentRateTypeRelationReset,
	rentRateTypeRelationSearch,
} from '@crac/core/modules/pricing/rentRateTypeRelation/state/actions/RentRateTypeRelationActions';
import type { IRentRateTypeRelationReducerState } from '@crac/core/modules/pricing/rentRateTypeRelation/state/stateType/RentRateTypeRelationReducerState';

const initialState: IRentRateTypeRelationReducerState = {
	rentRateTypeRelation: null,
	rentRateTypeRelationInsertRequest: { inProgress: false, messages: [], ok: true },
	rentRateTypeRelationModifyRequest: { inProgress: false, messages: [], ok: true },
	rentRateTypeRelations: [],
	rentRateTypeRelationSearchRequest: { inProgress: false, messages: [], ok: true },
	rentRateTypeRelationDeleteRequest: { inProgress: false, messages: [], ok: true },
};

const rentRateTypeRelationSlice = createSlice({
	name: 'rentRateTypeRelation',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(rentRateTypeRelationReset, () => {
			return initialState;
		});
		builder.addCase(rentRateTypeRelationSearch.pending, (state) => {
			return {
				...state,
				rentRateTypeRelationSearchRequest: { inProgress: true, ok: true, messages: [] },
			};
		});
		builder.addCase(rentRateTypeRelationSearch.rejected, (state, action) => {
			return {
				...state,
				rentRateTypeRelationSearchRequest: { inProgress: false, ok: false, messages: action.payload || [] },
			};
		});
		builder.addCase(rentRateTypeRelationSearch.fulfilled, (state, action) => {
			return {
				...state,
				rentRateTypeRelationSearchRequest: { inProgress: false, ok: true, messages: [] },
				rentRateTypeRelations: action.payload,
			};
		});
		builder.addCase(rentRateTypeRelationInsert.pending, (state) => {
			return {
				...state,
				rentRateTypeRelationInsertRequest: { inProgress: true, ok: true, messages: [] },
			};
		});
		builder.addCase(rentRateTypeRelationInsert.rejected, (state, action) => {
			return {
				...state,
				rentRateTypeRelationInsertRequest: { inProgress: false, ok: false, messages: action.payload || [] },
			};
		});
		builder.addCase(rentRateTypeRelationInsert.fulfilled, (state, action) => {
			return {
				...state,
				rentRateTypeRelationInsertRequest: { inProgress: false, ok: true, messages: [] },
				rentRateTypeRelations: [...state.rentRateTypeRelations, ...action.payload],
			};
		});
		builder.addCase(rentRateTypeRelationModify.pending, (state) => {
			return {
				...state,
				rentRateTypeRelationModifyRequest: { inProgress: true, ok: true, messages: [] },
			};
		});
		builder.addCase(rentRateTypeRelationModify.rejected, (state, action) => {
			return {
				...state,
				rentRateTypeRelationModifyRequest: { inProgress: false, ok: false, messages: action.payload || [] },
			};
		});
		builder.addCase(rentRateTypeRelationModify.fulfilled, (state, action) => {
			return {
				...state,
				rentRateTypeRelationModifyRequest: { inProgress: false, ok: true, messages: [] },
				rentRateTypeRelations: [
					...state.rentRateTypeRelations.filter(
						(relation) => !action.payload.find((item) => item.id === relation.id),
					),
					...action.payload,
				],
			};
		});
		builder.addCase(rentRateTypeRelationDelete.pending, (state) => {
			return {
				...state,
				rentRateTypeRelationDeleteRequest: { inProgress: true, ok: true, messages: [] },
			};
		});
		builder.addCase(rentRateTypeRelationDelete.rejected, (state, action) => {
			return {
				...state,
				rentRateTypeRelationDeleteRequest: { inProgress: false, ok: false, messages: action.payload || [] },
			};
		});
		builder.addCase(rentRateTypeRelationDelete.fulfilled, (state, action) => {
			return {
				...state,
				rentRateTypeRelationDeleteRequest: { inProgress: false, ok: true, messages: [] },
				rentRateTypeRelations: state.rentRateTypeRelations.filter(
					(relation) => relation.id !== action.payload.id,
				),
			};
		});
	},
});

export const rentRateTypeRelationReducer = rentRateTypeRelationSlice.reducer;
