import type { IMaximumBookingDuration } from '@crac/core/modules/pricing/maximumBookingDuration/entities/MaximumBookingDuration';
import type { IMaximumBookingDurationDeactivateParams } from '@crac/core/modules/pricing/maximumBookingDuration/services/MaximumBookingDurationDeactivateService';
import { maximumBookingDurationDeactivateService } from '@crac/core/modules/pricing/maximumBookingDuration/services/MaximumBookingDurationDeactivateService';
import type { IMaximumBookingDurationInsertParams } from '@crac/core/modules/pricing/maximumBookingDuration/services/MaximumBookingDurationInsertService';
import { maximumBookingDurationInsertService } from '@crac/core/modules/pricing/maximumBookingDuration/services/MaximumBookingDurationInsertService';
import type { IMaximumBookingDurationSearchParams } from '@crac/core/modules/pricing/maximumBookingDuration/services/MaximumBookingDurationSearchService';
import { maximumBookingDurationSearchService } from '@crac/core/modules/pricing/maximumBookingDuration/services/MaximumBookingDurationSearchService';
import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

/**
 * Searches for maximum booking durations based on specified parameters.
 *
 * @param {IMaximumBookingDuration[]} payload - The maximum booking durations to be returned.
 * @param {IMaximumBookingDurationSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of maximum booking durations.
 */
export const maximumBookingDurationSearch = createAsyncAction<
	IMaximumBookingDuration[],
	IMaximumBookingDurationSearchParams
>('maximumBookingDuration/search', maximumBookingDurationSearchService);

/**
 * Inserts new maximum booking durations.
 *
 * @param {IMaximumBookingDuration[]} payload - The maximum booking durations to be inserted.
 * @param {IMaximumBookingDurationInsertParams} params - Parameters for the insertion.
 * @returns {Promise<void>} - A promise that resolves after the maximum booking durations are inserted.
 */
export const maximumBookingDurationInsert = createAsyncAction<
	IMaximumBookingDuration[],
	IMaximumBookingDurationInsertParams
>('maximumBookingDuration/insert', maximumBookingDurationInsertService);

/**
 * Deactivates specific maximum booking durations.
 *
 * @param {IMaximumBookingDuration[]} payload - The maximum booking durations to be deactivated.
 * @param {IMaximumBookingDurationDeactivateParams} params - Parameters for the deactivation.
 * @returns {Promise<void>} - A promise that resolves after the maximum booking durations are deactivated.
 */
export const maximumBookingDurationDeactivate = createAsyncAction<
	IMaximumBookingDuration[],
	IMaximumBookingDurationDeactivateParams
>('maximumBookingDuration/deactivate', maximumBookingDurationDeactivateService);

/**
 * Clears the maximum booking duration state.
 */
export const maximumBookingDurationClear = createSyncAction('maximumBookingDuration/clear');
