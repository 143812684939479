import { createSlice } from '@reduxjs/toolkit';

import {
	choosableServiceConfigurationClear,
	choosableServiceConfigurationDeactivate,
	choosableServiceConfigurationInsert,
	choosableServiceConfigurationModify,
	choosableServiceConfigurationRemoveDeactivated,
	choosableServiceConfigurationSearch,
} from '@crac/core/modules/pricing/partnerConfiguration/choosableServiceConfiguration/state/actions/ChoosableServiceConfigurationActions';
import type { IChoosableServiceConfigurationReducerState } from '@crac/core/modules/pricing/partnerConfiguration/choosableServiceConfiguration/state/stateType/ChoosableServiceConfigurationReducerState';
const initialState: IChoosableServiceConfigurationReducerState = {
	choosableServiceConfigurationInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	choosableServiceConfigurationModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	choosableServiceConfigurationSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	choosableServiceConfigurationDeactivateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	choosableServicesConfigurations: [],
};

const choosableServiceConfigurationSlice = createSlice({
	name: 'ChoosableServiceConfigurationSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// SEARCH
		builder
			.addCase(choosableServiceConfigurationSearch.pending, (state) => {
				return {
					...state,
					choosableServiceConfigurationSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(choosableServiceConfigurationSearch.fulfilled, (state, action) => {
				return {
					...state,
					choosableServiceConfigurationSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					choosableServicesConfigurations: action.payload,
				};
			})
			.addCase(choosableServiceConfigurationSearch.rejected, (state, action) => {
				return {
					...state,
					choosableServiceConfigurationSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(choosableServiceConfigurationInsert.pending, (state) => {
				return {
					...state,
					choosableServiceConfigurationInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(choosableServiceConfigurationInsert.fulfilled, (state, action) => {
				return {
					...state,
					choosableServiceConfigurationInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					choosableServicesConfigurations: action.payload,
				};
			})
			.addCase(choosableServiceConfigurationInsert.rejected, (state, action) => {
				return {
					...state,
					choosableServiceConfigurationInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(choosableServiceConfigurationModify.pending, (state) => {
				return {
					...state,
					choosableServiceConfigurationModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(choosableServiceConfigurationModify.fulfilled, (state, action) => {
				return {
					...state,
					choosableServiceConfigurationModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					choosableServicesConfigurations: [
						...state.choosableServicesConfigurations.filter((item) => item.id !== action.payload.id),
						action.payload,
					],
				};
			})
			.addCase(choosableServiceConfigurationModify.rejected, (state, action) => {
				return {
					...state,
					choosableServiceConfigurationModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// REMOVE DEACTIVATED
		builder.addCase(choosableServiceConfigurationRemoveDeactivated, (state, action) => {
			return {
				...state,
				choosableServicesConfigurations: state.choosableServicesConfigurations.filter(
					({ id }) => id !== action.payload,
				),
			};
		});
		// DEACTIVATED
		builder
			.addCase(choosableServiceConfigurationDeactivate.pending, (state) => {
				return {
					...state,
					choosableServiceConfigurationDeactivateRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(choosableServiceConfigurationDeactivate.fulfilled, (state, action) => {
				return {
					...state,
					choosableServiceConfigurationDeactivateRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					choosableServicesConfigurations: [
						...state.choosableServicesConfigurations.filter(
							({ id }) => !action.payload.map((item) => item.id).includes(id),
						),
						...action.payload,
					],
				};
			})
			.addCase(choosableServiceConfigurationDeactivate.rejected, (state, action) => {
				return {
					...state,
					choosableServiceConfigurationDeactivateRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR
		builder.addCase(choosableServiceConfigurationClear, () => {
			return initialState;
		});
	},
});

export const choosableServiceConfigurationReducer = choosableServiceConfigurationSlice.reducer;
