import type { FC, ReactElement } from 'react';

import type { DragEndEvent } from '@dnd-kit/core';
import {
	DndContext,
	KeyboardSensor,
	MouseSensor,
	TouchSensor,
	closestCenter,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';

interface IDragAndDropContainer {
	children: ReactElement;
	onChangeOrder?: (from: string, to: string) => void;
}

export const DragAndDropContainer: FC<IDragAndDropContainer> = ({ children, onChangeOrder }) => {
	const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

	if (onChangeOrder) {
		const handleDragEnd = (event: DragEndEvent) => {
			const { active, over } = event;
			if (active && over && active.id !== over.id) {
				onChangeOrder(active.id as string, over.id as string);
			}
		};

		return (
			<DndContext
				collisionDetection={closestCenter}
				modifiers={[restrictToHorizontalAxis]}
				onDragEnd={handleDragEnd}
				sensors={sensors}
			>
				{children}
			</DndContext>
		);
	}

	return children;
};
