import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CustomerEndPoints } from './CustomerEndpoints';

export interface ICustomerUnSubscribeToNewsletterParams extends IServiceBaseParams {
	/**
	 * Customer information to send the link to unsubscribed the newsletter
	 */
	hash: string;
}

/**
 * Customer unsubscribe to newsletter
 * @param model ICustomerUnSubscribeToNewsletterParams
 * @returns Promise<ServiceResponse<boolean>>
 */
export const customerUnSubscribeToNewsletterService = (
	model: ICustomerUnSubscribeToNewsletterParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, ICustomerUnSubscribeToNewsletterParams>(
		CustomerEndPoints.UNSUBSCRIBE_TO_NEWSLETTER,
		model,
	);
};
