import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { BookingEndPoints } from './BookingEndpoints';
import type { IBooking } from '../entities/Booking';

export interface IBookingRevertToConfirmedParams extends IServiceBaseParams {
	bookingNumber: string;
	message?: string;
}

export const bookingRevertToConfirmedService = (
	model: IBookingRevertToConfirmedParams,
): Promise<ServiceResponse<IBooking>> =>
	Api.post<IBooking, IBookingRevertToConfirmedParams>(BookingEndPoints.REVERT_TO_CONFIRMED, model);
