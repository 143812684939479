import type { FC } from 'react';

import classNames from 'classnames';

import { Icon } from '@crac/ui/icons/Icon';
import { Tooltip, TooltipContent, TooltipTrigger } from '@crac/ui/tooltip';

interface ICustomLabelWithTooltipProps {
	name: string;
	label: string;
	tooltipText: string;
	showTooltip?: boolean;
	noMargin?: boolean;
	required?: boolean;
}

export const CustomLabelWithTooltip: FC<ICustomLabelWithTooltipProps> = ({
	name,
	label,
	tooltipText,
	showTooltip,
	noMargin,
	required,
}) => (
	<label
		className={classNames('tw-flex tw-items-center tw-gap-1', { 'tw-mb-1': !noMargin, 'tw-font-bold': required })}
		htmlFor={name}
	>
		{label}
		{showTooltip === true && (
			<Tooltip>
				<TooltipTrigger asChild>
					<span>
						<Icon className="tw-mb-1" name="FaInfoCircle" size="sm" />
					</span>
				</TooltipTrigger>
				<TooltipContent>{tooltipText}</TooltipContent>
			</Tooltip>
		)}
	</label>
);
