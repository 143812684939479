import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { BookingEndPoints } from './BookingEndpoints';

export interface IBookingStartContractSmartKeyParams extends IServiceBaseParams {
	bookingNumber: string;
}

export const bookingStartContractWithSmartKeyService = (
	params: IBookingStartContractSmartKeyParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IBookingStartContractSmartKeyParams>(BookingEndPoints.START_CONTRAT_SMARTKEY, params);
};
