import type { IRentRateTypeRelation } from '@crac/core/modules/pricing/rentRateTypeRelation/entities/RentRateTypeRelation';
import { RentRateTypeRelationEndpoints } from '@crac/core/modules/pricing/rentRateTypeRelation/services/RentRateTypeRelationEndpoints';
import type { IRentRateTypeRelationSaveParams } from '@crac/core/modules/pricing/rentRateTypeRelation/services/RentRateTypeRelationInsertService';
import { Api } from '@crac/core/modules/shared/api';

/**
 * Modify rent rate relation
 * @param model IRentRateTypeRelationSaveParams
 * @returns IRentRateTypeRelation[]
 */
export const rentRateTypeRelationModifyService = (model: IRentRateTypeRelationSaveParams) =>
	Api.post<IRentRateTypeRelation[], IRentRateTypeRelationSaveParams>(RentRateTypeRelationEndpoints.MODIFY, model);
