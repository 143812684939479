import type { FC } from 'react';

import type { ISupplier } from '@crac/core/modules/supplier/entities/Supplier';
import type { ISupplierLocation } from '@crac/core/modules/supplier/location/entities/SupplierLocation';
import type { ISupplierLocationInsertParams } from '@crac/core/modules/supplier/location/services/SupplierLocationInsertService';

import { SupplierFormAddressFields } from '~/features/shared/components/supplier/form/components/SupplierFormAddressFields';
import { SupplierFormContactFields } from '~/features/shared/components/supplier/form/components/SupplierFormContactFields';

import { LocationFormBasicFields } from './LocationFormBasicFields';
import { LocationFormWrapper } from './LocationFormWrapper';
import { getInitialValues } from '../../utils/helpers';

interface ILocationFormProps {
	onSave: (values: ISupplierLocationInsertParams) => void;
	onCancel: () => void;
	onCopy?: () => void;
	location?: ISupplierLocation;
	supplier?: ISupplier;
	isTab?: boolean;
}

export const LocationForm: FC<ILocationFormProps> = ({ onCancel, onSave, onCopy, location, supplier, isTab }) => {
	const initialValues = getInitialValues(location, supplier);
	return (
		<LocationFormWrapper
			initialValues={initialValues}
			isTab={isTab}
			onCancel={onCancel}
			onCopy={onCopy}
			onSave={onSave}
			title={location ? 'Modify location' : 'New location'}
		>
			<>
				<LocationFormBasicFields />
				<SupplierFormAddressFields initialValues={initialValues} />
				<SupplierFormContactFields initialValues={initialValues} isLocation />
			</>
		</LocationFormWrapper>
	);
};
