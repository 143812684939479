import type { IVehicleGroupIncludedConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/vehicleGroupIncludedConfiguration/entities/VehicleGroupIncludedConfiguration';
import { VehicleGroupIncludedConfigurationEndpoints } from '@crac/core/modules/pricing/partnerConfiguration/vehicleGroupIncludedConfiguration/services/VehicleGroupIncludedConfigurationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IVehicleGroupIncludedConfigurationDeactivateParams extends IServiceBaseParams {
	ids: number[];
}

/**
 * Deactivate vehicleGroupIncludedConfiguration
 */
export const vehicleGroupIncludedConfigurationDeactivateService = (
	model: IVehicleGroupIncludedConfigurationDeactivateParams,
): Promise<ServiceResponse<IVehicleGroupIncludedConfiguration[]>> => {
	return Api.post<IVehicleGroupIncludedConfiguration[], IVehicleGroupIncludedConfigurationDeactivateParams>(
		VehicleGroupIncludedConfigurationEndpoints.DEACTIVATE,
		model,
	);
};
