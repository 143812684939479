import type { FC } from 'react';
import { useMemo, useState } from 'react';

import { DropdownItemAction } from './DropdownItemAction';
import { RenderIcon } from './RenderIcon';
import { Dropdown, DropdownMenu, DropdownToggle } from '../../../../external/reactstrap/dropdown';
import type { IActionProps } from '../IList';
import { ActionContainer } from '../styled';

type IDropdownActionProps = IActionProps;

export const DropdownAction: FC<IDropdownActionProps> = ({ action, item }) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = (): void => {
		setIsOpen(!isOpen);
	};

	const isDisabled = useMemo(() => {
		if (typeof action.disabled === 'function') {
			return action.disabled(item);
		}

		return action.disabled;
	}, [action, item]);

	const noItemsShowing = useMemo(
		() => action.items && action.items.every(({ showIf }) => showIf && !showIf(item)),
		[action, item],
	);

	if (
		(action.showIf && !action.showIf(item)) ||
		!action.items ||
		(action.items && action.items.length === 0) ||
		noItemsShowing
	) {
		return null;
	}

	return (
		<ActionContainer>
			<Dropdown direction={action.direction || 'start'} disabled={isDisabled} isOpen={isOpen} toggle={toggle}>
				<DropdownToggle color={action.color || 'secondary'} size="sm">
					<RenderIcon icon={action?.icon} style={{ marginRight: action?.text ? '0.25rem' : '0' }} />
					{action.text ? <span className="d-none d-sm-inline-block">{action.text}</span> : null}
				</DropdownToggle>
				<DropdownMenu container="body">
					{action.items.map((obj) => (
						<DropdownItemAction item={item} {...obj} key={obj.key} />
					))}
				</DropdownMenu>
			</Dropdown>
		</ActionContainer>
	);
};
