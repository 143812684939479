import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { PaymentEndPoints } from './PaymentEntpoints';
import type { IPaymentLineUpdateChargeBackParams } from './PaymentLineUpdateChargeBackService';
import type { IPaymentLine } from '../entities/PaymentLine';

export interface IPaymentLineDeleteChargeBackParams extends IServiceBaseParams {
	bookingNumber: string;
	number: number;
}

export const paymentLineDeleteChargeBackService = (
	model: IPaymentLineDeleteChargeBackParams,
): Promise<ServiceResponse<IPaymentLine>> => {
	return Api.post<IPaymentLine, IPaymentLineUpdateChargeBackParams>(PaymentEndPoints.DELETE_CHARGE_BACK, model);
};
