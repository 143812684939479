/* eslint-disable react/jsx-handler-names */
import type { FC } from 'react';
import React from 'react';

import type { IActionStatus } from '../../../../types/IActions';
import { SpinnerNumber } from '../../spinnerNumber';
import type { IActionProps } from '../IList';
import { ActionContainer } from '../styled';

export const SpinnerAction: FC<IActionProps> = ({ action, item }) => {
	if (!action.status || !action.status.add || !action.status.remove) {
		return null;
	}

	const { status, min, max, value } = action;
	const actions = status as IActionStatus;

	let minValue = 0;
	let maxValue = 0;

	if (typeof max === 'string') {
		maxValue = item[max as string];
	}

	if (typeof min === 'string') {
		minValue = item[min];
	}

	if (typeof min === 'number') {
		minValue = min;
	}

	if (typeof max === 'number') {
		maxValue = max;
	}

	const handleOnchange = (newValue: number, currentAction: 'plus' | 'minus'): void => {
		if (actions.remove && currentAction === 'minus') {
			actions.remove.callback(item);
		}

		if (actions.add && currentAction === 'plus') {
			actions.add.callback(item);
		}
	};

	return (
		<ActionContainer>
			<SpinnerNumber
				activeState
				max={maxValue}
				min={minValue}
				onChange={handleOnchange}
				size={action.size || 'sm'}
				value={item[value as string] || 0}
			/>
		</ActionContainer>
	);
};
