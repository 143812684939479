import type { IBranchSchedule } from '@crac/core/modules/pricing/branchSchedule/entities/BranchSchedule';
import { BranchScheduleEndpoints } from '@crac/core/modules/pricing/branchSchedule/services/BranchScheduleEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IBranchScheduleInsertParams extends IServiceBaseParams {
	branchSchedules: IBranchSchedule[];
}

export const branchScheduleInsertService = (
	model: IBranchScheduleInsertParams,
): Promise<ServiceResponse<IBranchSchedule[]>> => {
	return Api.post<IBranchSchedule[], IBranchScheduleInsertParams>(BranchScheduleEndpoints.INSERT, model);
};
