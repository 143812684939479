import { AvailabilityEndpoints } from './AvailabilityEndpoints';
import { getMappedAndFilterVehicleGroupsAvailability } from '../../../../business/booking/BookingAvailability';
import { canCheckNearbyBranchesAvailability } from '../../../../business/branch/BranchWithAvailability';
import { branchServicesGetNearbyBranchesWithAvailability } from '../../../../data/services/BranchService';
import type { IBranch } from '../../../../models/entities/Branch';
import { VehicleCategory } from '../../../../models/types/VehicleCategory';
import { Api } from '../../../shared/api';
import type { CreationMethod } from '../../../shared/types/CreationMethod';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IAvailabilityAndPrice } from '../entities/AvailabilityAndPrice';

export interface IBookingGetAvailabilityParams extends Omit<IServiceBaseParams, 'creationMethod'> {
	agencyCode: string;
	bookingNumber?: string;
	coupon?: string;
	creationMethod: CreationMethod;
	customerBirthDate?: string | Date;
	dropOffBranchCode: string;
	dropOffDateTime: string | Date;
	pickUpBranchCode: string;
	pickUpDateTime: string | Date;
	quoteDateTime: string | Date;
	vehicleGroup?: string;
	vendorCode?: string;
	/**
	 * If true, it will check nearby branches availability
	 * - If pick up branch has nearby branches and there is no vehicle groups, it will check nearby branches availability
	 */
	checkNearbyBranches?: boolean;
	/**
	 * If true, it will disable the filter of the availability groups
	 */
	disableFilter?: boolean;
	excludeSchedule?: boolean;
	/**
	 * Branch to check nearby branches
	 *
	 * - It´s required if checkNearbyBranches is true
	 */
	pickUpBranch?: IBranch;

	vehicleCategory?: VehicleCategory | null;
}

/**
 * Get availability and check nearby branches
 * @params `IBookingGetAvailabilityParams`
 * @returns {Promise<IAvailabilityAndPrice>} `IAvailabilityAndPrice`
 */
export const bookingGetAvailabilityService = async (
	serviceParams: IBookingGetAvailabilityParams,
): Promise<ServiceResponse<IAvailabilityAndPrice | null>> => {
	let nearbyBranches: string[] = [];
	const { pickUpBranch, vehicleCategory, ...params } = serviceParams;

	const availabilityResponse = await Api.get<IAvailabilityAndPrice, IBookingGetAvailabilityParams>(
		AvailabilityEndpoints.GET_AVAILABILITY,
		params,
	);
	// RETURN ERROR
	if (!availabilityResponse.ok || !availabilityResponse.data) {
		return availabilityResponse;
	}

	// CHECK NEARBY BRANCHES
	const checkNearbyBranches = canCheckNearbyBranchesAvailability({
		vehicleGroupsAvailability: availabilityResponse.data.vehicleGroupsAvailability,
		pickUpBranch,
	});

	// Request nearby branches
	if (checkNearbyBranches) {
		const nearbyBranchesResponse = await branchServicesGetNearbyBranchesWithAvailability(params);

		nearbyBranches = nearbyBranchesResponse.data || [];
	}

	// FILTER AND MAP VEHICLE GROUPS
	let vehicleGroupsAvailability = getMappedAndFilterVehicleGroupsAvailability(
		availabilityResponse.data.vehicleGroupsAvailability,
		params.creationMethod,
		params.disableFilter,
	);

	/*
	 * FILTER VEHICLE CATEGORY.
	 * Hasta que se añada vehicleCategory en la disponibilidad, solo filtramos connectCar
	 */
	if (vehicleCategory === VehicleCategory.ConnectCar) {
		vehicleGroupsAvailability = vehicleGroupsAvailability.map((group) => ({
			...group,
			choosable: group.connectCar,
		}));
	}

	return new ServiceResponse<IAvailabilityAndPrice>(
		true,
		{
			...availabilityResponse.data,
			vehicleGroupsAvailability,
			nearbyBranches,
		},
		[],
	);
};
