import type { IAvailabilityGroup } from '@crac/core/modules/booking/availability/entities/AvailabilityGroup';
import { AvailabilityConfigurationEndpoints } from '@crac/core/modules/pricing/availabilityConfiguration/services/AvailabilityConfigurationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IAvailabilityConfigurationDeleteParams extends IServiceBaseParams {
	provider: string;
	vehicleGroupRequested: string;
	vehicleGroupDelivered: string;
}

/**
 * Delete group availability
 */
export const availabilityConfigDeleteService = (
	model: IAvailabilityConfigurationDeleteParams,
): Promise<ServiceResponse<IAvailabilityGroup>> => {
	return Api.post<IAvailabilityGroup, IAvailabilityConfigurationDeleteParams>(
		AvailabilityConfigurationEndpoints.DELETE,
		model,
	);
};
