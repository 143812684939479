import type { IRentRateTypeRelation } from '@crac/core/modules/pricing/rentRateTypeRelation/entities/RentRateTypeRelation';
import { RentRateTypeRelationEndpoints } from '@crac/core/modules/pricing/rentRateTypeRelation/services/RentRateTypeRelationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

interface IRentRateTypeRelationSave extends Omit<IRentRateTypeRelation, 'id'> {
	id?: number;
}

export interface IRentRateTypeRelationSaveParams extends IServiceBaseParams {
	relations: IRentRateTypeRelationSave[];
}

/**
 * Insert rent rate relation
 * @param model IRentRateTypeRelationSaveParams
 * @returns IRentRateTypeRelation[]
 */
export const rentRateTypeRelationInsertService = (model: IRentRateTypeRelationSaveParams) =>
	Api.post<IRentRateTypeRelation[], IRentRateTypeRelationSaveParams>(RentRateTypeRelationEndpoints.INSERT, model);
