export enum EmailEndpoints {
	/**
	 * Email send document
	 * @host `/email/sendDocument/`
	 */
	SEND_DOCUMENT = '/email/sendDocument/',

	/**
	 * Email send feedback
	 * @host `/email/sendFeedback/`
	 */
	SEND_FEEDBACK = '/email/sendFeedback',

	/**
	 * Email send booking to agency
	 * @host `/email/sendBookingAgency/`
	 */
	SEND_BOOKING_AGENCY = '/email/sendBookingAgency',

	/**
	 * Email send coupon by customer code
	 * @host `/email/sendCouponByCustomerCode/`
	 */
	SEND_COUPON_BY_CUSTOMER_CODE = '/email/sendCouponByCustomerCode',

	/**
	 * Email send contact form buy car
	 * @host `/email/sendContactFormBuyCar/`
	 */
	SEND_CONTACT_FORM_BUY_CAR = '/email/sendContactFormBuyCar',
	/**
	 * Email send contact form business
	 *  @host `/email/sendContactFormBusiness/`
	 */
	SEND_CONTACT_FORM_BUSINESS = '/email/sendContactFormBusiness',
	/**
	 * Email send fitur form
	 * @host `/email/sendFiturForm`
	 */
	SEND_FITUR_FORM = '/email/sendFiturForm',
	/**
	 * Email send contact form franchise
	 *  @host `/email/sendContactFormFranchise/`
	 */
	SEND_CONTACT_FORM_FRANCHISE = '/email/sendContactFormFranchise',
	/**
	 * Email send cv
	 * @host `/email/sendCV`
	 */
	SEND_CV = '/email/sendCV',
}
