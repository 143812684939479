import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { customerModifyService } from './CustomerModifyService';
import type { ICustomerSaveParams } from './CustomerSaveService';
import { commentServiceAddToCustomer } from '../../../data/services/CommentService';
import { CustomerValidationType } from '../../../models/types/CustomerValidationType';
import type { ICustomer } from '../entities/Customer';

export interface ICustomerModifyDuplicateTaxIdParams extends IServiceBaseParams {
	bookingCustomer: ICustomerSaveParams;
	selectedCustomer: ICustomerSaveParams;
}

export const customerModifyDuplicateTaxIdService = async (
	params: ICustomerModifyDuplicateTaxIdParams,
): Promise<ServiceResponse<ICustomer>> => {
	const { bookingCustomer, selectedCustomer } = params;

	const bookingCustomerResponse = await customerModifyService({
		...bookingCustomer,
		gender: bookingCustomer.gender || 0,
		driverLicenseExpeditionDate: undefined,
		driverLicenseExpirationDate: undefined,
		driverLicenseNumber: undefined,
		email: undefined,
		taxIdentificationNumber: undefined,
		validationType: CustomerValidationType.BASIC,
	});
	const selectedCustomerResponse = await customerModifyService({
		...selectedCustomer,
		email: bookingCustomer.email,
		gender: selectedCustomer.gender || 0,
		validationType: CustomerValidationType.BASIC,
	});

	// CHECK ERRORS
	if (!bookingCustomerResponse.ok) {
		return new ServiceResponse<ICustomer>(false, null, bookingCustomerResponse.errors);
	}

	if (!selectedCustomerResponse.ok) {
		return new ServiceResponse<ICustomer>(false, null, selectedCustomerResponse.errors);
	}

	if (bookingCustomerResponse.data && selectedCustomerResponse.data) {
		// ADD COMMENT
		const commentsTasks = [];
		commentsTasks.push(
			commentServiceAddToCustomer({
				code: bookingCustomer.code as string,
				message: `'DATA_REPLACE_EMAIL/${JSON.stringify({
					after: `{email: empty}`,
					before: `{email: ${bookingCustomer.email}}`,
				})}'`,
			}),
		);
		commentsTasks.push(
			commentServiceAddToCustomer({
				code: selectedCustomer.code as string,
				message: `'DATA_REPLACE_EMAIL/${JSON.stringify({
					after: `{email: ${bookingCustomer.email}}`,
					before: `{email: ${selectedCustomer.email}}`,
				})}'`,
			}),
		);

		await Promise.all(commentsTasks);
	}

	return new ServiceResponse<ICustomer>(true, selectedCustomerResponse.data, []);
};
