import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CustomerEndPoints } from './CustomerEndpoints';

export interface ICustomerSocialNetworkUnLinkParams extends IServiceBaseParams {
	customerCode: string;
	name: string;
}

/**
 * Unlink customer social network
 * @param model ICustomerSocialNetworkUnLinkParams
 * @returns Promise<ServiceResponse<boolean>>
 */
export const customerSocialNetworkUnLinkService = (
	model: ICustomerSocialNetworkUnLinkParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, ICustomerSocialNetworkUnLinkParams>(CustomerEndPoints.SOCIAL_NETWORK_UNLINK, model);
};
