import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CustomerEndPoints } from './CustomerEndpoints';

export interface ICustomerSubscribeToNewsletterParams extends IServiceBaseParams {
	/**
	 * Valid Email (Required)
	 */
	email: string;
	/**
	 * First surname (Required)
	 */
	firstSurname: string;

	/**
	 * Accept privacy policy (Required)
	 */
	acceptPrivacyPolicy: boolean;
	/**
	 * I agree to receive commercial communications (required)
	 */
	allowCommercialCommunications: boolean;
	/**
	 * I agree to receive personalized offers and updates. (Optional)
	 */
	allowCommercialSegmentation?: boolean;
	/**
	 * Name (Required)
	 */
	name: string;
	/**
	 * Second surname (Optional)
	 */
	secondSurname: string;
	/**
	 * Prefers language  (Optional)
	 */
	language?: string;
}

/**
 * Customer subscribe to newsletter
 * @param model ICustomerSubscribeToNewsletterParams
 * @returns Promise<ServiceResponse<boolean>>
 */
export const customerSubscribeToNewsletterService = (
	model: ICustomerSubscribeToNewsletterParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, ICustomerSubscribeToNewsletterParams>(CustomerEndPoints.SUBSCRIBE_TO_NEWSLETTER, model);
};
