import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { BookingEndPoints } from './BookingEndpoints';
import type { IBooking } from '../entities/Booking';

export interface IBookingGetByBookingNumberParams extends IServiceBaseParams {
	bookingNumber: string;
}

/**
 * Get booking by number
 *  {string} bookingNumber Booking number
 * @return {Promise<BookingPropType>} `BookingPropType`
 */
export const bookingGetByBookingNumberService = (
	model: IBookingGetByBookingNumberParams,
): Promise<ServiceResponse<IBooking>> => {
	return Api.get<IBooking, IBookingGetByBookingNumberParams>(BookingEndPoints.GET_BY_NUMBER, model);
};
