import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CustomerEndPoints } from './CustomerEndpoints';

export interface ICustomerSendResetPasswordParams extends IServiceBaseParams {
	email: string;
}

/**
 * Sent email reset password customer
 *  {string} customerCode Customer code
 * @returns {Promise} `CustomerPropType` Update customer
 */
export const customerSendResetPasswordService = (
	model: ICustomerSendResetPasswordParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.get<boolean, ICustomerSendResetPasswordParams>(CustomerEndPoints.SEND_RESET_PASSWORD, model);
};
