import type { PaymentChannelType, PaymentMethodType, PaymentOperationType } from '@crac/core/models/types/PaymentType';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { PaymentEndPoints } from './PaymentEntpoints';
import type { IPaymentLine } from '../entities/PaymentLine';

export interface IPaymentLineAddParams extends IServiceBaseParams {
	computerName?: string;
	ip?: string;
	amount: number;
	bookingNumber: string;
	channel: PaymentChannelType;
	method: PaymentMethodType;
	operation: PaymentOperationType;
}

/**
 * Add payment line in booking
 *  {string} bookingNumber booking number
 *  {PaymentLine} paymentLine PaymenLine Object
 * @returns {Promise<void>} `void`
 */
export const paymentLineAddService = (model: IPaymentLineAddParams): Promise<ServiceResponse<IPaymentLine>> => {
	return Api.post<IPaymentLine, IPaymentLineAddParams>(PaymentEndPoints.PAYMENT_LINE_ADD, model);
};
