import type { FC } from 'react';

import type { SortItemType } from '.';

interface ISortListItem {
	sort: SortItemType;
	onClearBy: (id: string) => void;
	onToggleSort: (id: string) => void;
}

export const SortListItem: FC<ISortListItem> = ({ onClearBy, onToggleSort, sort }) => {
	return (
		<button className="button-badge" type="button">
			<small onClick={() => onToggleSort(sort.id)}>
				{sort.label} <small className="fw-bold text-muted">({sort.desc ? 'desc' : 'asc'})</small>
			</small>{' '}
			<small aria-label="close-sort" className="close" onClick={() => onClearBy(sort.id)}>
				<i className="fa fa-fw fa-close" />
			</small>
		</button>
	);
};
