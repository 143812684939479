import type { FC } from 'react';

import { ToolBarButtons } from '@crac/components/intranet/shared/toolBar/ToolBarButtons';
import { Form } from '@crac/form/form';
import { FormState } from '@crac/form/form/FormState';
import { Button } from '@crac/ui/button';
import { Card, CardContent, CardFooter, CardHeader } from '@crac/ui/card';
import { Icon } from '@crac/ui/icons/Icon';

import type { ISupplierFormContentProps } from '../types';

interface ISupplierFormContentWrapperProps extends ISupplierFormContentProps {
	children: React.ReactNode;
}

export const SupplierFormContentWrapper: FC<ISupplierFormContentWrapperProps> = ({
	children,
	initialValues,
	onSubmit,
	title,
	onCancel,
	hasLocationTab,
}) => {
	const isEdit = initialValues !== undefined;

	if (hasLocationTab) {
		return (
			<Form aria-label="supplier-form" initialValues={initialValues} mode="onChange" onSubmit={onSubmit}>
				{/* Se quitado la prop isValid para que no se revaliden los campos en cada onChange */}
				{() => (
					<>
						<div className="tw-p-4">{children}</div>
						<ToolBarButtons className="tw-flex tw-justify-between">
							<Button color="link" onClick={onCancel}>
								<Icon className="tw-mr-1" name="FaClose" /> Cancel
							</Button>
							<FormState>
								{({ isValid, isDirty }) => (
									<Button color="primary" isDisabled={!isValid || !isDirty} type="submit">
										<Icon className="tw-mr-1" name="FaSave" /> Save
									</Button>
								)}
							</FormState>
						</ToolBarButtons>
					</>
				)}
			</Form>
		);
	}

	return (
		<Form aria-label="supplier-form" initialValues={initialValues} mode="onChange" onSubmit={onSubmit}>
			{/* Se quitado la prop isValid para que no se revaliden los campos en cada onChange */}
			{() => (
				<Card>
					<CardHeader>
						<h4 className="tw-flex tw-items-center tw-mb-0">
							<Icon className="tw-mr-1" name={isEdit ? 'FaPencil' : 'FaPlus'} size="lg" />
							{title}
						</h4>
					</CardHeader>
					<CardContent>
						{/* <pre>{JSON.stringify(watch(), null, 2)}</pre> */}
						{children}
					</CardContent>
					<CardFooter className="tw-flex tw-justify-between">
						<Button color="link" onClick={onCancel}>
							<Icon className="tw-mr-1" name="FaClose" /> Cancel
						</Button>
						<FormState>
							{({ isValid, isDirty }) => (
								<Button color="primary" isDisabled={!isValid || !isDirty} type="submit">
									<Icon className="tw-mr-1" name="FaSave" /> Save
								</Button>
							)}
						</FormState>
					</CardFooter>
				</Card>
			)}
		</Form>
	);
};
