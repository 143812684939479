import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CommentEndPoints } from './CommentsEndpoints';
import type { IComment } from '../entities/Comment';

export interface ICommentRemoveFromCompanyParams extends IServiceBaseParams {
	code: string;
	lineNumber: number;
	table?: number;
}

/**
 * Remove comment from company
 *  {string} lineNumber Line number
 *  {string} code Company code
 */
export const commentRemoveFromCompanyService = (
	model: ICommentRemoveFromCompanyParams,
): Promise<ServiceResponse<IComment>> => {
	return Api.post<IComment, ICommentRemoveFromCompanyParams>(CommentEndPoints.REMOVE_FROM_COMPANY, model);
};
