export enum IncludedServiceConfigurationEndpoints {
	/**
	 * Deactivate service invoiceable configuration
	 * @host `/includedServiceConfiguration/delete`
	 */
	DEACTIVATE = '/includedServiceConfiguration/deactivate',
	/**
	 * Get service included configuration
	 * @host `/includedServiceConfiguration/get`
	 */
	INSERT = '/includedServiceConfiguration/insert',
	/**
	 * Modify service included configuration
	 * @host `/includedServiceConfiguration/modify`
	 */
	MODIFY = '/includedServiceConfiguration/modify',
	/**
	 * Search service included configuration
	 * @host `/includedServiceConfiguration/search`
	 */
	SEARCH = '/includedServiceConfiguration/search',
}
