import type { IRentRateValidation } from '@crac/core/modules/pricing/rentRateValidation/entities/RentRateValidation';
import { RentRateValidationEndpoints } from '@crac/core/modules/pricing/rentRateValidation/services/RentRateValidationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

interface IRentRateValidationSave extends Omit<IRentRateValidation, 'id'> {
	id?: number;
}

export interface IRentRateValidationSaveParams extends IServiceBaseParams {
	validations: IRentRateValidationSave[];
}

/**
 * Insert rent rate validations
 * @param model IRentRateValidationInsertParams
 * @returns IRentRateValidation
 */
export const rentRateValidationInsertService = (model: IRentRateValidationSaveParams) =>
	Api.post<IRentRateValidation[], IRentRateValidationSaveParams>(RentRateValidationEndpoints.INSERT, model);
