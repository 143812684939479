import { Api } from '@crac/core/modules/shared/api';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CustomerEndPoints } from './CustomerEndpoints';
import type { ICustomerSaveParams } from './CustomerSaveService';
import type { ICustomer } from '../entities/Customer';

/**
 * Modify customer
 *  {Customer} customer Customer object
 * @returns {Promise} `CustomerPropType` Update customer
 */
export const customerModifyService = (model: ICustomerSaveParams): Promise<ServiceResponse<ICustomer>> => {
	return Api.post<ICustomer, ICustomerSaveParams>(CustomerEndPoints.MODIFY, model);
};
