import { postCodeServiceGetByCountryAndText } from '@crac/core/data/services/PostCodeService';
import type { ISupplier } from '@crac/core/modules/supplier/entities/Supplier';
import type { ISupplierLocation } from '@crac/core/modules/supplier/location/entities/SupplierLocation';
import type { ISupplierLocationInsertParams } from '@crac/core/modules/supplier/location/services/SupplierLocationInsertService';

export const loadPostCodeOptions = (countryCode: string) => async (text: string) => {
	if (countryCode && text.length > 2) {
		const response = await postCodeServiceGetByCountryAndText({ countryCode, text });
		if (response.ok && response.data) {
			return response.data.map(({ code }) => ({ label: code, value: code }));
		}
	}
	return [];
};

export const loadDefaultPostCodeOptions = (countryCode: string) => async (postCode: string) => {
	if (countryCode && postCode) {
		const response = await postCodeServiceGetByCountryAndText({ countryCode, text: postCode });
		if (response.ok && response.data) {
			return response.data.map(({ code }) => ({ label: code, value: code }));
		}
	}
	return [];
};

export const getInitialValues = (
	location?: ISupplierLocation,
	supplier?: ISupplier,
): ISupplierLocationInsertParams | undefined => {
	if (location) {
		return location;
	}

	if (supplier) {
		return {
			company: supplier.provider,
			supplier: supplier.code,
			name: supplier.name,
			address: supplier.address || '',
			city: supplier.city || '',
			country: supplier.country || '',
			county: supplier.county || '',
			email: supplier.email || '',
			nif: supplier.nif,
			postCode: supplier.postCode || '',
			contact: supplier.contact,
			phone: supplier.phone,
		};
	}

	return undefined;
};
