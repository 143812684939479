'use client';
import type { FC } from 'react';
import React from 'react';

import { Tooltip as RSTooltip } from 'reactstrap';
import type { TooltipProps as RSTooltipProps } from 'reactstrap/types/lib/Tooltip';

export type TooltipProps = RSTooltipProps;

export const Tooltip: FC<TooltipProps> = ({ children, ...props }): JSX.Element => {
	return <RSTooltip {...props}>{children}</RSTooltip>;
};
