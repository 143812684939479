import type { IDiscountCoupon } from '@crac/core/modules/pricing/discountCoupon/entities/DiscountCoupon';
import { DiscountCouponEndpoints } from '@crac/core/modules/pricing/discountCoupon/services/DiscountCouponEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IDiscountCouponDeactivateParams extends IServiceBaseParams {
	ids: number[];
}

/**
 * Deactivate discount coupons
 * @param ids number[]
 * @returns IDiscountCoupon[]
 */
export const discountCouponDeactivateService = (
	model: IDiscountCouponDeactivateParams,
): Promise<ServiceResponse<IDiscountCoupon[]>> => {
	return Api.post<IDiscountCoupon[], IDiscountCouponDeactivateParams>(DiscountCouponEndpoints.DEACTIVATE, model);
};
