import type { IRentRateTypeRelation } from '@crac/core/modules/pricing/rentRateTypeRelation/entities/RentRateTypeRelation';
import type { IRentRateTypeRelationDeleteParams } from '@crac/core/modules/pricing/rentRateTypeRelation/services/RentRateTypeRelationDeleteService';
import { rentRateTypeRelationDeleteService } from '@crac/core/modules/pricing/rentRateTypeRelation/services/RentRateTypeRelationDeleteService';
import type { IRentRateTypeRelationSaveParams } from '@crac/core/modules/pricing/rentRateTypeRelation/services/RentRateTypeRelationInsertService';
import { rentRateTypeRelationInsertService } from '@crac/core/modules/pricing/rentRateTypeRelation/services/RentRateTypeRelationInsertService';
import { rentRateTypeRelationModifyService } from '@crac/core/modules/pricing/rentRateTypeRelation/services/RentRateTypeRelationModifyService';
import type { IRentRateTypeRelationSearchParams } from '@crac/core/modules/pricing/rentRateTypeRelation/services/RentRateTypeRelationSearchService';
import { rentRateTypeRelationSearchService } from '@crac/core/modules/pricing/rentRateTypeRelation/services/RentRateTypeRelationSearchService';
import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

/**
 * Searches for rent rate type relations based on specified parameters.
 *
 * @param {IRentRateTypeRelation[]} payload - The rent rate type relations to be retrieved.
 * @param {IRentRateTypeRelationSearchParams} params - Parameters for the search of rent rate type relations.
 * @returns {Promise<void>} - A promise that resolves to an array of rent rate type relations.
 */
export const rentRateTypeRelationSearch = createAsyncAction<IRentRateTypeRelation[], IRentRateTypeRelationSearchParams>(
	'rentRateTypeRelation/search',
	rentRateTypeRelationSearchService,
);

/**
 * Inserts new rent rate type relations.
 *
 * @param {IRentRateTypeRelation[]} payload - The rent rate type relations to be inserted.
 * @param {IRentRateTypeRelationSaveParams} params - Parameters for the insertion of rent rate type relations.
 * @returns {Promise<void>} - A promise that resolves after the rent rate type relations are inserted.
 */
export const rentRateTypeRelationInsert = createAsyncAction<IRentRateTypeRelation[], IRentRateTypeRelationSaveParams>(
	'rentRateTypeRelation/insert',
	rentRateTypeRelationInsertService,
);

/**
 * Modifies existing rent rate type relations.
 *
 * @param {IRentRateTypeRelation[]} payload - The rent rate type relations to be modified.
 * @param {IRentRateTypeRelationSaveParams} params - Parameters for the modification of rent rate type relations.
 * @returns {Promise<void>} - A promise that resolves after the rent rate type relations are modified.
 */
export const rentRateTypeRelationModify = createAsyncAction<IRentRateTypeRelation[], IRentRateTypeRelationSaveParams>(
	'rentRateTypeRelation/modify',
	rentRateTypeRelationModifyService,
);

/**
 * Deletes a specific rent rate type relation.
 *
 * @param {IRentRateTypeRelation} payload - The rent rate type relation to be deleted.
 * @param {IRentRateTypeRelationDeleteParams} params - Parameters for the deletion of the rent rate type relation.
 * @returns {Promise<void>} - A promise that resolves after the rent rate type relation is deleted.
 */
export const rentRateTypeRelationDelete = createAsyncAction<IRentRateTypeRelation, IRentRateTypeRelationDeleteParams>(
	'rentRateTypeRelation/delete',
	rentRateTypeRelationDeleteService,
);

/**
 * Resets the rent rate type relation state.
 */
export const rentRateTypeRelationReset = createSyncAction('rentRateTypeRelation/reset');
