import * as React from 'react';

import * as TabsPrimitive from '@radix-ui/react-tabs';

import { cn } from '../../lib/utils/tailwindClass';

export const TabsContent = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
	<TabsPrimitive.Content className={cn('tabs-content', className)} ref={ref} {...props} />
));

TabsContent.displayName = TabsPrimitive.Content.displayName;
