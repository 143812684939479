import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { CustomerEndPoints } from './CustomerEndpoints';
import type { ICustomerLoginResponse } from '../../../models/serviceResponse/CustomerResponse';

export interface ICustomerLoginWithSocialNetworkParams extends IServiceBaseParams {
	socialNetworkId: string;
	name: string;
	email: string;
}

/**
 * Customer login with social network
 * @param model ICustomerLoginWithSocialNetworkParams
 * @returns Promise<ServiceResponse<ICustomerLoginResponse>>
 */
export const customerLoginWithSocialNetworkService = (model: ICustomerLoginWithSocialNetworkParams) => {
	return Api.post<ICustomerLoginResponse, ICustomerLoginWithSocialNetworkParams>(
		CustomerEndPoints.SOCIAL_NETWORK_LOGIN,
		model,
	);
};
