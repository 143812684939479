import type { IGroupOrderAndUpgrade } from '@crac/core/modules/pricing/vehicleGroupOrderAndUpgrade/entities/VehicleGroupOrderAndUpgrade';
import { VehicleGroupOrderAndUpgradeEndpoints } from '@crac/core/modules/pricing/vehicleGroupOrderAndUpgrade/services/VehicleGroupOrderAndUpgradeEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IVehicleGroupOrderAndUpgradeDeleteParams extends IServiceBaseParams {
	vehicleGroupRequested: string;
	vehicleGroupDelivered: string;
}

/**
 * Delete Group upgrade
 */
export const vehicleGroupOrderAndUpgradeDeleteService = (
	model: IVehicleGroupOrderAndUpgradeDeleteParams,
): Promise<ServiceResponse<IGroupOrderAndUpgrade>> => {
	return Api.post<IGroupOrderAndUpgrade, IVehicleGroupOrderAndUpgradeDeleteParams>(
		VehicleGroupOrderAndUpgradeEndpoints.DELETE,
		model,
	);
};
