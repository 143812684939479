import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CustomerEndPoints } from './CustomerEndpoints';

export interface ICustomerCanResetPasswordParams extends IServiceBaseParams {
	/**
	 * Hash received by url parameter
	 */
	hash: string;
}

/**
 * Check if customer can reset password
 * @param model ICustomerCanResetPasswordParams
 * @returns Promise<ServiceResponse<boolean>>
 */
export const customerCanResetPasswordService = (
	model: ICustomerCanResetPasswordParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.get<boolean, ICustomerCanResetPasswordParams>(CustomerEndPoints.CAN_RESET_PASSWORD, model);
};
