import type { IBranchSchedule } from '@crac/core/modules/pricing/branchSchedule/entities/BranchSchedule';
import { BranchScheduleEndpoints } from '@crac/core/modules/pricing/branchSchedule/services/BranchScheduleEndpoints';
import type { FromTo } from '@crac/core/modules/pricing/types/FromToType';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IBranchScheduleSearchParams extends IServiceBaseParams {
	date: FromTo<string | Date>;
	branch: number;
}

export const branchScheduleSearchService = (
	model: IBranchScheduleSearchParams,
): Promise<ServiceResponse<IBranchSchedule[]>> => {
	return Api.get<IBranchSchedule[], IBranchScheduleSearchParams>(BranchScheduleEndpoints.SEARCH, model);
};
