import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CommentEndPoints } from './CommentsEndpoints';
import type { IComment } from '../entities/Comment';

export interface ICommentGetByBookingParams extends IServiceBaseParams {
	bookingNumber: string;
}

export const commentGetByBookingService = (model: ICommentGetByBookingParams): Promise<ServiceResponse<IComment[]>> => {
	return Api.get<IComment[], ICommentGetByBookingParams>(CommentEndPoints.GET_BY_BOOKING, model);
};
