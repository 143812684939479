import type { IServiceRate } from '@crac/core/modules/pricing/serviceRate/entities/ServiceRate';
import type { IServiceRatesInsertParams } from '@crac/core/modules/pricing/serviceRate/services/ServiceRateInsertService';
import { serviceRatesInsertService } from '@crac/core/modules/pricing/serviceRate/services/ServiceRateInsertService';
import type { IServiceRatesSearchParams } from '@crac/core/modules/pricing/serviceRate/services/ServiceRateSearchService';
import { serviceRateSearchService } from '@crac/core/modules/pricing/serviceRate/services/ServiceRateSearchService';
import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

/**
 * Searches for service rates based on specified parameters.
 *
 * @param {IServiceRate[]} payload - The service rates to be retrieved.
 * @param {IServiceRatesSearchParams} params - Parameters for the search of service rates.
 * @returns {Promise<void>} - A promise that resolves to an array of service rates.
 */
export const serviceRateSearch = createAsyncAction<IServiceRate[], IServiceRatesSearchParams>(
	'serviceRate/search',
	serviceRateSearchService,
);

/**
 * Inserts new service rates.
 *
 * @param {IServiceRate[]} payload - The service rates to be inserted.
 * @param {IServiceRatesInsertParams} params - Parameters for the insertion of service rates.
 * @returns {Promise<void>} - A promise that resolves after the service rates are inserted.
 */
export const serviceRatesInsert = createAsyncAction<IServiceRate[], IServiceRatesInsertParams>(
	'serviceRate/insert',
	serviceRatesInsertService,
);

/**
 * Clears the service rate state.
 */
export const serviceRateClear = createSyncAction('serviceRate/clear');
