import { createSlice } from '@reduxjs/toolkit';

import {
	partnerCategoryClear,
	partnerCategoryGetAll,
	partnerCategoryGetByCode,
	partnerCategoryInsert,
	partnerCategoryModify,
	partnerCategorySearch,
} from '@crac/core/modules/pricing/partnerConfiguration/partnerCategory/state/actions/PartnerCategoryActions';
import type { IPartnerCategoryReducerState } from '@crac/core/modules/pricing/partnerConfiguration/partnerCategory/state/stateType/PartnerCategoryReducerState';

const initialState: IPartnerCategoryReducerState = {
	partnerCategorySearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	partnerCategories: [],
	partnerCategory: null,
	partnerCategoryGetAllRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	partnerCategoryGetByCodeRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	partnerCategoryInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	partnerCategoryModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const partnerCategorySlice = createSlice({
	name: 'PartnerCategorySlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET ALL
		builder
			.addCase(partnerCategoryGetAll.pending, (state) => {
				return {
					...state,
					partnerCategoryGetAllRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(partnerCategoryGetAll.fulfilled, (state, action) => {
				return {
					...state,
					partnerCategoryGetAllRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					partnerCategories: action.payload,
				};
			})
			.addCase(partnerCategoryGetAll.rejected, (state, action) => {
				return {
					...state,
					partnerCategoryGetAllRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET BY CODE
		builder
			.addCase(partnerCategoryGetByCode.pending, (state) => {
				return {
					...state,
					partnerCategoryGetByCodeRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(partnerCategoryGetByCode.fulfilled, (state, action) => {
				return {
					...state,
					partnerCategoryGetByCodeRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					partnerCategory: action.payload,
				};
			})
			.addCase(partnerCategoryGetByCode.rejected, (state, action) => {
				return {
					...state,
					partnerCategoryGetByCodeRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// SEARCH
		builder
			.addCase(partnerCategorySearch.pending, (state) => {
				return {
					...state,
					partnerCategorySearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(partnerCategorySearch.fulfilled, (state, action) => {
				return {
					...state,
					partnerCategorySearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					partnerCategories: action.payload,
				};
			})
			.addCase(partnerCategorySearch.rejected, (state, action) => {
				return {
					...state,
					partnerCategorySearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(partnerCategoryModify.pending, (state) => {
				return {
					...state,
					partnerCategoryModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(partnerCategoryModify.fulfilled, (state, action) => {
				return {
					...state,
					partnerCategoryModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					partnerCategories: [
						...state.partnerCategories.filter(
							(partnerCategory) => partnerCategory.code !== action.payload.code,
						),
						action.payload,
					],
				};
			})
			.addCase(partnerCategoryModify.rejected, (state, action) => {
				return {
					...state,
					partnerCategoryModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(partnerCategoryInsert.pending, (state) => {
				return {
					...state,
					partnerCategoryInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(partnerCategoryInsert.fulfilled, (state, action) => {
				return {
					...state,
					partnerCategoryInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					partnerCategories: [...state.partnerCategories, ...action.payload],
				};
			})
			.addCase(partnerCategoryInsert.rejected, (state, action) => {
				return {
					...state,
					partnerCategoryInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR
		builder.addCase(partnerCategoryClear, () => {
			return { ...initialState };
		});
	},
});

export const partnerCategoryReducer = partnerCategorySlice.reducer;
