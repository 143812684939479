import type { IChoosableServiceConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/choosableServiceConfiguration/entities/ChoosableServiceConfiguration';
import { ChoosableServiceConfigurationEndPoints } from '@crac/core/modules/pricing/partnerConfiguration/choosableServiceConfiguration/services/ChoosableServiceConfigurationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

/**
 * Modify a service choosable configuration params
 */
export interface IChoosableServiceConfigurationModifyParams
	extends IServiceBaseParams,
		Partial<IChoosableServiceConfiguration> {
	id: number;
	service: string;
}

/**
 * Modify choosable service configuration
 * @param params `IChoosableServiceConfigurationModifyParams`
 * @returns `Promise<ServiceResponse<IChoosableServiceConfiguration>>`
 */
export const choosableServiceConfigurationModifyService = (params: IChoosableServiceConfigurationModifyParams) => {
	return Api.post<IChoosableServiceConfiguration, IChoosableServiceConfigurationModifyParams>(
		ChoosableServiceConfigurationEndPoints.MODIFY,
		params,
	);
};
