import { removeKeyWithUndefinedValue } from '@crac/core/helpers/commons';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { VehicleModelEndPoints } from './FleetVehicleModelEndpoints';
import type { TransmissionType } from '../../../../models/types/TransmissionType';
import type { FuelType } from '../../types/FuelType';
import type { IVehicleModel } from '../entities/VehicleModel';

export interface IFleetVehicleModelSearchParams extends IServiceBaseParams {
	provider?: string;
	brandCodes?: string[];
	modelCode?: string;
	vehicleGroups?: string[];
	fuelType?: FuelType;
	show?: number;
	transmissionType?: TransmissionType;
	metaModel?: number;
	vehicleEngineId?: number;
	vehicleVersionId?: number;
	searchName?: string;
	showInFleet?: boolean;
}

export const fleetVehicleModelSearchService = (model: IFleetVehicleModelSearchParams) => {
	const params: IFleetVehicleModelSearchParams = {
		...model,
		modelCode: model.modelCode ? encodeURIComponent(model.modelCode) : undefined,
	};
	removeKeyWithUndefinedValue(params);

	return Api.get<IVehicleModel[], IFleetVehicleModelSearchParams>(VehicleModelEndPoints.SEARCH, params);
};
