export enum MaximumExcessRateEndpoints {
	/**
	 * Excess rate search
	 * @host `/maximumExcessRate/search/`
	 */
	SEARCH = '/maximumExcessRate/search',

	/**
	 * Excess rate insert
	 * @host `/maximumExcessRate/insert/`
	 */
	INSERT = '/maximumExcessRate/insert',

	/**
	 * Excess rate deactivate
	 * @host `/maximumExcessRate/deactivate/`
	 */
	DEACTIVATE = '/maximumExcessRate/deactivate',
}
