import type { IRentRateTypeRelation } from '@crac/core/modules/pricing/rentRateTypeRelation/entities/RentRateTypeRelation';
import { RentRateTypeRelationEndpoints } from '@crac/core/modules/pricing/rentRateTypeRelation/services/RentRateTypeRelationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

export interface IRentRateTypeRelationDeleteParams extends IServiceBaseParams {
	id: number;
}

/**
 * Delete rent rate relation
 * @param model IRentRateTypeRelationDeleteParams
 * @returns IRentRateTypeRelation
 */
export const rentRateTypeRelationDeleteService = (model: IRentRateTypeRelationDeleteParams) =>
	Api.post<IRentRateTypeRelation, IRentRateTypeRelationDeleteParams>(RentRateTypeRelationEndpoints.DELETE, model);
