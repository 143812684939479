import type { IRentRateValidation } from '@crac/core/modules/pricing/rentRateValidation/entities/RentRateValidation';
import { RentRateValidationEndpoints } from '@crac/core/modules/pricing/rentRateValidation/services/RentRateValidationEndpoints';
import type { IRentRateValidationSaveParams } from '@crac/core/modules/pricing/rentRateValidation/services/RentRateValidationInsertService';
import { Api } from '@crac/core/modules/shared/api';

/**
 * Modify rent rate validations
 * @param model IRentRateValidationModifyParams
 * @returns IRentRateValidation
 */
export const rentRateValidationModifyService = (model: IRentRateValidationSaveParams) =>
	Api.post<IRentRateValidation[], IRentRateValidationSaveParams>(RentRateValidationEndpoints.MODIFY, model);
