import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { CustomerEndPoints } from './CustomerEndpoints';
import type { CustomerValidationType } from '../../../models/types/CustomerValidationType';

export interface ICustomerValidateDataParams extends IServiceBaseParams {
	customerCode: string;
	validationType: CustomerValidationType;
}

export const customerValidateDataService = (model: ICustomerValidateDataParams) => {
	return Api.get<boolean, ICustomerValidateDataParams>(CustomerEndPoints.VALIDATE_DATA, model);
};
