import type { IRentRate } from '@crac/core/modules/pricing/rentRate/entities/RentRate';
import { RentRateEndpoints } from '@crac/core/modules/pricing/rentRate/services/RentRateEndpoints';
import type { FromTo } from '@crac/core/modules/pricing/types/FromToType';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IRentRatesSearchParams extends IServiceBaseParams {
	branch?: number;
	vehicleGroup?: string;
	rentRateType?: string;
	pickUp?: FromTo<Date | string>;
	quote?: FromTo<Date | string>;
}

export const rentRateSearchService = (model: IRentRatesSearchParams): Promise<ServiceResponse<IRentRate[]>> => {
	return Api.get<IRentRate[], IRentRatesSearchParams>(RentRateEndpoints.SEARCH, model);
};
