import type { IPartnerGroup } from '@crac/core/modules/pricing/partnerConfiguration/partnerGroup/entities/PartnerGroup';
import { PartnerGroupEndpoints } from '@crac/core/modules/pricing/partnerConfiguration/partnerGroup/services/PartnerGroupEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IPartnerGroupSearchParams extends IServiceBaseParams {
	code?: string;
	name?: string;
}

export const partnerGroupSearchService = (
	model: IPartnerGroupSearchParams,
): Promise<ServiceResponse<IPartnerGroup[]>> => {
	return Api.get<IPartnerGroup[], IPartnerGroupSearchParams>(PartnerGroupEndpoints.SEARCH, model);
};
