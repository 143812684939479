import type { IOccupancy } from '@crac/core/modules/pricing/occupancy/entities/Occupancy';
import { OccupancyEndpoints } from '@crac/core/modules/pricing/occupancy/services/OccupancyEndpoints';
import type { PartnerType } from '@crac/core/modules/pricing/partnerConfiguration/types/PartnerType';
import type { FromTo } from '@crac/core/modules/pricing/types/FromToType';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

export interface IOccupancySearchParams extends IServiceBaseParams {
	vehicleGroups?: string[];
	branches?: number[];
	contractTypes?: number[];
	pickUp: FromTo<string | Date>;
	partners?: Array<{
		type: PartnerType;
		code: string;
	}>;
	showActive?: number;
	partnerCategories?: string[];
	partnerGroups?: string[];
}

/**
 * Search occupancy
 */
export const occupancySearchService = (model: IOccupancySearchParams) => {
	return Api.get<IOccupancy[], IOccupancySearchParams>(OccupancyEndpoints.SEARCH, model);
};
