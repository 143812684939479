import type { InvoiceToCustomerType } from '@crac/core/models/types/InvoiceToCustomerType';
import { emailSendFeedBackService } from '@crac/core/modules/email/services/EmailSendFeedbackService';
import { Api } from '@crac/core/modules/shared/api';
import { CreationMethod } from '@crac/core/modules/shared/types/CreationMethod';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { BookingEndPoints } from './BookingEndpoints';
import type { IPaymentLine } from '../../../models/entities/PaymentLine';
import type { ICardDataParams } from '../../../models/serviceParams/CardParams';
import type { IBookingInsertExternalResponse } from '../../../models/serviceResponse/BookingResponse';
import type { IBrowserInformation } from '../../../models/types/BrowserInformation';
import type { IBookingLineInsertParams } from '../bookingLine/services/BookingLineInsertService';

export interface IBookingInsertParams extends IServiceBaseParams {
	agencyCode: string;
	coupon?: string;
	customerBirthDate?: string | Date;
	customerCode?: string;
	customerEmail?: string;
	customerFirstSurname?: string;
	customerName: string;
	customerPhoneCountry?: string;
	customerPhoneNumber?: string;
	customerSecondSurname?: string;
	discount?: number;
	dropOffBranchCode: string;
	dropOffDateTime: string | Date;
	flightTrainNumber?: string;
	invoiceToCustomerCode?: string;
	invoiceToCustomerType?: InvoiceToCustomerType;
	package?: string;
	pickUpBranchCode: string;
	pickUpDateTime: string | Date;
	pricesCalculated?: boolean;
	quoteDateTime: string | Date;
	services: IBookingLineInsertParams[];
	vehicleGroupCodeRequested: string;
	vendorCode?: string;
	voucher?: string;
	pickUpLatitude?: number;
	pickUpLongitude?: number;
	pickUpAddress?: string;
	dropOffLatitude?: number;
	dropOffLongitude?: number;
	dropOffAddress?: string;
	customerPreferedLanguage?: string;
}

export interface IBookingInsertExternalParams extends IBookingInsertParams {
	cardPayment?: boolean;
	cardData?: ICardDataParams;
	browser?: IBrowserInformation;
	paymentLines: IPaymentLine[];
	from?: 'web' | 'app';
}

/**
 * Return BookingInsert width payment
 */
export const bookingInsertExternalService = async (
	model: IBookingInsertExternalParams,
): Promise<ServiceResponse<IBookingInsertExternalResponse>> => {
	const response = await Api.post<IBookingInsertExternalResponse, IBookingInsertExternalParams>(
		BookingEndPoints.INSERT,
		model,
	);

	if (response.ok && response.data?.transaction && !response.data.transaction.transactionId) {
		await emailSendFeedBackService({
			json: JSON.stringify({
				from: model.from ?? 'web',
				action: BookingEndPoints.INSERT,
				params: model,
				response: response.data,
			}),
			creationMethod: CreationMethod.BRANCH,
			files: [],
		});
	}

	return response;
};
