import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CustomerEndPoints } from './CustomerEndpoints';
import type { ISocialNetWorkLink } from '../../../models/entities/SocialNetworkLink';

export interface ICustomerSocialNetworkGetbyCustomeParams extends IServiceBaseParams {
	customerCode: string;
}

/**
 * Customer get social network
 * @param model ICustomerLoginWithSocialNetworkParams
 * @returns Promise<ServiceResponse<ISocialNetWorkLink[]>>
 */
export const customerSocialNetworkGetByCustomerService = (
	model: ICustomerSocialNetworkGetbyCustomeParams,
): Promise<ServiceResponse<ISocialNetWorkLink[]>> => {
	return Api.get<ISocialNetWorkLink[], ICustomerSocialNetworkGetbyCustomeParams>(
		CustomerEndPoints.SOCIAL_NETWORK_GET_BY_CUSTOMER,
		model,
	);
};
