import { PickUpEndpoints } from './PickUpEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IBooking } from '../../entities/Booking';

export interface IBookingSignContractParams extends IServiceBaseParams {
	bookingNumber: string;
	ip: string;
	computerName: string;
}

/**
 * Sign contract
 *  @param {string} bookingNumber booking number
 *  @param {string} computerName ComputerName
 *  @param {string} ip Pinpad ip
 */
export const bookingSignContractService = (model: IBookingSignContractParams): Promise<ServiceResponse<IBooking>> => {
	return Api.post<IBooking, IBookingSignContractParams>(PickUpEndpoints.SIGN, model);
};
