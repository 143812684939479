import { useState } from 'react';

import type { supplierLocationDelete } from '@crac/core/modules/supplier/location/state/actions/SupplierLocationActions';

import { useAlertMessage } from '~/features/shared/hooks/useAlertMessage';
import { useManageRequestTemp } from '~/features/shared/hooks/useManageRequest';

import { useLocationSelector } from '../state/selector';

export const useLocationDelete = (onDelete: typeof supplierLocationDelete) => {
	const { supplierLocationDeleteRequest } = useLocationSelector();
	const [isSubmitted, setIsSubmitted] = useState(false);
	const { showConfirm } = useAlertMessage();

	const handleOnDelete = async (id: number) => {
		const confirm = await showConfirm({ message: 'Location will be deleted' });
		if (confirm) {
			onDelete({ id });
			setIsSubmitted(true);
		}
	};

	useManageRequestTemp({
		request: supplierLocationDeleteRequest,
		clearCondition: () => setIsSubmitted(false),
		condition: isSubmitted,
		successMsg: 'Location deleted successfully',
		toast: true,
	});

	return { handleOnDelete };
};
