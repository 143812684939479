import type { FC } from 'react';
import React from 'react';

import { Button } from '../../../external/reactstrap/button';
import type { IActionStatus } from '../../../types/IActions';
import { RenderIcon } from '../list/Actions/RenderIcon';
/**
 * Toggle button component
 *
 *  {object} item - Object check change property
 *  {string} toggleProp - name item prop, check is true or false
 *  {object} status - Config buttons add - remove
 *  {func} onAdd - callback call in add
 *  {func} onRemove - callback call in remove
 *
 * @example `status param`
 * 	status: {
 * add: { icon: 'plus', text: 'ADD', callback: this.handdleSelectedItem },
 * remove: { icon: 'remove', text: 'REMOVE', callback: this.handdleRemoveItem }
 * }
 *
 * @example
 * <ToggleButton
 * key={key}
 * item={item}
 * toggleProp="isSelected"
 * status={status}
 * onAdd={callbackAdd}
 * onRemove={callbackRemove}
 * />
 *
 */

interface IToggleButtonProps {
	item: any;
	toggleProp: string;
	block?: boolean;
	status: IActionStatus;
	disabled?: boolean | ((item: any) => boolean);
}

export const ToggleButton: FC<IToggleButtonProps> = ({ item, toggleProp, status, disabled = false, block = false }) => {
	const { add, remove } = status;
	const isActive = Boolean(item[toggleProp]);

	let isDisabled = false;
	if (disabled) {
		if (disabled.constructor.name === 'Function') {
			const checkIsDisabled = disabled as (item: any) => boolean;
			isDisabled = checkIsDisabled(item);
		}

		if (disabled.constructor.name === 'Boolean') {
			isDisabled = disabled as boolean;
		}
	}

	if (isActive) {
		if (remove) {
			return (
				<Button
					color={remove.color || 'warning'}
					data-test="toggleButton"
					isBlock={block}
					isDisabled={isDisabled}
					onClick={(): void => remove.callback(item)}
					size="sm"
					type="button"
				>
					<RenderIcon icon={remove.icon} />
					<span className="d-none d-sm-inline-block">{remove.text}</span>
				</Button>
			);
		}

		return null;
	}

	if (add) {
		return (
			<Button
				color={add.color || 'primary'}
				data-test="toggleButton"
				isBlock={block}
				isDisabled={isDisabled}
				onClick={(): void => add.callback(item)}
				size="sm"
				type="button"
			>
				<RenderIcon icon={add.icon} />
				<span className="d-none d-sm-inline-block">{add.text}</span>
			</Button>
		);
	}

	return null;
};
