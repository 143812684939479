import type { IRentRateType } from '@crac/core/modules/pricing/rentRateType/entities/RentRateType';
import type { IRentRateTypeInsertParams } from '@crac/core/modules/pricing/rentRateType/services/RentRateTypeInsertService';
import { rentRateTypeInsertService } from '@crac/core/modules/pricing/rentRateType/services/RentRateTypeInsertService';
import { rentRateTypeModifyService } from '@crac/core/modules/pricing/rentRateType/services/RentRateTypeModifyService';
import type { IRentRateTypesSearchParams } from '@crac/core/modules/pricing/rentRateType/services/RentRateTypeSearchService';
import { rentRateTypeSearchService } from '@crac/core/modules/pricing/rentRateType/services/RentRateTypeSearchService';
import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

/**
 * Searches for rent rate types based on specified parameters.
 *
 * @param {IRentRateType[]} payload - The rent rate types to be retrieved.
 * @param {IRentRateTypesSearchParams} params - Parameters for the search of rent rate types.
 * @returns {Promise<void>} - A promise that resolves to an array of rent rate types.
 */
export const rentRateTypeSearch = createAsyncAction<IRentRateType[], IRentRateTypesSearchParams>(
	'rentRateType/search',
	rentRateTypeSearchService,
);

/**
 * Inserts a new rent rate type.
 *
 * @param {IRentRateType} payload - The rent rate type to be inserted.
 * @param {IRentRateTypeInsertParams} params - Parameters for the insertion of the rent rate type.
 * @returns {Promise<void>} - A promise that resolves after the rent rate type is inserted.
 */
export const rentRateTypeInsert = createAsyncAction<IRentRateType, IRentRateTypeInsertParams>(
	'rentRateType/insert',
	rentRateTypeInsertService,
);

/**
 * Modifies an existing rent rate type.
 *
 * @param {IRentRateType} payload - The rent rate type to be modified.
 * @param {IRentRateTypeInsertParams} params - Parameters for the modification of the rent rate type.
 * @returns {Promise<void>} - A promise that resolves after the rent rate type is modified.
 */
export const rentRateTypeModify = createAsyncAction<IRentRateType, IRentRateTypeInsertParams>(
	'rentRateType/modify',
	rentRateTypeModifyService,
);

/**
 * Resets the rent rate type state.
 */
export const rentRateTypeReset = createSyncAction('rentRateType/reset');
