import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CustomerEndPoints } from './CustomerEndpoints';
import type { ICustomer } from '../entities/Customer';

export interface ICustomerDeleteParams extends IServiceBaseParams {
	customerCode: string;
}

export const customerDeleteService = (model: ICustomerDeleteParams): Promise<ServiceResponse<ICustomer>> => {
	return Api.post<ICustomer, ICustomerDeleteParams>(CustomerEndPoints.DELETE, model);
};
