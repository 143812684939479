import type { IPreauthorizationRate } from '@crac/core/modules/pricing/preauthorizationRate/entities/PreauthorizationRate';
import { PreauthorizationRateEndpoints } from '@crac/core/modules/pricing/preauthorizationRate/services/PreauthorizationRateEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IPreauthorizationRateDeactivateParams extends IServiceBaseParams {
	ids: number[];
}

/**
 * Deactivate preauthorization rates
 * @param model IPreauthorizationRateDeactivateParams
 * @returns IPreauthorizationRate[]
 */
export const preauthorizationRateDeactivateService = (
	model: IPreauthorizationRateDeactivateParams,
): Promise<ServiceResponse<IPreauthorizationRate[]>> => {
	return Api.post<IPreauthorizationRate[], IPreauthorizationRateDeactivateParams>(
		PreauthorizationRateEndpoints.DEACTIVATE,
		model,
	);
};
