import { createAction } from '@reduxjs/toolkit';

import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

import type { ICoupon } from '../../entitites/Coupon';
import type { ICouponInsertResponse } from '../../entitites/CouponInsertReponse';
import type { ICouponGetByBookingNumberParams } from '../../services/CouponGetByBookingNumberService';
import { couponGetByBookingNumberService } from '../../services/CouponGetByBookingNumberService';
import type { ICouponGetByCustomerCodeParams } from '../../services/CouponGetByCustomerCodeService';
import { couponGetByCustomerCodeService } from '../../services/CouponGetByCustomerCodeService';
import type { ICouponInsertCallBackParams } from '../../services/CouponInsertCallBackService';
import { couponInsertCallBackService } from '../../services/CouponInsertCallBackService';
import type { ICouponInsertParams } from '../../services/CouponInsertService';
import { couponInsertService } from '../../services/CouponInsertService';

/**
 * Retrieves coupons associated with a specific customer code.
 *
 * @param {ICoupon[]} payload - The coupons to be retrieved.
 * @param {ICouponGetByCustomerCodeParams} params - Parameters to identify coupons related to a specific customer code.
 * @returns {Promise<void>} - A promise that resolves to an array of coupons.
 */
export const couponGetByCustomerCode = createAsyncAction<ICoupon[], ICouponGetByCustomerCodeParams>(
	'coupon/getByCustomerCode',
	couponGetByCustomerCodeService,
);

/**
 * Retrieves coupons associated with a specific booking number.
 *
 * @param {ICoupon[]} payload - The coupons to be retrieved.
 * @param {ICouponGetByBookingNumberParams} params - Parameters to identify coupons related to a specific booking number.
 * @returns {Promise<void>} - A promise that resolves to an array of coupons.
 */
export const couponGetByBookingNumber = createAsyncAction<ICoupon[], ICouponGetByBookingNumberParams>(
	'coupon/getByBookingNumber',
	couponGetByBookingNumberService,
);

/**
 * Inserts a new coupon.
 *
 * @param {ICouponInsertResponse} payload - The response after inserting the coupon.
 * @param {ICouponInsertParams} params - Parameters detailing the coupon to be inserted.
 * @returns {Promise<void>} - A promise that resolves with the details of the inserted coupon.
 */
export const customerCouponInsert = createAsyncAction<ICouponInsertResponse, ICouponInsertParams>(
	'coupon/insert',
	couponInsertService,
);

/**
 * Handles the callback after inserting a coupon.
 *
 * @param {ICouponInsertResponse} payload - The response from the callback.
 * @param {ICouponInsertCallBackParams} params - Parameters from the insert callback.
 * @returns {Promise<void>} - A promise that resolves with the outcome of the callback process.
 */
export const customerCouponInsertCallBack = createAsyncAction<ICouponInsertResponse, ICouponInsertCallBackParams>(
	'coupon/insertCallBack',
	couponInsertCallBackService,
);

/**
 * Resets any errors related to coupon insertion.
 */
export const customerCouponInsertResetErrors = createAction('coupon/insertResetErrors');
