import type { FC } from 'react';

import { MailToLink } from '@crac/components/intranet/shared/links/MailToLink';
import { NoContent } from '@crac/components/intranet/shared/noContent';
import type { ITanStackTableProps } from '@crac/components/intranet/shared/tanStackTable';
import { TanStackTable } from '@crac/components/intranet/shared/tanStackTable';
import { ToolBarButtons } from '@crac/components/intranet/shared/toolBar/ToolBarButtons';
import type { ISupplierLocation } from '@crac/core/modules/supplier/location/entities/SupplierLocation';
import { Button } from '@crac/ui/button';
import { FaPencil, FaTrash, FaTrashAlt } from '@crac/ui/icons';
import { Icon } from '@crac/ui/icons/Icon';

import { CountryName } from '../list/CountryName';

interface ISupplierLocationListProps {
	onAdd: () => void;
	onModify: (location: ISupplierLocation) => void;
	onDelete: (id: number) => void;
	locations: ISupplierLocation[];
	isLoading: boolean;
}

export const SupplierLocationList: FC<ISupplierLocationListProps> = ({
	onAdd,
	onModify,
	onDelete,
	locations,
	isLoading,
}) => {
	if (isLoading) {
		return null;
	}

	if (!isLoading && !locations.length) {
		return (
			<NoContent className="tw-text-center tw-m-6" message="This supplier has no locations">
				<Button color="primary" onClick={onAdd} type="button">
					<Icon className="tw-mr-1" name="FaPlus" /> Add location
				</Button>
			</NoContent>
		);
	}

	const listConfig: ITanStackTableProps = {
		columns: [
			{ key: 'name' },
			{
				key: 'address',
				render: (value) => {
					const item = value as ISupplierLocation;
					return (
						<div className="d-flex flex-column">
							{item.address}
							<small>
								{[item.postCode, item.city, item.county].filter(Boolean).join(', ')},{' '}
								<CountryName value={item.country} />
							</small>
						</div>
					);
				},
			},
			{ key: 'nif', header: 'NIF', width: 80 },
			{
				key: 'email',
				header: 'Contact',
				render: (item) => {
					const { email, contact } = item as ISupplierLocation;
					return (
						<>
							{contact ? `${contact} ` : null}
							<MailToLink email={email} />
						</>
					);
				},
			},
			{
				key: 'phone',
				width: 80,
				render: (item) => {
					const { phone } = item as ISupplierLocation;
					return phone ? (
						<a href={`tel:${phone}`} title={phone}>
							{phone}
						</a>
					) : null;
				},
			},
		],
		actions: [
			{
				ariaLabel: 'modify-btn',
				id: 'id',
				key: 0,
				type: 'action',
				icon: FaPencil,
				color: 'primary',
				callback: (item: ISupplierLocation) => onModify(item),
			},
			{
				ariaLabel: 'delete-btn',
				id: 'id',
				key: 1,
				type: 'action',
				icon: FaTrashAlt,
				color: 'danger',
				callback: (item: ISupplierLocation) => onDelete(item.id),
			},
		],
		tableProps: { smallRowText: true },
		tableHeight: 'md',
		data: locations,
		actionWidth: 75,
	};

	return (
		<>
			<ToolBarButtons className="tw-flex tw-justify-end">
				<Button color="primary" onClick={onAdd} size="sm" type="button">
					<Icon className="tw-mr-1" name="FaPlus" /> Add location
				</Button>
			</ToolBarButtons>
			<TanStackTable {...listConfig} />
		</>
	);
};
