import type { IPartnerCategory } from '@crac/core/modules/pricing/partnerConfiguration/partnerCategory/entities/PartnerCategory';
import { PartnerCategoryEndpoints } from '@crac/core/modules/pricing/partnerConfiguration/partnerCategory/services/PartnerCategoryEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IPartnerCategoryModifyParams extends IServiceBaseParams {
	code: string;
	name: string;
}

export const partnerCategoryModifyService = (
	model: IPartnerCategoryModifyParams,
): Promise<ServiceResponse<IPartnerCategory>> => {
	return Api.post<IPartnerCategory, IPartnerCategoryModifyParams>(PartnerCategoryEndpoints.MODIFY, model);
};
