import type { IRentRateVariation } from '@crac/core/modules/pricing/rentRateVariation/entities/RentRateVariation';
import type { IRentRateVariationDeactivateParams } from '@crac/core/modules/pricing/rentRateVariation/services/RentRateVariationDeactivateService';
import { rentRateVariationDeactivateService } from '@crac/core/modules/pricing/rentRateVariation/services/RentRateVariationDeactivateService';
import type { IRentRateVariationInsertParams } from '@crac/core/modules/pricing/rentRateVariation/services/RentRateVariationInsertService';
import { rentRateVariationInsertService } from '@crac/core/modules/pricing/rentRateVariation/services/RentRateVariationInsertService';
import type { IRentRateVariationsSearchParams } from '@crac/core/modules/pricing/rentRateVariation/services/RentRateVariationSearchService';
import { rentRateVariationSearchService } from '@crac/core/modules/pricing/rentRateVariation/services/RentRateVariationSearchService';
import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

/**
 * Searches for rent rate variations based on specified parameters.
 *
 * @param {IRentRateVariation[]} payload - The rent rate variations to be retrieved.
 * @param {IRentRateVariationsSearchParams} params - Parameters for the search of rent rate variations.
 * @returns {Promise<void>} - A promise that resolves to an array of rent rate variations.
 */
export const rentRateVariationsSearch = createAsyncAction<IRentRateVariation[], IRentRateVariationsSearchParams>(
	'rentRateVariations/search',
	rentRateVariationSearchService,
);

/**
 * Inserts new rent rate variations.
 *
 * @param {IRentRateVariation[]} payload - The rent rate variations to be inserted.
 * @param {IRentRateVariationInsertParams} params - Parameters for the insertion of rent rate variations.
 * @returns {Promise<void>} - A promise that resolves after the rent rate variations are inserted.
 */
export const rentRateVariationInsert = createAsyncAction<IRentRateVariation[], IRentRateVariationInsertParams>(
	'rentRateVariation/insert',
	rentRateVariationInsertService,
);

/**
 * Deactivates specific rent rate variations.
 *
 * @param {IRentRateVariation[]} payload - The rent rate variations to be deactivated.
 * @param {IRentRateVariationDeactivateParams} params - Parameters for the deactivation of rent rate variations.
 * @returns {Promise<void>} - A promise that resolves after the rent rate variations are deactivated.
 */
export const rentRateVariationDeactivate = createAsyncAction<IRentRateVariation[], IRentRateVariationDeactivateParams>(
	'rentRateVariation/deactivate',
	rentRateVariationDeactivateService,
);

/**
 * Resets the rent rate variation state.
 */
export const rentRateVariationReset = createSyncAction('rentRateVariation/reset');
