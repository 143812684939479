import type { UnitTime } from '@crac/core/models/types/UnitTime';
import type { IKmRate } from '@crac/core/modules/pricing/kmRate/entities/KmRate';
import { KmRateEndpoints } from '@crac/core/modules/pricing/kmRate/services/KmRateEndpoints';
import type { PartnerType } from '@crac/core/modules/pricing/partnerConfiguration/types/PartnerType';
import type { FromTo } from '@crac/core/modules/pricing/types/FromToType';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IKmRateSearchParams extends IServiceBaseParams {
	quote?: FromTo<string | Date>;
	pickUp?: FromTo<string | Date>;
	contractTypes?: number[];
	partners?: Array<{
		type: PartnerType;
		code: string;
	}>;
	branches?: number[];
	vehicleGroups?: string[];
	rentRateTypes?: string[];
	bookingDuration?: FromTo<number>;
	unitTime?: UnitTime;
	showActive?: number;
	partnerCategories?: string[];
	partnerGroups?: string[];
}

/**
 * Search km rates
 * @param model IKmRateSearchParams
 * @returns IKmRate[]
 */
export const kmRateSearchService = (model: IKmRateSearchParams): Promise<ServiceResponse<IKmRate[]>> => {
	return Api.get<IKmRate[], IKmRateSearchParams>(KmRateEndpoints.SEARCH, model);
};
