import type { IPackageConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/packageConfiguration/entities/PackageConfiguration';
import type { IPackageConfigurationDeactivateParams } from '@crac/core/modules/pricing/partnerConfiguration/packageConfiguration/services/PackageConfigurationDeactivateService';
import { packageConfigurationDeactivateService } from '@crac/core/modules/pricing/partnerConfiguration/packageConfiguration/services/PackageConfigurationDeactivateService';
import type { IPackageConfigurationInsertParams } from '@crac/core/modules/pricing/partnerConfiguration/packageConfiguration/services/PackageConfigurationInsertService';
import { packageConfigurationInsertService } from '@crac/core/modules/pricing/partnerConfiguration/packageConfiguration/services/PackageConfigurationInsertService';
import type { IPackageConfigurationModifyParams } from '@crac/core/modules/pricing/partnerConfiguration/packageConfiguration/services/PackageConfigurationModifyService';
import { packageConfigurationModifyService } from '@crac/core/modules/pricing/partnerConfiguration/packageConfiguration/services/PackageConfigurationModifyService';
import { packageConfigurationSearchService } from '@crac/core/modules/pricing/partnerConfiguration/packageConfiguration/services/PackageConfigurationSearchService';
import type { IPackageConfigurationSearchParams } from '@crac/core/modules/pricing/partnerConfiguration/packageConfiguration/services/PackageConfigurationSearchService';
import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

/**
 * Searches for package configurations based on specified parameters.
 *
 * @param {IPackageConfiguration[]} payload - The package configurations to be retrieved.
 * @param {IPackageConfigurationSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of package configurations.
 */
export const packageConfigurationSearch = createAsyncAction<IPackageConfiguration[], IPackageConfigurationSearchParams>(
	'packageConfiguration/search',
	packageConfigurationSearchService,
);

/**
 * Inserts new package configurations.
 *
 * @param {IPackageConfiguration[]} payload - The package configurations to be inserted.
 * @param {IPackageConfigurationInsertParams} params - Parameters for the insertion.
 * @returns {Promise<void>} - A promise that resolves after the package configurations are inserted.
 */
export const packageConfigurationInsert = createAsyncAction<IPackageConfiguration[], IPackageConfigurationInsertParams>(
	'packageConfiguration/insert',
	packageConfigurationInsertService,
);

/**
 * Modifies an existing package configuration.
 *
 * @param {IPackageConfiguration} payload - The package configuration to be modified.
 * @param {IPackageConfigurationModifyParams} params - Parameters for the modification.
 * @returns {Promise<void>} - A promise that resolves after the package configuration is modified.
 */
export const packageConfigurationModify = createAsyncAction<IPackageConfiguration, IPackageConfigurationModifyParams>(
	'packageConfiguration/modify',
	packageConfigurationModifyService,
);

/**
 * Removes deactivated package configurations from the state.
 *
 * @param {number} payload - The ID of the package configuration to be removed.
 */
export const packageConfigurationRemoveDeactivated = createSyncAction<number>('packageConfiguration/removeDeactivated');

/**
 * Deactivates specific package configurations.
 *
 * @param {IPackageConfiguration[]} payload - The package configurations to be deactivated.
 * @param {IPackageConfigurationDeactivateParams} params - Parameters for the deactivation.
 * @returns {Promise<void>} - A promise that resolves after the package configurations are deactivated.
 */
export const packageConfigurationDeactivate = createAsyncAction<
	IPackageConfiguration[],
	IPackageConfigurationDeactivateParams
>('packageConfiguration/deactivate', packageConfigurationDeactivateService);

/**
 * Clears the package configuration state.
 */
export const packageConfigurationClear = createSyncAction('packageConfiguration/clear');
