import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CommentEndPoints } from './CommentsEndpoints';
import type { IComment } from '../entities/Comment';

export interface ICommentAddToVehicleParams extends IServiceBaseParams {
	code: string;
	message: string;
}

export const commentAddToVehicleService = (model: ICommentAddToVehicleParams): Promise<ServiceResponse<IComment>> => {
	return Api.post<IComment, ICommentAddToVehicleParams>(CommentEndPoints.ADD_TO_VEHICLE, model);
};
