import type { UnitTime } from '@crac/core/models/types/UnitTime';
import type { IServiceRateType } from '@crac/core/modules/pricing/serviceRateType/entities/ServiceRateType';
import { ServiceRateTypeEndpoints } from '@crac/core/modules/pricing/serviceRateType/services/ServiceRateTypeEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IServiceRateTypeInsertParams extends IServiceBaseParams {
	code: string;
	name: string;
	unitTime: UnitTime;
}

export const serviceRateTypeInsertService = (
	model: IServiceRateTypeInsertParams,
): Promise<ServiceResponse<IServiceRateType>> => {
	return Api.post<IServiceRateType, IServiceRateTypeInsertParams>(ServiceRateTypeEndpoints.INSERT, model);
};
