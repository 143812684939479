import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CommentEndPoints } from './CommentsEndpoints';
import type { IComment } from '../entities/Comment';

export interface ICommentAddToCompanyParams extends IServiceBaseParams {
	code: string;
	message: string;
}

/**
 * Add comment to company
 *  {string} message Comment message
 *  {string} code Company code
 */
export const commentAddToCompanyService = (model: ICommentAddToCompanyParams): Promise<ServiceResponse<IComment>> => {
	return Api.post<IComment, ICommentAddToCompanyParams>(CommentEndPoints.ADD_TO_COMPANY, model);
};
