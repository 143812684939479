import { createSlice } from '@reduxjs/toolkit';

import {
	occupancyClear,
	occupancyDeactivate,
	occupancyInsert,
	occupancySearch,
} from '@crac/core/modules/pricing/occupancy/state/actions/OccupancyActions';
import type { IOccupancyReducerState } from '@crac/core/modules/pricing/occupancy/state/stateType/OccupancyReducerState';
const initialState: IOccupancyReducerState = {
	occupancies: [],
	occupancyInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	occupancySearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	occupancyDeactivateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const occupancySlice = createSlice({
	name: 'OccupancySlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// SEARCH
		builder
			.addCase(occupancySearch.pending, (state) => {
				return {
					...state,
					occupancySearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(occupancySearch.fulfilled, (state, action) => {
				return {
					...state,
					occupancySearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					occupancies: action.payload,
				};
			})
			.addCase(occupancySearch.rejected, (state, action) => {
				return {
					...state,
					occupancySearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		/**
		 *
		 * CLEAR
		 */
		builder.addCase(occupancyClear, () => {
			return initialState;
		});
		// INSERT
		builder
			.addCase(occupancyInsert.pending, (state) => {
				return {
					...state,
					occupancyInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(occupancyInsert.fulfilled, (state) => {
				return {
					...state,
					occupancyInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(occupancyInsert.rejected, (state, action) => {
				return {
					...state,
					occupancyInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// DEACTIVATE
		builder
			.addCase(occupancyDeactivate.pending, (state) => {
				return {
					...state,
					occupancyDeactivateRequest: {
						ok: true,
						inProgress: true,
						messages: [],
					},
				};
			})
			.addCase(occupancyDeactivate.fulfilled, (state, action) => {
				return {
					...state,
					occupancyDeactivateRequest: {
						ok: true,
						inProgress: false,
						messages: [],
					},
					occupancies: [
						...state.occupancies.filter(({ id }) => !action.payload.map((item) => item.id).includes(id)),
						...action.payload,
					],
				};
			})
			.addCase(occupancyDeactivate.rejected, (state, action) => {
				return {
					...state,
					occupancyDeactivateRequest: {
						ok: false,
						inProgress: false,
						messages: action.payload || [],
					},
				};
			});
	},
});

export const occupancyReducer = occupancySlice.reducer;
