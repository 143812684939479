import { Api } from '@crac/core/modules/shared/api';
import type { CreationMethod } from '@crac/core/modules/shared/types/CreationMethod';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { BookingModifyEndpoints } from './BookingModifyEndpoints';
import type { IBookingLineInsertParams } from '../../bookingLine/services/BookingLineInsertService';
import type { IBooking } from '../../entities/Booking';

export interface IBookingModifyParams extends IServiceBaseParams {
	actualDropOffDateTime?: string | Date | null;
	agencyCode?: string;
	allowRegisterWithPending?: boolean;
	bookingNumber: string;
	bookingState?: number;
	coupon?: string;
	creationMethod?: CreationMethod;
	customerBirthDate?: string | Date | null;
	customerCode?: string;
	customerName?: string;
	discount?: number;
	dropOffBranchCode?: string;
	dropOffComment?: string;
	dropOffDateTime?: string | Date | null;
	dropOffFuelPercentage?: number;
	dropOffKM?: number;
	flightTrainNumber?: string;
	invoiceToCustomerCode?: string;
	invoiceToCustomerType?: number;
	pickUpBranchCode?: string;
	pickUpComment?: string;
	pickUpDateTime?: string | Date | null;
	plateNumber?: string;
	quoteDateTime?: string | Date | null;
	services?: IBookingLineInsertParams[];
	vehicleGroupCodeDelivered?: string;
	vehicleGroupCodePaid?: string;
	vehicleGroupCodeRequested?: string;
	vendorCode?: string;
	voucher?: string;
	provisionalAddress?: string;
	provisionalAddress2?: string;
	provisionalCity?: string;
	provisionalPostCode?: string;
}

export interface IBookingFlightTrainModifyParams extends IServiceBaseParams {
	flightTrainNumber: string;
	customerCode: string;
	bookingNumber: string;
	agencyCode: string;
	creationMethod: CreationMethod;
	dropOffBranchCode: string;
	pickUpBranchCode: string;
	vehicleGroupCodeRequested: string;
}

/**
 * Modify booking data
 *  {Object} BookingPropType booking
 * @returns {Promise<BookingPropType>} `BookingPropType`
 */
export const bookingModifyService = (model: IBookingModifyParams): Promise<ServiceResponse<IBooking>> => {
	return Api.post<IBooking, IBookingModifyParams>(BookingModifyEndpoints.MODIFY, model);
};
