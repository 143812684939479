export enum PickUpEndpoints {
	/**
	 * Booking sign
	 * @host `/booking/sign/`
	 */
	SIGN = '/booking/sign/',

	/**
	 * Booking check can do direct pick up
	 * @host `/booking/canDoDirectPickup`
	 */
	CAN_DO_DIRECT_PICKUP = '/booking/canDoDirectPickup',

	/**
	 * Booking do direct pick up
	 * @host `/booking/doDirectPickup`
	 */
	DO_DIRECT_PICKUP = '/booking/doDirectPickup',

	/**
	 * Get pending pickup by branch
	 * @host `/booking/getPendingPickupsByBranch/`
	 */
	GET_PENDING_PICKUP_BY_BRANCH = '/booking/getPendingPickupsByBranch/',

	/**
	 * Booking start contract
	 * @host `/booking/startContract/`
	 */
	START_CONTRACT = '/booking/startContract/',

	/**
	 * Register contract
	 * @host `/booking/registerContract/`
	 */
	REGISTER_CONTRACT = '/booking/registerContract/',
}
