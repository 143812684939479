import { createSlice } from '@reduxjs/toolkit';

import {
	partnerGroupClear,
	partnerGroupGetAll,
	partnerGroupGetByCode,
	partnerGroupInsert,
	partnerGroupModify,
	partnerGroupSearch,
} from '@crac/core/modules/pricing/partnerConfiguration/partnerGroup/state/actions/PartnerGroupActions';
import type { IPartnerGroupReducerState } from '@crac/core/modules/pricing/partnerConfiguration/partnerGroup/state/stateType/PartnerGroupReducerState';
const initialState: IPartnerGroupReducerState = {
	partnerGroupGetAllRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	partnerGroups: [],
	partnerGroup: null,
	partnerGroupGetByCodeRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	partnerGroupInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	partnerGroupModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	partnerGroupSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const partnerGroupSlice = createSlice({
	name: 'PartnerGroupReducer',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET ALL
		builder
			.addCase(partnerGroupGetAll.pending, (state) => {
				return {
					...state,
					partnerGroupGetAllRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(partnerGroupGetAll.fulfilled, (state, action) => {
				return {
					...state,
					partnerGroupGetAllRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					partnerGroups: action.payload,
				};
			})
			.addCase(partnerGroupGetAll.rejected, (state, action) => {
				return {
					...state,
					partnerGroupGetAllRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET BY CODE
		builder
			.addCase(partnerGroupGetByCode.pending, (state) => {
				return {
					...state,
					partnerGroupGetByCodeRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(partnerGroupGetByCode.fulfilled, (state, action) => {
				return {
					...state,
					partnerGroupGetByCodeRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					partnerGroup: action.payload,
				};
			})
			.addCase(partnerGroupGetByCode.rejected, (state, action) => {
				return {
					...state,
					partnerGroupGetByCodeRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// SEARCH
		builder
			.addCase(partnerGroupSearch.pending, (state) => {
				return {
					...state,
					partnerGroupSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(partnerGroupSearch.fulfilled, (state, action) => {
				return {
					...state,
					partnerGroupSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					partnerGroups: action.payload,
				};
			})
			.addCase(partnerGroupSearch.rejected, (state, action) => {
				return {
					...state,
					partnerGroupSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(partnerGroupModify.pending, (state) => {
				return {
					...state,
					partnerGroupModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(partnerGroupModify.fulfilled, (state, action) => {
				return {
					...state,
					partnerGroupModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					partnerGroups: [
						...state.partnerGroups.filter((partnerGroup) => partnerGroup.code !== action.payload.code),
						action.payload,
					],
				};
			})
			.addCase(partnerGroupModify.rejected, (state, action) => {
				return {
					...state,
					partnerGroupModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(partnerGroupInsert.pending, (state) => {
				return {
					...state,
					partnerGroupInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(partnerGroupInsert.fulfilled, (state, action) => {
				return {
					...state,
					partnerGroupInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					partnerGroups: [...state.partnerGroups, { ...action.payload }],
				};
			})
			.addCase(partnerGroupInsert.rejected, (state, action) => {
				return {
					...state,
					partnerGroupInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR
		builder.addCase(partnerGroupClear, () => {
			return {
				...initialState,
			};
		});
	},
});

export const partnerGroupReducer = partnerGroupSlice.reducer;
