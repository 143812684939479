import type { ICouponConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/couponConfiguration/entities/CouponConfiguration';
import type { ICouponConfigurationDeactivateParams } from '@crac/core/modules/pricing/partnerConfiguration/couponConfiguration/services/CouponConfigurationDeactivateService';
import { couponConfigurationDeactivateService } from '@crac/core/modules/pricing/partnerConfiguration/couponConfiguration/services/CouponConfigurationDeactivateService';
import type { ICouponConfigurationInsertParams } from '@crac/core/modules/pricing/partnerConfiguration/couponConfiguration/services/CouponConfigurationInsertService';
import { couponConfigurationInsertService } from '@crac/core/modules/pricing/partnerConfiguration/couponConfiguration/services/CouponConfigurationInsertService';
import type { ICouponConfigurationModifyParams } from '@crac/core/modules/pricing/partnerConfiguration/couponConfiguration/services/CouponConfigurationModifyService';
import { couponConfigurationModifyService } from '@crac/core/modules/pricing/partnerConfiguration/couponConfiguration/services/CouponConfigurationModifyService';
import { couponConfigurationSearchService } from '@crac/core/modules/pricing/partnerConfiguration/couponConfiguration/services/CouponConfigurationSearchService';
import type { ICouponConfigurationSearchParams } from '@crac/core/modules/pricing/partnerConfiguration/couponConfiguration/services/CouponConfigurationSearchService';
import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

/**
 * Searches for coupon configurations based on specified parameters.
 *
 * @param {ICouponConfiguration[]} payload - The coupon configurations to be returned.
 * @param {ICouponConfigurationSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of coupon configurations.
 */
export const couponConfigurationSearch = createAsyncAction<ICouponConfiguration[], ICouponConfigurationSearchParams>(
	'couponConfiguration/search',
	couponConfigurationSearchService,
);

/**
 * Inserts new coupon configurations.
 *
 * @param {ICouponConfiguration[]} payload - The coupon configurations to be inserted.
 * @param {ICouponConfigurationInsertParams} params - Parameters for the insertion.
 * @returns {Promise<void>} - A promise that resolves after the coupon configurations are inserted.
 */
export const couponConfigurationInsert = createAsyncAction<ICouponConfiguration[], ICouponConfigurationInsertParams>(
	'couponConfiguration/insert',
	couponConfigurationInsertService,
);

/**
 * Modifies an existing coupon configuration.
 *
 * @param {ICouponConfiguration} payload - The coupon configuration to be modified.
 * @param {ICouponConfigurationModifyParams} params - Parameters for the modification.
 * @returns {Promise<void>} - A promise that resolves after the coupon configuration is modified.
 */
export const couponConfigurationModify = createAsyncAction<ICouponConfiguration, ICouponConfigurationModifyParams>(
	'couponConfiguration/modify',
	couponConfigurationModifyService,
);

/**
 * Deactivates coupon configurations.
 *
 * @param {ICouponConfiguration[]} payload - The coupon configurations to be deactivated.
 * @param {ICouponConfigurationDeactivateParams} params - Parameters for the deactivation.
 * @returns {Promise<void>} - A promise that resolves after the coupon configurations are deactivated.
 */
export const couponConfigurationDeactivate = createAsyncAction<
	ICouponConfiguration[],
	ICouponConfigurationDeactivateParams
>('couponConfiguration/deactivate', couponConfigurationDeactivateService);

/**
 * Removes deactivated coupon configurations from the state.
 *
 * @param {number} payload - The ID of the coupon configuration to be removed.
 */
export const couponConfigurationRemoveDeactivated = createSyncAction<number>('couponConfiguration/removeDeactivated');

/**
 * Clears the coupon configuration state.
 */
export const couponConfigurationClear = createSyncAction('couponConfiguration/clear');
