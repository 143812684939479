import type { ISelectOptionType } from '@crac/core/models/types/SelectOptionType';
import type { ISupplier } from '@crac/core/modules/supplier/entities/Supplier';
import { supplierSearchService } from '@crac/core/modules/supplier/services/SupplierSearchService';
import type { SupplierType } from '@crac/core/modules/supplier/types/SupplierType';

import { showError } from '~/features/shared/helpers/showError';

export const fetchSupplierOptions = async ({
	provider,
	supplier,
	type,
}: {
	provider: string;
	supplier?: string;
	type: SupplierType;
}): Promise<ISelectOptionType<string>[]> => {
	const response = await supplierSearchService({
		provider,
		supplier,
		type,
	});

	if (response.ok && response.data) {
		const data = response.data as ISupplier[];
		return data.map((supplier) => ({
			label: `${supplier.code} - ${supplier.name}`,
			value: supplier.code,
		}));
	}
	showError(response.errors);
	return [];
};

export const loadSupplierOptions =
	(provider: string, type: SupplierType) =>
	async ({ label: supplier }: { label: string }) => {
		if (supplier.length >= 2) {
			return await fetchSupplierOptions({
				provider,
				supplier,
				type,
			});
		}
		return [];
	};
