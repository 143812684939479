import type { IStopSale } from '@crac/core/modules/pricing/stopSale/entities/StopSale';
import { StopSaleEndpoints } from '@crac/core/modules/pricing/stopSale/services/StopSaleEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

export interface IStopSalesInsertParams extends IServiceBaseParams {
	stopSales: IStopSale[];
}

/**
 * Insert stop sales
 */
export const stopSaleServiceInsert = (model: IStopSalesInsertParams) => {
	return Api.post<IStopSale[], IStopSalesInsertParams>(StopSaleEndpoints.INSERT, model);
};
