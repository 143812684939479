import type { IPackageConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/packageConfiguration/entities/PackageConfiguration';
import { PackageConfigurationEndpoints } from '@crac/core/modules/pricing/partnerConfiguration/packageConfiguration/services/PackageConfigurationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IPackageConfigurationDeactivateParams extends IServiceBaseParams {
	ids: number[];
}

/**
 * Deactivate package configuration
 * @param model IPackageConfigurationDeactivateParams
 * @returns IPackageConfiguration[]
 */
export const packageConfigurationDeactivateService = (
	model: IPackageConfigurationDeactivateParams,
): Promise<ServiceResponse<IPackageConfiguration[]>> => {
	return Api.post<IPackageConfiguration[], IPackageConfigurationDeactivateParams>(
		PackageConfigurationEndpoints.DEACTIVATE,
		model,
	);
};
