import { type FC, useMemo } from 'react';

import { TabPane } from '@crac/components/external/reactstrap/tab';
import { Modal } from '@crac/components/intranet/shared/modal';
import { getKeyByValue } from '@crac/core/helpers/commons';
import type { ISupplier } from '@crac/core/modules/supplier/entities/Supplier';
import { SupplierType } from '@crac/core/modules/supplier/types/SupplierType';
import { TabsContent } from '@crac/ui/tabs';

import { useSupplierFormInitialValue } from './hooks/useSupplierFormInitialValue';
import { SupplierFormContent } from './SupplierFormContent';
import { useSupplierFormCancel } from '../hooks/useSupplierFormCancel';
import { useSupplierInsert } from '../hooks/useSupplierInsert';
import { useSupplierModify } from '../hooks/useSupplierModify';
import { LocationTabContent } from '../location';
import { LocationTabs } from '../location/LocationTabs';

interface ISupplierFormProps {
	/**
	 * Optional route to redirect to when the form is cancelled
	 * @default 'back'
	 */
	cancelRoute?: string;
	/**
	 * Optional fields to show in the form
	 *
	 * - address: fields for address (country, city, postCode, county, address)
	 * - paymentTerms: fields for payment terms
	 * - contact: fields for contact information (email, phone, contact)
	 */
	fields?: {
		address?: boolean;
		paymentTerms?: boolean;
		contact?: boolean;
	};
	/**
	 * Optional: if true, the form will be rendered as a modal
	 * @default true
	 */
	isModal?: boolean;
	/**
	 * Optional supplier to prefill the form. Use in modify mode
	 * @default undefined
	 */
	supplier?: ISupplier;
	/**
	 * Supplier type
	 * @default SupplierType.ALL
	 */
	type: SupplierType;

	hasLocationTab?: boolean;
}

export const SupplierForm: FC<ISupplierFormProps> = ({
	type,
	supplier,
	cancelRoute,
	isModal = true,
	fields,
	hasLocationTab,
}) => {
	const resetAddress = Boolean(fields?.address);
	const isModify = useMemo(() => Boolean(supplier?.code), [supplier]);

	const { handleCancel } = useSupplierFormCancel({ cancelRoute, resetAddress });
	const { handleModify } = useSupplierModify({ code: supplier?.code, cancelRoute, resetAddress });
	const { handleInsert } = useSupplierInsert({ cancelRoute, supplierType: type, resetAddress });

	const { initialValues } = useSupplierFormInitialValue(supplier);

	const handleSubmit = isModify ? handleModify : handleInsert;

	const formTitle = supplier
		? `Modify ${getKeyByValue(SupplierType, type).toLowerCase()} ${supplier.code}`
		: `New ${getKeyByValue(SupplierType, type).toLowerCase()}`;

	if (isModal) {
		if (hasLocationTab && supplier) {
			return (
				<Modal isOpen onClose={handleCancel}>
					<LocationTabs>
						<TabsContent className="tw-p-0" value="1">
							<SupplierFormContent
								fields={fields}
								hasLocationTab={hasLocationTab}
								initialValues={initialValues}
								onCancel={handleCancel}
								onSubmit={handleSubmit}
								supplierType={type}
								title={formTitle}
							/>
						</TabsContent>
						<TabsContent className="tw-p-0" value="2">
							<LocationTabContent supplier={supplier} />
						</TabsContent>
					</LocationTabs>
				</Modal>
			);
		}

		return (
			<Modal isOpen onClose={handleCancel}>
				<SupplierFormContent
					fields={fields}
					initialValues={initialValues}
					onCancel={handleCancel}
					onSubmit={handleSubmit}
					supplierType={type}
					title={formTitle}
				/>
			</Modal>
		);
	}

	return (
		<SupplierFormContent
			fields={fields}
			initialValues={initialValues}
			onCancel={handleCancel}
			onSubmit={handleSubmit}
			supplierType={type}
			title={formTitle}
		/>
	);
};
