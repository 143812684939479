import type { FC } from 'react';
import React from 'react';

import type { IListAction } from './IList';
import { ListActionsRender } from './ListActionsRender';

interface IListActionsProps {
	actions?: IListAction[] | ((item: any) => IListAction);
	item: any;
}

export const ListActions: FC<IListActionsProps> = ({ actions, item }) => {
	if (!actions || actions.length === 0) {
		return null;
	}

	if (actions.constructor.name === 'Array') {
		const items = actions as Array<IListAction>;

		return (
			<td className="align-middle text-end actions-container">
				{items.map((action: IListAction) => (
					<ListActionsRender action={action} item={item} key={action.key} />
				))}
			</td>
		);
	}

	if (actions.constructor.name === 'Function') {
		return (
			<td className="align-middle text-end actions-container">
				<ListActionsRender item={item} render={actions as (item: any) => IListAction} />
			</td>
		);
	}

	return null;
};
