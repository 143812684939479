import type { ICouponConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/couponConfiguration/entities/CouponConfiguration';
import { CouponConfigurationEndpoints } from '@crac/core/modules/pricing/partnerConfiguration/couponConfiguration/services/CouponConfigurationEndpoints';
import type { IPartnerConfigurationSearch } from '@crac/core/modules/pricing/partnerConfiguration/types/PartnerConfigurationSearchType';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

/**
 * All params are optionals, but at least one parameter is required in the search
 */
export interface ICouponConfigurationSearchParams extends IServiceBaseParams, IPartnerConfigurationSearch {
	couponCode?: string;
}

/**
 * Search coupon configuration
 * @param params `ICouponConfigurationSearchParams`
 * @returns `Promise<ServiceResponse<ICouponConfiguration[]>>`
 */
export const couponConfigurationSearchService = (params: ICouponConfigurationSearchParams) => {
	return Api.get<ICouponConfiguration[], ICouponConfigurationSearchParams>(
		CouponConfigurationEndpoints.SEARCH,
		params,
	);
};
