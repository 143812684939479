import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import type { IRequestState } from '@crac/core/modules/shared/types/RequestStateType';
import type { ISupplierLocationInsertParams } from '@crac/core/modules/supplier/location/services/SupplierLocationInsertService';

import { routesIdMap } from '~/config/routesIdMap';
import { routes } from '~/features/routes';
import { useAlertMessage } from '~/features/shared/hooks/useAlertMessage';
import { useManageRequestTemp } from '~/features/shared/hooks/useManageRequest';

type LocationSaveProps = {
	request: IRequestState;
	onSave: (values: ISupplierLocationInsertParams) => void;
	onCancel?: () => void;
};

export const useLocationSave = ({ onSave, request, onCancel }: LocationSaveProps) => {
	const navigate = useNavigate();
	const { showConfirm } = useAlertMessage();
	const [isSubmitted, setIsSubmitted] = useState(false);

	const handleOnCancel = () => {
		if (onCancel) {
			onCancel();
			return;
		}
		navigate(`${routes[routesIdMap.WorkshopLocation].path}${location.search}`);
	};

	const handleOnSave = async (values: ISupplierLocationInsertParams) => {
		const confirm = await showConfirm({ message: 'Location will be saved', confirmButtonText: 'Yes, save it' });
		if (confirm) {
			onSave(values);
			setIsSubmitted(true);
		}
	};

	useManageRequestTemp({
		clearCondition: () => setIsSubmitted(false),
		condition: isSubmitted,
		request,
		successCallback: handleOnCancel,
		successMsg: 'Location saved successfully',
		toast: true,
	});

	return { handleOnCancel, handleOnSave };
};
