import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { BookingModifyEndpoints } from './BookingModifyEndpoints';
import type { IBooking } from '../../entities/Booking';

export interface IBookingCustomerChangeParams extends IServiceBaseParams {
	bookingNumber: string;
	customerCode: string;
}

export const bookingCustomerChangeService = (
	model: IBookingCustomerChangeParams,
): Promise<ServiceResponse<IBooking>> => {
	return Api.post<IBooking, IBookingCustomerChangeParams>(BookingModifyEndpoints.CHANGE_CUSTOMER, model);
};
