import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { PaymentEndPoints } from './PaymentEntpoints';
import type { IPaymentLine } from '../entities/PaymentLine';

export interface IPaymentLineGetByBookingParams extends IServiceBaseParams {
	bookingNumber: string;
}

/**
 * GET payment line from booking
 *  {string} bookingNumber booking number
 * @returns {Promise<Array>} `Array<PaymentLine>` Payment line list
 */
export const paymentLineGetByBookingService = (
	model: IPaymentLineGetByBookingParams,
): Promise<ServiceResponse<IPaymentLine[]>> => {
	return Api.get<IPaymentLine[], IPaymentLineGetByBookingParams>(PaymentEndPoints.PAYMENT_LINE_GET_BY_BOOKING, model);
};
