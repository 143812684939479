import { createAction } from '@reduxjs/toolkit';

import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

import { cityServiceGetByCountryAndPostCode, cityServiceGetByProvince } from '../../../../data/services/CityService';
import { postCodeServiceGetByCountryAndText } from '../../../../data/services/PostCodeService';
import {
	provinceServiceGetByCode,
	provinceServiceGetByCountry,
	provinceServiceGetByCountryAndPostCode,
} from '../../../../data/services/ProvinceService';
import type { ICity } from '../../../../models/entities/City';
import type { ICustomerPending } from '../../../../models/entities/CustomerPending';
import type { IPostCode } from '../../../../models/entities/PostCode';
import type { IProvince } from '../../../../models/entities/Province';
import type { ISocialNetWorkLink } from '../../../../models/entities/SocialNetworkLink';
import type {
	ICityGetByCountryAndPostCodeParams,
	ICityGetByProvinceParams,
} from '../../../../models/serviceParams/CityParams';
import type { IPostCodeGetByCountryAndTextParams } from '../../../../models/serviceParams/PostCodeParams';
import type {
	IProvinceGetByCodeParams,
	IProvinceGetByCountryAndPostCodeParams,
	IProvinceGetByCountryParams,
} from '../../../../models/serviceParams/ProvinceParams';
import type {
	ICustomerGetDetailDataResponse,
	ICustomerLoginResponse,
	ICustomerSignUpResponse,
} from '../../../../models/serviceResponse/CustomerResponse';
import type { ICustomer } from '../../entities/Customer';
import type { ICustomerAcceptGdprParams } from '../../services/CustomerAcceptGdprService';
import { customerAcceptGdprService } from '../../services/CustomerAcceptGdprService';
import type { ICustomerAssignPointsParams } from '../../services/CustomerAssignPointsService';
import { customerAssignPointsService } from '../../services/CustomerAssignPointsService';
import type { ICustomerBlockParams } from '../../services/CustomerBlockService';
import { CustomerBlockService } from '../../services/CustomerBlockService';
import { customerCanMakeContractService } from '../../services/CustomerCanMakeContractService';
import type { ICustomerCanMakeContractParams } from '../../services/CustomerCanMakeContractService';
import type { ICustomerCanResetPasswordParams } from '../../services/CustomerCanResetPasswordService';
import { customerCanResetPasswordService } from '../../services/CustomerCanResetPasswordService';
import type { ICustomerDeleteParams } from '../../services/CustomerDeleteService';
import { customerDeleteService } from '../../services/CustomerDeleteService';
import type { ICustomerEmailValidationParams } from '../../services/CustomerEmailValidationService';
import { customerEmailValidationService } from '../../services/CustomerEmailValidationService';
import { customerGetByCodeService } from '../../services/CustomerGetByCodeService';
import type { ICustomerGetByCodeParams } from '../../services/CustomerGetByCodeService';
import type { ICustomerGetDetailData } from '../../services/CustomerGetDetailDataService';
import { customerGetDetailDataService } from '../../services/CustomerGetDetailDataService';
import { customerGetPendingsService } from '../../services/CustomerGetPendingsService';
import type { ICustomerGetPendingsParams } from '../../services/CustomerGetPendingsService';
import type { ICustomerLoginParams } from '../../services/CustomerLoginService';
import { customerLoginService } from '../../services/CustomerLoginService';
import type { ICustomerLoginWithSocialNetworkParams } from '../../services/CustomerLoginWithSocialNetworkService';
import { customerLoginWithSocialNetworkService } from '../../services/CustomerLoginWithSocialNetworkService';
import type { ICustomerModifyDuplicateTaxIdParams } from '../../services/CustomerModifyDuplicateTaxIdService';
import { customerModifyDuplicateTaxIdService } from '../../services/CustomerModifyDuplicateTaxIdService';
import type { ICustomerModifyPasswordParams } from '../../services/CustomerModifyPasswordService';
import { customerModifyPasswordService } from '../../services/CustomerModifyPasswordService';
import { customerModifyService } from '../../services/CustomerModifyService';
import type { ICustomerResetPasswordParams } from '../../services/CustomerResetPasswordService';
import { customerResetPasswordService } from '../../services/CustomerResetPasswordService';
import { customerSaveService } from '../../services/CustomerSaveService';
import type { ICustomerSaveParams } from '../../services/CustomerSaveService';
import { customerSearchByFieldService } from '../../services/CustomerSearchByFieldService';
import { customerSearchService } from '../../services/CustomerSearchService';
import type { ICustomerSearchParams } from '../../services/CustomerSearchService';
import type { ICustomerSendResetPasswordParams } from '../../services/CustomerSendResetPasswordService';
import { customerSendResetPasswordService } from '../../services/CustomerSendResetPasswordService';
import type { ICustomerSignUpParams } from '../../services/CustomerSignUpService';
import { customerSignUpService } from '../../services/CustomerSignUpService';
import type { ICustomerSocialNetworkGetbyCustomeParams } from '../../services/CustomerSocialNetworkGetByCustomerService';
import { customerSocialNetworkGetByCustomerService } from '../../services/CustomerSocialNetworkGetByCustomerService';
import { customerSocialNetworkLinkService } from '../../services/CustomerSocialNetworkLinkService';
import type { ICustomerSocialNetworkLinkParams } from '../../services/CustomerSocialNetworkLinkService';
import { customerSocialNetworkUnLinkService } from '../../services/CustomerSocialNetworkUnLinkService';
import type { ICustomerSocialNetworkUnLinkParams } from '../../services/CustomerSocialNetworkUnLinkService';
import type { ICustomerSubscribeToNewsletterParams } from '../../services/CustomerSubscribeToNewsletterService';
import { customerSubscribeToNewsletterService } from '../../services/CustomerSubscribeToNewsletterService';
import type { ICustomerUnSubscribeToNewsletterParams } from '../../services/CustomerUnSubscribeToNewsletterService';
import { customerUnSubscribeToNewsletterService } from '../../services/CustomerUnSubscribeToNewsletterService';
import { customerValidateDataService } from '../../services/CustomerValidateDataService';
import type { ICustomerValidateDataParams } from '../../services/CustomerValidateDataService';

/**
 * Searches for customers based on specified search parameters.
 *
 * @param {ICustomer[]} payload - The array of customers matching the search criteria.
 * @param {ICustomerSearchParams} params - Parameters used for searching customers, such as name, ID, or other identifiers.
 * @returns {Promise<void>} - A promise that resolves to an array of customers matching the search criteria.
 */
export const customerSearch = createAsyncAction<ICustomer[], ICustomerSearchParams>(
	'customer/search',
	customerSearchService,
);

/**
 * Retrieves a customer's details by their unique code.
 *
 * @param {ICustomer} payload - The customer's details.
 * @param {ICustomerGetByCodeParams} params - Parameters for retrieving a customer by their unique code.
 * @returns {Promise<void>} - A promise that resolves to the customer's details.
 */
export const customerGetByCode = createAsyncAction<ICustomer, ICustomerGetByCodeParams>(
	'customer/getByCode',
	customerGetByCodeService,
);

/**
 * Retrieves a customer's details for invoicing purposes by their unique code.
 *
 * @param {ICustomer} payload - The customer's details for invoicing.
 * @param {ICustomerGetByCodeParams} params - Parameters for retrieving a customer by their unique code for invoicing.
 * @returns {Promise<void>} - A promise that resolves to the customer's invoicing details.
 */
export const customerInvoiceToGetByCode = createAsyncAction<ICustomer, ICustomerGetByCodeParams>(
	'customer/invoiceToGetByCode',
	customerGetByCodeService,
);

/**
 * Saves a new customer or updates an existing customer's details.
 *
 * @param {ICustomer} payload - The customer's details to be saved or updated.
 * @param {ICustomerSaveParams} params - Parameters for saving or updating the customer.
 * @returns {Promise<void>} - A promise that resolves after the customer is saved or updated.
 */
export const customerSave = createAsyncAction<ICustomer, ICustomerSaveParams>('customer/save', customerSaveService);

/**
 * Modifies an existing customer's details.
 *
 * @param {ICustomer} payload - The customer's details to be modified.
 * @param {ICustomerSaveParams} params - Parameters for modifying the customer.
 * @returns {Promise<void>} - A promise that resolves after the customer is modified.
 */
export const customerModify = createAsyncAction<ICustomer, ICustomerSaveParams>(
	'customer/modify',
	customerModifyService,
);

/**
 * Modifies a customer's record that has a duplicate tax ID.
 *
 * @param {ICustomer} payload - The customer data after modification.
 * @param {ICustomerModifyDuplicateTaxIdParams} params - Parameters for modifying the customer record with a duplicate tax ID, including details like tax ID and customer identifier.
 * @returns {Promise<void>} - A promise that resolves with the updated customer data after successful modification. Throws an error if modification fails.
 */
export const customerModifyDuplicateTaxId = createAsyncAction<ICustomer, ICustomerModifyDuplicateTaxIdParams>(
	'customer/modifyDuplicateTaxId',
	customerModifyDuplicateTaxIdService,
);

/**
 * Blocks a customer account.
 *
 * @param {ICustomer} payload - The customer to be blocked.
 * @param {ICustomerBlockParams} params - Parameters for blocking the customer.
 * @returns {Promise<void>} - A promise that resolves after the customer account is blocked.
 */
export const customerBlock = createAsyncAction<ICustomer, ICustomerBlockParams>('customer/block', CustomerBlockService);

/**
 * Checks if a customer is eligible to make a contract.
 *
 * @param {ICustomer} payload - The customer to check eligibility for.
 * @param {ICustomerCanMakeContractParams} params - Parameters for checking the customer's eligibility to make a contract.
 * @returns {Promise<void>} - A promise that resolves with the customer's eligibility status.
 */
export const customerCanMakeContract = createAsyncAction<ICustomer, ICustomerCanMakeContractParams>(
	'customer/canMakeContract',
	customerCanMakeContractService,
);

/**
 * Records a customer's acceptance of GDPR regulations.
 *
 * @param {ICustomer} payload - The customer accepting GDPR.
 * @param {ICustomerAcceptGdprParams} params - Parameters for the GDPR acceptance.
 * @returns {Promise<void>} - A promise that resolves after recording the customer's GDPR acceptance.
 */
export const customerAcceptGdpr = createAsyncAction<ICustomer, ICustomerAcceptGdprParams>(
	'customer/acceptGdpr',
	customerAcceptGdprService,
);

/**
 * Retrieves pending actions or items associated with a customer.
 *
 * @param {ICustomerPending[]} payload - The list of pending items for the customer.
 * @param {ICustomerGetPendingsParams} params - Parameters for retrieving pending items.
 * @returns {Promise<void>} - A promise that resolves to an array of customer pending items.
 */
export const customerGetPendings = createAsyncAction<ICustomerPending[], ICustomerGetPendingsParams>(
	'customer/getPendings',
	customerGetPendingsService,
);

/**
 * Assigns points to a customer.
 *
 * @param {ICustomer} payload - The customer to whom points are assigned.
 * @param {ICustomerAssignPointsParams} params - Parameters for assigning points to the customer.
 */
export const customerAssignPoints = createAsyncAction<ICustomer, ICustomerAssignPointsParams>(
	'customer/assignPoints',
	customerAssignPointsService,
);

/**
 * Logs in a customer.
 *
 * @param {ICustomerLoginResponse} payload - The response data after successful login, including customer details and session information.
 * @param {ICustomerLoginParams} params - Parameters for the customer login.
 * @returns {Promise<void>} - A promise that resolves with the login response data.
 */
export const customerLogin = createAsyncAction<ICustomerLoginResponse, ICustomerLoginParams>(
	'customer/login',
	customerLoginService,
);

/**
 * Clears the customer state.
 */
export const customerClear = createAction('customer/clear');

/**
 * Validates customer data.
 *
 * @param {boolean} payload - The result of the validation process, indicating whether the data is valid.
 * @param {ICustomerValidateDataParams} params - Parameters for validating the customer data.
 * @returns {Promise<void>} - A promise that resolves with the result of the validation process.
 */
export const customerValidateData = createAsyncAction<boolean, ICustomerValidateDataParams>(
	'customer/validateData',
	customerValidateDataService,
);

/**
 * Clears the search state for customers.
 */
export const customerClearSearch = createAction('customer/clearSearch');

/**
 * Searches for customers based on specific fields.
 *
 * @param {ICustomer[]} payload - The customers to be retrieved.
 * @param {ICustomerSearchParams} params - Parameters for searching customers, such as name, email, or other identifiers.
 * @returns {Promise<void>} - A promise that resolves to an array of customers matching the search criteria.
 */
export const customerSearchByField = createAsyncAction<ICustomer[], ICustomerSearchParams>(
	'customer/searchByField',
	customerSearchByFieldService,
);

/**
 * Logs in a customer using a social network account.
 *
 * @param {ICustomerLoginResponse} payload - The response containing customer login details and status.
 * @param {ICustomerLoginWithSocialNetworkParams} params - Parameters for logging in with a social network, such as account tokens or identifiers.
 * @returns {Promise<void>} - A promise that resolves with the customer's login response.
 */
export const customerLoginWithSocialNetwork = createAsyncAction<
	ICustomerLoginResponse,
	ICustomerLoginWithSocialNetworkParams
>('customer/loginWithSocialNetwork', customerLoginWithSocialNetworkService);

/**
 * Retrieves social network links associated with a specific customer.
 *
 * @param {ISocialNetWorkLink[]} payload - The social network links to be retrieved.
 * @param {ICustomerSocialNetworkGetbyCustomeParams} params - Parameters for retrieving social network links for a specific customer.
 * @returns {Promise<void>} - A promise that resolves to an array of social network links for the customer.
 */
export const customerSocialNetworkGetByCustomer = createAsyncAction<
	ISocialNetWorkLink[],
	ICustomerSocialNetworkGetbyCustomeParams
>('customer/socialNetworkGetByCustomer', customerSocialNetworkGetByCustomerService);

/**
 * Links a social network account to a customer profile.
 *
 * @param {ISocialNetWorkLink} payload - The social network account details to be linked.
 * @param {ICustomerSocialNetworkLinkParams} params - Parameters for linking the social network account.
 * @returns {Promise<void>} - A promise that resolves after the social network account is linked.
 */
export const customerSocialNetworkLink = createAsyncAction<ISocialNetWorkLink, ICustomerSocialNetworkLinkParams>(
	'customer/socialNetworkLink',
	customerSocialNetworkLinkService,
);

/**
 * Unlinks a social network account from a customer profile.
 *
 * @param {boolean} payload - The response status indicating the success of the unlinking process.
 * @param {ICustomerSocialNetworkUnLinkParams} params - Parameters for unlinking the social network account.
 * @returns {Promise<void>} - A promise that resolves after the social network account is unlinked.
 */
export const customerSocialNetworkUnLink = createAsyncAction<boolean, ICustomerSocialNetworkUnLinkParams>(
	'customer/socialNetworkUnLink',
	customerSocialNetworkUnLinkService,
);

/**
 * Subscribes a customer to a newsletter.
 *
 * @param {boolean} payload - The response status indicating the success of the subscription process.
 * @param {ICustomerSubscribeToNewsletterParams} params - Parameters for subscribing to the newsletter.
 * @returns {Promise<void>} - A promise that resolves after the customer is subscribed to the newsletter.
 */
export const customerSubscribeToNewsletter = createAsyncAction<boolean, ICustomerSubscribeToNewsletterParams>(
	'customer/subscribeToNewsletter',
	customerSubscribeToNewsletterService,
);

/**
 * Unsubscribes a customer from a newsletter.
 *
 * @param {boolean} payload - The response status indicating the success of the unsubscription process.
 * @param {ICustomerUnSubscribeToNewsletterParams} params - Parameters for unsubscribing from the newsletter.
 * @returns {Promise<void>} - A promise that resolves after the customer is unsubscribed from the newsletter.
 */
export const customerUnSubscribeToNewsletter = createAsyncAction<boolean, ICustomerUnSubscribeToNewsletterParams>(
	'customer/unSubscribeToNewsletter',
	customerUnSubscribeToNewsletterService,
);

/**
 * Checks if a customer can reset their password.
 *
 * @param {boolean} payload - The response status indicating whether the customer can reset their password.
 * @param {ICustomerCanResetPasswordParams} params - Parameters for checking the customer's ability to reset the password.
 * @returns {Promise<void>} - A promise that resolves with the status of whether the customer can reset the password.
 */
export const customerCanResetPassword = createAsyncAction<boolean, ICustomerCanResetPasswordParams>(
	'customer/canResetPassword',
	customerCanResetPasswordService,
);

/**
 * Resets the password for a customer account.
 *
 * @param {boolean} payload - The response indicating whether the password reset was successful.
 * @param {ICustomerResetPasswordParams} params - Parameters for resetting the password, such as email or account identifier.
 * @returns {Promise<void>} - A promise that resolves after the password reset process is completed.
 */
export const customerResetPassword = createAsyncAction<boolean, ICustomerResetPasswordParams>(
	'customer/resetPassword',
	customerResetPasswordService,
);

/**
 * Sends a password reset request for a customer account.
 *
 * @param {boolean} payload - The response indicating whether the reset password request was successfully sent.
 * @param {ICustomerSendResetPasswordParams} params - Parameters for sending the reset password request, such as email address.
 * @returns {Promise<void>} - A promise that resolves after the reset password request is sent.
 */
export const customerSendResetPassword = createAsyncAction<boolean, ICustomerSendResetPasswordParams>(
	'customer/sendResetPassword',
	customerSendResetPasswordService,
);

/**
 * Signs up a new customer.
 *
 * @param {ICustomerSignUpResponse} payload - The response received after signing up, typically including customer details and account information.
 * @param {ICustomerSignUpParams} params - Parameters for signing up a new customer, such as personal details and credentials.
 * @returns {Promise<void>} - A promise that resolves with the sign-up response.
 */
export const customerSignUp = createAsyncAction<ICustomerSignUpResponse, ICustomerSignUpParams>(
	'customer/signUp',
	customerSignUpService,
);

/**
 * Modifies the password for a customer account.
 *
 * @param {boolean} payload - The response indicating whether the password modification was successful.
 * @param {ICustomerModifyPasswordParams} params - Parameters for modifying the password, such as the current and new passwords.
 * @returns {Promise<void>} - A promise that resolves after the password modification process is completed.
 */
export const customerModifyPassword = createAsyncAction<boolean, ICustomerModifyPasswordParams>(
	'customer/modifyPassword',
	customerModifyPasswordService,
);

/**
 * Deletes a customer account.
 *
 * @param {ICustomer} payload - The customer account to be deleted.
 * @param {ICustomerDeleteParams} params - Parameters for deleting the customer account, such as account identifier.
 * @returns {Promise<void>} - A promise that resolves after the customer account is deleted.
 */
export const customerDelete = createAsyncAction<ICustomer, ICustomerDeleteParams>(
	'customer/delete',
	customerDeleteService,
);

/**
 * Validates a customer's email address.
 *
 * @param {boolean} payload - The response indicating whether the email validation was successful.
 * @param {ICustomerEmailValidationParams} params - Parameters for email validation, such as a validation token or customer identifier.
 * @returns {Promise<void>} - A promise that resolves after the email validation process is completed.
 */
export const customerEmailValidation = createAsyncAction<boolean, ICustomerEmailValidationParams>(
	'customer/emailValidation',
	customerEmailValidationService,
);

/**
 * Retrieves detailed data for a specific customer.
 *
 * @param {ICustomer} payload - The detailed customer data to be retrieved.
 * @param {ICustomerGetDetailData} params - Parameters for retrieving detailed customer data, such as customer ID.
 * @returns {Promise<void>} - A promise that resolves to the detailed data of the specified customer.
 */
export const customerGetDetailData = createAsyncAction<ICustomerGetDetailDataResponse, ICustomerGetDetailData>(
	'customer/getDetailData',
	customerGetDetailDataService,
);

/**
 * Retrieves provinces based on a given birth country.
 *
 * @param {IProvince[]} payload - The provinces to be retrieved.
 * @param {IProvinceGetByCountryParams} params - Parameters for retrieving provinces, such as the country code.
 * @returns {Promise<void>} - A promise that resolves to an array of provinces belonging to the specified country.
 */
export const customerBirthProvincesGetByBirthCountry = createAsyncAction<IProvince[], IProvinceGetByCountryParams>(
	'customer/birthProvincesGetByBirthCountry',
	provinceServiceGetByCountry,
);

/**
 * Retrieves provinces based on a country and postal code.
 *
 * @param {IProvince[]} payload - The provinces to be retrieved.
 * @param {IProvinceGetByCountryAndPostCodeParams} params - Parameters for retrieving provinces, such as the country code and postal code.
 * @returns {Promise<void>} - A promise that resolves to an array of provinces within the specified country and postal code.
 */
export const customerProvincesGetByCountryAndPostCode = createAsyncAction<
	IProvince[],
	IProvinceGetByCountryAndPostCodeParams
>('customer/provincesGetByCountryAndPostCode', provinceServiceGetByCountryAndPostCode);

/**
 * Retrieves a birth province by its code.
 *
 * @param {IProvince} payload - The province to be retrieved.
 * @param {IProvinceGetByCodeParams} params - Parameters for retrieving the province, such as the province code.
 * @returns {Promise<void>} - A promise that resolves to the province matching the given code.
 */
export const customerGetBirthProvinceByCode = createAsyncAction<IProvince, IProvinceGetByCodeParams>(
	'customer/getBirthProvinceByCode',
	provinceServiceGetByCode,
);

/**
 * Retrieves a province by its code.
 *
 * @param {IProvince} payload - The province to be retrieved.
 * @param {IProvinceGetByCodeParams} params - Parameters for retrieving the province, such as the province code.
 * @returns {Promise<void>} - A promise that resolves to the province matching the given code.
 */
export const customerGetProvinceByCode = createAsyncAction<IProvince, IProvinceGetByCodeParams>(
	'customer/getProvinceByCode',
	provinceServiceGetByCode,
);

/**
 * Retrieves cities based on a given birth province.
 *
 * @param {ICity[]} payload - The cities to be retrieved.
 * @param {ICityGetByProvinceParams} params - Parameters for retrieving cities, such as the province code.
 * @returns {Promise<void>} - A promise that resolves to an array of cities belonging to the specified province.
 */
export const customerBirthCitiesGetByBirthProvince = createAsyncAction<ICity[], ICityGetByProvinceParams>(
	'customer/birthCitiesGetByBirthProvince',
	cityServiceGetByProvince,
);

/**
 * Retrieves cities based on a country and postal code.
 *
 * @param {ICity[]} payload - The cities to be retrieved.
 * @param {ICityGetByCountryAndPostCodeParams} params - Parameters for retrieving cities, such as the country and postal code.
 * @returns {Promise<void>} - A promise that resolves to an array of cities within the specified country and postal code.
 */
export const customerCitiesGetByCountryAndPostCode = createAsyncAction<ICity[], ICityGetByCountryAndPostCodeParams>(
	'customer/citiesGetByCountryAndPostCode',
	cityServiceGetByCountryAndPostCode,
);

/**
 * Retrieves postal codes based on a country and partial text match.
 *
 * @param {IPostCode[]} payload - The postal codes to be retrieved.
 * @param {IPostCodeGetByCountryAndTextParams} params - Parameters for retrieving postal codes, such as the country and partial text.
 * @returns {Promise<void>} - A promise that resolves to an array of postal codes matching the criteria.
 */
export const customerPostCodesGetByCountryAndText = createAsyncAction<IPostCode[], IPostCodeGetByCountryAndTextParams>(
	'customer/postCodesGetByCountryAndText',
	postCodeServiceGetByCountryAndText,
);

/**
 * Resets the state of customer birth provinces.
 */
export const customerBirthProvincesReset = createAction('customer/birthProvincesReset');

/**
 * Resets the state of customer birth cities.
 */
export const customerBirthCitiesReset = createAction('customer/birthCitiesReset');

/**
 * Resets the state of customer provinces.
 */
export const customerProvincesReset = createAction('customer/provincesReset');

/**
 * Resets the state of customer cities.
 */
export const customerCitiesReset = createAction('customer/citiesReset');
