import type { IRentRateValidation } from '@crac/core/modules/pricing/rentRateValidation/entities/RentRateValidation';
import { RentRateValidationEndpoints } from '@crac/core/modules/pricing/rentRateValidation/services/RentRateValidationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

export interface IRentRateValidationSearchParams extends IServiceBaseParams {
	branch?: number;
	vehicleGroupMinor?: string;
	vehicleGroupMajor?: string;
}

/**
 * Search rent rate validations
 * @param model IRentRateValidationSearchParams
 * @returns IRentRateValidation[]
 */
export const rentRateValidationSearchService = (model: IRentRateValidationSearchParams) =>
	Api.get<IRentRateValidation[], IRentRateValidationSearchParams>(RentRateValidationEndpoints.SEARCH, model);
