import type { IInvoiceableServiceConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/invoiceableServiceConfiguration/entities/InvoiceableServiceConfiguration';
import { InvoiceableServiceConfigurationEndpoints } from '@crac/core/modules/pricing/partnerConfiguration/invoiceableServiceConfiguration/services/InvoiceableServiceConfigurationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

/**
 * Modify a service invoiceable configuration params
 */
export interface IInvoiceableServiceConfigurationModifyParams
	extends IServiceBaseParams,
		Partial<IInvoiceableServiceConfiguration> {
	id: number;
	service: string;
}

/**
 * Modify service invoiceable configuration
 * @param params `IInvoiceableServiceConfigurationModifyParams`
 * @returns `Promise<ServiceResponse<IInvoiceableServiceConfiguration>>`
 */
export const invoiceableServiceConfigurationModifyService = (params: IInvoiceableServiceConfigurationModifyParams) => {
	return Api.post<IInvoiceableServiceConfiguration, IInvoiceableServiceConfigurationModifyParams>(
		InvoiceableServiceConfigurationEndpoints.MODIFY,
		params,
	);
};
