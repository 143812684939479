import type { CSSProperties, ReactElement } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { Cell } from '@tanstack/react-table';

interface ChildrenProps {
	style: CSSProperties;
	setNodeRef: (node: HTMLElement | null) => void;
}

export const DragAlongCell = ({
	cell,
	children,
}: {
	cell: Cell<any, unknown>;
	children: (props: ChildrenProps) => ReactElement;
}) => {
	const { isDragging, setNodeRef, transform } = useSortable({
		id: cell.column.id,
	});

	const style: CSSProperties = {
		opacity: isDragging ? 0.8 : 1,
		position: 'relative',
		transform: CSS.Translate.toString(transform),
		transition: 'width transform 0.2s ease-in-out',
		width: cell.column.getSize(),
		zIndex: isDragging ? 1 : 0,
	};

	return children({ style, setNodeRef });
};
