import type { IInvoiceableServiceConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/invoiceableServiceConfiguration/entities/InvoiceableServiceConfiguration';
import type { IInvoiceableServiceConfigurationDeactivateParams } from '@crac/core/modules/pricing/partnerConfiguration/invoiceableServiceConfiguration/services/InvoiceableServiceConfigurationDeactivateService';
import { invoiceableServiceConfigurationDeactivateService } from '@crac/core/modules/pricing/partnerConfiguration/invoiceableServiceConfiguration/services/InvoiceableServiceConfigurationDeactivateService';
import type { IInvoiceableServiceConfigurationInsertParams } from '@crac/core/modules/pricing/partnerConfiguration/invoiceableServiceConfiguration/services/InvoiceableServiceConfigurationInsertService';
import { invoiceableServiceConfigurationInsertService } from '@crac/core/modules/pricing/partnerConfiguration/invoiceableServiceConfiguration/services/InvoiceableServiceConfigurationInsertService';
import type { IInvoiceableServiceConfigurationModifyParams } from '@crac/core/modules/pricing/partnerConfiguration/invoiceableServiceConfiguration/services/InvoiceableServiceConfigurationModifyService';
import { invoiceableServiceConfigurationModifyService } from '@crac/core/modules/pricing/partnerConfiguration/invoiceableServiceConfiguration/services/InvoiceableServiceConfigurationModifyService';
import type { IInvoiceableServiceConfigurationSearchParams } from '@crac/core/modules/pricing/partnerConfiguration/invoiceableServiceConfiguration/services/InvoiceableServiceConfigurationSearchService';
import { invoiceableServiceConfigurationSearchService } from '@crac/core/modules/pricing/partnerConfiguration/invoiceableServiceConfiguration/services/InvoiceableServiceConfigurationSearchService';
import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

/**
 * Searches for invoiceable service configurations based on specified parameters.
 *
 * @param {IInvoiceableServiceConfiguration[]} payload - The invoiceable service configurations to be returned.
 * @param {IInvoiceableServiceConfigurationSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of invoiceable service configurations.
 */
export const invoiceableServiceConfigurationSearch = createAsyncAction<
	IInvoiceableServiceConfiguration[],
	IInvoiceableServiceConfigurationSearchParams
>('invoiceableServiceConfiguration/search', invoiceableServiceConfigurationSearchService, {
	emptyCreationMethod: true,
});

/**
 * Inserts new invoiceable service configurations into the system.
 *
 * @param {IInvoiceableServiceConfiguration[]} payload - The invoiceable service configurations to be inserted.
 * @param {IInvoiceableServiceConfigurationInsertParams} params - Parameters detailing the invoiceable service configurations to be inserted.
 * @returns {Promise<void>} - A promise that resolves after the invoiceable service configurations are successfully inserted.
 */
export const invoiceableServiceConfigurationInsert = createAsyncAction<
	IInvoiceableServiceConfiguration[],
	IInvoiceableServiceConfigurationInsertParams
>('invoiceableServiceConfiguration/insert', invoiceableServiceConfigurationInsertService, {
	emptyCreationMethod: true,
});

/**
 * Modifies an existing invoiceable service configuration.
 *
 * @param {IInvoiceableServiceConfiguration} payload - The invoiceable service configuration to be modified.
 * @param {IInvoiceableServiceConfigurationModifyParams} params - Parameters for the modification.
 * @returns {Promise<void>} - A promise that resolves after the invoiceable service configuration is modified.
 */
export const invoiceableServiceConfigurationModify = createAsyncAction<
	IInvoiceableServiceConfiguration,
	IInvoiceableServiceConfigurationModifyParams
>('invoiceableServiceConfiguration/modify', invoiceableServiceConfigurationModifyService, {
	emptyCreationMethod: true,
});

/**
 * Removes deactivated invoiceable service configurations from the state.
 *
 * @param {number} payload - The ID of the invoiceable service configuration to be removed.
 */
export const invoiceableServiceConfigurationRemoveDeactivated = createSyncAction<number>(
	'invoiceableServiceConfiguration/removeDeactivated',
);

/**
 * Deactivates invoiceable service configurations.
 *
 * @param {IInvoiceableServiceConfiguration[]} payload - The invoiceable service configurations to be deactivated.
 * @param {IInvoiceableServiceConfigurationDeactivateParams} params - Parameters for the deactivation.
 * @returns {Promise<void>} - A promise that resolves after the invoiceable service configurations are deactivated.
 */
export const invoiceableServiceConfigurationDeactivate = createAsyncAction<
	IInvoiceableServiceConfiguration[],
	IInvoiceableServiceConfigurationDeactivateParams
>('invoiceableServiceConfiguration/deactivate', invoiceableServiceConfigurationDeactivateService);

/**
 * Clears the invoiceable service configuration state.
 */
export const invoiceableServiceConfigurationClear = createSyncAction('invoiceableServiceConfiguration/clear');
