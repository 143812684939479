import type { INotAllowedMovement } from '@crac/core/modules/pricing/notAllowedMovement/entities/NotAllowedMovement';
import { NotAllowedMovementEndpoints } from '@crac/core/modules/pricing/notAllowedMovement/services/NotAllowedMovementEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface INotAllowedMovementInsertParams extends IServiceBaseParams {
	notAllowedMovements: INotAllowedMovement[];
}

export const notAllowedMovementInsertService = (
	model: INotAllowedMovementInsertParams,
): Promise<ServiceResponse<INotAllowedMovement[]>> => {
	return Api.post<INotAllowedMovement[], INotAllowedMovementInsertParams>(NotAllowedMovementEndpoints.INSERT, model);
};
