import { createSlice } from '@reduxjs/toolkit';

import {
	depositRateClear,
	depositRateDeactivate,
	depositRateInsert,
	depositRateSearch,
} from '@crac/core/modules/pricing/depositRate/state/actions/DepositRateActions';
import type { IDepositRateReducerState } from '@crac/core/modules/pricing/depositRate/state/stateType/DepositRateReducerState';

const initialState: IDepositRateReducerState = {
	depositRateInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	depositRates: [],
	depositRateSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	depositRateDeactivateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const depositRateSlice = createSlice({
	name: 'DepositRateSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR
		builder.addCase(depositRateClear, () => {
			return initialState;
		});
		// SEARCH
		builder
			.addCase(depositRateSearch.pending, (state) => {
				return {
					...state,
					depositRateSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(depositRateSearch.fulfilled, (state, action) => {
				return {
					...state,
					depositRateSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					depositRates: action.payload,
				};
			})
			.addCase(depositRateSearch.rejected, (state, action) => {
				return {
					...state,
					depositRateSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(depositRateInsert.pending, (state) => {
				return {
					...state,
					depositRateInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(depositRateInsert.fulfilled, (state) => {
				return {
					...state,
					depositRateInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(depositRateInsert.rejected, (state, action) => {
				return {
					...state,
					depositRateInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// DEACTIVATE
		builder
			.addCase(depositRateDeactivate.pending, (state) => {
				return {
					...state,
					depositRateDeactivateRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(depositRateDeactivate.fulfilled, (state, action) => {
				return {
					...state,
					depositRateDeactivateRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					depositRates: [
						...state.depositRates.filter(({ id }) => !action.payload.map((item) => item.id).includes(id)),
						...action.payload,
					],
				};
			})
			.addCase(depositRateDeactivate.rejected, (state, action) => {
				return {
					...state,
					depositRateDeactivateRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const depositRateReducer = depositRateSlice.reducer;
