import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CommentEndPoints } from './CommentsEndpoints';
import type { IComment } from '../entities/Comment';

export interface ICommentRemoveFromCustomerParams extends IServiceBaseParams {
	code: string;
	lineNumber: number;
	table?: number;
}

/**
 * Remove comment from customer
 *  {string} lineNumber Line number
 *  {string} code Customer code
 */
export const commentRemoveFromCustomerService = (
	model: ICommentRemoveFromCustomerParams,
): Promise<ServiceResponse<IComment>> => {
	return Api.post<IComment, ICommentRemoveFromCustomerParams>(CommentEndPoints.REMOVE_FROM_CUSTOMER, model);
};
