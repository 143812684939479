import { PickUpEndpoints } from './PickUpEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IBooking } from '../../entities/Booking';

export interface IBookingPickUpGetPendingByBranchParams extends IServiceBaseParams {
	branchCode: string;
}
/**
 * Get list pending Drop Off booking by branch
 *  {Booking} booking Data register booking
 * @return {Promise} `Array<BookingPropType>` Booking data
 */
export const bookingGetPendingPickUpByBranchService = (
	model: IBookingPickUpGetPendingByBranchParams,
): Promise<ServiceResponse<IBooking[]>> => {
	return Api.get<IBooking[], IBookingPickUpGetPendingByBranchParams>(
		PickUpEndpoints.GET_PENDING_PICKUP_BY_BRANCH,
		model,
	);
};
