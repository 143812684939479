import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CommentEndPoints } from './CommentsEndpoints';
import type { IComment } from '../entities/Comment';

export interface ICommentAddToBookingParams extends IServiceBaseParams {
	code: string;
	message: string;
}

/**
 * Add comment to booking
 *  {string} message Comment message
 *  {string} code Booking code
 */
export const commentAddToBookingService = (model: ICommentAddToBookingParams): Promise<ServiceResponse<IComment>> => {
	return Api.post<IComment, ICommentAddToBookingParams>(CommentEndPoints.ADD_TO_BOOKING, model);
};
