import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CustomerEndPoints } from './CustomerEndpoints';
import type { IAccidentalAddress } from '../../../models/entities/AccidentalAddress';
import type { ICustomer } from '../entities/Customer';

export interface ICustomerSaveParams extends IServiceBaseParams, IAccidentalAddress {
	allowCommercialCommunications?: boolean;
	allowCommercialSegmentation?: boolean;
	allowPendingInRegisterContract?: boolean;
	birthCity?: string;
	birthCountry?: string;
	birthDate?: string | Date;
	birthProvince?: string;
	block?: string;
	blocked?: boolean;
	bookingNumber?: string;
	city?: string;
	code?: string;
	country?: string;
	customerRentType?: number;
	digitizationRegistrationDate?: string | Date;
	door?: string;
	driverLicenseAuthority?: string;
	driverLicenseCountry?: string;
	driverLicenseExpeditionDate?: string | Date;
	driverLicenseExpirationDate?: string | Date;
	driverLicenseNumber?: string;
	email?: string;
	firstSurname?: string;
	floor?: string;
	gender: number;
	identificationDocumentExpirationDate?: string | Date;
	isValidDigitization?: boolean;
	letter?: string;
	moreThanOneBooking?: boolean;
	name: string;
	number?: string;
	password?: string;
	pec?: string;
	phone?: string;
	phoneCountry?: string;
	points?: number;
	postalCode?: string;
	preferedLanguage?: string;
	profession?: string;
	province?: string;
	roadName?: string;
	roadType?: number | null;
	sdiCode?: string;
	secondSurname?: string;
	staff?: boolean;
	stair?: string;
	taxIdentificationNumber?: string;
	taxOffice?: string;
	transfer?: boolean;
	validationType?: number;
}

/**
 * Insert customer
 *  {Customer} customer Customer object
 * @returns {Promise} `CustomerPropType` Update customer
 */
export const customerSaveService = (model: ICustomerSaveParams): Promise<ServiceResponse<ICustomer>> => {
	return Api.post<ICustomer, ICustomerSaveParams>(CustomerEndPoints.INSERT, model);
};
