import type { ReactElement, RefObject } from 'react';

import type { Table } from '@tanstack/react-table';
import type { VirtualItem } from '@tanstack/react-virtual';
import type { VirtualItem as ReactVirtualItem } from 'react-virtual';

import { useReactVirtual, useTanStackVirtualizer } from './hooks';

type RenderProps = {
	virtualRows: VirtualItem[] | ReactVirtualItem[];
	paddingTop: number;
	paddingBottom: number;
	totalSize: number;
};

interface IVirtualizerContainerProps {
	table: Table<any>;
	tableContainerRef: RefObject<HTMLDivElement>;
	renderSubComponent?: boolean;
	children: (props: RenderProps) => ReactElement;
}

const TanStackVirtualizer = ({ table, tableContainerRef, children }: IVirtualizerContainerProps) => {
	const { paddingBottom, paddingTop, totalSize, virtualRows } = useTanStackVirtualizer({ table, tableContainerRef });
	return children({ virtualRows, paddingBottom, paddingTop, totalSize });
};

const ReactVirtual = ({ table, tableContainerRef, children }: IVirtualizerContainerProps) => {
	const { paddingBottom, paddingTop, totalSize, virtualRows } = useReactVirtual({ table, tableContainerRef });
	return children({ paddingBottom, paddingTop, totalSize, virtualRows });
};

export const VirtualizerContainer = ({
	table,
	tableContainerRef,
	renderSubComponent,
	children,
}: IVirtualizerContainerProps) =>
	renderSubComponent ? (
		<ReactVirtual table={table} tableContainerRef={tableContainerRef}>
			{children}
		</ReactVirtual>
	) : (
		<TanStackVirtualizer table={table} tableContainerRef={tableContainerRef}>
			{children}
		</TanStackVirtualizer>
	);
