import type { IMaximumExcessRate } from '@crac/core/modules/pricing/maximumExcessRate/entities/MaximumExcessRate';
import { MaximumExcessRateEndpoints } from '@crac/core/modules/pricing/maximumExcessRate/services/MaximumExcessRateEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

export interface IMaximumExcessRateDeactivateParams extends IServiceBaseParams {
	ids: number[];
}

/**
 * Deactivate excess rate
 * @param model IMaximumExcessRateDeactivateParams
 * @returns IMaximumExcessRate[]
 */
export const maximumExcessRateDeactivateService = (model: IMaximumExcessRateDeactivateParams) =>
	Api.post<IMaximumExcessRate[], IMaximumExcessRateDeactivateParams>(MaximumExcessRateEndpoints.DEACTIVATE, model);
