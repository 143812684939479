import { PickUpEndpoints } from './PickUpEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';

export interface IBookingCanDoDirectPickUpParams extends IServiceBaseParams {
	bookingNumber: string;
}

/**
 * Check can do direct pickup
 * @param params Booking number
 */
export const bookingCanDoDirectPickUpService = (
	params: IBookingCanDoDirectPickUpParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.get<boolean, IBookingCanDoDirectPickUpParams>(PickUpEndpoints.CAN_DO_DIRECT_PICKUP, params);
};
