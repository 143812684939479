import { emailSendFeedBackService } from '@crac/core/modules/email/services/EmailSendFeedbackService';
import { Api } from '@crac/core/modules/shared/api';
import { CreationMethod } from '@crac/core/modules/shared/types/CreationMethod';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { BookingEndPoints } from './BookingEndpoints';
import type { IBookingInsertExternalResponse } from '../../../models/serviceResponse/BookingResponse';

export interface IPaymentCallbackParams extends IServiceBaseParams {
	currencyCode?: string;
	transactionNumber: string;
	transactionId: number;
	from?: 'web' | 'app';
}

export const bookingInsertCallBackService = async (
	model: IPaymentCallbackParams,
): Promise<ServiceResponse<IBookingInsertExternalResponse>> => {
	const response = await Api.post<IBookingInsertExternalResponse, IPaymentCallbackParams>(
		BookingEndPoints.INSERT_CALLBACK,
		model,
	);

	if (response.ok && response.data?.transaction && !response.data.transaction.transactionId) {
		await emailSendFeedBackService({
			json: JSON.stringify({
				from: model.from ?? 'web',
				action: BookingEndPoints.INSERT_CALLBACK,
				params: model,
				response: response.data,
			}),
			creationMethod: CreationMethod.BRANCH,
			files: [],
		});
	}

	return response;
};
