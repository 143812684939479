import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { BookingEndPoints } from './BookingEndpoints';

export interface IBookingSendExtendPaymentEmailParams extends IServiceBaseParams {
	bookingNumber: string;
	dropOffDateTime: string | Date;
	quoteDateTime: string | Date;
}

export const bookingSendExtendPaymentEmailService = (
	model: IBookingSendExtendPaymentEmailParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IBookingSendExtendPaymentEmailParams>(BookingEndPoints.SEND_EXTEND_PAYMENT_EMAIL, model);
};
