import type { IServiceRateType } from '@crac/core/modules/pricing/serviceRateType/entities/ServiceRateType';
import { ServiceRateTypeEndpoints } from '@crac/core/modules/pricing/serviceRateType/services/ServiceRateTypeEndpoints';
import type { IServiceRateTypeInsertParams } from '@crac/core/modules/pricing/serviceRateType/services/ServiceRateTypeInsertService';
import { Api } from '@crac/core/modules/shared/api';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export const serviceRateTypeModifyService = (
	model: IServiceRateTypeInsertParams,
): Promise<ServiceResponse<IServiceRateType>> => {
	return Api.post<IServiceRateType, IServiceRateTypeInsertParams>(ServiceRateTypeEndpoints.MODIFY, model);
};
