import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import {
	pickUpCreateCustomerAndModifyBooking,
	pickUpModifyCustomerAndBooking,
} from '@crac/core/modules/booking/pickUp/state/actions/PickUpActions';
import type { ICustomer } from '@crac/core/modules/customer/entities/Customer';
import {
	customerAcceptGdpr,
	customerCanMakeContract,
	customerClear,
	customerGetByCode,
	customerGetDetailData,
	customerInvoiceToGetByCode,
	customerModify,
	customerModifyDuplicateTaxId,
	customerResetPassword,
	customerSave,
	customerSendResetPassword,
	customerValidateData,
} from '@crac/core/modules/customer/state/actions/CustomerActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { customerDigitizationRemove } from '@crac/core/redux/actions/CustomerDigitizationActions';
import type { ICustomerDetailReducerState } from '@crac/core/redux-store/reducersState/customer/DetailReducerState';

const initialState: ICustomerDetailReducerState = {
	customer: null,
	customerInvoiceTo: null,
	customerAcceptGDPRRequest: {
		inProgress: true,
		messages: [],
		ok: true,
	},
	customerCanMakeContractRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	customerGetByCodeRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	customerInvoiceToGetByCodeRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	customerModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	customerSaveRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	customerSendResetPasswordRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	customerValidationDataRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	customerDigitizationRemoveRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	customerModifyDuplicateTaxIdRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	customerGetDetailDataRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const detailSlice = createSlice({
	name: 'CustomerDetailSlice',
	initialState,
	reducers: {},
	// eslint-disable-next-line max-lines-per-function
	extraReducers: (builder) => {
		// GET_BY_CODE
		builder
			.addCase(customerGetByCode.pending, (state) => {
				return {
					...state,
					customer: null,
					customerGetByCodeRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerGetByCode.fulfilled, (state, action) => {
				return {
					...state,
					customer: action.payload,
					customerGetByCodeRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerGetByCode.rejected, (state, action) => {
				return {
					...state,
					customerGetByCodeRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INVOICE_TO_GET_BY_CODE
		builder
			.addCase(customerInvoiceToGetByCode.pending, (state) => {
				return {
					...state,
					customerInvoiceTo: null,
					customerInvoiceToGetByCodeRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerInvoiceToGetByCode.fulfilled, (state, action) => {
				return {
					...state,
					customerInvoiceTo: action.payload,
					customerInvoiceToGetByCodeRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerInvoiceToGetByCode.rejected, (state, action) => {
				return {
					...state,
					customerInvoiceToGetByCodeRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(customerSave.pending, (state) => {
				return {
					...state,
					customerSaveRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerSave.fulfilled, (state, action) => {
				return {
					...state,
					customer: deepCopy(action.payload),
					customerSaveRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerSave.rejected, (state, action) => {
				return {
					...state,
					customerSaveRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(customerModify.pending, (state) => {
				return {
					...state,
					customerModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerModify.fulfilled, (state, action) => {
				return {
					...state,
					customer: deepCopy(action.payload),
					customerModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerModify.rejected, (state, action) => {
				return {
					...state,
					customerModifyRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// MODIFY_DUPLICATE_TAX_ID_REQUEST
		builder
			.addCase(customerModifyDuplicateTaxId.pending, (state) => {
				return {
					...state,
					customerModifyDuplicateTaxIdRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerModifyDuplicateTaxId.fulfilled, (state, action) => {
				return {
					...state,
					customer: deepCopy(action.payload),
					customerModifyDuplicateTaxIdRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerModifyDuplicateTaxId.rejected, (state, action) => {
				return {
					...state,
					customerModifyDuplicateTaxIdRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// GET_DETAIL_DATA
		builder
			.addCase(customerGetDetailData.pending, (state) => {
				return {
					...state,
					customer: null,
					customerGetDetailDataRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerGetDetailData.fulfilled, (state, action) => {
				return {
					...state,
					customer: deepCopy(action.payload?.customer) || null,
					customerGetDetailDataRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerGetDetailData.rejected, (state, action) => {
				return {
					...state,
					customerGetDetailDataRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// VALIDATION
		builder
			.addCase(customerValidateData.pending, (state) => {
				return {
					...state,
					customerValidationDataRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerValidateData.fulfilled, (state, action) => {
				return {
					...state,
					customerValidationDataRequest: {
						inProgress: false,
						messages: [],
						ok: action.payload,
					},
				};
			})
			.addCase(customerValidateData.rejected, (state, action) => {
				return {
					...state,
					customerValidationDataRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// CAN_MAKE_CONTRACT
		builder
			.addCase(customerCanMakeContract.pending, (state) => {
				return {
					...state,
					customerCanMakeContractRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerCanMakeContract.fulfilled, (state) => {
				return {
					...state,
					customerCanMakeContractRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerCanMakeContract.rejected, (state, action) => {
				return {
					...state,
					customerCanMakeContractRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// RESET_PASSWORD
		builder
			.addCase(customerResetPassword.pending, (state) => {
				return {
					...state,
					customerSendResetPasswordRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerResetPassword.fulfilled, (state) => {
				return {
					...state,
					customerSendResetPasswordRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerResetPassword.rejected, (state, action) => {
				return {
					...state,
					customerSendResetPasswordRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// SEND_RESET_PASSWORD
		builder
			.addCase(customerSendResetPassword.pending, (state) => {
				return {
					...state,
					customerSendResetPasswordRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerSendResetPassword.fulfilled, (state) => {
				return {
					...state,
					customerSendResetPasswordRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerSendResetPassword.rejected, (state, action) => {
				return {
					...state,
					customerSendResetPasswordRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// ACCEPT_GDPR
		builder
			.addCase(customerAcceptGdpr.pending, (state) => {
				return {
					...state,
					customerAcceptGDPRRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerAcceptGdpr.fulfilled, (state) => {
				return {
					...state,
					customerAcceptGDPRRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerAcceptGdpr.rejected, (state, action) => {
				return {
					...state,
					customerAcceptGDPRRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// DIGITIZATION_REMOVE
		builder
			.addCase(customerDigitizationRemove.pending, (state) => {
				return {
					...state,
					customerDigitizationRemoveRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerDigitizationRemove.fulfilled, (state, action) => {
				return {
					...state,
					customer: deepCopy(action.payload),
					customerDigitizationRemoveRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerDigitizationRemove.rejected, (state, action) => {
				return {
					...state,
					customerDigitizationRemoveRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		/**
		 * CLEAR CACHE
		 * CLEAR
		 */
		builder.addMatcher(isAnyOf(clearGlobalCache, customerClear), () => {
			return initialState;
		});
		// PICKUP MODIFY
		builder
			.addMatcher(
				isAnyOf(pickUpModifyCustomerAndBooking.pending, pickUpCreateCustomerAndModifyBooking.pending),
				(state) => {
					return {
						...state,
						customerModifyRequest: {
							inProgress: true,
							messages: [],
							ok: true,
						},
					};
				},
			)
			.addMatcher(
				isAnyOf(pickUpModifyCustomerAndBooking.fulfilled, pickUpCreateCustomerAndModifyBooking.fulfilled),
				(state, action) => {
					return {
						...state,
						customer: deepCopy(action.payload.customer) as ICustomer,
						customerModifyRequest: {
							inProgress: false,
							messages: [],
							ok: true,
						},
					};
				},
			)
			.addMatcher(
				isAnyOf(pickUpModifyCustomerAndBooking.rejected, pickUpCreateCustomerAndModifyBooking.rejected),
				(state, action) => {
					return {
						...state,
						customerModifyRequest: {
							inProgress: false,
							messages: deepCopy(action.payload) || [],
							ok: false,
						},
					};
				},
			);
	},
});

export const detailReducer = detailSlice.reducer;
