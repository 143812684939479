import type { IOneWay } from '@crac/core/modules/pricing/oneWay/entities/OneWay';
import type { IOneWayDeleteParams } from '@crac/core/modules/pricing/oneWay/services/OneWayDeleteService';
import { oneWayDeleteService } from '@crac/core/modules/pricing/oneWay/services/OneWayDeleteService';
import type { IOneWayInsertParams } from '@crac/core/modules/pricing/oneWay/services/OneWayInsertService';
import { oneWayInsertService } from '@crac/core/modules/pricing/oneWay/services/OneWayInsertService';
import type { IOneWayModifyParams } from '@crac/core/modules/pricing/oneWay/services/OneWayModifyService';
import { oneWayModifyService } from '@crac/core/modules/pricing/oneWay/services/OneWayModifyService';
import type { IOneWaySearchParams } from '@crac/core/modules/pricing/oneWay/services/OneWaySearchService';
import { oneWaySearchService } from '@crac/core/modules/pricing/oneWay/services/OneWaySearchService';
import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

/**
 * Searches for one-way records based on specified parameters.
 *
 * @param {IOneWay[]} payload - The one-way records to be retrieved.
 * @param {IOneWaySearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of one-way records.
 */
export const oneWaySearch = createAsyncAction<IOneWay[], IOneWaySearchParams>('oneWay/search', oneWaySearchService);

/**
 * Inserts new one-way records.
 *
 * @param {IOneWay[]} payload - The one-way records to be inserted.
 * @param {IOneWayInsertParams} params - Parameters for the insertion.
 * @returns {Promise<void>} - A promise that resolves after the one-way records are inserted.
 */
export const oneWayInsert = createAsyncAction<IOneWay[], IOneWayInsertParams>('oneWay/insert', oneWayInsertService);

/**
 * Modifies an existing one-way record.
 *
 * @param {IOneWay} payload - The one-way record to be modified.
 * @param {IOneWayModifyParams} params - Parameters for the modification.
 * @returns {Promise<void>} - A promise that resolves after the one-way record is modified.
 */
export const oneWayModify = createAsyncAction<IOneWay, IOneWayModifyParams>('oneWay/modify', oneWayModifyService);

/**
 * Deletes a specific one-way record.
 *
 * @param {IOneWay} payload - The one-way record to be deleted.
 * @param {IOneWayDeleteParams} params - Parameters for the deletion.
 * @returns {Promise<void>} - A promise that resolves after the one-way record is deleted.
 */
export const oneWayDelete = createAsyncAction<IOneWay, IOneWayDeleteParams>('oneWay/delete', oneWayDeleteService);

/**
 * Clears the one-way state.
 *
 */
export const oneWayClear = createSyncAction('oneWay/clear');
