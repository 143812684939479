import type { IPackageConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/packageConfiguration/entities/PackageConfiguration';
import { PackageConfigurationEndpoints } from '@crac/core/modules/pricing/partnerConfiguration/packageConfiguration/services/PackageConfigurationEndpoints';
import type { PackageType } from '@crac/core/modules/pricing/partnerConfiguration/packageConfiguration/types/PackageType';
import type { IPartnerConfigurationSearch } from '@crac/core/modules/pricing/partnerConfiguration/types/PartnerConfigurationSearchType';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IPackageConfigurationSearchParams extends IServiceBaseParams, IPartnerConfigurationSearch {
	packageType?: PackageType;
	services?: string[];
}

/**
 * Search package configurations
 * @param model IPackageConfigurationSearchParams
 * @returns IPackageConfiguration[]
 */
export const packageConfigurationSearchService = (
	model: IPackageConfigurationSearchParams,
): Promise<ServiceResponse<IPackageConfiguration[]>> => {
	return Api.get<IPackageConfiguration[], IPackageConfigurationSearchParams>(
		PackageConfigurationEndpoints.SEARCH,
		model,
	);
};
