import { createAction } from '@reduxjs/toolkit';

import { removeKeyWithUndefinedValue } from '@crac/core/helpers/commons';
import type { ConnectedVehicleProvider } from '@crac/core/modules/fleet/types/ConnectedVehicleProvider';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { VehicleSearchEndPoints } from './VehicleSearchEndpoints';
import type { FleetTypology } from '../../../../models/types/FleetTypology';
import type { VehicleSearchType } from '../../../../models/types/VehicleSearchType';
import type { VehicleStateType } from '../../../../models/types/VehicleStateType';
import type { IVehicle } from '../../entities/Vehicle';
import type { MovementRestrictionType } from '../../types/MovementRestrictionType';

export interface IVehicleSearchParams extends IServiceBaseParams {
	agreedRepurchaseDateFrom?: string | Date;
	agreedRepurchaseDateTo?: string | Date;
	blockStartDate?: string | Date;
	branchCodes?: string[];
	brandParkingId?: string;
	colors?: string[];
	createdAtFrom?: string | Date;
	createdAtTo?: string | Date;
	expectedDefleetDateFrom?: string | Date;
	expectedDefleetDateTo?: string | Date;
	fleetTypologies?: FleetTypology[];
	frameNumber?: string;
	frameNumbers?: string[];
	insuranceEndDateFrom?: string | Date;
	insuranceEndDateTo?: string | Date;
	kmFrom?: number;
	kmTo?: number;
	lastRevisionDateFrom?: string | Date;
	lastRevisionDateTo?: string | Date;
	movementRestrictions?: MovementRestrictionType[];
	plateNumber?: string;
	plateNumbers?: string[];
	previsionCode?: string;
	provider?: string;
	purchaseDateFrom?: string | Date;
	purchaseDateTo?: string | Date;
	receptionDateFrom?: string | Date;
	receptionDateTo?: string | Date;
	registrationDateFrom?: string | Date;
	registrationDateTo?: string | Date;
	SkProvider?: ConnectedVehicleProvider;
	suppliers?: string[];
	vehicleBrandCodes?: string[];
	vehicleGroupCodes?: string[];
	vehicleModelCodes?: string[];
	vehicleSearchType?: VehicleSearchType;
	vehicleStates?: VehicleStateType[];
	// OLD PARAMS
	expectedDefleetDate?: string | Date;
	repurchaseDate?: string | Date;
	carrierCodes?: string[];
	lastContractDropOffDateTimeFrom?: string;
	lastContractDropOffDateTimeTo?: string;
	actualDefleetDateTimeFrom?: string;
	actualDefleetDateTimeTo?: string;
}

const encodeModels = (values?: string | string[]) => {
	if (values) {
		return Array.isArray(values) ? values.map((item) => encodeURIComponent(item)) : [encodeURIComponent(values)];
	}
	return undefined;
};

/**
 * Search vehicles
 * @param model {IVehicleSearchParams} - The search parameters.
 * @returns {Promise} - A promise that resolves to the vehicles that match the search parameters.
 */
export const vehicleSearchService = (model: IVehicleSearchParams): Promise<ServiceResponse<IVehicle[]>> => {
	const params: IVehicleSearchParams = { ...model, vehicleModelCodes: encodeModels(model.vehicleModelCodes) };
	removeKeyWithUndefinedValue(params);
	return Api.get<IVehicle[], IVehicleSearchParams>(VehicleSearchEndPoints.SEARCH, params);
};

/**
 * Clears the vehicle search state.
 */
export const vehicleSearchClear = createAction('vehicle/searchClear');
