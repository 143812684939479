import type { FC } from 'react';
import React from 'react';

import type { IActionStatus } from '../../../../types/IActions';
import { ToggleButton } from '../../toggleButton';
import type { IActionProps } from '../IList';
import { ActionContainer } from '../styled';

export const ToggleAction: FC<IActionProps> = ({ action, item }) => {
	return (
		<ActionContainer>
			<ToggleButton
				block={action.block}
				disabled={action.disabled}
				item={item}
				key={action.key}
				status={action.status as IActionStatus}
				toggleProp={action.toggleProp as string}
			/>
		</ActionContainer>
	);
};
