import { createSlice } from '@reduxjs/toolkit';

import {
	maximumExcessRateClear,
	maximumExcessRateDeactivate,
	maximumExcessRateInsert,
	maximumExcessRateSearch,
} from '@crac/core/modules/pricing/maximumExcessRate/state/actions/MaximumExcessRateActions';
import type { IMaximumExcessRateReducerState } from '@crac/core/modules/pricing/maximumExcessRate/state/stateType/MaximumExcessRateReducerState';
const initialState: IMaximumExcessRateReducerState = {
	maximumExcessRateInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	maximumExcessRates: [],
	maximumExcessRatesSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	maximumExcessRate: null,
	maximumExcessRateDeactivateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const maximumExcessRateSlice = createSlice({
	name: 'MaximumExcessRateSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// SEARCH
		builder
			.addCase(maximumExcessRateSearch.pending, (state) => {
				return {
					...state,
					maximumExcessRatesSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(maximumExcessRateSearch.fulfilled, (state, action) => {
				return {
					...state,
					maximumExcessRatesSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					maximumExcessRates: action.payload,
				};
			})
			.addCase(maximumExcessRateSearch.rejected, (state, action) => {
				return {
					...state,
					maximumExcessRatesSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(maximumExcessRateInsert.pending, (state) => {
				return {
					...state,
					maximumExcessRateInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(maximumExcessRateInsert.fulfilled, (state) => {
				return {
					...state,
					maximumExcessRateInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(maximumExcessRateInsert.rejected, (state, action) => {
				return {
					...state,
					maximumExcessRateInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// DEACTIVATE
		builder
			.addCase(maximumExcessRateDeactivate.pending, (state) => {
				return {
					...state,
					maximumExcessRateDeactivateRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(maximumExcessRateDeactivate.fulfilled, (state, action) => {
				return {
					...state,
					maximumExcessRateDeactivateRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					maximumExcessRates: [
						...state.maximumExcessRates.filter(
							({ id }) => !action.payload.map((item) => item.id).includes(id),
						),
						...action.payload,
					],
				};
			})
			.addCase(maximumExcessRateDeactivate.rejected, (state, action) => {
				return {
					...state,
					maximumExcessRateDeactivateRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR
		builder.addCase(maximumExcessRateClear, () => {
			return initialState;
		});
	},
});

export const maximumExcessRateReducer = maximumExcessRateSlice.reducer;
