import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { customerModifyService } from './CustomerModifyService';
import { GenderType } from '../../../models/types/GenderType';
import type { ICustomer } from '../entities/Customer';

export interface ICustomerBlockParams extends IServiceBaseParams {
	customer: ICustomer;
}

export const CustomerBlockService = async (params: ICustomerBlockParams): Promise<ServiceResponse<ICustomer>> => {
	const { customer } = params;

	const response = await customerModifyService({
		...customer,
		blocked: !customer.blocked,
		gender: customer.gender || GenderType.Empty,
	});

	return response;
};
