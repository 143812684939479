import { createAction } from '@reduxjs/toolkit';

import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

import type { IPaymentLine } from '../../entities/PaymentLine';
import type { IPaymentLineAddParams } from '../../services/PaymentLineAddService';
import { paymentLineAddService } from '../../services/PaymentLineAddService';
import type { IPaymentLineDeleteChargeBackParams } from '../../services/PaymentLineDeleteChargeBackService';
import { paymentLineDeleteChargeBackService } from '../../services/PaymentLineDeleteChargeBackService';
import type { IPaymentLineGetByBookingParams } from '../../services/PaymentLineGetByBookingService';
import { paymentLineGetByBookingService } from '../../services/PaymentLineGetByBookingService';
import type { IPaymentLineGetByCustomerParams } from '../../services/PaymentLineGetByCustomerService';
import { paymentLineGetByCustomerService } from '../../services/PaymentLineGetByCustomerService';
import type { IPaymentLineSearchParams } from '../../services/PaymentLineSearchService';
import { paymentLineSearchService } from '../../services/PaymentLineSearchService';
import type { IPaymentLineUpdateChargeBackParams } from '../../services/PaymentLineUpdateChargeBackService';
import { paymentLineUpdateChargeBackService } from '../../services/PaymentLineUpdateChargeBackService';

/**
 * Inserts a new payment line.
 *
 * @param {IPaymentLine} payload - The payment line to be inserted.
 * @param {IPaymentLineAddParams} params - Parameters for the insertion of the payment line.
 * @returns {Promise<void>} - A promise that resolves after the payment line is inserted.
 */
export const paymentLineInsert = createAsyncAction<IPaymentLine, IPaymentLineAddParams>(
	'paymentLine/insert',
	paymentLineAddService,
);

/**
 * Retrieves payment lines associated with a specific booking.
 *
 * @param {IPaymentLine[]} payload - The payment lines to be retrieved.
 * @param {IPaymentLineGetByBookingParams} params - Parameters to identify payment lines associated with a booking.
 * @returns {Promise<void>} - A promise that resolves to an array of payment lines.
 */
export const paymentLineGetByBooking = createAsyncAction<IPaymentLine[], IPaymentLineGetByBookingParams>(
	'paymentLine/getByBooking',
	paymentLineGetByBookingService,
);

/**
 * Retrieves payment lines associated with a specific customer.
 *
 * @param {IPaymentLine[]} payload - The payment lines to be retrieved.
 * @param {IPaymentLineGetByCustomerParams} params - Parameters to identify payment lines associated with a customer.
 * @returns {Promise<void>} - A promise that resolves to an array of payment lines.
 */
export const paymentLineGetByCustomer = createAsyncAction<IPaymentLine[], IPaymentLineGetByCustomerParams>(
	'paymentLine/getByCustomer',
	paymentLineGetByCustomerService,
);

/**
 * Searches for payment lines based on specified parameters.
 *
 * @param {IPaymentLine[]} payload - The payment lines to be returned based on the search criteria.
 * @param {IPaymentLineSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of payment lines.
 */
export const paymentLineSearch = createAsyncAction<IPaymentLine[], IPaymentLineSearchParams>(
	'paymentLine/search',
	paymentLineSearchService,
);

/**
 * Updates a chargeback on a specific payment line.
 *
 * @param {IPaymentLine} payload - The payment line to be updated.
 * @param {IPaymentLineUpdateChargeBackParams} params - Parameters for updating the chargeback on the payment line.
 * @returns {Promise<void>} - A promise that resolves after the chargeback is updated.
 */
export const paymentLineUpdateChargeBack = createAsyncAction<IPaymentLine, IPaymentLineUpdateChargeBackParams>(
	'paymentLine/updateChargeBack',
	paymentLineUpdateChargeBackService,
);

/**
 * Clears the payment line state.
 */
export const paymentLineClear = createAction('paymentLine/clear');

/**
 * Deletes a chargeback from a specific payment line.
 *
 * @param {IPaymentLine} payload - The payment line from which the chargeback is to be deleted.
 * @param {IPaymentLineDeleteChargeBackParams} params - Parameters for deleting the chargeback from the payment line.
 * @returns {Promise<void>} - A promise that resolves after the chargeback is deleted.
 */
export const paymentLineDeleteChargeBack = createAsyncAction<IPaymentLine, IPaymentLineDeleteChargeBackParams>(
	'paymentLine/deleteChargeBack',
	paymentLineDeleteChargeBackService,
);
