import { PickUpEndpoints } from './PickUpEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IBooking } from '../../entities/Booking';

export interface IBookingRegisterContractParams extends IServiceBaseParams {
	bookingNumber: string;
}
/**
 * Register contract by booking number
 *  {string} bookingNumber booking number
 * @returns {Promise<BookingPropType>} `BookingPropType`
 */
export const bookingRegisterContractService = (
	model: IBookingRegisterContractParams,
): Promise<ServiceResponse<IBooking>> => {
	return Api.post<IBooking, IBookingRegisterContractParams>(PickUpEndpoints.REGISTER_CONTRACT, model);
};
