import type { IIncludedServiceConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/includedServiceConfiguration/entities/IncludedServiceConfiguration';
import { IncludedServiceConfigurationEndpoints } from '@crac/core/modules/pricing/partnerConfiguration/includedServiceConfiguration/services/IncludedServiceConfigurationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

/**
 * Deactivate a service invoiceable configuration params
 */
export interface IIncludedServiceConfigurationDeactivateParams extends IServiceBaseParams {
	ids: number[];
}

/**
 * Deactivate included service configuration
 * @param params `IIncludedServiceConfigurationDeactivateParams`
 * @returns `Promise<ServiceResponse<IIncludedServiceConfiguration>>`
 */
export const includedServiceConfigurationDeactivateService = (
	params: IIncludedServiceConfigurationDeactivateParams,
) => {
	return Api.post<IIncludedServiceConfiguration[], IIncludedServiceConfigurationDeactivateParams>(
		IncludedServiceConfigurationEndpoints.DEACTIVATE,
		params,
	);
};
