/* eslint-disable no-negated-condition */
import { styled } from 'styled-components';

export const SpinnerNumberContainer = styled.div<{ size?: 'sm' | 'lg' }>`
	height: ${(props) => (!props.size ? `${36}px` : 'auto')};

	.position-relative.form-group {
		margin: 0;
	}

	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button,
	.form-control {
		-webkit-appearance: none;
		margin: 0;
		text-align: center;
	}
`;
