import type { FC } from 'react';
import React from 'react';

import classNames from 'classnames';

import { StylesToolBar } from './styles';

interface IToolBarProps {
	className?: string;
	children?: React.ReactNode;
}

export const ToolBarButtons: FC<IToolBarProps> = ({ children, className, ...attributes }) => {
	const classes = classNames('ToolBarButtons p-2', className);
	if (!children) {
		return null;
	}
	return (
		<StylesToolBar className={classes} data-testid="toolBarButtons" {...attributes}>
			{children}
		</StylesToolBar>
	);
};
