import type { IIncludedServiceConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/includedServiceConfiguration/entities/IncludedServiceConfiguration';
import { IncludedServiceConfigurationEndpoints } from '@crac/core/modules/pricing/partnerConfiguration/includedServiceConfiguration/services/IncludedServiceConfigurationEndpoints';
import type { IPartnerConfigurationSearch } from '@crac/core/modules/pricing/partnerConfiguration/types/PartnerConfigurationSearchType';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

/**
 * All parameters are optionals, but at least one parameter is required in the search
 */
export interface IIncludedServiceConfigurationSearchParams extends IServiceBaseParams, IPartnerConfigurationSearch {
	services?: string[];
}

/**
 * Search included service configuration
 * @param params `IIncludedServiceConfigurationSearchParams`
 * @returns `Promise<ServiceResponse<IIncludedServiceConfiguration[]>>`
 */
export const includedServiceConfigurationSearchService = (params: IIncludedServiceConfigurationSearchParams) => {
	return Api.get<IIncludedServiceConfiguration[], IIncludedServiceConfigurationSearchParams>(
		IncludedServiceConfigurationEndpoints.SEARCH,
		params,
	);
};
