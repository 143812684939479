import type { IPartnerCategory } from '@crac/core/modules/pricing/partnerConfiguration/partnerCategory/entities/PartnerCategory';
import { PartnerCategoryEndpoints } from '@crac/core/modules/pricing/partnerConfiguration/partnerCategory/services/PartnerCategoryEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export type IPartnerCategoryGetAllParams = IServiceBaseParams;

export const partnerCategoryGetAllService = (
	model: IPartnerCategoryGetAllParams,
): Promise<ServiceResponse<IPartnerCategory[]>> => {
	return Api.get<IPartnerCategory[], IPartnerCategoryGetAllParams>(PartnerCategoryEndpoints.GET_ALL, model);
};
