import type { IOccupancy } from '@crac/core/modules/pricing/occupancy/entities/Occupancy';
import type { IOccupancyDeactivateParams } from '@crac/core/modules/pricing/occupancy/services/OccupancyDeactivateService';
import { occupancyDeactivateService } from '@crac/core/modules/pricing/occupancy/services/OccupancyDeactivateService';
import type { IOccupancyInsertParams } from '@crac/core/modules/pricing/occupancy/services/OccupancyInsertService';
import { occupancyInsertService } from '@crac/core/modules/pricing/occupancy/services/OccupancyInsertService';
import type { IOccupancySearchParams } from '@crac/core/modules/pricing/occupancy/services/OccupancySearchService';
import { occupancySearchService } from '@crac/core/modules/pricing/occupancy/services/OccupancySearchService';
import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

/**
 * Searches for occupancy records based on specified parameters.
 *
 * @param {IOccupancy[]} payload - The occupancy records to be retrieved.
 * @param {IOccupancySearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of occupancy records.
 */
export const occupancySearch = createAsyncAction<IOccupancy[], IOccupancySearchParams>(
	'occupancy/search',
	occupancySearchService,
);

/**
 * Inserts new occupancy records.
 *
 * @param {IOccupancy[]} payload - The occupancy records to be inserted.
 * @param {IOccupancyInsertParams} params - Parameters for the insertion.
 * @returns {Promise<void>} - A promise that resolves after the occupancy records are inserted.
 */
export const occupancyInsert = createAsyncAction<IOccupancy[], IOccupancyInsertParams>(
	'occupancy/insert',
	occupancyInsertService,
);

/**
 * Deactivates specific occupancy records.
 *
 * @param {IOccupancy[]} payload - The occupancy records to be deactivated.
 * @param {IOccupancyDeactivateParams} params - Parameters for the deactivation.
 * @returns {Promise<void>} - A promise that resolves after the occupancy records are deactivated.
 */
export const occupancyDeactivate = createAsyncAction<IOccupancy[], IOccupancyDeactivateParams>(
	'occupancy/deactivate',
	occupancyDeactivateService,
);

/**
 * Clears the occupancy state.
 */
export const occupancyClear = createSyncAction('occupancy/clear');
