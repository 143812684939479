import type { IVehicleGroupIncludedConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/vehicleGroupIncludedConfiguration/entities/VehicleGroupIncludedConfiguration';
import { VehicleGroupIncludedConfigurationEndpoints } from '@crac/core/modules/pricing/partnerConfiguration/vehicleGroupIncludedConfiguration/services/VehicleGroupIncludedConfigurationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IVehicleGroupIncludedConfigurationModifyParams
	extends IServiceBaseParams,
		Partial<IVehicleGroupIncludedConfiguration> {
	id: number;
	vehicleGroup: string;
}

/**
 * Modify vehicleGroupIncludedConfiguration
 */
export const vehicleGroupIncludedConfigurationModifyService = (
	model: IVehicleGroupIncludedConfigurationModifyParams,
): Promise<ServiceResponse<IVehicleGroupIncludedConfiguration>> => {
	return Api.post<IVehicleGroupIncludedConfiguration, IVehicleGroupIncludedConfigurationModifyParams>(
		VehicleGroupIncludedConfigurationEndpoints.MODIFY,
		model,
	);
};
