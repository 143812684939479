import { RentRateMarginEndpoints } from '@crac/core/modules/pricing/rentRateMargin/services/RentRateMarginEndpoints';
import type { IRentRateVariation } from '@crac/core/modules/pricing/rentRateVariation/entities/RentRateVariation';
import type { IRentRateVariationInsertParams } from '@crac/core/modules/pricing/rentRateVariation/services/RentRateVariationInsertService';
import { Api } from '@crac/core/modules/shared/api';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export const rentRateMarginInsertService = (
	model: IRentRateVariationInsertParams,
): Promise<ServiceResponse<IRentRateVariation[]>> => {
	return Api.post<IRentRateVariation[], IRentRateVariationInsertParams>(RentRateMarginEndpoints.INSERT, model);
};
