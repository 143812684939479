import { PickUpEndpoints } from './PickUpEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IBooking } from '../../entities/Booking';

export interface IBookingDoDirectPickUpParams extends IServiceBaseParams {
	bookingNumber: string;
}

/**
 * Do direct pickup
 * @param params booking number
 */
export const bookingDoDirectPickUpService = (
	params: IBookingDoDirectPickUpParams,
): Promise<ServiceResponse<IBooking>> => {
	return Api.post<IBooking, IBookingDoDirectPickUpParams>(PickUpEndpoints.DO_DIRECT_PICKUP, params);
};
