import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CustomerEndPoints } from './CustomerEndpoints';

export interface ICustomerEmailValidationParams extends IServiceBaseParams {
	hash: string;
}

/**
 * Email Validation
 * @param model ICustomerEmailValidation
 * @returns Promise<ServiceResponse<boolean>>
 */
export const customerEmailValidationService = (
	model: ICustomerEmailValidationParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.get<boolean, ICustomerEmailValidationParams>(CustomerEndPoints.EMAIL_VALIDATION, model);
};
