import type { IInvoiceableServiceConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/invoiceableServiceConfiguration/entities/InvoiceableServiceConfiguration';
import { InvoiceableServiceConfigurationEndpoints } from '@crac/core/modules/pricing/partnerConfiguration/invoiceableServiceConfiguration/services/InvoiceableServiceConfigurationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

/**
 * To insert a service invoiceable configuration
 */
export interface IInvoiceableServiceConfigurationInsertParams extends IServiceBaseParams {
	invoiceablesServicesConfigurations: IInvoiceableServiceConfiguration[];
}

/**
 * Insert service invoiceable configuration
 * @param params `IInvoiceableServiceConfigurationInsertParams`
 * @returns `Promise<ServiceResponse<IInvoiceableServiceConfiguration[]>>`
 */
export const invoiceableServiceConfigurationInsertService = (params: IInvoiceableServiceConfigurationInsertParams) => {
	return Api.post<IInvoiceableServiceConfiguration[], IInvoiceableServiceConfigurationInsertParams>(
		InvoiceableServiceConfigurationEndpoints.INSERT,
		params,
	);
};
