import { RentRateMarginEndpoints } from '@crac/core/modules/pricing/rentRateMargin/services/RentRateMarginEndpoints';
import type { IRentRateVariation } from '@crac/core/modules/pricing/rentRateVariation/entities/RentRateVariation';
import type { IRentRateVariationDeactivateParams } from '@crac/core/modules/pricing/rentRateVariation/services/RentRateVariationDeactivateService';
import { Api } from '@crac/core/modules/shared/api';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export const rentRateMarginDeactivateService = (
	model: IRentRateVariationDeactivateParams,
): Promise<ServiceResponse<IRentRateVariation[]>> => {
	return Api.post<IRentRateVariation[], IRentRateVariationDeactivateParams>(
		RentRateMarginEndpoints.DEACTIVATE,
		model,
	);
};
