import type { IDepositRate } from '@crac/core/modules/pricing/depositRate/entities/DepositRate';
import { DepositRateEndpoints } from '@crac/core/modules/pricing/depositRate/services/DepositRateEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IDepositRateInsertParams extends IServiceBaseParams {
	depositRates: IDepositRate[];
}

/**
 * Insert deposit rates
 */
export const depositRateInsertService = (model: IDepositRateInsertParams): Promise<ServiceResponse<IDepositRate[]>> => {
	return Api.post<IDepositRate[], IDepositRateInsertParams>(DepositRateEndpoints.INSERT, model);
};
