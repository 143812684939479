export enum ServiceRateEndpoints {
	/**
	 * @host `ServiceRate/Search/`
	 */
	SEARCH = '/serviceRate/Search/',

	/**
	 * @host `ServiceRate/Insert`
	 */
	INSERT = '/serviceRate/Insert/',
}
