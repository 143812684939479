import { Api } from '@crac/core/modules/shared/api';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { CouponEndPoint } from './CouponEndpoints';
import type { ICoupon } from '../entitites/Coupon';

export interface ICouponGetByCustomerCodeParams extends IServiceBaseParams {
	customerCode: string;
}

/**
 * Get by customerCode
 * @returns  Promise<ServiceResponse<ICoupon[]>>
 */
export const couponGetByCustomerCodeService = (
	model: ICouponGetByCustomerCodeParams,
): Promise<ServiceResponse<ICoupon[]>> => {
	return Api.get<ICoupon[], ICouponGetByCustomerCodeParams>(CouponEndPoint.COUPON_GET_BY_CUSTOMER_CODE, model);
};
