import type { CSSProperties, FC } from 'react';

import type { DraggableAttributes } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { type Header, flexRender } from '@tanstack/react-table';
import classNames from 'classnames';

import { DraggableTableHeader } from './dragAndDrop/DraggableTableHeader';
import { columnSortingTitle, isPinnedId } from './utils';

interface ITableHeaderCell {
	header: Header<any, unknown>;
	isSortingEnabled?: boolean;
	isDnD?: boolean;
}

interface HeaderProps {
	setNodeRef?: (node: HTMLElement | null) => void;
	style?: CSSProperties;
	attributes?: DraggableAttributes;
	listeners?: SyntheticListenerMap;
}

export const TableHeaderCell: FC<ITableHeaderCell> = ({ header, isDnD, isSortingEnabled }) => {
	const headerCell = ({ setNodeRef, style, attributes, listeners }: HeaderProps) => (
		<th
			className={classNames(
				(header.column.columnDef.meta as any)?.className,
				isSortingEnabled && header.column.getCanSort() ? 'select-none text-nowrap' : '',
			)}
			ref={setNodeRef}
			style={{ ...style, width: header.getSize() }}
		>
			{header.isPlaceholder ? null : (
				<span
					{...attributes}
					{...listeners}
					style={attributes ? { userSelect: 'none', cursor: 'move' } : undefined}
				>
					{flexRender(header.column.columnDef.header, header.getContext())}
				</span>
			)}
			{isSortingEnabled && !isPinnedId(header.id) ? (
				<span
					className="p-0 m-0 ms-1 cursor-pointer"
					onClick={header.column.getToggleSortingHandler()}
					title={columnSortingTitle(header.column, true)}
				>
					{{
						asc: <i className="m-0 p-0 fa fa-fw fa-caret-up" />,
						desc: <i className="m-0 p-0 fa fa-fw fa-caret-down" />,
					}[header.column.getIsSorted() as string] ?? <i className="m-0 p-0 fa fa-fw fa-sort" />}
				</span>
			) : null}
		</th>
	);

	if (isDnD && !isPinnedId(header.id)) {
		return <DraggableTableHeader header={header}>{(props) => headerCell(props)}</DraggableTableHeader>;
	}

	return headerCell({});
};
