import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CustomerEndPoints } from './CustomerEndpoints';
import type { ICustomerPending } from '../../../models/entities/CustomerPending';

export interface ICustomerGetPendingsParams extends IServiceBaseParams {
	customerCode: string;
}

/**
 * Return list pending of customer
 *  {*} customerCode Customer code
 */
export const customerGetPendingsService = (
	model: ICustomerGetPendingsParams,
): Promise<ServiceResponse<ICustomerPending[]>> => {
	return Api.get<ICustomerPending[], ICustomerGetPendingsParams>(CustomerEndPoints.PENDINGS, model);
};
