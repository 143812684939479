export enum PartnerCategoryEndpoints {
	/**
	 * Get partnerCategory by code
	 * @host `/partnerCategory/getByCode`
	 */
	GET_BY_CODE = '/partnerCategory/getByCode',
	/**
	 * Get all partnerCategory
	 * @host `/partnerCategory/getAll`
	 */
	GET_ALL = '/partnerCategory/getAll',
	/**
	 * Search partnerCategory
	 * @host `/partnerCategory/search`
	 */
	SEARCH = '/partnerCategory/search',
	/**
	 * Insert partnerCategory
	 * @host `/partnerCategory/insert`
	 */
	INSERT = '/partnerCategory/insert',
	/**
	 * Modify partnerCategory
	 * @host `/partnerCategory/modify`
	 */
	MODIFY = '/partnerCategory/modify',
}
