import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CouponEndPoint } from './CouponEndpoints';
import type { ICoupon } from '../entitites/Coupon';

export interface ICouponGetByBookingNumberParams extends IServiceBaseParams {
	bookingNumber: string;
}

export const couponGetByBookingNumberService = (
	model: ICouponGetByBookingNumberParams,
): Promise<ServiceResponse<ICoupon[]>> => {
	return Api.get<ICoupon[], ICouponGetByBookingNumberParams>(CouponEndPoint.COUPON_BY_BOOKING_NUMBER, model);
};
