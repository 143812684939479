import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CustomerEndPoints } from './CustomerEndpoints';

export interface ICustomerModifyPasswordParams extends IServiceBaseParams {
	/**
	 * Customer email - (Required)
	 */
	email: string;
	/**
	 * Customer current password - (Required)
	 */
	oldPassword: string;
	/**
	 * Customer new password - (Required)
	 */
	newPassword: string;
	/**
	 * Repeated new password - (Required)
	 */
	repeatedNewPassword: string;
}

/**
 * Modify customer's password
 * @param model ICustomerModifyPasswordParams
 */
export const customerModifyPasswordService = (
	model: ICustomerModifyPasswordParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, ICustomerModifyPasswordParams>(CustomerEndPoints.MODIFY_PASSWORD, model);
};
