import { createSlice } from '@reduxjs/toolkit';

import {
	availabilityAssistClear,
	availabilityAssistDelete,
	availabilityAssistModify,
	availabilityAssistSearch,
	availabilityConfigClear,
	availabilityConfigDelete,
	availabilityConfigInsert,
	availabilityConfigModify,
	availabilityConfigSearch,
} from '@crac/core/modules/pricing/availabilityConfiguration/state/actions/AvailabilityConfigurationActions';
import type { IAvailabilityConfigurationReducerState } from '@crac/core/modules/pricing/availabilityConfiguration/state/stateType/AvailabilityConfigurationReducerState';

export const initialState: IAvailabilityConfigurationReducerState = {
	availabilityConfigDeleteRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	availabilityConfigModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	availabilityConfigSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	availabilityConfigInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	availabilityAssistSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	availabilityAssistDeleteRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	availabilityAssistModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	availabilityGroups: [],
	assistanceGroups: [],
};

const availabilityConfigSlice = createSlice({
	name: 'AvailabilityConfigSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR
		builder.addCase(availabilityConfigClear, (state) => {
			return {
				...state,
				availabilityGroups: [],
			};
		});
		// SEARCH
		builder
			.addCase(availabilityConfigSearch.pending, (state) => {
				return {
					...state,
					availabilityConfigSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(availabilityConfigSearch.fulfilled, (state, action) => {
				return {
					...state,
					availabilityConfigSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					availabilityGroups: action.payload,
				};
			})
			.addCase(availabilityConfigSearch.rejected, (state, action) => {
				return {
					...state,
					availabilityConfigSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(availabilityConfigModify.pending, (state) => {
				return {
					...state,
					availabilityConfigModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(availabilityConfigModify.fulfilled, (state, action) => {
				const [modifiedGroup] = action.payload;
				const requestedGroups = [
					...state.availabilityGroups
						.filter(
							({ vehicleGroupRequested }) =>
								vehicleGroupRequested === modifiedGroup.vehicleGroupRequested,
						)
						.filter(
							({ vehicleGroupDelivered }) =>
								vehicleGroupDelivered !== modifiedGroup.vehicleGroupDelivered,
						),
					modifiedGroup,
				].sort((acc, curr) => acc.order - curr.order);
				return {
					...state,
					availabilityConfigModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					availabilityGroups: [
						...state.availabilityGroups.filter(
							(group) => group.vehicleGroupRequested !== modifiedGroup.vehicleGroupRequested,
						),
						...requestedGroups,
					].sort((acc, curr) => acc.vehicleGroupRequested.localeCompare(curr.vehicleGroupRequested)),
				};
			})
			.addCase(availabilityConfigModify.rejected, (state, action) => {
				return {
					...state,
					availabilityConfigModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// DELETE
		builder
			.addCase(availabilityConfigDelete.pending, (state) => {
				return {
					...state,
					availabilityConfigDeleteRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(availabilityConfigDelete.fulfilled, (state, action) => {
				return {
					...state,
					availabilityConfigDeleteRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					availabilityGroups: state.availabilityGroups
						? state.availabilityGroups.filter(
								(group) => group.vehicleGroupDelivered !== action.payload.vehicleGroupDelivered,
							)
						: [],
				};
			})
			.addCase(availabilityConfigDelete.rejected, (state, action) => {
				return {
					...state,
					availabilityConfigDeleteRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		/**
		 * ---------------------------- ASSISTANCE ---------------------------
		 */

		// CLEAR
		builder.addCase(availabilityAssistClear, (state) => {
			return {
				...state,
				assistanceGroups: [],
			};
		});
		// INSERT
		builder
			.addCase(availabilityConfigInsert.pending, (state) => {
				return {
					...state,
					availabilityConfigInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(availabilityConfigInsert.fulfilled, (state, action) => {
				return {
					...state,
					availabilityConfigInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					assistanceGroups: state.assistanceGroups
						? [...state.assistanceGroups, ...action.payload].sort((acc, curr) => acc.order - curr.order)
						: action.payload,
				};
			})
			.addCase(availabilityConfigInsert.rejected, (state, action) => {
				return {
					...state,
					availabilityConfigInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// SEARCH
		builder
			.addCase(availabilityAssistSearch.pending, (state) => {
				return {
					...state,
					availabilityAssistSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(availabilityAssistSearch.fulfilled, (state, action) => {
				return {
					...state,
					availabilityAssistSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					assistanceGroups: action.payload,
				};
			})
			.addCase(availabilityAssistSearch.rejected, (state, action) => {
				return {
					...state,
					availabilityAssistSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(availabilityAssistModify.pending, (state) => {
				return {
					...state,
					availabilityAssistModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(availabilityAssistModify.fulfilled, (state, action) => {
				return {
					...state,
					availabilityAssistModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					assistanceGroups: state.assistanceGroups
						? [
								...state.assistanceGroups.filter(
									(group) => group.vehicleGroupDelivered !== action.payload[0].vehicleGroupDelivered,
								),
								...action.payload,
							].sort((acc, curr) => acc.order - curr.order)
						: action.payload,
				};
			})
			.addCase(availabilityAssistModify.rejected, (state, action) => {
				return {
					...state,
					availabilityAssistModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// DELETE
		builder
			.addCase(availabilityAssistDelete.pending, (state) => {
				return {
					...state,
					availabilityAssistDeleteRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(availabilityAssistDelete.fulfilled, (state, action) => {
				return {
					...state,
					availabilityAssistDeleteRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					assistanceGroups: state.assistanceGroups
						? state.assistanceGroups.filter(
								(group) => group.vehicleGroupDelivered !== action.payload.vehicleGroupDelivered,
							)
						: [],
				};
			})
			.addCase(availabilityAssistDelete.rejected, (state, action) => {
				return {
					...state,
					availabilityAssistDeleteRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const availabilityConfigReducer = availabilityConfigSlice.reducer;
