import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import type { IComment } from '@crac/core/models/entities/Comment';
import type { ICommentReducerState } from '@crac/core/modules/comments/state/stateType/CommentStateType';
import {
	commentAddToBooking,
	commentGetByBooking,
	commentRemoveFromBooking,
} from '@crac/core/redux/actions/CommentActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';

const initialState: ICommentReducerState = {
	comments: [],
	commentsRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	insertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	removeRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const commentsSlice = createSlice({
	name: 'BookingCommentsSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => initialState);
		// GET BY BOOKING
		builder
			.addCase(commentGetByBooking.pending, (state) => {
				return {
					...state,
					comments: [],
					commentsRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(commentGetByBooking.fulfilled, (state, action) => {
				return {
					...state,
					comments: deepCopy(action.payload),
					commentsRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(commentGetByBooking.rejected, (state, action) => {
				return {
					...state,
					commentsRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// COMMENT ADD TO BOOKING
		builder
			.addCase(commentAddToBooking.pending, (state) => {
				return {
					...state,
					insertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(commentAddToBooking.fulfilled, (state, action) => {
				return {
					...state,
					comments: [...state.comments, action.payload as IComment],
					insertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(commentAddToBooking.rejected, (state, action) => {
				return {
					...state,
					insertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// REMOVE FROM BOOKING
		builder
			.addCase(commentRemoveFromBooking.pending, (state) => {
				return {
					...state,
					removeRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(commentRemoveFromBooking.fulfilled, (state, action) => {
				return {
					...state,
					comments: state.comments
						? state.comments.filter((comment) => comment.lineNumber !== action.payload.lineNumber)
						: [],
					removeRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(commentRemoveFromBooking.rejected, (state, action) => {
				return {
					...state,
					removeRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const commentsReducer = commentsSlice.reducer;
