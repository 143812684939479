import type { FC } from 'react';

import { SortableContext, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import type { HeaderGroup } from '@tanstack/react-table';

import { TableHeaderCell } from './TableHeaderCell';
import { getActionsColumnWidth } from './utils';
import type { IListActionsType } from '../list';

interface ITableHeaderGroup {
	items?: string[];
	isSortingEnabled?: boolean;
	isDnD?: boolean;
	headerGroup: HeaderGroup<any>;
	actions?: IListActionsType;
	actionsWidth?: string | number;
}

export const TableHeaderGroup: FC<ITableHeaderGroup> = ({
	headerGroup,
	isDnD,
	isSortingEnabled,
	items,
	actions,
	actionsWidth,
}) => {
	const renderHeaders = (
		<>
			{headerGroup.headers.map((header) => (
				<TableHeaderCell
					header={header}
					isDnD={isDnD}
					isSortingEnabled={isSortingEnabled}
					key={`header_${headerGroup.id}_${header.id}`}
				/>
			))}
			{actions?.length ? (
				<th
					key={`header_${headerGroup.id}_actions`}
					style={
						getActionsColumnWidth(actionsWidth) ? { width: getActionsColumnWidth(actionsWidth) } : undefined
					}
				/>
			) : null}
		</>
	);

	if (isDnD && items) {
		return (
			<tr>
				<SortableContext items={items} strategy={horizontalListSortingStrategy}>
					{renderHeaders}
				</SortableContext>
			</tr>
		);
	}

	return <tr>{renderHeaders}</tr>;
};
