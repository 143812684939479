import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CustomerEndPoints } from './CustomerEndpoints';
import type { ISocialNetWorkLink } from '../../../models/entities/SocialNetworkLink';

export interface ICustomerSocialNetworkLinkParams extends IServiceBaseParams {
	customerCode: string;
	email: string;
	socialNetworkId: string;
	socialNetworkName: string;
}

/**
 * Link customer social network
 * @param model ICustomerSocialNetworkLinkParams
 * @returns Promise<ServiceResponse<ISocialNetWorkLink>>
 */
export const customerSocialNetworkLinkService = (
	model: ICustomerSocialNetworkLinkParams,
): Promise<ServiceResponse<ISocialNetWorkLink>> => {
	return Api.post<ISocialNetWorkLink, ICustomerSocialNetworkLinkParams>(CustomerEndPoints.SOCIAL_NETWORK_LINK, model);
};
