import type { IMaximumExcessRate } from '@crac/core/modules/pricing/maximumExcessRate/entities/MaximumExcessRate';
import { MaximumExcessRateEndpoints } from '@crac/core/modules/pricing/maximumExcessRate/services/MaximumExcessRateEndpoints';
import type { PartnerType } from '@crac/core/modules/pricing/partnerConfiguration/types/PartnerType';
import type { FromTo } from '@crac/core/modules/pricing/types/FromToType';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

export interface IMaximumExcessRateSearchParams extends IServiceBaseParams {
	quote?: FromTo<string | Date>;
	pickup?: FromTo<string | Date>;
	contractTypes?: number[];
	partners?: Array<{ code: string; type: PartnerType }>;
	branches?: number[];
	vehicleGroups?: string[];
	rentRateTypes?: string[];
	partnerCategories?: string[];
	partnerGroups?: string[];
	showActive?: number;
}

/**
 * Search excess rates
 * @param model IMaximumExcessRateSearchParams
 * @returns IMaximumExcessRate[]
 */
export const maximumExcessRateSearchService = (model: IMaximumExcessRateSearchParams) =>
	Api.get<IMaximumExcessRate[], IMaximumExcessRateSearchParams>(MaximumExcessRateEndpoints.SEARCH, model);
