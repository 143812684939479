import type { FC } from 'react';

import { RenderIcon } from './RenderIcon';
import { Button } from '../../../../external/reactstrap/button';
import { checkIsActionDisabled } from '../helpers';
import type { IActionProps } from '../IList';
import { TooltipWrapper } from '../TooltipWrapper';

export const CallbackAction: FC<IActionProps> = ({ action, item }) => {
	// Disabled
	const isDisabled = checkIsActionDisabled(action, item);

	// Callback
	const handleCallBack = (itemCallback: any): void => {
		if (action && action.callback) {
			action.callback(itemCallback);
		}
	};

	return (
		<TooltipWrapper action={action} item={item}>
			<Button
				aria-label={action.ariaLabel}
				color={action.color}
				isBlock={action.block}
				isDisabled={isDisabled}
				key={action.key}
				onClick={(): void => handleCallBack(item)}
				size={action.size || 'sm'}
			>
				<RenderIcon icon={action?.icon} style={{ marginRight: action?.text ? '0.25rem' : '0' }} />
				<span className="d-none d-sm-inline-block">{action.text}</span>
			</Button>
		</TooltipWrapper>
	);
};
