import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { BookingEndPoints } from './BookingEndpoints';

export interface IBookingHasUpgradeParams extends IServiceBaseParams {
	vehicleGroupDelivered: string;
	vehicleGroupRequested: string;
}

export const bookingHasUpgradeService = (model: IBookingHasUpgradeParams): Promise<ServiceResponse<boolean>> => {
	return Api.get<boolean, IBookingHasUpgradeParams>(BookingEndPoints.BOOKING_HAS_UPGRADE, model);
};
