import type { IAvailabilityGroup } from '@crac/core/modules/booking/availability/entities/AvailabilityGroup';
import { AvailabilityConfigurationEndpoints } from '@crac/core/modules/pricing/availabilityConfiguration/services/AvailabilityConfigurationEndpoints';
import type { IAvailabilityConfigurationInsertParams } from '@crac/core/modules/pricing/availabilityConfiguration/services/AvailabilityConfigurationInsertService';
import { Api } from '@crac/core/modules/shared/api';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

/**
 * Modify group availability
 */
export const availabilityConfigModifyService = (
	model: IAvailabilityConfigurationInsertParams,
): Promise<ServiceResponse<IAvailabilityGroup[]>> => {
	return Api.post<IAvailabilityGroup[], IAvailabilityConfigurationInsertParams>(
		AvailabilityConfigurationEndpoints.MODIFY,
		model,
	);
};
