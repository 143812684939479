import type { IBooking } from '@crac/core/modules/booking/entities/Booking';
import { bookingModifyService } from '@crac/core/modules/booking/modify/services/BookingModifyService';
import { customerModifyService } from '@crac/core/modules/customer/services/CustomerModifyService';
import { customerSaveService } from '@crac/core/modules/customer/services/CustomerSaveService';
import { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import type { IPickUpModifyCustomerAndBooking } from '../../../../../models/serviceParams/PickUpParams';
import { createAsyncAction } from '../../../../shared/state/createAsyncAction';
import type { IPickUpActionResponse } from '../../entitites/PickupActionResponse';
import type { IBookingCanDoDirectPickUpParams } from '../../services/BookingCanDoDirectPickUpService';
import { bookingCanDoDirectPickUpService } from '../../services/BookingCanDoDirectPickUpService';
import type { IBookingDoDirectPickUpParams } from '../../services/BookingDoDirectPickUpService';
import { bookingDoDirectPickUpService } from '../../services/BookingDoDirectPickUpService';
import {
	type IBookingPickUpGetPendingByBranchParams,
	bookingGetPendingPickUpByBranchService,
} from '../../services/BookingGetPendingPickUpByBranchService';
import {
	type IBookingRegisterContractParams,
	bookingRegisterContractService,
} from '../../services/BookingRegisterContractService';
import type { IBookingSignContractParams } from '../../services/BookingSignContractService';
import { bookingSignContractService } from '../../services/BookingSignContractService';
import {
	type IBookingStartContractParams,
	bookingPickUpStartContractService,
} from '../../services/BookingStartContractService';

/**
 * Used when modifying a customer in pick up.
 * Modifies a customer and booking residence data.
 *
 * @param {ICustomer} payload - The customer with modified details.
 * @param {IPickUpModifyCustomerAndBooking} params - Parameters for modifying both the customer and associated booking.
 * @returns {Promise<void>} - A promise that resolves after the customer and booking details are modified.
 */
export const pickUpModifyCustomerAndBooking = createAsyncAction<IPickUpActionResponse, IPickUpModifyCustomerAndBooking>(
	'pickUp/modifyCustomerAndBooking',
	async (params: IPickUpModifyCustomerAndBooking) => {
		const { booking, customer } = params;
		const customerResponse = await customerModifyService(customer);

		if (!customerResponse.ok) {
			return new ServiceResponse<IPickUpActionResponse>(false, null, customerResponse.errors);
		}

		const bookingResponse = await bookingModifyService({
			...booking,
			bookingNumber: booking.bookingNumber,
			provisionalAddress: customer.provisionalAddress,
			provisionalAddress2: customer.provisionalAddress2,
			provisionalCity: customer.provisionalCity,
			provisionalPostCode: customer.provisionalPostCode,
			services: undefined,
		});

		if (!bookingResponse.ok) {
			return new ServiceResponse<IPickUpActionResponse>(false, null, bookingResponse.errors);
		}

		return new ServiceResponse<IPickUpActionResponse>(true, {
			booking: bookingResponse.data,
			customer: customerResponse.data,
		});
	},
);

/**
 * Used when creating a new customer in pick up.
 * Creates a customer and modifies booking residence data.
 *
 * @param {ICustomer} payload - The newly created customer.
 * @param {IPickUpCreateCustomerAndModifyBooking} params - Parameters for creating a new customer and modifying the associated booking.
 * @returns {Promise<void>} - A promise that resolves after the new customer is created and the booking details are modified.
 */
export const pickUpCreateCustomerAndModifyBooking = createAsyncAction<
	IPickUpActionResponse,
	IPickUpModifyCustomerAndBooking
>('pickUp/createCustomerAndModifyBooking', async (params: IPickUpModifyCustomerAndBooking) => {
	const { booking, customer } = params;
	const customerResponse = await customerSaveService(customer);

	if (!customerResponse.ok) {
		return new ServiceResponse<IPickUpActionResponse>(false, null, customerResponse.errors);
	}

	const bookingResponse = await bookingModifyService({
		...booking,
		bookingNumber: booking.bookingNumber,
		provisionalAddress: customer.provisionalAddress,
		provisionalAddress2: customer.provisionalAddress2,
		provisionalCity: customer.provisionalCity,
		provisionalPostCode: customer.provisionalPostCode,
		services: undefined,
	});

	if (!bookingResponse.ok) {
		return new ServiceResponse<IPickUpActionResponse>(false, null, bookingResponse.errors);
	}

	return new ServiceResponse<IPickUpActionResponse>(true, {
		booking: bookingResponse.data,
		customer: customerResponse.data,
	});
});

/**
 * Retrieves pending bookings for pick-up at a specific branch.
 *
 * @param {IBooking[]} payload - The bookings to be retrieved.
 * @param {IBookingPickUpGetPendingByBranchParams} params - Parameters to identify pending bookings at a branch.
 * @returns {Promise<void>} - A promise that resolves to an array of pending bookings.
 */
export const bookingPickUpGetPendingByBranch = createAsyncAction<IBooking[], IBookingPickUpGetPendingByBranchParams>(
	'bookingPickUp/getPendingByBranch',
	bookingGetPendingPickUpByBranchService,
);

/**
 * Initiates the contract start process for a booking pick-up.
 *
 * @param {IBooking} payload - The booking for which to start the contract.
 * @param {IBookingStartContractParams} params - Parameters needed to start the contract for the booking pick-up.
 * @returns {Promise<void>} - A promise that resolves after starting the contract process.
 */
export const bookingPickUpStartContract = createAsyncAction<IBooking, IBookingStartContractParams>(
	'bookingPickUp/startContract',
	bookingPickUpStartContractService,
);

/**
 * Registers a contract for a booking.
 *
 * @param {IBooking} payload - The booking for which the contract is registered.
 * @param {IBookingRegisterContractParams} params - Parameters required for registering the contract.
 * @returns {Promise<void>} - A promise that resolves after registering the contract.
 */
export const bookingRegisterContract = createAsyncAction<IBooking, IBookingRegisterContractParams>(
	'booking/registerContract',
	bookingRegisterContractService,
);

/**
 * Checks if a direct pick-up can be performed for a specific booking.
 *
 * @param {boolean} payload - The result indicating whether a direct pick-up is possible.
 * @param {IBookingCanDoDirectPickUpParams} params - Parameters for checking the possibility of a direct pick-up, such as booking ID and related conditions.
 * @returns {Promise<void>} - A promise that resolves with the result of the check.
 */
export const bookingCanDoDirectPickUp = createAsyncAction<boolean, IBookingCanDoDirectPickUpParams>(
	'booking/canDoDirectPickUp',
	bookingCanDoDirectPickUpService,
);

/**
 * Performs a direct pick-up for a specific booking.
 *
 * @param {IBooking} payload - The booking for which the direct pick-up is performed.
 * @param {IBookingDoDirectPickUpParams} params - Parameters for performing the direct pick-up, such as booking ID and pick-up details.
 * @returns {Promise<void>} - A promise that resolves with the updated booking after the direct pick-up.
 */
export const bookingDoDirectPickUp = createAsyncAction<IBooking, IBookingDoDirectPickUpParams>(
	'booking/doDirectPickUp',
	bookingDoDirectPickUpService,
);

/**
 * Signs a contract for a booking.
 *
 * @param {IBooking} payload - The booking for which the contract is signed.
 * @param {IBookingSignContractParams} params - Parameters for signing the contract, such as digital signature details.
 * @returns {Promise<void>} - A promise that resolves after the contract is signed.
 */
export const bookingSignContract = createAsyncAction<IBooking, IBookingSignContractParams>(
	'booking/signContract',
	bookingSignContractService,
);
