import { BranchScheduleEndpoints } from '@crac/core/modules/pricing/branchSchedule/services/BranchScheduleEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IBranchScheduleDeleteParams extends IServiceBaseParams {
	id: number;
}

export const branchScheduleDeleteService = (model: IBranchScheduleDeleteParams): Promise<ServiceResponse<number>> => {
	return Api.post<number, IBranchScheduleDeleteParams>(BranchScheduleEndpoints.DELETE, model);
};
