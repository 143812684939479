/* eslint-disable max-lines-per-function */
/* eslint-disable react/no-unstable-nested-components */
import type { FC, ReactNode, SetStateAction } from 'react';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';

import type { ColumnDef, Row, RowSelectionState, SortingFnOption } from '@tanstack/react-table';
import { getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import classNames from 'classnames';

import { splitCamelCase } from '@crac/core/helpers/commons';

import { EXPAND_COLUMN_ID, SELECT_COLUMN_ID } from './constants';
import { DragAndDropContainer } from './dragAndDrop/DragAndDropContainer';
import { useTanStackSorting } from './hooks';
import { SortingSummary } from './sorting';
import { TableBodyCell } from './TableBodyCell';
import { TableHeaderGroup } from './TableHeaderGroup';
import { TanStackTableIndeterminateCheckbox } from './TanStackTableIndeterminateCheckbox';
import { checkIfColumnsAreSorted, getTableMaxHeight } from './utils';
import { VirtualizerContainer } from './VirtualizerContainer';
import { Button } from '../../../external/reactstrap/button';
import type { IListProps } from '../../../intranet/shared/list';
import { ListActions } from '../../../intranet/shared/list/ListActions';

type TanStackTableColumn = {
	/**
	 * Name use in object
	 */
	key: string;
	/**
	 * Custom render column value
	 */
	render?: (value: unknown, index?: number) => ReactNode;
	/**
	 * Custom render column title
	 */
	header?: string;
	/**
	 * Column width in px
	 */
	width?: number;
	/**
	 * Apply class to column
	 */
	className?: string;
	/**
	 * id
	 */
	id?: string;
	/**
	 * small rows text
	 */
	smallRowText?: boolean;

	/**
	 * custom sorting function for the column
	 */
	sortingFn?: SortingFnOption<any>;

	/**
	 * force undefined values to the end
	 */
	sortUndefined?: false | 1 | -1 | 'first' | 'last' | undefined;
	/**
	 * enable/disable sorting in this column.
	 * default - true
	 */
	enableSorting?: boolean;
};

export interface ITanStackTableProps {
	data: any[];
	actions?: IListProps['actions'];
	columns: TanStackTableColumn[];
	onChangeSelectedRows?: (ids: { [key: number]: any }) => void;
	renderSubComponent?: (row: Row<any>) => JSX.Element;
	clearRows?: boolean;
	tableProps?: {
		rowHeight?: 'sm' | 'md' | 'lg';
		border?: boolean;
		striped?: boolean;
		inverse?: boolean;
		hover?: boolean;
		smallRowText?: boolean;
		className?: string;
	};
	tableHeight?: 'full' | 'lg' | 'md' | 'sm';
	actionWidth?: number | string;
	id?: string | string[];
	/**
	 * Field name use as id, use to select rows
	 */
	idFieldName?: string;
	enableSorting?: boolean;
	columnOrder?: string[];
	onColumnOrderChange?: (cols: SetStateAction<string[]>) => void;
	onChangeOrder?: (from: string, to: string) => void;
	onResetOrder?: (cols: string[]) => void;
	/**
	 * Sirve para ponerle un tabindex a los checks de la tabla
	 */
	checksTabIndex?: number;
	initialRowSelection?: RowSelectionState;
}

export const TanStackTable: FC<ITanStackTableProps> = ({
	data,
	onChangeSelectedRows,
	columns,
	actions,
	clearRows,
	tableProps = {
		rowHeight: 'sm',
		border: false,
		striped: false,
		inverse: false,
		hover: true,
		className: '',
		smallRowText: false,
	},
	tableHeight = 'lg',
	actionWidth: actionsWidth,
	renderSubComponent,
	id,
	idFieldName = 'id',
	enableSorting,
	columnOrder,
	onChangeOrder,
	onColumnOrderChange,
	onResetOrder,
	checksTabIndex,
	initialRowSelection,
}) => {
	const [rowSelection, setRowSelection] = useState(initialRowSelection ?? {});
	const [lastSelectedIndex, setLastSelectedIndex] = useState<number | null>(null);
	const { sorting, setSorting, ...props } = useTanStackSorting();
	const tableContainerRef = useRef<HTMLDivElement>(null);
	const tableWrapperContainerRef = useRef<HTMLDivElement>(null);
	const tableColumns: ColumnDef<any>[] = [];

	const getId = (item: any): string | null => {
		if (id) {
			if (Array.isArray(id)) {
				return id.map((idItem) => item[idItem]).join('-');
			}

			return item[id as string];
		}

		return null;
	};

	if (onChangeSelectedRows) {
		/**
		 * Handle the selection of all rows.
		 * Toggles between selecting all rows and deselecting all rows.
		 */
		const handleToggleAllRowsSelected = () => {
			const newSelection: { [key: number]: any } = {};
			if (Object.keys(rowSelection).length === data.length) {
				setLastSelectedIndex(null);
			} else {
				for (let idx = 0; idx < data.length; idx += 1) {
					newSelection[idx] = true;
				}
			}
			setRowSelection(newSelection);
		};
		/**
		 * Handle the selection of a checkbox.
		 * Supports shift-click for range selection.
		 *
		 * @param event - Mouse event triggering the selection
		 * @param index - Index of the row being selected
		 */
		const handleCheckboxSelection = (event: MouseEvent, index: number) => {
			const newSelection: { [key: number]: any } = { ...rowSelection };
			if (event.shiftKey && lastSelectedIndex !== null) {
				if (index === lastSelectedIndex) {
					return;
				}
				const start = Math.min(lastSelectedIndex, index);
				const end = Math.max(lastSelectedIndex, index);
				const newState = newSelection[end] !== true || newSelection[start] !== true;
				for (let idx = start; idx <= end; idx += 1) {
					newSelection[idx] = newState;
				}
			} else if (newSelection[index]) {
				delete newSelection[index];
			} else {
				newSelection[index] = true;
			}
			setLastSelectedIndex(index);
			setRowSelection(newSelection);
		};

		tableColumns.push({
			id: SELECT_COLUMN_ID,
			size: 30,
			enableSorting: false,
			header: ({ table }) => (
				<TanStackTableIndeterminateCheckbox
					{...{
						checked: table.getIsAllRowsSelected(),
						indeterminate: table.getIsSomeRowsSelected(),
						onChange: () => handleToggleAllRowsSelected(),
					}}
				/>
			),
			cell: ({ row }) => (
				<TanStackTableIndeterminateCheckbox
					{...{
						checked: row.getIsSelected(),
						disabled: !row.getCanSelect(),
						indeterminate: row.getIsSomeSelected(),
						'aria-label': `select-row-${row.original[idFieldName]}`,
						onChange: (event) => handleCheckboxSelection(event.nativeEvent as MouseEvent, row.index),
						tabIndex: checksTabIndex,
					}}
				/>
			),
		});
	}

	if (renderSubComponent) {
		tableColumns.push({
			id: EXPAND_COLUMN_ID,
			size: 20,
			enableSorting: false,
			header: () => null,
			cell: ({ row }) => (
				<Button className="m-0 p-0" color="link" onClick={() => row.getToggleExpandedHandler()}>
					{row.getIsExpanded() ? (
						<i className="fa fa-fw fa-chevron-up" />
					) : (
						<i className="fa fa-fw fa-chevron-down" />
					)}
				</Button>
			),
		});
	}

	columns.forEach(({ key, render, header, width, className, id, sortUndefined, enableSorting, sortingFn }) => {
		const col: ColumnDef<any> = {
			accessorKey: key,
			header: header ? header : splitCamelCase(key),
			meta: {
				className: classNames(className),
				width,
			},
			cell: ({ getValue, row }) => {
				const value = getValue();
				return render ? render(row.original, row.index) : (value as any);
			},
			size: width,
			id,
			sortUndefined,
			enableSorting,
		};
		tableColumns.push(sortingFn ? { ...col, sortingFn } : col);
	});

	const table = useReactTable({
		data,
		columns: tableColumns,
		state: {
			rowSelection,
			sorting,
			columnOrder,
			columnPinning: { left: [SELECT_COLUMN_ID, EXPAND_COLUMN_ID] },
		},
		// Pipeline
		getRowCanExpand: renderSubComponent ? () => true : undefined,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		enableRowSelection: true,
		onColumnOrderChange,
		onRowSelectionChange: setRowSelection,
		getSortedRowModel: enableSorting ? getSortedRowModel() : undefined,
		onSortingChange: enableSorting ? setSorting : undefined,
		enableSorting,
		enableMultiSort: enableSorting,
		isMultiSortEvent: enableSorting ? () => true : undefined,
	});
	const { rows } = table.getRowModel();

	/**
	 * Listener change on selected items and dispatch  to parent
	 */
	useEffect(() => {
		if (onChangeSelectedRows) {
			const ids: { [key: number]: any } = {};

			table.getSelectedRowModel().rows.forEach(({ original }) => {
				const dataItem = original as any;
				if (idFieldName) {
					ids[dataItem[idFieldName]] = dataItem;
				}
			});

			onChangeSelectedRows(ids);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rowSelection, table, idFieldName]);

	/**
	 * Clear selected rows
	 */
	useEffect(() => {
		if (clearRows) {
			table.toggleAllRowsSelected(false);
		}
	}, [clearRows, table]);

	const handleOnResetOrder = () => {
		if (onResetOrder) {
			onResetOrder(columns.map(({ key }) => key));
		}
	};

	const isSorted = useMemo(
		() =>
			Boolean(
				columnOrder &&
					checkIfColumnsAreSorted(
						columns.map(({ key }) => key),
						columnOrder,
					),
			),
		[columnOrder, columns],
	);
	return (
		<>
			<SortingSummary
				{...props}
				columns={columns}
				isSorted={isSorted}
				onReset={handleOnResetOrder}
				showOrderReset={Boolean(columnOrder)}
				sorting={sorting}
			/>
			<DragAndDropContainer onChangeOrder={onChangeOrder}>
				<VirtualizerContainer
					renderSubComponent={Boolean(renderSubComponent)}
					table={table}
					tableContainerRef={tableContainerRef}
				>
					{({ paddingBottom, paddingTop, virtualRows }) => (
						<div ref={tableWrapperContainerRef}>
							<div
								className={classNames('table-responsive w-100 overflow-auto')}
								ref={tableContainerRef}
								style={{
									maxHeight: getTableMaxHeight(rows, tableHeight),
									overflowAnchor: 'none',
								}}
							>
								<table
									className={classNames(
										`table-sticky w-100 mb-0 table table-${tableProps.rowHeight}`,
										tableProps?.className,
										{
											'table-hover': Boolean(tableProps?.hover),
											'table-bordered': tableProps?.border,
											'table-borderless': tableProps?.border,
											'table-striped': tableProps?.striped,
										},
									)}
									style={tableProps.smallRowText ? { fontSize: '0.875em' } : undefined}
								>
									<thead
										className="bg-white"
										style={{
											background: 'white',
											position: 'sticky',
											top: 0,
											zIndex: 10,
											boxShadow: '0 2px 8px #0000000f',
										}}
									>
										{table.getHeaderGroups().map((headerGroup) => (
											<TableHeaderGroup
												actions={actions}
												actionsWidth={actionsWidth}
												headerGroup={headerGroup}
												isDnD={Boolean(columnOrder)}
												isSortingEnabled={enableSorting}
												items={columnOrder}
												key={`header_${headerGroup.id}`}
											/>
										))}
									</thead>
									<tbody
										{...{
											style: { width: table.getCenterTotalSize() },
										}}
									>
										{paddingTop > 0 && (
											<tr key="pd-t">
												<td style={{ height: paddingTop }} />
											</tr>
										)}
										{virtualRows.map((virtualRow) => {
											const row = rows[virtualRow.index] as Row<any>;
											const itemId = getId(row.original) ?? `row_${row.id}`;
											/*
											 * const rowOriginalWithId = {
											 * 	...row.original,
											 * };
											 */

											return (
												<Fragment key={`fragment_${itemId ?? `row_${row.id}`}`}>
													<tr
														key={itemId ?? `row_${row.id}`}
														style={{ cursor: renderSubComponent ? 'pointer' : 'default' }}
													>
														{row.getVisibleCells().map((cell) => {
															return (
																<TableBodyCell
																	cell={cell}
																	hasSubComponent={Boolean(renderSubComponent)}
																	isDnD={Boolean(columnOrder)}
																	itemId={itemId}
																	items={columnOrder}
																	key={`${itemId ?? 'cell'}_${cell.id}`}
																	row={row}
																/>
															);
														})}
														{actions ? (
															<ListActions actions={actions} item={row.original} />
														) : null}
													</tr>
													{row.getIsExpanded() && renderSubComponent ? (
														<tr key={`${row.id}_expand`}>
															<td colSpan={row.getVisibleCells().length + 1}>
																{renderSubComponent(row)}
															</td>
														</tr>
													) : null}
												</Fragment>
											);
										})}
										{paddingBottom > 0 ? (
											<tr key="pd-b">
												<td style={{ height: paddingBottom }} />
											</tr>
										) : null}
									</tbody>
								</table>
							</div>
						</div>
					)}
				</VirtualizerContainer>
			</DragAndDropContainer>
		</>
	);
};
