import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { BookingEndPoints } from './BookingEndpoints';
import type { IBooking } from '../entities/Booking';

export interface IBookingAllowPendingParams extends IServiceBaseParams {
	bookingNumber: string;
	allowRegisterWithPending: boolean;
}

export const bookingAllowPendingService = (model: IBookingAllowPendingParams): Promise<ServiceResponse<IBooking>> =>
	Api.post<IBooking, IBookingAllowPendingParams>(BookingEndPoints.ALLOW_PENDING, model);
