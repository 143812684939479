import type { IChoosableServiceConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/choosableServiceConfiguration/entities/ChoosableServiceConfiguration';
import { ChoosableServiceConfigurationEndPoints } from '@crac/core/modules/pricing/partnerConfiguration/choosableServiceConfiguration/services/ChoosableServiceConfigurationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

/**
 * To insert a service choosable configuration
 */
export interface IChoosableServiceConfigurationInsertParams extends IServiceBaseParams {
	ChoosablesServicesConfigurations: IChoosableServiceConfiguration[];
}

/**
 * Insert choosable service configuration
 * @param params `IChoosableServiceConfigurationInsertParams`
 * @returns `Promise<ServiceResponse<IChoosableServiceConfiguration[]>>`
 */
export const choosableServiceConfigurationInsertService = (params: IChoosableServiceConfigurationInsertParams) => {
	return Api.post<IChoosableServiceConfiguration[], IChoosableServiceConfigurationInsertParams>(
		ChoosableServiceConfigurationEndPoints.INSERT,
		params,
	);
};
