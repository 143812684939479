import type { IKmRate } from '@crac/core/modules/pricing/kmRate/entities/KmRate';
import { KmRateEndpoints } from '@crac/core/modules/pricing/kmRate/services/KmRateEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IKmRateDeactivateParams extends IServiceBaseParams {
	ids: number[];
}

/**
 * Deactivate km rates
 * @param ids number[]
 * @returns IKmRate[]
 */
export const kmRateDeactivateService = (model: IKmRateDeactivateParams): Promise<ServiceResponse<IKmRate[]>> => {
	return Api.post<IKmRate[], IKmRateDeactivateParams>(KmRateEndpoints.DEACTIVATE, model);
};
