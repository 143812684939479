import type { IPartnerGroup } from '@crac/core/modules/pricing/partnerConfiguration/partnerGroup/entities/PartnerGroup';
import type { IPartnerGroupGetAllParams } from '@crac/core/modules/pricing/partnerConfiguration/partnerGroup/services/PartnerGroupGetAllService';
import { partnerGroupGetAllService } from '@crac/core/modules/pricing/partnerConfiguration/partnerGroup/services/PartnerGroupGetAllService';
import type { IPartnerGroupGetByCodeParams } from '@crac/core/modules/pricing/partnerConfiguration/partnerGroup/services/PartnerGroupGetByCodeService';
import { partnerGroupGetByCodeService } from '@crac/core/modules/pricing/partnerConfiguration/partnerGroup/services/PartnerGroupGetByCodeService';
import type { IPartnerGroupInsertParams } from '@crac/core/modules/pricing/partnerConfiguration/partnerGroup/services/PartnerGroupInsertService';
import { partnerGroupInsertService } from '@crac/core/modules/pricing/partnerConfiguration/partnerGroup/services/PartnerGroupInsertService';
import type { IPartnerGroupModifyParams } from '@crac/core/modules/pricing/partnerConfiguration/partnerGroup/services/PartnerGroupModifyService';
import { partnerGroupModifyService } from '@crac/core/modules/pricing/partnerConfiguration/partnerGroup/services/PartnerGroupModifyService';
import { partnerGroupSearchService } from '@crac/core/modules/pricing/partnerConfiguration/partnerGroup/services/PartnerGroupSearchService';
import type { IPartnerGroupSearchParams } from '@crac/core/modules/pricing/partnerConfiguration/partnerGroup/services/PartnerGroupSearchService';
import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

/**
 * Retrieves all partner groups.
 *
 * @param {IPartnerGroup[]} payload - The partner groups to be retrieved.
 * @param {IPartnerGroupGetAllParams} params - Parameters for retrieving all partner groups.
 * @returns {Promise<void>} - A promise that resolves to an array of partner groups.
 */
export const partnerGroupGetAll = createAsyncAction<IPartnerGroup[], IPartnerGroupGetAllParams>(
	'partnerGroup/getAll',
	partnerGroupGetAllService,
);

/**
 * Retrieves partner groups by their specific code.
 *
 * @param {IPartnerGroup[]} payload - The partner groups to be retrieved.
 * @param {IPartnerGroupGetByCodeParams} params - Parameters to identify the partner groups by code.
 * @returns {Promise<void>} - A promise that resolves to an array of partner groups.
 */
export const partnerGroupGetByCode = createAsyncAction<IPartnerGroup, IPartnerGroupGetByCodeParams>(
	'partnerGroup/getByCode',
	partnerGroupGetByCodeService,
);

/**
 * Searches for partner groups based on specified parameters.
 *
 * @param {IPartnerGroup[]} payload - The partner groups to be returned based on the search criteria.
 * @param {IPartnerGroupSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of partner groups.
 */
export const partnerGroupSearch = createAsyncAction<IPartnerGroup[], IPartnerGroupSearchParams>(
	'partnerGroup/search',
	partnerGroupSearchService,
);

/**
 * Inserts a new partner group.
 *
 * @param {IPartnerGroup} payload - The partner group to be inserted.
 * @param {IPartnerGroupInsertParams} params - Parameters for the insertion of the partner group.
 * @returns {Promise<void>} - A promise that resolves after the partner group is inserted.
 */
export const partnerGroupInsert = createAsyncAction<IPartnerGroup, IPartnerGroupInsertParams>(
	'partnerGroup/insert',
	partnerGroupInsertService,
);

/**
 * Modifies an existing partner group.
 *
 * @param {IPartnerGroup} payload - The partner group to be modified.
 * @param {IPartnerGroupModifyParams} params - Parameters for the modification of the partner group.
 * @returns {Promise<void>} - A promise that resolves after the partner group is modified.
 */
export const partnerGroupModify = createAsyncAction<IPartnerGroup, IPartnerGroupModifyParams>(
	'partnerGroup/modify',
	partnerGroupModifyService,
);

/**
 * Clears the partner group state.
 */
export const partnerGroupClear = createSyncAction('partnerGroup/clear');
