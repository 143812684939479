import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { SpinnerNumberContainer } from './styled';
import { Button } from '../../../external/reactstrap/button';
import { Input } from '../../../external/reactstrap/input';
import { InputGroup } from '../../../external/reactstrap/inputGroup/InputGroup';

type SpinnerActionType = 'plus' | 'minus';

interface ISpinnerNumberProps {
	activeState?: boolean;
	disabled?: boolean;
	max?: number;
	min?: number;
	onChange?: (val: number, action: SpinnerActionType) => void;
	size?: 'sm' | 'lg';
	value?: number;
	tabIndex?: number;
	step?: string | number;
	name?: string;
}

const SpinnerNumber = forwardRef<HTMLInputElement, ISpinnerNumberProps>(
	(
		{
			activeState = false,
			disabled = false,
			max = Number.MAX_SAFE_INTEGER,
			min = 0,
			size = 'sm',
			step = 1,
			tabIndex,
			value = 0,
			onChange,
			name = 'count',
		},
		ref,
	) => {
		const inputRef = useRef<HTMLInputElement>(null);

		const [innerValue, setInnerValue] = useState(value || 0);

		const handleSetInnerValue = useCallback(() => {
			const currentValue = Number(value);
			const minValue = Number(min);
			const maxValue = Number(max);
			let newValue = currentValue;

			if (currentValue < minValue) {
				newValue = Math.max(minValue, currentValue);
			} else if (currentValue >= minValue && currentValue <= maxValue) {
				if (currentValue > maxValue) {
					newValue = maxValue;
				}
			} else if (currentValue > maxValue) {
				newValue = Number(currentValue);
			}

			setInnerValue(newValue);
		}, [max, min, value]);

		useEffect(() => {
			handleSetInnerValue();
		}, [handleSetInnerValue]);

		const handleChange = (innerValue: number, action: SpinnerActionType): void => {
			setInnerValue(innerValue);
			if (onChange) {
				onChange(innerValue, action);
			}
		};

		const calculateAction = useCallback(
			(action: SpinnerActionType) => {
				const minValue = Number(min);
				const maxValue = Number(max);
				const oldValue = innerValue;
				let newValue = oldValue;

				if (action === 'plus') {
					newValue += Number(step);
				} else if (action === 'minus') {
					newValue -= Number(step);
				}

				if (oldValue < minValue) {
					newValue = Math.max(minValue, innerValue);
				} else if (oldValue >= minValue && oldValue <= maxValue) {
					if (innerValue > maxValue) {
						newValue = maxValue;
					}
				} else if (oldValue > maxValue) {
					newValue = oldValue;
				}

				return newValue;
			},
			[innerValue, max, min, step],
		);

		const handlePlus = () => {
			const newValue = calculateAction('plus');
			handleChange(newValue, 'plus');
		};

		const handleMinus = () => {
			const newValue = calculateAction('minus');
			handleChange(newValue, 'minus');
		};

		const activeButton = innerValue && activeState && innerValue > 0;

		useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

		return (
			<SpinnerNumberContainer data-testid="spinnerNumber" size={size}>
				<InputGroup size={size}>
					{disabled ? null : (
						<Button
							aria-label="button-remove"
							className="input-group-text"
							color={activeButton ? 'warning' : 'primary'}
							data-testid="button-remove"
							isDisabled={innerValue <= Number(min)}
							onClick={handleMinus}
							size={size}
							tabIndex={-1}
							type="button"
						>
							<i className="fa fa-fw fa-minus-circle" />
						</Button>
					)}

					<Input
						aria-label={name}
						data-testid="input-number"
						disabled={disabled}
						id={name}
						innerRef={inputRef}
						max={max}
						min={min}
						name={name}
						onChange={() => undefined}
						pattern="[0-9]*"
						tabIndex={tabIndex}
						type="number"
						value={innerValue}
					/>
					{disabled ? null : (
						<Button
							aria-label="button-add"
							className="input-group-text"
							color={activeButton ? 'warning' : 'primary'}
							data-testid="button-add"
							isDisabled={innerValue >= Number(max)}
							onClick={handlePlus}
							size={size}
							tabIndex={-1}
							type="button"
						>
							<i className="fa fa-fw fa-plus-circle" />
						</Button>
					)}
				</InputGroup>
			</SpinnerNumberContainer>
		);
	},
);

SpinnerNumber.displayName = 'SpinnerNumber';

export { SpinnerNumber };
