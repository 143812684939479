import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CustomerEndPoints } from './CustomerEndpoints';

export interface ICustomerResetPasswordParams extends IServiceBaseParams {
	/**
	 * New password
	 */
	password: string;
	/**
	 * Repeat new password
	 */
	repeatedPassword: string;
	/**
	 * Hash received by url parameter
	 */
	hash: string;
}

/**
 * Change customer external password
 * @param model ICustomerResetPasswordParams
 * @returns Promise<ServiceResponse<boolean>>
 */
export const customerResetPasswordService = (
	model: ICustomerResetPasswordParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, ICustomerResetPasswordParams>(CustomerEndPoints.RESET_PASSWORD, model);
};
