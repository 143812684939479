import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CommentEndPoints } from './CommentsEndpoints';
import type { IComment } from '../entities/Comment';

export interface ICommentGetByVehicleParams extends IServiceBaseParams {
	plateNumber: string;
}

export const commentGetByVehicleService = (model: ICommentGetByVehicleParams): Promise<ServiceResponse<IComment[]>> => {
	return Api.get<IComment[], ICommentGetByVehicleParams>(CommentEndPoints.GET_BY_VEHICLE, model);
};
