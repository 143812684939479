import type { IRentRateFormula } from '@crac/core/modules/pricing/rentRateFormula/entities/RentRateFormula';
import { RentRateFormulaEndpoints } from '@crac/core/modules/pricing/rentRateFormula/services/RentRateFormulaEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IRentRateFormulasSearchParams extends IServiceBaseParams {
	branches: number[];
	rentRateType: string;
}

export const rentRateFormulaSearchService = (
	model: IRentRateFormulasSearchParams,
): Promise<ServiceResponse<IRentRateFormula[]>> => {
	return Api.get<IRentRateFormula[], IRentRateFormulasSearchParams>(RentRateFormulaEndpoints.SEARCH, model);
};
