'use client';
import type { FC } from 'react';
import React from 'react';

import { InputGroup as RSInputGroup } from 'reactstrap';
import type { InputGroupProps as RSInputGroupProps } from 'reactstrap/types/lib/InputGroup';

export type InputGroupProps = RSInputGroupProps;

export const InputGroup: FC<InputGroupProps> = ({ children, ...props }) => {
	return <RSInputGroup {...props}>{children}</RSInputGroup>;
};
