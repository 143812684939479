import type { CSSProperties, ReactElement } from 'react';

import type { DraggableAttributes } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { Header } from '@tanstack/react-table';

interface ChildrenProps {
	attributes: DraggableAttributes;
	style: CSSProperties;
	setNodeRef: (node: HTMLElement | null) => void;
	listeners?: SyntheticListenerMap;
}

export const DraggableTableHeader = ({
	header,
	children,
}: {
	header: Header<any, unknown>;
	children: (props: ChildrenProps) => ReactElement;
}) => {
	const { attributes, isDragging, listeners, setNodeRef, transform } = useSortable({
		id: header.column.id,
	});

	const style: CSSProperties = {
		opacity: isDragging ? 0.8 : 1,
		position: 'relative',
		transform: CSS.Translate.toString(transform),
		transition: 'width transform 0.2s ease-in-out',
		whiteSpace: 'nowrap',
		width: header.column.getSize(),
		zIndex: isDragging ? 1 : 0,
	};

	return children({ attributes, listeners, style, setNodeRef });
};
