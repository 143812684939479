import type { IOneWay } from '@crac/core/modules/pricing/oneWay/entities/OneWay';
import { OneWayEndpoints } from '@crac/core/modules/pricing/oneWay/services/OneWayEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IOneWayDeleteParams extends IServiceBaseParams, IOneWay {}

/**
 * Delete one way
 * @param model IOneWayDeleteParams
 * @returns IOneWay
 */
export const oneWayDeleteService = (model: IOneWayDeleteParams): Promise<ServiceResponse<IOneWay>> => {
	return Api.post<IOneWay, IOneWayDeleteParams>(OneWayEndpoints.DELETE, model);
};
