import type { IListAction } from './IList';

export const checkIsActionDisabled = (action: IListAction, item: any) => {
	let isDisabled = false;
	if (action.disabled) {
		if (action.disabled.constructor.name === 'Function') {
			const checkIsDisabled = action.disabled as (item: any) => boolean;
			isDisabled = checkIsDisabled(item);
		}

		if (action.disabled.constructor.name === 'Boolean') {
			isDisabled = action.disabled as boolean;
		}
	}

	return isDisabled;
};
