import type { FC } from 'react';

import { Col } from '@crac/components/external/reactstrap/col';
import { Row } from '@crac/components/external/reactstrap/row';
import { SupplierType } from '@crac/core/modules/supplier/types/SupplierType';
import { InputField } from '@crac/form/field';

import { SelectProvider } from '~/features/shared/components/selectProvider';
import { SelectSupplier } from '~/features/shared/components/selectSupplier';

interface ILocationFormBasicFieldsProps {
	isCopy?: boolean;
}

export const LocationFormBasicFields: FC<ILocationFormBasicFieldsProps> = ({ isCopy = false }) => {
	return (
		<Row>
			<Col lg="6" md="12">
				<SelectProvider isClearable name="company" required />
			</Col>
			<Col lg="6" md="12">
				<SelectSupplier
					isClearable
					label="Supplier"
					name="supplier"
					provider="company"
					required
					supplierType={SupplierType.ALL}
				/>
			</Col>
			{!isCopy && (
				<Col md="12">
					<InputField label="Name" name="name" validation={{ required: true, maxLength: 50 }} />
				</Col>
			)}
		</Row>
	);
};
