import type { IDiscountCoupon } from '@crac/core/modules/pricing/discountCoupon/entities/DiscountCoupon';
import { DiscountCouponEndpoints } from '@crac/core/modules/pricing/discountCoupon/services/DiscountCouponEndpoints';
import type { PartnerType } from '@crac/core/modules/pricing/partnerConfiguration/types/PartnerType';
import type { FromTo } from '@crac/core/modules/pricing/types/FromToType';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IDiscountCouponSearchParams extends IServiceBaseParams {
	code?: string;
	vehicleGroups?: string[];
	branches?: number[];
	partners?: Array<{
		type: PartnerType;
		code: string;
	}>;
	pickUp: FromTo<Date | string>;
	quote: FromTo<Date | string>;
	showActive?: number;
	bookingDuration?: FromTo<number>;
	durationInAdvance?: number;
	unitTime?: number;
	partnerCategories?: string[];
	partnerGroups?: string[];
}

/**
 * Search discount coupons
 */
export const discountCouponSearchService = (
	model: IDiscountCouponSearchParams,
): Promise<ServiceResponse<IDiscountCoupon[]>> => {
	return Api.get<IDiscountCoupon[], IDiscountCouponSearchParams>(DiscountCouponEndpoints.SEARCH, model);
};
