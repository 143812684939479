export enum AvailabilityConfigurationEndpoints {
	/**
	 * AvailabilityConfiguration search
	 * @host `/availabilityconfiguration/search/`
	 */
	SEARCH = '/availabilityconfiguration/search',

	/**
	 * AvailabilityConfiguration new
	 * @host `/availabilityconfiguration/insert`
	 */
	INSERT = '/availabilityconfiguration/insert',

	/**
	 * AvailabilityConfiguration modify
	 * @host `/availabilityconfiguration/modify`
	 */
	MODIFY = '/availabilityconfiguration/modify',

	/**
	 * AvailabilityConfiguration delete
	 * @host `/availabilityconfiguration/delete
	 */
	DELETE = '/availabilityconfiguration/delete',
}
