export enum DepositRateEndpoints {
	/**
	 * Search deposit rates
	 * @host `/depositRate/search/`
	 */
	SEARCH = '/depositRate/search/',

	/**
	 * Insert deposit rates
	 * @host `/depositRate/insert`
	 */
	INSERT = '/depositRate/insert/',

	/**
	 * @host `/depositRate/deactivate`
	 */
	DEACTIVATE = '/depositRate/deactivate',
}
