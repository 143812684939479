import type { IChoosableServiceConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/choosableServiceConfiguration/entities/ChoosableServiceConfiguration';
import { ChoosableServiceConfigurationEndPoints } from '@crac/core/modules/pricing/partnerConfiguration/choosableServiceConfiguration/services/ChoosableServiceConfigurationEndpoints';
import type { IPartnerConfigurationSearch } from '@crac/core/modules/pricing/partnerConfiguration/types/PartnerConfigurationSearchType';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

/**
 * All params are optional but at least one must be sent.
 */
export interface IChoosableServiceConfigurationSearchParams extends IServiceBaseParams, IPartnerConfigurationSearch {
	services?: string[];
}

/**
 * Search choosable service configuration
 * @param params `IChoosableServiceConfigurationSearchParams`
 * @returns `Promise<ServiceResponse<IChoosableServiceConfiguration[]>>`
 */
export const choosableServiceConfigurationSearchService = (params: IChoosableServiceConfigurationSearchParams) => {
	return Api.get<IChoosableServiceConfiguration[], IChoosableServiceConfigurationSearchParams>(
		ChoosableServiceConfigurationEndPoints.SEARCH,
		params,
	);
};
