export enum StopSaleEndpoints {
	/**
	 * @host `/stopSale/search`
	 */
	SEARCH = '/stopSale/search',
	/**
	 * @host `/stopSale/insert`
	 */
	INSERT = '/stopSale/insert',
	/**
	 * @host `/stopSale/deactivate`
	 */
	DEACTIVATE = '/stopSale/deactivate',
}
