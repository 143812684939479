import { type FC, useEffect, useState } from 'react';

import type { ISelectOptionType } from '@crac/core/models/types/SelectOptionType';
import type { SupplierType } from '@crac/core/modules/supplier/types/SupplierType';
import { SelectField } from '@crac/form/field';
import { useField } from '@crac/form/field/hooks';

import { fetchSupplierOptions, loadSupplierOptions } from './helpers';
import { CustomLabelWithTooltip } from '../customLabelWithTooltip';

export interface ISelectSupplierProps {
	name: string;
	label: string;
	supplierType: SupplierType;
	required?: boolean;
	disabled?: boolean;
	className?: string;
	provider?: string;
	providerInputValue?: string;
	multiple?: boolean;
	isClearable?: boolean;
	canSelectNone?: boolean;
	/** If true, the options will be loaded asynchronously */
	async?: boolean;
}

interface IFieldProps {
	options?: ISelectOptionType<string>[];
	loadOptions?: (value: string) => Promise<ISelectOptionType<string>[]>;
}

export const SelectSupplier: FC<ISelectSupplierProps> = ({
	name,
	label,
	supplierType,
	provider = 'provider',
	providerInputValue,
	required,
	disabled,
	multiple,
	isClearable = true,
	async,
	...props
}) => {
	const providerFieldValue = useField(provider);
	const cacheKey = providerFieldValue ? `${name}-${providerFieldValue}` : name;

	const providerValue = providerInputValue ?? providerFieldValue;
	const [fieldProps, setFieldProps] = useState<IFieldProps>({});
	useEffect(() => {
		if (!providerValue) {
			setFieldProps({ options: [] });
			return;
		}
		if (async) {
			setFieldProps({
				loadOptions: (label: string) => loadSupplierOptions(providerValue, supplierType)({ label }),
			});
		} else {
			fetchSupplierOptions({ provider: providerValue, type: supplierType }).then((options) =>
				setFieldProps({ options }),
			);
		}
	}, [async, supplierType, providerValue]);

	return (
		<>
			{props.canSelectNone ? (
				<CustomLabelWithTooltip
					label={label}
					name={name}
					required={required}
					showTooltip
					tooltipText="Use 'Find empty' check for getting all items without value"
				/>
			) : null}

			<SelectField
				disabled={!providerValue || disabled}
				isClearable={isClearable}
				key={cacheKey}
				label={props.canSelectNone ? undefined : label}
				multiple={multiple}
				name={name}
				validation={{ required: required ? true : undefined }}
				valueType="string"
				{...props}
				{...fieldProps}
			/>
		</>
	);
};
