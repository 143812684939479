import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { DocumentEndPoints } from './DocumentEndPoints';
import type { IDocument } from '../entities/Document';

export interface IDocumentGetByBookingParams extends IServiceBaseParams {
	bookingNumber: string;
}

/**
 * Get documents by booking number
 *  {string} bookingNumber Booking number
 * @return {Promise} `DocumentPropType` List document
 */
export const documentGetByBookingService = (
	model: IDocumentGetByBookingParams,
): Promise<ServiceResponse<IDocument[]>> => {
	return Api.get<IDocument[], IDocumentGetByBookingParams>(DocumentEndPoints.GET_BY_BOOKING, model);
};
