import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CustomerEndPoints } from './CustomerEndpoints';
import type { ICustomer } from '../entities/Customer';

export interface ICustomerAssignPointsParams extends IServiceBaseParams {
	customerCode: string;
	points: number;
}

/**
 * Customer assign points
 *  {string} customerCode
 *  {Number} points
 */
export const customerAssignPointsService = (
	model: ICustomerAssignPointsParams,
): Promise<ServiceResponse<ICustomer>> => {
	return Api.post<ICustomer, ICustomerAssignPointsParams>(CustomerEndPoints.ASSIGN_POINTS, model);
};
