import * as React from 'react';

import * as TabsPrimitive from '@radix-ui/react-tabs';

import { cn } from '../../lib/utils/tailwindClass';

export const TabsList = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.List>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => <TabsPrimitive.List className={cn('tabs-list', className)} ref={ref} {...props} />);

TabsList.displayName = TabsPrimitive.List.displayName;
