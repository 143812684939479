import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { PaymentEndPoints } from './PaymentEntpoints';
import type { IPaymentLine } from '../entities/PaymentLine';

export interface IPaymentLineUpdateChargeBackParams extends IServiceBaseParams {
	chargeBackBanck?: string;
	chargeBackReason?: string;
	bookingNumber: string;
	chargeBackRequest?: string | Date;
	chargeBackConfirmation?: string | Date;
	number: number;
	chargeBackAmount?: number;
}

export const paymentLineUpdateChargeBackService = (
	model: IPaymentLineUpdateChargeBackParams,
): Promise<ServiceResponse<IPaymentLine>> => {
	return Api.post<IPaymentLine, IPaymentLineUpdateChargeBackParams>(PaymentEndPoints.UPDATE_CHARGE_BACK, model);
};
