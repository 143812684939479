import type { IPreauthorizationRate } from '@crac/core/modules/pricing/preauthorizationRate/entities/PreauthorizationRate';
import type { IPreauthorizationRateDeactivateParams } from '@crac/core/modules/pricing/preauthorizationRate/services/PreauthorizationRateDeactivateService';
import { preauthorizationRateDeactivateService } from '@crac/core/modules/pricing/preauthorizationRate/services/PreauthorizationRateDeactivateService';
import type { IPreauthorizationRateInsertParams } from '@crac/core/modules/pricing/preauthorizationRate/services/PreauthorizationRateInsertService';
import { preauthorizationRateInsertService } from '@crac/core/modules/pricing/preauthorizationRate/services/PreauthorizationRateInsertService';
import type { IPreauthorizationRateSearchParams } from '@crac/core/modules/pricing/preauthorizationRate/services/PreauthorizationRateSearchService';
import { preauthorizationRateSearchService } from '@crac/core/modules/pricing/preauthorizationRate/services/PreauthorizationRateSearchService';
import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

/**
 * Searches for preauthorization rates based on specified parameters.
 *
 * @param {IPreauthorizationRate[]} payload - The preauthorization rates to be retrieved.
 * @param {IPreauthorizationRateSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of preauthorization rates.
 */
export const preauthorizationRateSearch = createAsyncAction<IPreauthorizationRate[], IPreauthorizationRateSearchParams>(
	'preauthorizationRate/search',
	preauthorizationRateSearchService,
);

/**
 * Inserts new preauthorization rates.
 *
 * @param {IPreauthorizationRate[]} payload - The preauthorization rates to be inserted.
 * @param {IPreauthorizationRateInsertParams} params - Parameters for the insertion.
 * @returns {Promise<void>} - A promise that resolves after the preauthorization rates are inserted.
 */
export const preauthorizationRateInsert = createAsyncAction<IPreauthorizationRate[], IPreauthorizationRateInsertParams>(
	'preauthorizationRate/insert',
	preauthorizationRateInsertService,
);

/**
 * Deactivates specific preauthorization rates.
 *
 * @param {IPreauthorizationRate[]} payload - The preauthorization rates to be deactivated.
 * @param {IPreauthorizationRateDeactivateParams} params - Parameters for the deactivation.
 * @returns {Promise<void>} - A promise that resolves after the preauthorization rates are deactivated.
 */
export const preauthorizationRateDeactivate = createAsyncAction<
	IPreauthorizationRate[],
	IPreauthorizationRateDeactivateParams
>('preauthorizationRate/deactivate', preauthorizationRateDeactivateService);

/**
 * Clears the preauthorization rate state.
 */
export const preauthorizationRateClear = createSyncAction('preauthorizationRate/clear');
