import type { ICardDataParams } from '@crac/core/models/serviceParams/CardParams';
import type { IBrowserInformation } from '@crac/core/models/types/BrowserInformation';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CouponEndPoint } from './CouponEndpoints';
import type { ICouponInsertResponse } from '../entitites/CouponInsertReponse';

export interface ICouponInsertParams extends IServiceBaseParams {
	amount: number;
	browser?: IBrowserInformation;
	cardData?: ICardDataParams;
	couponsNumber: number;
	vendorCode: string;
}

/**
 * Insert customer coupon
 * @param params ICouponInsertParams
 * @returns
 */
export const couponInsertService = (params: ICouponInsertParams): Promise<ServiceResponse<ICouponInsertResponse>> =>
	Api.post<ICouponInsertResponse, ICouponInsertParams>(CouponEndPoint.COUPON_INSERT, params);
