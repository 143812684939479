import { createSlice } from '@reduxjs/toolkit';

import type { IBookingReducerState } from '@crac/core/modules/booking/state/stateType/BookingReducerState';
import { bookingGetByVehicle } from '@crac/core/redux/actions/BookingActions';
const initialState: IBookingReducerState = {
	bookings: { data: [], inProgress: false, messages: [], ok: true },
};

const bookingsSlice = createSlice({
	name: 'VehicleGetBookingSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// BOOKING GET BY VEHICLE
		builder
			.addCase(bookingGetByVehicle.pending, (state) => {
				return {
					...state,
					bookings: {
						data: [],
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingGetByVehicle.fulfilled, (state, action) => {
				return {
					...state,
					bookings: {
						data: action.payload,
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingGetByVehicle.rejected, (state, action) => {
				return {
					...state,
					bookings: {
						data: [],
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const bookingsReducer = bookingsSlice.reducer;
