export enum OccupancyEndpoints {
	/**
	 * @host `/occupancyConfiguration/search`
	 */
	SEARCH = '/occupancyConfiguration/search',
	/**
	 * @host `/occupancyConfiguration/insert`
	 */
	INSERT = '/occupancyConfiguration/insert',
	/**
	 * @host `/occupancyConfiguration/deactivate`
	 */
	DEACTIVATE = '/occupancyConfiguration/deactivate',
}
