export enum RentRateTypeRelationEndpoints {
	/**
	 * @host `/RentRateTypeRelation/Search/`
	 */
	SEARCH = '/rentRateTypeRelation/search/',
	/**
	 * @host `/RentRateTypeRelation/insert/`
	 */
	INSERT = '/rentRateTypeRelation/insert/',
	/**
	 * @host `/RentRateTypeRelation/modify/`
	 */
	MODIFY = '/rentRateTypeRelation/modify/',
	/**
	 * @host `/RentRateTypeRelation/delete/`
	 */
	DELETE = '/rentRateTypeRelation/delete/',
}
