import type { Column, Row } from '@tanstack/react-table';

import type { ITanStackTableProps } from '.';

import { EXPAND_COLUMN_ID, SELECT_COLUMN_ID } from './constants';

export const parsePixelsToNumber = (value: string) => {
	const number = value.replace('px', '');
	// Se añade un píxel para asegurar de que cabe bien en el contenedor
	return Number(number) + 1;
};

export const getActionsColumnWidth = (actionsWidth: ITanStackTableProps['actionWidth']) => {
	if (actionsWidth) {
		if (typeof actionsWidth === 'string') {
			return actionsWidth;
		}
		return `${actionsWidth}px`;
	}

	const actionsContainer = document.querySelectorAll('td.actions-container');
	const buttons = actionsContainer[0]?.querySelectorAll('button');
	const links = actionsContainer[0]?.querySelectorAll('a');
	const tableWidth = document.querySelector('table')?.offsetWidth;
	const actionsHeader = document.querySelector('thead>tr>th:last-child');

	// Calculate width
	let width = 0;
	const arrayOfButtons = Array.from(buttons || []);
	arrayOfButtons.forEach((element) => {
		const htmlElement = element as HTMLElement;
		width += htmlElement.offsetWidth;
	});
	const arrayOfLinks = Array.from(links || []);
	arrayOfLinks.forEach((element) => {
		const htmlElement = element as HTMLElement;
		width += htmlElement.offsetWidth;
	});

	if (!width) {
		return undefined;
	}

	if (!tableWidth || !actionsHeader) {
		/*
		 * Me he encontrado algún caso en el que tableWidth es 0. Encuentra la tabla pero tiene el width a 0.
		 * En estos casos, si se renderiza mal la tabla, se arreglaría pasando por props actionsWidth, ya que sin tableWidth no se puede calcular el porcentaje.
		 */
		return `${width}px`;
	}

	// Calculate percentage
	const { padding } = getComputedStyle(actionsHeader);
	const realWidth = width + parsePixelsToNumber(padding) * 2;
	const percentage = (realWidth * 100) / tableWidth;

	return `${percentage}%`;
};

export const isPinnedId = (id: string): boolean => [SELECT_COLUMN_ID, EXPAND_COLUMN_ID].some((col) => col.includes(id));

export const columnSortingTitle = (col: Column<any, unknown>, enableSorting?: boolean) => {
	if (enableSorting && col.getCanSort()) {
		if (col.getNextSortingOrder() === 'asc') {
			return 'Sort ascending';
		}
		if (col.getNextSortingOrder() === 'desc') {
			return 'Sort descending';
		}
		return 'Clear sort';
	}
	return undefined;
};
const tableMaxHeight = {
	lg: '900px',
	md: '500px',
	sm: '300px',
};

export const getTableMaxHeight = (rows: Row<any>[], tableHeight?: 'full' | 'lg' | 'md' | 'sm') => {
	if (tableHeight === 'full') {
		return '90vh';
	}

	if (tableHeight === 'lg' && rows.length > 50) {
		return tableMaxHeight.lg;
	}

	if (tableHeight === 'md') {
		return tableMaxHeight.md;
	}

	if (tableHeight === 'sm') {
		return tableMaxHeight.sm;
	}

	return undefined;
};

export const checkIfColumnsAreSorted = (defaultColumns: string[], order: string[]): boolean => {
	return JSON.stringify(defaultColumns) !== JSON.stringify(order);
};
