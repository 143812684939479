import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { BookingEndPoints } from './BookingEndpoints';
import type { IBooking } from '../entities/Booking';

export interface IBookingGetPendingParams extends IServiceBaseParams {
	branchCode: string;
}

/**
 * Return list booking pending by branch
 *  {*} branchCode Branch code
 */
export const bookingGetPendingsService = (model: IBookingGetPendingParams): Promise<ServiceResponse<IBooking[]>> => {
	return Api.get<IBooking[], IBookingGetPendingParams>(BookingEndPoints.PENDINGS, model);
};
