import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

import type { IComment } from '../../entities/Comment';
import type { ICommentAddToBookingParams } from '../../services/CommentAddToBookingService';
import { commentAddToBookingService } from '../../services/CommentAddToBookingService';
import type { ICommentAddToCompanyParams } from '../../services/CommentAddToCompanyService';
import { commentAddToCompanyService } from '../../services/CommentAddToCompanyService';
import type { ICommentAddToCustomerParams } from '../../services/CommentAddToCustomerService';
import { commentAddToCustomerService } from '../../services/CommentAddToCustomerService';
import type { ICommentAddToVehicleParams } from '../../services/CommentAddToVehicleService';
import { commentAddToVehicleService } from '../../services/CommentAddToVehicleService';
import type { ICommentGetByBookingParams } from '../../services/CommentGetByBookingService';
import { commentGetByBookingService } from '../../services/CommentGetByBookingService';
import type { ICommentGetByCompanyParams } from '../../services/CommentGetByCompanyService';
import { commentGetByCompanyService } from '../../services/CommentGetByCompanyService';
import type { ICommentGetByCustomerParams } from '../../services/CommentGetByCustomerService';
import { commentGetByCustomerService } from '../../services/CommentGetByCustomerService';
import type { ICommentGetByVehicleParams } from '../../services/CommentGetByVehicleService';
import { commentGetByVehicleService } from '../../services/CommentGetByVehicleService';
import type { ICommentRemoveFromBookingParams } from '../../services/CommentRemoveFromBookingService';
import { commentRemoveFromBookingService } from '../../services/CommentRemoveFromBookingService';
import type { ICommentRemoveFromCompanyParams } from '../../services/CommentRemoveFromCompanyService';
import { commentRemoveFromCompanyService } from '../../services/CommentRemoveFromCompanyService';
import type { ICommentRemoveFromCustomerParams } from '../../services/CommentRemoveFromCustomerService';
import { commentRemoveFromCustomerService } from '../../services/CommentRemoveFromCustomerService';
import type { ICommentRemoveFromVehicleParams } from '../../services/CommentRemoveFromVehicleService';
import { commentRemoveFromVehicleService } from '../../services/CommentRemoveFromVehicleService';

/**
 * Retrieves comments associated with a specific customer.
 *
 * @param {IComment[]} payload - The comments to be retrieved.
 * @param {ICommentGetByCustomerParams} params - Parameters to identify comments related to a specific customer.
 * @returns {Promise<void>} - A promise that resolves to an array of comments.
 */
export const commentGetByCustomer = createAsyncAction<IComment[], ICommentGetByCustomerParams>(
	'comment/getByCustomer',
	commentGetByCustomerService,
);

/**
 * Retrieves comments associated with a specific company.
 *
 * @param {IComment[]} payload - The comments to be retrieved.
 * @param {ICommentGetByCompanyParams} params - Parameters to identify comments related to a specific company.
 * @returns {Promise<void>} - A promise that resolves to an array of comments.
 */
export const commentGetByCompany = createAsyncAction<IComment[], ICommentGetByCompanyParams>(
	'comment/getByCompany',
	commentGetByCompanyService,
);

/**
 * Retrieves comments associated with a specific booking.
 *
 * @param {IComment[]} payload - The comments to be retrieved.
 * @param {ICommentGetByBookingParams} params - Parameters to identify comments related to a specific booking.
 * @returns {Promise<void>} - A promise that resolves to an array of comments.
 */
export const commentGetByBooking = createAsyncAction<IComment[], ICommentGetByBookingParams>(
	'comment/getByBooking',
	commentGetByBookingService,
);

/**
 * Retrieves comments associated with a specific vehicle.
 *
 * @param {IComment[]} payload - The comments to be retrieved.
 * @param {ICommentGetByVehicleParams} params - Parameters to identify comments related to a specific vehicle.
 * @returns {Promise<void>} - A promise that resolves to an array of comments.
 */
export const commentGetByVehicle = createAsyncAction<IComment[], ICommentGetByVehicleParams>(
	'comment/getByVehicle',
	commentGetByVehicleService,
);

/**
 * Adds a comment to a specific vehicle.
 *
 * @param {IComment} payload - The comment to be added.
 * @param {ICommentAddToVehicleParams} params - Parameters detailing the comment and the vehicle it is related to.
 * @returns {Promise<void>} - A promise that resolves after the comment is added.
 */
export const commentAddToVehicle = createAsyncAction<IComment, ICommentAddToVehicleParams>(
	'comment/addToVehicle',
	commentAddToVehicleService,
);

/**
 * Adds a comment to a specific booking.
 *
 * @param {IComment} payload - The comment to be added.
 * @param {ICommentAddToBookingParams} params - Parameters detailing the comment and the booking it is related to.
 * @returns {Promise<void>} - A promise that resolves after the comment is added.
 */
export const commentAddToBooking = createAsyncAction<IComment, ICommentAddToBookingParams>(
	'comment/addToBooking',
	commentAddToBookingService,
);

/**
 * Adds a comment to a specific customer.
 *
 * @param {IComment} payload - The comment to be added.
 * @param {ICommentAddToCustomerParams} params - Parameters detailing the comment and the customer it is related to.
 * @returns {Promise<void>} - A promise that resolves after the comment is added.
 */
export const commentAddToCustomer = createAsyncAction<IComment, ICommentAddToCustomerParams>(
	'comment/addToCustomer',
	commentAddToCustomerService,
);

/**
 * Adds a comment to a specific company.
 *
 * @param {IComment} payload - The comment to be added.
 * @param {ICommentAddToCompanyParams} params - Parameters detailing the comment and the company it is related to.
 * @returns {Promise<void>} - A promise that resolves after the comment is added.
 */
export const commentAddToCompany = createAsyncAction<IComment, ICommentAddToCompanyParams>(
	'comment/addToCompany',
	commentAddToCompanyService,
);

/**
 * Removes a comment from a specific vehicle.
 *
 * @param {IComment} payload - The comment to be removed.
 * @param {ICommentRemoveFromVehicleParams} params - Parameters detailing the comment and the vehicle it is related to.
 * @returns {Promise<void>} - A promise that resolves after the comment is removed.
 */
export const commentRemoveFromVehicle = createAsyncAction<IComment, ICommentRemoveFromVehicleParams>(
	'comment/removeFromVehicle',
	commentRemoveFromVehicleService,
);

/**
 * Removes a comment from a specific customer.
 *
 * @param {IComment} payload - The comment to be removed.
 * @param {ICommentRemoveFromCustomerParams} params - Parameters detailing the comment and the customer it is related to.
 * @returns {Promise<void>} - A promise that resolves after the comment is removed.
 */
export const commentRemoveFromCustomer = createAsyncAction<IComment, ICommentRemoveFromCustomerParams>(
	'comment/removeFromCustomer',
	commentRemoveFromCustomerService,
);

/**
 * Removes a comment from a specific company.
 *
 * @param {IComment} payload - The comment to be removed.
 * @param {ICommentRemoveFromCompanyParams} params - Parameters detailing the comment and the company it is related to.
 * @returns {Promise<void>} - A promise that resolves after the comment is removed.
 */
export const commentRemoveFromCompany = createAsyncAction<IComment, ICommentRemoveFromCompanyParams>(
	'comment/removeFromCompany',
	commentRemoveFromCompanyService,
);

/**
 * Removes a comment from a specific booking.
 *
 * @param {IComment} payload - The comment to be removed.
 * @param {ICommentRemoveFromBookingParams} params - Parameters detailing the comment and the booking it is related to.
 * @returns {Promise<void>} - A promise that resolves after the comment is removed.
 */
export const commentRemoveFromBooking = createAsyncAction<IComment, ICommentRemoveFromBookingParams>(
	'comment/removeFromBooking',
	commentRemoveFromBookingService,
);
