import type { UnitTime } from '@crac/core/models/types/UnitTime';
import type { IRentRateType } from '@crac/core/modules/pricing/rentRateType/entities/RentRateType';
import { RentRateTypeEndpoints } from '@crac/core/modules/pricing/rentRateType/services/RentRateTypeEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IRentRateTypesSearchParams extends IServiceBaseParams {
	code?: string;
	name?: string;
	active?: boolean;
	duration?: string;
	unitTime?: UnitTime;
}

export const rentRateTypeSearchService = (
	model: IRentRateTypesSearchParams,
): Promise<ServiceResponse<IRentRateType[]>> => {
	return Api.get<IRentRateType[], IRentRateTypesSearchParams>(RentRateTypeEndpoints.SEARCH, model);
};
