import type { IServiceRate } from '@crac/core/modules/pricing/serviceRate/entities/ServiceRate';
import { ServiceRateEndpoints } from '@crac/core/modules/pricing/serviceRate/services/ServiceRateEndpoints';
import type { FromTo } from '@crac/core/modules/pricing/types/FromToType';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IServiceRatesSearchParams extends IServiceBaseParams {
	branches?: number[];
	vehicleGroups?: string[];
	serviceRateTypes: string[];
	pickUp?: FromTo<Date | string>;
	quote?: FromTo<Date | string>;
	partners?: Array<{ code: string; type: number }>;
	services?: string[];
}

export const serviceRateSearchService = (
	model: IServiceRatesSearchParams,
): Promise<ServiceResponse<IServiceRate[]>> => {
	return Api.get<IServiceRate[], IServiceRatesSearchParams>(ServiceRateEndpoints.SEARCH, model);
};
