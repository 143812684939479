import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CommentEndPoints } from './CommentsEndpoints';
import type { IComment } from '../entities/Comment';

export interface ICommentGetByCompanyParams extends IServiceBaseParams {
	companyCode: string;
}

export const commentGetByCompanyService = (model: ICommentGetByCompanyParams): Promise<ServiceResponse<IComment[]>> => {
	return Api.get<IComment[], ICommentGetByCompanyParams>(CommentEndPoints.GET_BY_COMPANY, model);
};
