import type { IPartnerGroup } from '@crac/core/modules/pricing/partnerConfiguration/partnerGroup/entities/PartnerGroup';
import { PartnerGroupEndpoints } from '@crac/core/modules/pricing/partnerConfiguration/partnerGroup/services/PartnerGroupEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IPartnerGroupGetByCodeParams extends IServiceBaseParams {
	code: string;
}

export const partnerGroupGetByCodeService = (
	model: IPartnerGroupGetByCodeParams,
): Promise<ServiceResponse<IPartnerGroup>> => {
	return Api.get<IPartnerGroup, IPartnerGroupGetByCodeParams>(PartnerGroupEndpoints.GET_BY_CODE, model);
};
