import { RentRateMarginEndpoints } from '@crac/core/modules/pricing/rentRateMargin/services/RentRateMarginEndpoints';
import type { IRentRateVariation } from '@crac/core/modules/pricing/rentRateVariation/entities/RentRateVariation';
import type { IRentRateVariationsSearchParams } from '@crac/core/modules/pricing/rentRateVariation/services/RentRateVariationSearchService';
import { Api } from '@crac/core/modules/shared/api';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export const rentRateMarginSearchService = (
	model: IRentRateVariationsSearchParams,
): Promise<ServiceResponse<IRentRateVariation[]>> => {
	return Api.get<IRentRateVariation[], IRentRateVariationsSearchParams>(RentRateMarginEndpoints.SEARCH, model);
};
