import type { IMaximumBookingDuration } from '@crac/core/modules/pricing/maximumBookingDuration/entities/MaximumBookingDuration';
import { MaximumBookingDurationEndpoints } from '@crac/core/modules/pricing/maximumBookingDuration/services/MaximumBookingDurationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IMaximumBookingDurationDeactivateParams extends IServiceBaseParams {
	ids: number[];
}

/**
 * Deactivate maximum booking durations
 */
export const maximumBookingDurationDeactivateService = (
	model: IMaximumBookingDurationDeactivateParams,
): Promise<ServiceResponse<IMaximumBookingDuration[]>> => {
	return Api.post<IMaximumBookingDuration[], IMaximumBookingDurationDeactivateParams>(
		MaximumBookingDurationEndpoints.DEACTIVATE,
		model,
	);
};
