import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CustomerEndPoints } from './CustomerEndpoints';
import type { ICustomer } from '../entities/Customer';

export interface ICustomerSearchParams extends IServiceBaseParams {
	birthCountry?: string;
	birthDate?: string | Date;
	closedBookings?: boolean;
	code?: string;
	driverLicense?: string;
	driverLicenseCountry?: string;
	email?: string;
	firstSurname?: string;
	name?: string;
	taxIdentificationCountry?: string;
	taxIdentificationNumber?: string;
	phoneNumber?: string;
}

/**
 * Search customer
 * @returns {Promise} `CustomerPropType`
 */
export const customerSearchService = (model: ICustomerSearchParams): Promise<ServiceResponse<ICustomer[]>> => {
	return Api.get<ICustomer[], ICustomerSearchParams>(CustomerEndPoints.SEARCH, model);
};
