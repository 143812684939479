import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { customerGetByCodeService } from './CustomerGetByCodeService';
import { cityServiceGetByCountryAndPostCode, cityServiceGetByProvince } from '../../../data/services/CityService';
import {
	provinceServiceGetByCountry,
	provinceServiceGetByCountryAndPostCode,
} from '../../../data/services/ProvinceService';
import type { ICity } from '../../../models/entities/City';
import type { ICountry } from '../../../models/entities/Country';
import type { IProvince } from '../../../models/entities/Province';
import type { ICustomerGetDetailDataResponse } from '../../../models/serviceResponse/CustomerResponse';

export interface ICustomerGetDetailData extends IServiceBaseParams {
	countries: ICountry[];
	customerCode: string;
}

export const customerGetDetailDataService = async (
	params: ICustomerGetDetailData,
): Promise<ServiceResponse<ICustomerGetDetailDataResponse>> => {
	const { customerCode, countries } = params;
	let provinces: IProvince[] = [];
	let birthProvinces: IProvince[] = [];
	let cities: ICity[] = [];
	let birthCities: ICity[] = [];

	// GET CUSTOMER BY CODE
	const customerResponse = await customerGetByCodeService({ customerCode });

	if (!customerResponse.ok || !customerResponse.data) {
		return new ServiceResponse(false, null, customerResponse.errors);
	}

	const birthCountry = countries.find((country) => country.code === customerResponse.data?.birthCountry);

	// GET BIRTH CUSTOMER DATA
	if (birthCountry && birthCountry.data) {
		// BIRTH PROVINCES
		const birthProvincesResponse = await provinceServiceGetByCountry({
			countryCode: birthCountry.code,
		});

		if (birthProvincesResponse.ok && birthProvincesResponse.data) {
			birthProvinces = birthProvincesResponse.data;
		}

		// GET BIRTH CITIES
		if (customerResponse.data.birthProvince) {
			const birthCitiesResponse = await cityServiceGetByProvince({
				provinceCode: customerResponse.data.birthProvince,
			});

			if (birthCitiesResponse.ok && birthCitiesResponse.data) {
				birthCities = birthCitiesResponse.data;
			}
		}
	}

	// CUSTOMER RESIDENT DATA
	const country = countries.find((country) => country.code === customerResponse.data?.country);

	if (country && country.data && customerResponse.data.postalCode) {
		// GET PROVINCES
		const provincesResponse = await provinceServiceGetByCountryAndPostCode({
			countryCode: country.code,
			postCode: customerResponse.data.postalCode,
		});

		if (provincesResponse.ok && provincesResponse.data) {
			provinces = provincesResponse.data;
		}

		// GET CITIES
		const citiesResponse = await cityServiceGetByCountryAndPostCode({
			countryCode: country.code,
			postCode: customerResponse.data.postalCode,
		});

		if (citiesResponse.ok && citiesResponse.data) {
			cities = citiesResponse.data;
		}
	}

	return new ServiceResponse(true, {
		birthProvinces,
		birthCities,
		provinces,
		cities,
		customer: customerResponse.data,
	});
};
