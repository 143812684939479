import type { IIncludedServiceConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/includedServiceConfiguration/entities/IncludedServiceConfiguration';
import { IncludedServiceConfigurationEndpoints } from '@crac/core/modules/pricing/partnerConfiguration/includedServiceConfiguration/services/IncludedServiceConfigurationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

/**
 * To insert a service invoiceable configuration
 */
export interface IIncludedServiceConfigurationInsertParams extends IServiceBaseParams {
	IncludedServicesConfigurations: IIncludedServiceConfiguration[];
}

/**
 * Insert included service configuration
 * @param params `IIncludedServiceConfigurationInsertParams`
 * @returns `Promise<ServiceResponse<IIncludedServiceConfiguration[]>>`
 */
export const includedServiceConfigurationInsertService = (params: IIncludedServiceConfigurationInsertParams) => {
	return Api.post<IIncludedServiceConfiguration[], IIncludedServiceConfigurationInsertParams>(
		IncludedServiceConfigurationEndpoints.INSERT,
		params,
	);
};
