import type { IAvailabilityGroup } from '@crac/core/modules/booking/availability/entities/AvailabilityGroup';
import { AvailabilityConfigurationEndpoints } from '@crac/core/modules/pricing/availabilityConfiguration/services/AvailabilityConfigurationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IAvailabilityConfigurationSearchParams extends IServiceBaseParams {
	provider: string;
	vehicleGroupRequested?: string;
	vehicleGroupDelivered?: string;
}

/**
 * Search group availability
 */
export const availabilityConfigSearchService = (
	model: IAvailabilityConfigurationSearchParams,
): Promise<ServiceResponse<IAvailabilityGroup[]>> => {
	return Api.get<IAvailabilityGroup[], IAvailabilityConfigurationSearchParams>(
		AvailabilityConfigurationEndpoints.SEARCH,
		model,
	);
};
