import type { IGroupOrderAndUpgrade } from '@crac/core/modules/pricing/vehicleGroupOrderAndUpgrade/entities/VehicleGroupOrderAndUpgrade';
import type { IVehicleGroupOrderAndUpgradeDeleteParams } from '@crac/core/modules/pricing/vehicleGroupOrderAndUpgrade/services/VehicleGroupOrderAndUpgradeDeleteService';
import { vehicleGroupOrderAndUpgradeDeleteService } from '@crac/core/modules/pricing/vehicleGroupOrderAndUpgrade/services/VehicleGroupOrderAndUpgradeDeleteService';
import type { IVehicleGroupOrderAndUpgradeInsertParams } from '@crac/core/modules/pricing/vehicleGroupOrderAndUpgrade/services/VehicleGroupOrderAndUpgradeInsertService';
import { vehicleGroupOrderAndUpgradeInsertService } from '@crac/core/modules/pricing/vehicleGroupOrderAndUpgrade/services/VehicleGroupOrderAndUpgradeInsertService';
import { vehicleGroupOrderAndUpgradeModifyService } from '@crac/core/modules/pricing/vehicleGroupOrderAndUpgrade/services/VehicleGroupOrderAndUpgradeModifyService';
import type { IVehicleGroupOrderAndUpgradeSearchParams } from '@crac/core/modules/pricing/vehicleGroupOrderAndUpgrade/services/VehicleGroupOrderAndUpgradeSearchService';
import { vehicleGroupOrderAndUpgradeSearchService } from '@crac/core/modules/pricing/vehicleGroupOrderAndUpgrade/services/VehicleGroupOrderAndUpgradeSearchService';
import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

/**
 * Searches for orders and upgrades within vehicle groups based on specified parameters.
 *
 * @param {IGroupOrderAndUpgrade[]} payload - The group orders and upgrades to be retrieved.
 * @param {IVehicleGroupOrderAndUpgradeSearchParams} params - Parameters for the search of group orders and upgrades.
 * @returns {Promise<void>} - A promise that resolves to an array of group orders and upgrades.
 */
export const vehicleGroupOrderAndUpgradeSearch = createAsyncAction<
	IGroupOrderAndUpgrade[],
	IVehicleGroupOrderAndUpgradeSearchParams
>('vehicleGroupOrderAndUpgrade/search', vehicleGroupOrderAndUpgradeSearchService);

/**
 * Inserts new orders and upgrades into vehicle groups.
 *
 * @param {IGroupOrderAndUpgrade[]} payload - The group orders and upgrades to be inserted.
 * @param {IVehicleGroupOrderAndUpgradeInsertParams} params - Parameters for the insertion of group orders and upgrades.
 * @returns {Promise<void>} - A promise that resolves after the group orders and upgrades are inserted.
 */
export const vehicleGroupOrderAndUpgradeInsert = createAsyncAction<
	IGroupOrderAndUpgrade[],
	IVehicleGroupOrderAndUpgradeInsertParams
>('vehicleGroupOrderAndUpgrade/insert', vehicleGroupOrderAndUpgradeInsertService);

/**
 * Modifies existing orders and upgrades within vehicle groups.
 *
 * @param {IGroupOrderAndUpgrade[]} payload - The group orders and upgrades to be modified.
 * @param {IVehicleGroupOrderAndUpgradeInsertParams} params - Parameters for the modification of group orders and upgrades.
 * @returns {Promise<void>} - A promise that resolves after the group orders and upgrades are modified.
 */
export const vehicleGroupOrderAndUpgradeModify = createAsyncAction<
	IGroupOrderAndUpgrade[],
	IVehicleGroupOrderAndUpgradeInsertParams
>('vehicleGroupOrderAndUpgrade/modify', vehicleGroupOrderAndUpgradeModifyService);

/**
 * Deletes a specific order or upgrade from vehicle groups.
 *
 * @param {IGroupOrderAndUpgrade} payload - The group order or upgrade to be deleted.
 * @param {IVehicleGroupOrderAndUpgradeDeleteParams} params - Parameters for the deletion of the group order or upgrade.
 * @returns {Promise<void>} - A promise that resolves after the group order or upgrade is deleted.
 */
export const vehicleGroupOrderAndUpgradeDelete = createAsyncAction<
	IGroupOrderAndUpgrade,
	IVehicleGroupOrderAndUpgradeDeleteParams
>('vehicleGroupOrderAndUpgrade/delete', vehicleGroupOrderAndUpgradeDeleteService);

/**
 * Clears the vehicle group order and upgrade state.
 */
export const vehicleGroupOrderAndUpgradeClear = createSyncAction('vehicleGroupOrderAndUpgrade/clear');

export const vehicleGroupOrderAndUpgradeAssistSearch = createAsyncAction<
	IGroupOrderAndUpgrade[],
	IVehicleGroupOrderAndUpgradeSearchParams
>('vehicleGroupOrderAndUpgrade/AssistSearch', vehicleGroupOrderAndUpgradeSearchService);

/**
 * Modifies existing orders and upgrades within vehicle groups.
 *
 * @param {IGroupOrderAndUpgrade[]} payload - The group orders and upgrades to be modified.
 * @param {IVehicleGroupOrderAndUpgradeInsertParams} params - Parameters for the modification of group orders and upgrades.
 * @returns {Promise<void>} - A promise that resolves after the group orders and upgrades are modified.
 */
export const vehicleGroupOrderAndUpgradeAssistModify = createAsyncAction<
	IGroupOrderAndUpgrade[],
	IVehicleGroupOrderAndUpgradeInsertParams
>('vehicleGroupOrderAndUpgrade/AssistModify', vehicleGroupOrderAndUpgradeModifyService);

/**
 * Deletes a specific order or upgrade from vehicle groups.
 *
 * @param {IGroupOrderAndUpgrade} payload - The group order or upgrade to be deleted.
 * @param {IVehicleGroupOrderAndUpgradeDeleteParams} params - Parameters for the deletion of the group order or upgrade.
 * @returns {Promise<void>} - A promise that resolves after the group order or upgrade is deleted.
 */
export const vehicleGroupOrderAndUpgradeAssistDelete = createAsyncAction<
	IGroupOrderAndUpgrade,
	IVehicleGroupOrderAndUpgradeDeleteParams
>('vehicleGroupOrderAndUpgrade/AssistDelete', vehicleGroupOrderAndUpgradeDeleteService);
