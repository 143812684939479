import type { IIncludedServiceConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/includedServiceConfiguration/entities/IncludedServiceConfiguration';
import type { IIncludedServiceConfigurationDeactivateParams } from '@crac/core/modules/pricing/partnerConfiguration/includedServiceConfiguration/services/IncludedServiceConfigurationDeactivateService';
import { includedServiceConfigurationDeactivateService } from '@crac/core/modules/pricing/partnerConfiguration/includedServiceConfiguration/services/IncludedServiceConfigurationDeactivateService';
import type { IIncludedServiceConfigurationInsertParams } from '@crac/core/modules/pricing/partnerConfiguration/includedServiceConfiguration/services/IncludedServiceConfigurationInsertService';
import { includedServiceConfigurationInsertService } from '@crac/core/modules/pricing/partnerConfiguration/includedServiceConfiguration/services/IncludedServiceConfigurationInsertService';
import type { IIncludedServiceConfigurationModifyParams } from '@crac/core/modules/pricing/partnerConfiguration/includedServiceConfiguration/services/IncludedServiceConfigurationModifyService';
import { includedServiceConfigurationModifyService } from '@crac/core/modules/pricing/partnerConfiguration/includedServiceConfiguration/services/IncludedServiceConfigurationModifyService';
import { includedServiceConfigurationSearchService } from '@crac/core/modules/pricing/partnerConfiguration/includedServiceConfiguration/services/IncludedServiceConfigurationSearchService';
import type { IIncludedServiceConfigurationSearchParams } from '@crac/core/modules/pricing/partnerConfiguration/includedServiceConfiguration/services/IncludedServiceConfigurationSearchService';
import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

/**
 * Searches for included service configurations based on specified parameters.
 *
 * @param {IIncludedServiceConfiguration[]} payload - The included service configurations to be returned.
 * @param {IIncludedServiceConfigurationSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of included service configurations.
 */
export const includedServiceConfigurationSearch = createAsyncAction<
	IIncludedServiceConfiguration[],
	IIncludedServiceConfigurationSearchParams
>('includedServiceConfiguration/search', includedServiceConfigurationSearchService);

/**
 * Inserts new included service configurations into the system.
 *
 * @param {IIncludedServiceConfiguration[]} payload - The included service configurations to be inserted.
 * @param {IIncludedServiceConfigurationInsertParams} params - Parameters detailing the included service configurations to be inserted.
 * @returns {Promise<void>} - A promise that resolves after the included service configurations are successfully inserted.
 */
export const includedServiceConfigurationInsert = createAsyncAction<
	IIncludedServiceConfiguration[],
	IIncludedServiceConfigurationInsertParams
>('includedServiceConfiguration/insert', includedServiceConfigurationInsertService);

/**
 * Modifies an existing included service configuration.
 *
 * @param {IIncludedServiceConfiguration} payload - The included service configuration to be modified.
 * @param {IIncludedServiceConfigurationModifyParams} params - Parameters for the modification.
 * @returns {Promise<void>} - A promise that resolves after the included service configuration is modified.
 */
export const includedServiceConfigurationModify = createAsyncAction<
	IIncludedServiceConfiguration,
	IIncludedServiceConfigurationModifyParams
>('includedServiceConfiguration/modify', includedServiceConfigurationModifyService);

/**
 * Removes deactivated included service configurations from the state.
 *
 * @param {number} payload - The ID of the included service configuration to be removed.
 */
export const includedServiceConfigurationRemoveDeactivated = createSyncAction<number>(
	'includedServiceConfiguration/removeDeactivated',
);

/**
 * Deactivates included service configurations.
 *
 * @param {IIncludedServiceConfiguration[]} payload - The included service configurations to be deactivated.
 * @param {IIncludedServiceConfigurationDeactivateParams} params - Parameters for the deactivation.
 * @returns {Promise<void>} - A promise that resolves after the included service configurations are deactivated.
 */
export const includedServiceConfigurationDeactivate = createAsyncAction<
	IIncludedServiceConfiguration[],
	IIncludedServiceConfigurationDeactivateParams
>('includedServiceConfiguration/deactivate', includedServiceConfigurationDeactivateService);

/**
 * Clears the included service configuration state.
 */
export const includedServiceConfigurationClear = createSyncAction('includedServiceConfiguration/clear');
