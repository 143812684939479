import type { IBranchSchedule } from '@crac/core/modules/pricing/branchSchedule/entities/BranchSchedule';
import type { IBranchScheduleDeleteParams } from '@crac/core/modules/pricing/branchSchedule/services/BranchScheduleDeleteService';
import { branchScheduleDeleteService } from '@crac/core/modules/pricing/branchSchedule/services/BranchScheduleDeleteService';
import type { IBranchScheduleInsertParams } from '@crac/core/modules/pricing/branchSchedule/services/BranchScheduleInsertService';
import { branchScheduleInsertService } from '@crac/core/modules/pricing/branchSchedule/services/BranchScheduleInsertService';
import { branchScheduleModifyService } from '@crac/core/modules/pricing/branchSchedule/services/BranchScheduleModifyService';
import type { IBranchScheduleModifyParams } from '@crac/core/modules/pricing/branchSchedule/services/BranchScheduleModifyService';
import { branchScheduleSearchService } from '@crac/core/modules/pricing/branchSchedule/services/BranchScheduleSearchService';
import type { IBranchScheduleSearchParams } from '@crac/core/modules/pricing/branchSchedule/services/BranchScheduleSearchService';
import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

/**
 * Searches for branch schedules.
 *
 * @param {IBranchSchedule[]} payload `IBranchSchedule[]` The payload for the action.
 * @param {IBranchScheduleSearchParams} IBranchScheduleSearchParams - The search parameters.
 * @returns {Promise<void>} - The branch schedules.
 */
export const branchScheduleSearch = createAsyncAction<IBranchSchedule[], IBranchScheduleSearchParams>(
	'branchSchedule/search',
	branchScheduleSearchService,
);

/**
 * Modifies a branch schedule.
 * @param {IBranchSchedule} payload `IBranchSchedule` The branch schedule modified.
 * @param {IBranchScheduleModifyParams} IBranchScheduleModifyParams - Params to modify.
 * @returns {Promise<void>} A promise that resolves when the branch schedule is modified
 */
export const branchScheduleModify = createAsyncAction<IBranchSchedule, IBranchScheduleModifyParams>(
	'branchSchedule/modify',
	branchScheduleModifyService,
);

/**
 * Deletes a branch schedule.
 *
 * @param {number} payload - The ID of the branch schedule to delete.
 * @param {IBranchScheduleDeleteParams} meta - Additional metadata for the action.
 * @returns {AsyncAction<number, IBranchScheduleDeleteParams>} The delete branch schedule action.
 */
export const branchScheduleDelete = createAsyncAction<number, IBranchScheduleDeleteParams>(
	'branchSchedule/delete',
	branchScheduleDeleteService,
);

/**
 * Clears the branch schedule.
 */
export const branchScheduleClear = createSyncAction('branchSchedule/clear');

// ------------------------------- RANGES ------------------------------

export const branchScheduleRangeClear = createSyncAction('branch/scheduleClearRange');

export const branchScheduleRangeSearch = createAsyncAction<IBranchSchedule[], IBranchScheduleSearchParams>(
	'branch/scheduleSearchRange',
	branchScheduleSearchService,
);

export const branchScheduleRangeInsert = createAsyncAction<IBranchSchedule[], IBranchScheduleInsertParams>(
	'branch/scheduleInsertRange',
	branchScheduleInsertService,
);

export const branchScheduleRangeModify = createAsyncAction<IBranchSchedule, IBranchScheduleModifyParams>(
	'branch/scheduleModifyRange',
	branchScheduleModifyService,
);

export const branchScheduleRangeDelete = createAsyncAction<number, IBranchScheduleDeleteParams>(
	'branch/scheduleDeleteRange',
	branchScheduleDeleteService,
);
