import { DateTime } from 'luxon';

import { agencyServiceGetByCode, agencyServiceSearch } from '@crac/core/data/services/AgencyService';
import { branchServiceGetByPartnerCodeAndType } from '@crac/core/data/services/BranchService';
import { companyServiceGetByCode, companyServiceSearch } from '@crac/core/data/services/CompanyService';
import { vendorServiceGetByCode, vendorServiceSearch } from '@crac/core/data/services/VendorService';
import { formatSelect } from '@crac/core/helpers/commons';
import { combineDateAndTime, zeroPrefix } from '@crac/core/helpers/date-times';
import type { IAgency } from '@crac/core/models/entities/Agency';
import type { IBranch } from '@crac/core/models/entities/Branch';
import type { IVendor } from '@crac/core/models/entities/Vendor';
import { BookingType } from '@crac/core/models/types/BookingType';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { ISelectOptionType } from '@crac/core/models/types/SelectOptionType';
import type { ICompany } from '@crac/core/modules/company/entities/Company';
import type { IVehicleGroupByProvider } from '@crac/core/modules/fleet/vehicleGroup/entities/VehicleGroup';
import { vehicleGroupGetByBranchService } from '@crac/core/modules/fleet/vehicleGroup/services/VehicleGroupGetByBranchService';
import { PartnerType } from '@crac/core/modules/pricing/partnerConfiguration/types/PartnerType';
import type { BranchWithVehicle } from '@crac/core/modules/pricing/types/BranchWithVehicleType';
import { CreationMethod } from '@crac/core/modules/shared/types/CreationMethod';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';
import type { ISelectOption } from '@crac/form/field/selectField/types';

import type { IGenericBranchPartner, IGenericFilterPartners, IPartnerObj, IPartnerWithContract } from '../utils/types';

export const splitDateFromString = (dateString: string, toUTC = true) => {
	const date = DateTime.fromISO(dateString, { zone: toUTC ? 'utc' : '' }).toUTC();
	return { date: date.startOf('day').toJSDate(), time: `${zeroPrefix(date.hour)}:${zeroPrefix(date.minute)}` };
};

const filterResults = <T extends IGenericFilterPartners>(data: T | T[], contract?: number): T[] => {
	if (contract !== undefined && ![BookingType.PREBOOKING, BookingType.GUIDE].includes(contract)) {
		if ([BookingType.BROKER, BookingType.SALESPERSON].includes(contract)) {
			if (Array.isArray(data)) {
				const filteredData = data.filter((item) => item.contractType === contract);
				if (BookingType.BROKER === contract) {
					return filteredData.filter((item) => item.isBroker);
				}
				return filteredData.filter((item) => !item.isBroker);
			}

			if (data.contractType === contract) {
				if (BookingType.BROKER === contract) {
					return data.isBroker ? [data] : [];
				}
				return data.isBroker ? [] : [data];
			}
		}
		const filteredObj = contract === (data as T).contractType ? [data as T] : [];
		return Array.isArray(data) ? data.filter((item) => contract === item.contractType) : filteredObj;
	}

	return Array.isArray(data) ? data : [data];
};
export const allowedPartners = [PartnerType.vendor, PartnerType.agency, PartnerType.company];

export const handleSelectAsyncChange =
	<T extends IGenericFilterPartners>(
		getByCode: (model: any) => Promise<ServiceResponse<T>>,
		serviceSearch: (model: any) => Promise<ServiceResponse<T[]>>,
		contract?: number,
	) =>
	async (value: string) => {
		if ((value && value.length < 2) || !value) {
			return [];
		}
		if (value) {
			const vendor = await getByCode({ code: value });
			if (vendor.data) {
				return formatSelect(
					'name' as keyof T,
					'code' as keyof T,
					filterResults<T>(vendor.data, contract),
				) as unknown as ISelectOptionType<string>[];
			}
		}
		const result = await serviceSearch({ name: value });
		if (result.data) {
			return formatSelect<T>(
				'name' as keyof T,
				'code' as keyof T,
				filterResults<T>(result.data, contract),
			) as unknown as ISelectOptionType<string>[];
		}
		return [];
	};

export const partnerOptions = (partner: number, contract?: number) => {
	switch (partner) {
		case PartnerType.agency:
			return handleSelectAsyncChange<IAgency>(agencyServiceGetByCode, agencyServiceSearch, contract);
		case PartnerType.company:
			return handleSelectAsyncChange<ICompany>(companyServiceGetByCode, companyServiceSearch, contract);
		case PartnerType.vendor:
			return handleSelectAsyncChange<IVendor>(vendorServiceGetByCode, vendorServiceSearch, contract);
		default:
			return undefined;
	}
};

export const getDefaultOption = (partner: number, value: string) => {
	const loadFunc = partnerOptions(partner);
	if (loadFunc) {
		return loadFunc(value);
	}
	return undefined;
};

export const joinDateTime = (value?: { date?: Date; time?: string }, zone?: string) => {
	if (value && value.date && value.time) {
		return combineDateAndTime(value.date, value.time, zone).toJSON();
	}
	return undefined;
};

export const getPartnerObj = (codes: string[] | undefined, partnerType: PartnerType): IPartnerObj[] =>
	codes && codes.length ? codes.map((partnerCode) => ({ partnerType, partnerCode })) : [];

export const getPosibleCombinationsByVehicleAndBranch = <T extends BranchWithVehicle>(
	combinations: T[],
	branches: IBranch[],
	vehicleGroupsByProvider: IVehicleGroupByProvider[],
): T[] =>
	combinations.filter((combination) => {
		if (!combination.branch || !combination.vehicleGroup) {
			return true;
		}
		const combinationProvider = branches.find(({ code }) => code === String(combination.branch))?.provider;
		if (!combinationProvider) {
			return false;
		}
		if (
			vehicleGroupsByProvider
				.find((group) => group.provider === combinationProvider)
				?.vehicleGroups.map((vehicleGroup) => vehicleGroup.code)
				.includes(combination.vehicleGroup)
		) {
			return true;
		}
		return false;
	});

export const getPosibleCombinationsByBranchAndPartner = async <T extends IGenericBranchPartner>(
	combinations: T[],
	partners: IPartnerObj[],
) => {
	const branchesByPartnerCodeAndTypePromises = partners.map(async (partner) => {
		const res = await branchServiceGetByPartnerCodeAndType({
			code: partner.partnerCode,
			type: partner.partnerType,
		});
		if (res.ok && res.data) {
			return Promise.resolve({
				code: partner.partnerCode,
				branches: res.data,
			});
		}
		return undefined;
	});
	const branchesByPartner = await Promise.all(branchesByPartnerCodeAndTypePromises);

	return combinations.filter((combination) => {
		if (combination.branch && combination.partnerCode) {
			const branchesByPartnerResults = branchesByPartner.find((branchByPartner) => {
				if (branchByPartner && combination.partnerCode) {
					return branchByPartner.code === combination.partnerCode;
				}
				return false;
			});

			if (branchesByPartnerResults !== undefined && branchesByPartnerResults.branches) {
				return branchesByPartnerResults.branches.includes(String(combination.branch));
			}
		}
		return true;
	});
};

export const getPartnerTypeFromContract = (type: number): number => {
	if ([BookingType.SALESPERSON, BookingType.BROKER].includes(type)) {
		return PartnerType.vendor;
	}

	if ([BookingType.COMPANY].includes(type)) {
		return PartnerType.company;
	}

	return PartnerType.agency;
};

export const getGroupOptionsByBranch = async (branchCode?: string): Promise<ISelectOptionType<string>[]> => {
	if (branchCode) {
		const response = await vehicleGroupGetByBranchService({ branchCode });
		if (response.ok && response.data) {
			return formatSelect(
				'code',
				'code',
				response.data.filter(({ rac }) => rac),
			) as ISelectOptionType<string>[];
		}
		return [];
	}
	return [];
};

export const getBranchOptions = (branches: IBranch[]) =>
	branches
		.filter(({ canMakeBooking }) => canMakeBooking)
		.map(({ code, name }) => ({ label: `${code} - ${name}`, value: Number(code) }));

export const checkAgencyContract = (agency: IAgency, obj: IPartnerWithContract, contracts: number[]) => {
	const emptyObj = [{ ...obj }];
	switch (agency.contractType) {
		case BookingType.DIRECT:
			return contracts.includes(BookingType.DIRECT) ? [{ ...obj, contractType: BookingType.DIRECT }] : emptyObj;
		case BookingType.WALKING:
			return contracts.includes(BookingType.WALKING) ? [{ ...obj, contractType: BookingType.WALKING }] : emptyObj;
		case BookingType.PREBOOKING:
		case BookingType.GUIDE: {
			if (!agency.centauroAgency) {
				if (contracts.includes(BookingType.GUIDE) && contracts.includes(BookingType.PREBOOKING)) {
					return [
						{ ...obj, contractType: BookingType.PREBOOKING },
						{ ...obj, contractType: BookingType.GUIDE },
					];
				}
				if (contracts.includes(BookingType.PREBOOKING)) {
					return [{ ...obj, contractType: BookingType.PREBOOKING }];
				}

				return contracts.includes(BookingType.GUIDE) ? [{ ...obj, contractType: BookingType.GUIDE }] : emptyObj;
			}
			return emptyObj;
		}
		case BookingType.FREE:
			return contracts.includes(BookingType.FREE) ? [{ ...obj, contractType: BookingType.FREE }] : emptyObj;
		case BookingType.TRANSFER:
			return contracts.includes(BookingType.TRANSFER)
				? [{ ...obj, contractType: BookingType.TRANSFER }]
				: emptyObj;
		case BookingType.STAFF:
			return contracts.includes(BookingType.STAFF) ? [{ ...obj, contractType: BookingType.STAFF }] : emptyObj;
		case BookingType.INTERNET:
			return contracts.includes(BookingType.INTERNET)
				? [{ ...obj, contractType: BookingType.INTERNET }]
				: emptyObj;
		case BookingType.GOLDCLUB:
			return contracts.includes(BookingType.GOLDCLUB)
				? [{ ...obj, contractType: BookingType.GOLDCLUB }]
				: emptyObj;
		default:
			return emptyObj;
	}
};

export const fuseWithContracts = async (
	obj: IPartnerWithContract,
	contracts: number[],
): Promise<IPartnerWithContract[]> => {
	if (obj.partnerType === PartnerType.company && contracts.includes(BookingType.COMPANY)) {
		return [{ ...obj, contractType: BookingType.COMPANY }];
	}

	if (obj.partnerType === PartnerType.vendor && contracts.includes(BookingType.SALESPERSON)) {
		const res = await vendorServiceGetByCode({ code: obj.partnerCode as string });
		if (contracts.includes(BookingType.SALESPERSON) && res && res.data && !res.data.isBroker) {
			return [{ ...obj, contractType: BookingType.SALESPERSON }];
		}
	}

	if (obj.partnerType === PartnerType.vendor && contracts.includes(BookingType.BROKER)) {
		const res = await vendorServiceGetByCode({ code: obj.partnerCode as string });
		if (contracts.includes(BookingType.BROKER) && res && res.data && res.data.isBroker) {
			return [{ ...obj, contractType: BookingType.BROKER }];
		}
	}

	if (obj.partnerType === PartnerType.agency) {
		const res = await agencyServiceGetByCode({ code: obj.partnerCode as string });
		if (res && res.data) {
			return checkAgencyContract(res.data, obj, contracts);
		}
	}

	return [{ ...obj }];
};

export const combinePartners = async (
	contracts: number[],
	partners: { partnerType: number; partnerCode: string }[],
): Promise<IPartnerWithContract[]> => {
	if (contracts.length && partners.length) {
		const tasks: Promise<IPartnerWithContract[]>[] = [];
		for (const partner of partners) {
			tasks.push(fuseWithContracts(partner, contracts));
		}
		const result = await Promise.all(tasks);
		const unusedContracts = contracts.filter(
			(contract) => !result.flat().some(({ contractType }) => contractType === contract),
		);

		return unusedContracts.length
			? [
					...result.flat(),
					...unusedContracts.map((num) => ({
						contractType: num,
						partnerType: getPartnerTypeFromContract(num),
					})),
				]
			: result.flat();
	}

	if (contracts.length && !partners.length) {
		return contracts.map((contractType) => ({
			contractType,
			partnerType: getPartnerTypeFromContract(contractType),
		}));
	}

	if (!contracts.length && partners.length) {
		return partners;
	}

	return [];
};

export const checkContracts = (contracts: number[], values?: number[]) => {
	if (values && values.length) {
		return values.every((contract) => !contracts.includes(contract));
	}
	return true;
};

type createdPropsType = {
	branch?: number;
	created?: { user: string; date: string };
	updated?: { user: string; date: string };
};
export const toolTipInfoFormat = <T extends createdPropsType>(item: T, branches: IBranch[]): string => {
	if (!item.created) {
		return '';
	}
	const branch = branches.find((branch) => Number(branch.code) === item.branch);
	const createdDate = DateTime.fromISO(item.created.date).setZone(branch ? branch.timeZone : 'local');

	if (item.updated) {
		const updatedDate = DateTime.fromISO(item.updated.date).setZone(branch ? branch.timeZone : 'local');
		return `Created by ${item.created.user}\n${createdDate.toFormat('dd/MM/y HH:mm')}\nUpdated by ${
			item.updated.user
		}\n${updatedDate.toFormat('dd/MM/y HH:mm')}`;
	}

	return `Created by ${item.created.user}\n${createdDate.toFormat('dd/MM/y HH:mm')}`;
};

export const mapStringToArray = (arr: string, convertToNumber?: boolean) =>
	arr
		.replace(' ', '')
		.split(',')
		.map((item) => (convertToNumber ? Number(item) : item));

export const getPricingPermission = (permissions: PermissionsType[] = []) => [PermissionsType.Pricing, ...permissions];

export const handleLoadDefaultAgencies = async (agencies: string[] | string) => {
	const agencyTasks: any[] = [];
	if (Array.isArray(agencies)) {
		agencies.forEach((code) => {
			agencyTasks.push(
				agencyServiceSearch({
					code,
					creationMethod: CreationMethod.BRANCH,
				}),
			);
		});
	} else {
		agencyTasks.push(
			agencyServiceSearch({
				code: agencies as string,
				creationMethod: CreationMethod.BRANCH,
			}),
		);
	}

	const agencyOptions: ISelectOption<string>[] = [];
	const agencyResponse: ServiceResponse<IAgency[]>[] = await Promise.all(agencyTasks);

	agencyResponse.forEach((response) => {
		if (response.ok && response.data) {
			const options = formatSelect('name', 'code', response.data) as ISelectOptionType<string>[];
			agencyOptions.push(...options);
		}
	});

	return agencyOptions;
};

export const handleLoadDefaultCompanies = async (companies: string[] | string) => {
	const companyTasks: any[] = [];
	if (Array.isArray(companies)) {
		companies.forEach((code) => {
			companyTasks.push(
				companyServiceSearch({
					code,
					creationMethod: CreationMethod.BRANCH,
				}),
			);
		});
	} else {
		companyTasks.push(
			companyServiceSearch({
				code: companies as string,
				creationMethod: CreationMethod.BRANCH,
			}),
		);
	}

	const companyOptions: ISelectOptionType<string>[] = [];
	const companyResponse: ServiceResponse<IAgency[]>[] = await Promise.all(companyTasks);

	companyResponse.forEach((response) => {
		if (response.ok && response.data) {
			const options = formatSelect('name', 'code', response.data) as ISelectOptionType<string>[];
			companyOptions.push(...options);
		}
	});

	return companyOptions;
};

export const handleLoadDefaultVendors = async (vendors: string[]) => {
	const vendorTasks: any[] = [];
	if (Array.isArray(vendors)) {
		vendors.forEach((code) => {
			vendorTasks.push(
				vendorServiceSearch({
					code,
					creationMethod: CreationMethod.BRANCH,
				}),
			);
		});
	} else {
		vendorTasks.push(
			vendorServiceSearch({
				code: vendors as string,
				creationMethod: CreationMethod.BRANCH,
			}),
		);
	}

	const vendorOptions: ISelectOptionType<string>[] = [];
	const vendorResponse: ServiceResponse<IAgency[]>[] = await Promise.all(vendorTasks);

	vendorResponse.forEach((response) => {
		if (response.ok && response.data) {
			const options = formatSelect('name', 'code', response.data) as ISelectOptionType<string>[];
			vendorOptions.push(...options);
		}
	});

	return vendorOptions;
};

export const partnerDefaultOptions = (type: number) => {
	switch (type) {
		case PartnerType.agency:
			return handleLoadDefaultAgencies;
		case PartnerType.company:
			return handleLoadDefaultCompanies;
		case PartnerType.vendor:
			return handleLoadDefaultVendors;
		default:
			return undefined;
	}
};
