import { type FC, useState } from 'react';

import type { SortItemType } from '.';

import { SortListItem } from './SortListItem';

interface ISortList {
	sorts: SortItemType[];
	onClearBy: (id: string) => void;
	onToggleSort: (id: string) => void;
	onClear: () => void;
}

export const SortList: FC<ISortList> = ({ sorts, onClear, ...props }) => {
	const [showMore, setShowMore] = useState(false);
	const toggleMore = () => setShowMore((state) => !state);

	if (sorts.length === 0) {
		return null;
	}

	return (
		<div className="d-flex gap-1">
			{sorts.slice(0, 6).map((item) => (
				<SortListItem key={item.id} sort={item} {...props} />
			))}
			{sorts.length > 6 ? (
				<>
					<button
						className="button-badge fw-bold"
						onClick={toggleMore}
						title={showMore ? 'Collapse' : 'Show more'}
						type="button"
					>
						<small>
							{showMore ? <i className="fa fa-fw fa-minus" /> : `+${sorts.slice(6).length} more`}
						</small>
					</button>
					{showMore
						? sorts.slice(6).map((item) => <SortListItem key={item.id} sort={item} {...props} />)
						: null}
				</>
			) : null}
			<button className="button-badge-clear" onClick={onClear} type="button">
				<small>
					<i className="fa fa-fw fa-close" /> Clear all
				</small>
			</button>
		</div>
	);
};
