import type { IMaximumExcessRate } from '@crac/core/modules/pricing/maximumExcessRate/entities/MaximumExcessRate';
import type { IMaximumExcessRateDeactivateParams } from '@crac/core/modules/pricing/maximumExcessRate/services/MaximumExcessRateDeactivateService';
import { maximumExcessRateDeactivateService } from '@crac/core/modules/pricing/maximumExcessRate/services/MaximumExcessRateDeactivateService';
import type { IMaximumExcessRateInsertParams } from '@crac/core/modules/pricing/maximumExcessRate/services/MaximumExcessRateInsertService';
import { maximumExcessRateInsertService } from '@crac/core/modules/pricing/maximumExcessRate/services/MaximumExcessRateInsertService';
import type { IMaximumExcessRateSearchParams } from '@crac/core/modules/pricing/maximumExcessRate/services/MaximumExcessRateSearchService';
import { maximumExcessRateSearchService } from '@crac/core/modules/pricing/maximumExcessRate/services/MaximumExcessRateSearchService';
import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

/**
 * Searches for maximum excess rates based on specified parameters.
 *
 * @param {IMaximumExcessRate[]} payload - The maximum excess rates to be returned.
 * @param {IMaximumExcessRateSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of maximum excess rates.
 */
export const maximumExcessRateSearch = createAsyncAction<IMaximumExcessRate[], IMaximumExcessRateSearchParams>(
	'maximumExcessRate/search',
	maximumExcessRateSearchService,
);

/**
 * Inserts new maximum excess rates.
 *
 * @param {IMaximumExcessRate[]} payload - The maximum excess rates to be inserted.
 * @param {IMaximumExcessRateInsertParams} params - Parameters for the insertion.
 * @returns {Promise<void>} - A promise that resolves after the maximum excess rates are inserted.
 */
export const maximumExcessRateInsert = createAsyncAction<IMaximumExcessRate[], IMaximumExcessRateInsertParams>(
	'maximumExcessRate/insert',
	maximumExcessRateInsertService,
);

/**
 * Deactivates specific maximum excess rates.
 *
 * @param {IMaximumExcessRate[]} payload - The maximum excess rates to be deactivated.
 * @param {IMaximumExcessRateDeactivateParams} params - Parameters for the deactivation.
 * @returns {Promise<void>} - A promise that resolves after the maximum excess rates are deactivated.
 */
export const maximumExcessRateDeactivate = createAsyncAction<IMaximumExcessRate[], IMaximumExcessRateDeactivateParams>(
	'maximumExcessRate/deactivate',
	maximumExcessRateDeactivateService,
);

/**
 * Clears the maximum excess rate state.
 */
export const maximumExcessRateClear = createSyncAction('maximumExcessRate/clear');
