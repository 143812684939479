import type { IPackageConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/packageConfiguration/entities/PackageConfiguration';
import { PackageConfigurationEndpoints } from '@crac/core/modules/pricing/partnerConfiguration/packageConfiguration/services/PackageConfigurationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IPackageConfigurationInsertParams extends IServiceBaseParams {
	packagesConfigurations: IPackageConfiguration[];
}

/**
 * Insert package configuration
 * @param model IPackageConfigurationInsertParams
 * @returns IPackageConfiguration[]
 */
export const packageConfigurationInsertService = (
	model: IPackageConfigurationInsertParams,
): Promise<ServiceResponse<IPackageConfiguration[]>> => {
	return Api.post<IPackageConfiguration[], IPackageConfigurationInsertParams>(
		PackageConfigurationEndpoints.INSERT,
		model,
	);
};
