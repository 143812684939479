import type { IRentRateVariation } from '@crac/core/modules/pricing/rentRateVariation/entities/RentRateVariation';
import { RentRateVariationEndpoints } from '@crac/core/modules/pricing/rentRateVariation/services/RentRateVariationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IRentRateVariationDeactivateParams extends IServiceBaseParams {
	ids: number[];
}

export const rentRateVariationDeactivateService = (
	model: IRentRateVariationDeactivateParams,
): Promise<ServiceResponse<IRentRateVariation[]>> => {
	return Api.post<IRentRateVariation[], IRentRateVariationDeactivateParams>(
		RentRateVariationEndpoints.DEACTIVATE,
		model,
	);
};
