import type { IServiceRate, IServiceRateNew } from '@crac/core/modules/pricing/serviceRate/entities/ServiceRate';
import { ServiceRateEndpoints } from '@crac/core/modules/pricing/serviceRate/services/ServiceRateEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IServiceRatesInsertParams extends IServiceBaseParams {
	serviceRates: IServiceRateNew[];
}

export const serviceRatesInsertService = (
	model: IServiceRatesInsertParams,
): Promise<ServiceResponse<IServiceRate[]>> => {
	return Api.post<IServiceRate[], IServiceRatesInsertParams>(ServiceRateEndpoints.INSERT, model);
};
