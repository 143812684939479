import type { IDepositRate } from '@crac/core/modules/pricing/depositRate/entities/DepositRate';
import type { IDepositRateDeactivateParams } from '@crac/core/modules/pricing/depositRate/services/DepositRateDeactivateService';
import { depositRateDeactivateService } from '@crac/core/modules/pricing/depositRate/services/DepositRateDeactivateService';
import type { IDepositRateInsertParams } from '@crac/core/modules/pricing/depositRate/services/DepositRateInsertService';
import { depositRateInsertService } from '@crac/core/modules/pricing/depositRate/services/DepositRateInsertService';
import type { IDepositRateSearchParams } from '@crac/core/modules/pricing/depositRate/services/DepositRateSearchService';
import { depositRateSearchService } from '@crac/core/modules/pricing/depositRate/services/DepositRateSearchService';
import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

/**
 * Searches for deposit rates based on specified parameters.
 *
 * @param {IDepositRate[]} payload - The deposit rates to be returned.
 * @param {IDepositRateSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of deposit rates.
 */
export const depositRateSearch = createAsyncAction<IDepositRate[], IDepositRateSearchParams>(
	'depositRate/search',
	depositRateSearchService,
);

/**
 * Inserts new deposit rates into the system.
 *
 * @param {IDepositRate[]} payload - The deposit rates to be inserted.
 * @param {IDepositRateInsertParams} params - Parameters detailing the deposit rates to be inserted.
 * @returns {Promise<void>} - A promise that resolves after the deposit rates are successfully inserted.
 */
export const depositRateInsert = createAsyncAction<IDepositRate[], IDepositRateInsertParams>(
	'depositRate/insert',
	depositRateInsertService,
);

/**
 * Deactivates specified deposit rates.
 *
 * @param {IDepositRate[]} payload - The deposit rates to be deactivated.
 * @param {IDepositRateDeactivateParams} params - Parameters for deactivating the deposit rates.
 * @returns {Promise<void>} - A promise that resolves after the deposit rates are deactivated.
 */
export const depositRateDeactivate = createAsyncAction<IDepositRate[], IDepositRateDeactivateParams>(
	'depositRate/deactivate',
	depositRateDeactivateService,
);

/**
 * Clears the deposit rate state.
 */
export const depositRateClear = createSyncAction('depositRate/clear');
