import type { IDepositRate } from '@crac/core/modules/pricing/depositRate/entities/DepositRate';
import { DepositRateEndpoints } from '@crac/core/modules/pricing/depositRate/services/DepositRateEndpoints';
import type { PartnerType } from '@crac/core/modules/pricing/partnerConfiguration/types/PartnerType';
import type { FromTo } from '@crac/core/modules/pricing/types/FromToType';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IDepositRateSearchParams extends IServiceBaseParams {
	pickUp?: FromTo<Date | string>;
	quote?: FromTo<Date | string>;
	branches?: number[];
	vehicleGroups?: string[];
	contractTypes?: number[];
	partners?: Array<{
		code: string;
		type: PartnerType;
	}>;
	rentRateTypes?: string[];
	partnerCategories?: string[];
	partnerGroups?: string[];
	showActive?: number;
	unitTime?: number;
}

/**
 * Search deposit rates
 */
export const depositRateSearchService = (model: IDepositRateSearchParams): Promise<ServiceResponse<IDepositRate[]>> => {
	return Api.get<IDepositRate[], IDepositRateSearchParams>(DepositRateEndpoints.SEARCH, model);
};
