import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CustomerEndPoints } from './CustomerEndpoints';
import type { ICustomer } from '../entities/Customer';

export interface ICustomerCanMakeContractParams extends IServiceBaseParams {
	bookingNumber: string;
	customerCode: string;
}

/**
 * Customer can make contract
 *  {Number} customerCode Customer code
 *  {String} bookingNumber current booking number
 * @returns {Promise} `CustomerPropType` Update customer
 */
export const customerCanMakeContractService = (
	model: ICustomerCanMakeContractParams,
): Promise<ServiceResponse<ICustomer>> => {
	return Api.get<ICustomer, ICustomerCanMakeContractParams>(CustomerEndPoints.CAN_MAKE_CONTRACT, model);
};
