import type { FC, ReactNode } from 'react';

import { ToolBarButtons } from '@crac/components/intranet/shared/toolBar/ToolBarButtons';
import type { ISupplierLocationInsertParams } from '@crac/core/modules/supplier/location/services/SupplierLocationInsertService';
import { Form } from '@crac/form/form';
import { Button } from '@crac/ui/button';
import { Card, CardContent, CardFooter, CardHeader } from '@crac/ui/card';
import { Icon } from '@crac/ui/icons/Icon';

interface ILocationFormWrapperProps {
	isTab?: boolean;
	children: ReactNode;
	initialValues?: ISupplierLocationInsertParams;
	onSave: (params: ISupplierLocationInsertParams) => void;
	onCancel: () => void;
	onCopy?: () => void;
	title: string;
}

export const LocationFormWrapper: FC<ILocationFormWrapperProps> = ({
	children,
	isTab,
	initialValues,
	onCancel,
	onSave,
	onCopy,
	title,
}) => {
	if (isTab) {
		return (
			<Form aria-label="save-form" initialValues={initialValues} onSubmit={onSave}>
				{({ formState: { isValid, isDirty }, getValues }) => (
					<>
						<div className="tw-relative">
							<h4 className="tw-text-center pt-3">{title}</h4>
							{onCopy ? (
								<Button
									className="tw-absolute tw-top-0 tw-right-0 tw-m-2"
									color="secondary"
									onClick={onCopy}
									size="sm"
									type="button"
								>
									<Icon className="tw-mr-1" name="FaRegClone" /> Copy from supplier
								</Button>
							) : null}
						</div>
						<div className="tw-p-4">{children}</div>
						<ToolBarButtons className="tw-flex tw-justify-between">
							<Button color="link" onClick={onCancel} type="button">
								<Icon className="tw-mr-1" name="FaClose" /> Cancel
							</Button>
							<Button color="primary" isDisabled={!isValid || !isDirty} type="submit">
								<Icon className="tw-mr-1" name="FaSave" /> Save
							</Button>
						</ToolBarButtons>
					</>
				)}
			</Form>
		);
	}

	return (
		<Form aria-label="save-form" initialValues={initialValues} onSubmit={onSave}>
			{({ formState: { isValid, isDirty } }) => (
				<Card>
					<CardHeader className="tw-flex tw-justify-between">
						<h4 className="tw-flex tw-items-center tw-m-0">
							<Icon className="tw-mr-1" name="FaMapMarkerAlt" /> {title}
						</h4>
						{onCopy ? (
							<Button color="secondary" onClick={onCopy} type="button">
								<Icon className="tw-mr-1" name="FaRegClone" /> Copy from supplier
							</Button>
						) : null}
					</CardHeader>
					<CardContent>{children}</CardContent>
					<CardFooter className="tw-flex tw-justify-between">
						<Button color="link" onClick={onCancel} type="button">
							<Icon className="tw-mr-1" name="FaClose" /> Cancel
						</Button>
						<Button color="primary" isDisabled={!isValid || !isDirty} type="submit">
							<Icon className="tw-mr-1" name="FaSave" /> Save
						</Button>
					</CardFooter>
				</Card>
			)}
		</Form>
	);
};
