import type { IKmRate } from '@crac/core/modules/pricing/kmRate/entities/KmRate';
import { KmRateEndpoints } from '@crac/core/modules/pricing/kmRate/services/KmRateEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IKmRateInsertParams extends IServiceBaseParams {
	kmRates: IKmRate[];
}

/**
 * Insert km rates
 * @param model IKmRateInsertParams
 * @returns IKmRate[]
 */
export const kmRateInsertService = (model: IKmRateInsertParams): Promise<ServiceResponse<IKmRate[]>> => {
	return Api.post<IKmRate[], IKmRateInsertParams>(KmRateEndpoints.INSERT, model);
};
