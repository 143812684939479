import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { BookingEndPoints } from './BookingEndpoints';
import type { IBooking } from '../entities/Booking';

export interface IBookingGetByCustomerParams extends IServiceBaseParams {
	customerCode: string;
}

/**
 * Get bookings by customer
 *  {string} string customerCode
 * @returns {Array<BookingPropType>} `Array<BookingPropType>`
 */
export const bookingGetByCustomerService = (
	model: IBookingGetByCustomerParams,
): Promise<ServiceResponse<IBooking[]>> => {
	return Api.get<IBooking[], IBookingGetByCustomerParams>(BookingEndPoints.GET_BY_CUSTOMER, model);
};
