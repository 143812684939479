export enum BranchScheduleEndpoints {
	/**
	 * Search branch schedules
	 * @host `/branchSchedule/search`
	 */
	SEARCH = '/branchSchedule/search/',
	/**
	 * Insert branch schedules
	 * @host `/branchSchedule/insert`
	 */
	INSERT = '/branchSchedule/insert/',
	/**
	 * Modify branch schedules
	 * @host `/branchSchedule/modify`
	 */
	MODIFY = '/branchSchedule/modify/',
	/**
	 * Delete branch schedules
	 * @host `/branchSchedule/delete`
	 */
	DELETE = '/branchSchedule/delete/',
}
