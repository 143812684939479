import type { IRentRateValidation } from '@crac/core/modules/pricing/rentRateValidation/entities/RentRateValidation';
import { RentRateValidationEndpoints } from '@crac/core/modules/pricing/rentRateValidation/services/RentRateValidationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

export interface IRentRateValidationDeleteParams extends IServiceBaseParams {
	id: number;
}

/**
 * Delete rent rate validation
 * @param model IRentRateValidationDeleteParams
 * @returns IRentRateValidation
 */
export const rentRateValidationDeleteService = (model: IRentRateValidationDeleteParams) =>
	Api.post<IRentRateValidation, IRentRateValidationDeleteParams>(RentRateValidationEndpoints.DELETE, model);
