import type { FC } from 'react';
import React from 'react';

import classnames from 'classnames';

import { getStaticTexts } from '@crac/i18n';

import { BaseComponentValues } from '../../../types/IComponentBaseProps';

interface INoContentProps {
	message?: string;
	icon?: string;
	type?: 'danger' | 'normal';
	className?: string;
	locale?: string;
	children?: React.ReactNode;
}

export const NoContent: FC<INoContentProps> = ({
	locale = BaseComponentValues.locale,
	message,
	icon = 'info-circle',
	children,
	type = 'normal',
	className,
}) => {
	const texts = getStaticTexts(locale as string);
	return (
		<div
			className={classnames('no-content', className, {
				'text-danger': type === 'danger',
			})}
			data-testid="noContent"
		>
			<h4>
				<i className={`fa fa-fw fa-${icon}`} />
				{message || texts.noDataRecordered}
			</h4>
			{children}
		</div>
	);
};
