export enum BookingModifyEndpoints {
	/**
	 * Booking change customer
	 * @host `/booking/customerchange`
	 */
	CHANGE_CUSTOMER = '/booking/customerchange',

	/**
	 * Booking modify
	 * @host `/booking/modify/`
	 */
	MODIFY = '/booking/modify/',

	/**
	 * Cacel booking
	 * @host `/booking/cancel/`
	 */
	CANCEL = '/booking/cancel/',

	/**
	 * Change vehicle on contract
	 * @host `/booking/changevehicleoncontract/`
	 */
	CHANGE_VEHICLE_CONTRACT = '/booking/changevehicleoncontract/',

	/**
	 * Booking start change of vehicle
	 * @host `/booking/startchangeofvehicle/`
	 */
	START_CHANGE_OF_VEHICLE = '/booking/startchangeofvehicle/',

	/**
	 * Booking confirm change of vehicle
	 * @host `/booking/confirmchangeofvehicle/`
	 */
	CONFIRM_CHANGE_OF_VEHICLE = '/booking/confirmchangeofvehicle',

	/**
	 * Booking cancel change of vehicle
	 * @host `/booking/cancelchangeofvehicle/`
	 */
	CANCEL_CHANGE_OF_VEHICLE = '/booking/cancelchangeofvehicle/',

	/**
	 *
	 * Booking get extend availability
	 * @host `/booking/getextendavailability/`
	 */
	GET_EXTEND_AVAILABILITY = '/booking/getextendavailability',

	/**
	 * Booking extend
	 * @host `/booking/extend/`
	 */
	EXTEND = '/booking/extend',

	/**
	 * Booking extend
	 * @host `/booking/extendCallBack/`
	 */
	EXTEND_CALLBACK = '/booking/extendCallBack',

	/**
	 * Booking change discount
	 * @host `/booking/changediscount/`
	 */
	CHANGE_DISCOUNT = '/booking/changediscount',

	/**
	 * Booking payment
	 * @host '/booking/payment/'
	 */
	PAYMENT = '/booking/payment',

	/**
	 * Booking payment callBack
	 * @host '/booking/paymentCallBack'
	 */
	PAYMENT_CALLBACK = '/booking/paymentCallBack',

	/**
	 * Cancel booking
	 * @host `/booking/cancelSubscription/`
	 */
	CANCEL_SUBSCRIPTION = '/booking/cancelSubscription',
}
