import type { IOneWay } from '@crac/core/modules/pricing/oneWay/entities/OneWay';
import { OneWayEndpoints } from '@crac/core/modules/pricing/oneWay/services/OneWayEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IOneWayModifyParams extends IServiceBaseParams, IOneWay {}

/**
 * Modify one way
 * @param model IOneWayModifyParams
 * @returns IOneWay
 */
export const oneWayModifyService = (model: IOneWayModifyParams): Promise<ServiceResponse<IOneWay>> => {
	return Api.post<IOneWay, IOneWayModifyParams>(OneWayEndpoints.MODIFY, model);
};
