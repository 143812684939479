import type { FC } from 'react';

interface IMailToLink {
	email?: string;
}

export const MailToLink: FC<IMailToLink> = ({ email }) => {
	if (!email) {
		return null;
	}

	return (
		<a href={`mailto:${email}`} title={email}>
			{email}
		</a>
	);
};
