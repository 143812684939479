import type { IRentRateFormula } from '@crac/core/modules/pricing/rentRateFormula/entities/RentRateFormula';
import { RentRateFormulaEndpoints } from '@crac/core/modules/pricing/rentRateFormula/services/RentRateFormulaEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IRentRateFormulaDeleteParams extends IServiceBaseParams, Pick<IRentRateFormula, 'id'> {}

export const rentRateFormulaDeleteService = (
	model: IRentRateFormulaDeleteParams,
): Promise<ServiceResponse<IRentRateFormula>> => {
	return Api.post<IRentRateFormula, IRentRateFormulaDeleteParams>(RentRateFormulaEndpoints.DELETE, model);
};
