import type { IStopSale } from '@crac/core/modules/pricing/stopSale/entities/StopSale';
import { StopSaleEndpoints } from '@crac/core/modules/pricing/stopSale/services/StopSaleEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

export interface IStopSalesDeactivateParams extends IServiceBaseParams {
	ids: number[];
}

export const stopSaleServiceDeactivate = (model: IStopSalesDeactivateParams) => {
	return Api.post<IStopSale[], IStopSalesDeactivateParams>(StopSaleEndpoints.DEACTIVATE, model);
};
