import { type FC, useState } from 'react';

import type { ISupplier } from '@crac/core/modules/supplier/entities/Supplier';
import type { ISupplierLocation } from '@crac/core/modules/supplier/location/entities/SupplierLocation';
import type { ISupplierLocationInsertParams } from '@crac/core/modules/supplier/location/services/SupplierLocationInsertService';
import {
	supplierLocationDelete,
	supplierLocationInsert,
	supplierLocationModify,
} from '@crac/core/modules/supplier/location/state/actions/SupplierLocationActions';

import { useBindAndMemoizeActions } from '~/features/shared/hooks/useBindAndMemoizeActions';
import { LocationForm } from '~/features/workshop/location/components/form/LocationForm';
import { useLocationDelete } from '~/features/workshop/location/hooks/useLocationDelete';
import { useLocationSave } from '~/features/workshop/location/hooks/useLocationSave';
import { useLocationSelector } from '~/features/workshop/location/state/selector';

import { useSupplierLocationLoad } from './hooks/useSupplierLocationLoad';
import { SupplierLocationList } from './SupplierLocationList';

const actionsImported = {
	onInsert: supplierLocationInsert,
	onModify: supplierLocationModify,
	onDelete: supplierLocationDelete,
};

interface ILocationTabProps {
	supplier: ISupplier;
}

export const LocationTabContent: FC<ILocationTabProps> = ({ supplier }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [location, setLocation] = useState<ISupplierLocation | undefined>(undefined);
	const [doCopy, setDoCopy] = useState(false);
	const { onModify, onInsert, onDelete } = useBindAndMemoizeActions(actionsImported);
	const { supplierLocationInsertRequest, supplierLocationModifyRequest } = useLocationSelector();
	const { locations, isLoading } = useSupplierLocationLoad(supplier.code);
	const { handleOnDelete } = useLocationDelete(onDelete);

	const onSave = (values: ISupplierLocationInsertParams) => {
		if (location) {
			onModify({ ...values, id: location.id });
			return;
		}
		onInsert(values);
	};

	const toggleOpen = () => setIsOpen(!isOpen);

	const onCancel = () => {
		toggleOpen();
		setDoCopy(false);
		setLocation(undefined);
	};

	const { handleOnCancel, handleOnSave } = useLocationSave({
		onCancel,
		onSave,
		request: location ? supplierLocationModifyRequest : supplierLocationInsertRequest,
	});

	const onSelectLocation = (item: ISupplierLocation) => {
		setLocation(item);
		toggleOpen();
	};

	const initialSupplier: ISupplier = {
		...supplier,
		postCode: undefined,
		county: undefined,
		city: undefined,
		email: undefined,
		contact: undefined,
		phone: undefined,
		address: undefined,
	};

	return isOpen ? (
		<LocationForm
			isTab
			location={location}
			onCancel={handleOnCancel}
			onSave={handleOnSave}
			supplier={initialSupplier}
		/>
	) : (
		<SupplierLocationList
			isLoading={isLoading}
			locations={locations}
			onAdd={toggleOpen}
			onDelete={handleOnDelete}
			onModify={onSelectLocation}
		/>
	);
};
