import type { ICouponConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/couponConfiguration/entities/CouponConfiguration';
import { CouponConfigurationEndpoints } from '@crac/core/modules/pricing/partnerConfiguration/couponConfiguration/services/CouponConfigurationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

/**
 * To insert a service coupon configuration
 */
export interface ICouponConfigurationInsertParams extends IServiceBaseParams {
	couponsApplicationsConfigurations: ICouponConfiguration[];
}

/**
 * Insert coupon configuration
 * @param params `ICouponConfigurationInsertParams`
 * @returns `Promise<ServiceResponse<ICouponConfiguration[]>>`
 */
export const couponConfigurationInsertService = (params: ICouponConfigurationInsertParams) => {
	return Api.post<ICouponConfiguration[], ICouponConfigurationInsertParams>(
		CouponConfigurationEndpoints.INSERT,
		params,
	);
};
