import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CommentEndPoints } from './CommentsEndpoints';
import type { IComment } from '../entities/Comment';

export interface ICommentRemoveFromVehicleParams extends IServiceBaseParams {
	code: string;
	lineNumber: number;
	table?: number;
}

/**
 * Remove comment from vehicle
 *  {string} lineNumber Line number
 *  {string} code Plate number
 */
export const commentRemoveFromVehicleService = (
	model: ICommentRemoveFromVehicleParams,
): Promise<ServiceResponse<IComment>> => {
	return Api.post<IComment, ICommentRemoveFromVehicleParams>(CommentEndPoints.REMOVE_FROM_VEHICLE, model);
};
