import type { FC } from 'react';

import { Link } from 'react-router-dom';

import { RenderIcon } from './RenderIcon';
import type { IListDropdownItem } from '../IList';

interface IDropdownItemAction extends IListDropdownItem {
	item: any;
}

export const DropdownItemAction: FC<IDropdownItemAction> = ({ icon, id, path, text, item, showIf }) => {
	if (showIf && !showIf(item)) {
		return null;
	}

	let url = path as string;
	if (id.constructor === Object) {
		Object.keys(id).forEach((key) => {
			const encodedValue = encodeURIComponent(item[(id as Record<string, any>)[key]]);
			url = url.replace(new RegExp(`:${key}\\?`, 'u'), encodedValue);
			url = url.replace(new RegExp(`:${key}`, 'u'), encodedValue);
		});
	} else {
		url = url.replace(/:id/u, item[id as string]);
		url = url.replace(/:id/u, item[id as string]);
	}
	const [pathname, search] = url.split('?');
	const toUrl = { pathname, search };

	return (
		<Link className="dropdown-item" to={toUrl}>
			<RenderIcon icon={icon} style={{ marginRight: text ? '0.25rem' : '0' }} />
			{text ? <span>{text}</span> : null}
		</Link>
	);
};
