import { createSlice } from '@reduxjs/toolkit';

import {
	couponConfigurationClear,
	couponConfigurationDeactivate,
	couponConfigurationInsert,
	couponConfigurationModify,
	couponConfigurationRemoveDeactivated,
	couponConfigurationSearch,
} from '@crac/core/modules/pricing/partnerConfiguration/couponConfiguration/state/actions/CouponConfigurationActions';
import type { ICouponConfigurationReducerState } from '@crac/core/modules/pricing/partnerConfiguration/couponConfiguration/state/stateType/CouponConfigurationReducerState';

const initialState: ICouponConfigurationReducerState = {
	couponConfigurationDeactivateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	couponConfigurationInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	couponConfigurationModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	couponConfigurationSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	couponsConfigurations: [],
};

const couponConfigurationSlice = createSlice({
	name: 'CouponConfigurationSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// SEARCH
		builder
			.addCase(couponConfigurationSearch.pending, (state) => {
				return {
					...state,
					couponConfigurationSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(couponConfigurationSearch.fulfilled, (state, action) => {
				return {
					...state,
					couponConfigurationSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					couponsConfigurations: action.payload,
				};
			})
			.addCase(couponConfigurationSearch.rejected, (state, action) => {
				return {
					...state,
					couponConfigurationSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(couponConfigurationInsert.pending, (state) => {
				return {
					...state,
					couponConfigurationInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(couponConfigurationInsert.fulfilled, (state, action) => {
				return {
					...state,
					couponConfigurationInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					couponsConfigurations: action.payload,
				};
			})
			.addCase(couponConfigurationInsert.rejected, (state, action) => {
				return {
					...state,
					couponConfigurationInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(couponConfigurationModify.pending, (state) => {
				return {
					...state,
					couponConfigurationModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(couponConfigurationModify.fulfilled, (state, action) => {
				return {
					...state,
					couponConfigurationModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					couponsConfigurations: [
						...state.couponsConfigurations.filter((item) => item.id !== action.payload.id),
						action.payload,
					],
				};
			})
			.addCase(couponConfigurationModify.rejected, (state, action) => {
				return {
					...state,
					couponConfigurationModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// REMOVE DEACTIVATED
		builder.addCase(couponConfigurationRemoveDeactivated, (state, action) => {
			return {
				...state,
				couponsConfigurations: state.couponsConfigurations.filter(({ id }) => id !== action.payload),
			};
		});
		// DELETE
		builder
			.addCase(couponConfigurationDeactivate.pending, (state) => {
				return {
					...state,
					couponConfigurationDeactivateRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(couponConfigurationDeactivate.fulfilled, (state, action) => {
				return {
					...state,
					couponConfigurationDeactivateRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					couponsConfigurations: [
						...state.couponsConfigurations.filter(
							({ id }) => !action.payload.map((item) => item.id).includes(id),
						),
						...action.payload,
					],
				};
			})
			.addCase(couponConfigurationDeactivate.rejected, (state, action) => {
				return {
					...state,
					couponConfigurationDeactivateRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR
		builder.addCase(couponConfigurationClear, () => {
			return initialState;
		});
	},
});

export const couponConfigurationReducer = couponConfigurationSlice.reducer;
