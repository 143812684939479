import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { BookingEndPoints } from './BookingEndpoints';

export interface IBookingBillBookingParams extends IServiceBaseParams {
	bookingNumber: string;
}

/**
 * Bill booking
 *  {string} string bookingNumber
 * @returns {Array<BookingPropType>} `Array<BookingPropType>`
 */
export const bookingBillBookingService = (model: IBookingBillBookingParams): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IBookingBillBookingParams>(BookingEndPoints.BILL_BOOKING, model);
};
