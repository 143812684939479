import type { IPartnerCategory } from '@crac/core/modules/pricing/partnerConfiguration/partnerCategory/entities/PartnerCategory';
import { PartnerCategoryEndpoints } from '@crac/core/modules/pricing/partnerConfiguration/partnerCategory/services/PartnerCategoryEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IPartnerCategoryInsertParams extends IServiceBaseParams {
	partnerCategories: IPartnerCategory[];
}

export const partnerCategoryInsertService = (
	model: IPartnerCategoryInsertParams,
): Promise<ServiceResponse<IPartnerCategory[]>> => {
	return Api.post<IPartnerCategory[], IPartnerCategoryInsertParams>(PartnerCategoryEndpoints.INSERT, model);
};
