import type { IPreauthorizationRate } from '@crac/core/modules/pricing/preauthorizationRate/entities/PreauthorizationRate';
import { PreauthorizationRateEndpoints } from '@crac/core/modules/pricing/preauthorizationRate/services/PreauthorizationRateEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IPreauthorizationRateInsertParams extends IServiceBaseParams {
	preauthorizationRates: IPreauthorizationRate[];
}

/**
 * Insert preauthorization rates
 * @param model IPreauthorizationRateInsertParams
 * @returns IPreauthorizationRate[]
 */
export const preauthorizationRateInsertService = (
	model: IPreauthorizationRateInsertParams,
): Promise<ServiceResponse<IPreauthorizationRate[]>> => {
	return Api.post<IPreauthorizationRate[], IPreauthorizationRateInsertParams>(
		PreauthorizationRateEndpoints.INSERT,
		model,
	);
};
