import type { IPartnerGroup } from '@crac/core/modules/pricing/partnerConfiguration/partnerGroup/entities/PartnerGroup';
import { PartnerGroupEndpoints } from '@crac/core/modules/pricing/partnerConfiguration/partnerGroup/services/PartnerGroupEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export type IPartnerGroupGetAllParams = IServiceBaseParams;

export const partnerGroupGetAllService = (
	model: IPartnerGroupGetAllParams,
): Promise<ServiceResponse<IPartnerGroup[]>> => {
	return Api.get<IPartnerGroup[], IPartnerGroupGetAllParams>(PartnerGroupEndpoints.GET_ALL, model);
};
