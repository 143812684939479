import type { UnitTime } from '@crac/core/models/types/UnitTime';
import type { IMaximumBookingDuration } from '@crac/core/modules/pricing/maximumBookingDuration/entities/MaximumBookingDuration';
import { MaximumBookingDurationEndpoints } from '@crac/core/modules/pricing/maximumBookingDuration/services/MaximumBookingDurationEndpoints';
import type { PartnerType } from '@crac/core/modules/pricing/partnerConfiguration/types/PartnerType';
import type { FromTo } from '@crac/core/modules/pricing/types/FromToType';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IMaximumBookingDurationSearchParams extends IServiceBaseParams {
	vehicleGroups?: string[];
	branches?: number[];
	partners?: Array<{ code: string; type: PartnerType }>;
	pickUp?: FromTo<string | Date>;
	quote?: FromTo<string | Date>;
	contractTypes?: number[];
	showActive?: number;
	partnerCategories?: string[];
	partnerGroups?: string[];
	unitTime?: UnitTime;
}

/**
 * Search maximum booking durations
 */
export const maximumBookingDurationSearchService = (
	model: IMaximumBookingDurationSearchParams,
): Promise<ServiceResponse<IMaximumBookingDuration[]>> => {
	return Api.get<IMaximumBookingDuration[], IMaximumBookingDurationSearchParams>(
		MaximumBookingDurationEndpoints.SEARCH,
		model,
	);
};
