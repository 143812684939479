import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { BookingEndPoints } from './BookingEndpoints';

export interface IBookingRefundPaymentsParams extends IServiceBaseParams {
	bookingNumber: string;
}

/**
 * Refund payments
 *  {Object} BookingPropType booking
 * @returns {Array<BookingPropType>} `Array<BookingPropType>`
 */
export const bookingRefundPaymentsService = (
	model: IBookingRefundPaymentsParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IBookingRefundPaymentsParams>(BookingEndPoints.REFUND_PAYMENTS, model);
};
