import type { IOccupancy } from '@crac/core/modules/pricing/occupancy/entities/Occupancy';
import { OccupancyEndpoints } from '@crac/core/modules/pricing/occupancy/services/OccupancyEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

export interface IOccupancyInsertParams extends IServiceBaseParams {
	occupancyConfigurations: IOccupancy[];
}

/**
 * Insert occupancy
 */
export const occupancyInsertService = (model: IOccupancyInsertParams) => {
	return Api.post<IOccupancy[], IOccupancyInsertParams>(OccupancyEndpoints.INSERT, model);
};
