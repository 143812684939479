import type { INotAllowedMovement } from '@crac/core/modules/pricing/notAllowedMovement/entities/NotAllowedMovement';
import { NotAllowedMovementEndpoints } from '@crac/core/modules/pricing/notAllowedMovement/services/NotAllowedMovementEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface INotAllowedMovementDeleteParams extends IServiceBaseParams {
	zone: {
		from: string;
		to: string;
	};
	vehicleGroup: string;
}

export const notAllowedMovementDeleteService = (
	model: INotAllowedMovementDeleteParams,
): Promise<ServiceResponse<INotAllowedMovement>> => {
	return Api.post<INotAllowedMovement, INotAllowedMovementDeleteParams>(NotAllowedMovementEndpoints.DELETE, model);
};
