export enum KmRateEndpoints {
	/**
	 * Km rate search
	 * @host `/kmRate/search/`
	 */
	SEARCH = '/kmRate/search',

	/**
	 * Km rate insert
	 * @host `/kmRate/insert/`
	 */
	INSERT = '/kmRate/insert',

	/**
	 * Km rate deactivate
	 * @host `/kmRate/deactivate`
	 */
	DEACTIVATE = '/kmRate/deactivate',
}
