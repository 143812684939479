import type { IChoosableServiceConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/choosableServiceConfiguration/entities/ChoosableServiceConfiguration';
import { ChoosableServiceConfigurationEndPoints } from '@crac/core/modules/pricing/partnerConfiguration/choosableServiceConfiguration/services/ChoosableServiceConfigurationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

/**
 * Deactivate choosable service configuration params
 */
export interface IChoosableServiceConfigurationDeactivateParams extends IServiceBaseParams {
	ids: number[];
}

/**
 * Deactivate choosable service configuration
 * @param params `IChoosableServiceConfigurationDeactivateParams`
 * @returns `Promise<ServiceResponse<IChoosableServiceConfiguration>>`
 */
export const choosableServiceConfigurationDeactivateService = (
	params: IChoosableServiceConfigurationDeactivateParams,
) => {
	return Api.post<IChoosableServiceConfiguration[], IChoosableServiceConfigurationDeactivateParams>(
		ChoosableServiceConfigurationEndPoints.DEACTIVATE,
		params,
	);
};
