import type { IInvoiceableServiceConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/invoiceableServiceConfiguration/entities/InvoiceableServiceConfiguration';
import { InvoiceableServiceConfigurationEndpoints } from '@crac/core/modules/pricing/partnerConfiguration/invoiceableServiceConfiguration/services/InvoiceableServiceConfigurationEndpoints';
import type { IPartnerConfigurationSearch } from '@crac/core/modules/pricing/partnerConfiguration/types/PartnerConfigurationSearchType';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

/**
 * All parameters are optional, but at least one parameter must be sent in the search
 */
export interface IInvoiceableServiceConfigurationSearchParams extends IServiceBaseParams, IPartnerConfigurationSearch {
	services?: string[];
	quantity?: number;
	creationMethod?: number;
}

/**
 * Search invoiceable service configuration
 * @param params `IServiceInvoiceableConfigurationSearchParams`
 * @returns `Promise<ServiceResponse<IServiceInvoiceableConfiguration[]>>`
 */
export const invoiceableServiceConfigurationSearchService = (params: IInvoiceableServiceConfigurationSearchParams) => {
	return Api.get<IInvoiceableServiceConfiguration[], IInvoiceableServiceConfigurationSearchParams>(
		InvoiceableServiceConfigurationEndpoints.SEARCH,
		params,
	);
};
