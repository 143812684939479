import * as React from 'react';

import * as DialogPrimitive from '@radix-ui/react-dialog';
import { LuX } from 'react-icons/lu';

import { DialogOverlay } from './DialogOverlay';
import { cn } from '../../lib/utils/tailwindClass';
import { DialogPortal } from '../Dialog';

interface IDialogContentProps extends React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> {
	showDefaultClose?: boolean;
}

export const DialogContent = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Content>, IDialogContentProps>(
	({ className, children, showDefaultClose = true, ...props }, ref) => (
		<DialogPortal>
			<DialogOverlay />
			<DialogPrimitive.Content
				aria-describedby={undefined}
				className={cn('dialog-content', className)}
				ref={ref}
				{...props}
			>
				{children}
				{showDefaultClose === true && (
					<DialogPrimitive.Close className="dialog-content-close">
						<LuX className="tw-h-4 tw-w-4" />
						<span className="tw-sr-only">Close</span>
					</DialogPrimitive.Close>
				)}
			</DialogPrimitive.Content>
		</DialogPortal>
	),
);

DialogContent.displayName = DialogPrimitive.Content.displayName;
