import { rentRateMarginDeactivateService } from '@crac/core/modules/pricing/rentRateMargin/services/RentRateMarginDeactivateService';
import { rentRateMarginInsertService } from '@crac/core/modules/pricing/rentRateMargin/services/RentRateMarginInsertService';
import { rentRateMarginSearchService } from '@crac/core/modules/pricing/rentRateMargin/services/RentRateMarginSearchService';
import type { IRentRateVariation } from '@crac/core/modules/pricing/rentRateVariation/entities/RentRateVariation';
import type { IRentRateVariationDeactivateParams } from '@crac/core/modules/pricing/rentRateVariation/services/RentRateVariationDeactivateService';
import type { IRentRateVariationInsertParams } from '@crac/core/modules/pricing/rentRateVariation/services/RentRateVariationInsertService';
import type { IRentRateVariationsSearchParams } from '@crac/core/modules/pricing/rentRateVariation/services/RentRateVariationSearchService';
import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

/**
 * Searches for rent rate variations based on specified parameters.
 *
 * @param {IRentRateVariation[]} payload - The rent rate variations to be retrieved.
 * @param {IRentRateVariationsSearchParams} params - Parameters for the search of rent rate variations.
 * @returns {Promise<void>} - A promise that resolves to an array of rent rate variations.
 */
export const rentRateMarginSearch = createAsyncAction<IRentRateVariation[], IRentRateVariationsSearchParams>(
	'rentRateMargin/search',
	rentRateMarginSearchService,
);

/**
 * Inserts new rent rate variations.
 *
 * @param {IRentRateVariation[]} payload - The rent rate variations to be inserted.
 * @param {IRentRateVariationInsertParams} params - Parameters for the insertion of rent rate variations.
 * @returns {Promise<void>} - A promise that resolves after the rent rate variations are inserted.
 */
export const rentRateMarginInsert = createAsyncAction<IRentRateVariation[], IRentRateVariationInsertParams>(
	'rentRateMargin/insert',
	rentRateMarginInsertService,
);

/**
 * Deactivates specific rent rate variations.
 *
 * @param {IRentRateVariation[]} payload - The rent rate variations to be deactivated.
 * @param {IRentRateVariationDeactivateParams} params - Parameters for the deactivation of rent rate variations.
 * @returns {Promise<void>} - A promise that resolves after the rent rate variations are deactivated.
 */
export const rentRateMarginDeactivate = createAsyncAction<IRentRateVariation[], IRentRateVariationDeactivateParams>(
	'rentRateMargin/deactivate',
	rentRateMarginDeactivateService,
);

/**
 * Resets the rent rate margin state.
 */
export const rentRateMarginReset = createSyncAction('rentRateMargin/reset');
