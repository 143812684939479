import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CommentEndPoints } from './CommentsEndpoints';
import type { IComment } from '../entities/Comment';

export interface ICommentGetByCustomerParams extends IServiceBaseParams {
	customerCode: string;
}

export const commentGetByCustomerService = (
	model: ICommentGetByCustomerParams,
): Promise<ServiceResponse<IComment[]>> => {
	return Api.get<IComment[], ICommentGetByCustomerParams>(CommentEndPoints.GET_BY_CUSTOMER, model);
};
