import type { IPartnerConfigurationSearch } from '@crac/core/modules/pricing/partnerConfiguration/types/PartnerConfigurationSearchType';
import type { IVehicleGroupIncludedConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/vehicleGroupIncludedConfiguration/entities/VehicleGroupIncludedConfiguration';
import { VehicleGroupIncludedConfigurationEndpoints } from '@crac/core/modules/pricing/partnerConfiguration/vehicleGroupIncludedConfiguration/services/VehicleGroupIncludedConfigurationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IVehicleGroupIncludedConfigurationSearchParams
	extends IServiceBaseParams,
		IPartnerConfigurationSearch {
	vehicleGroups?: string[];
}

/**
 * Search vehicleGroupIncludedConfiguration
 */
export const vehicleGroupIncludedConfigurationSearchService = (
	model: IVehicleGroupIncludedConfigurationSearchParams,
): Promise<ServiceResponse<IVehicleGroupIncludedConfiguration[]>> => {
	return Api.get<IVehicleGroupIncludedConfiguration[], IVehicleGroupIncludedConfigurationSearchParams>(
		VehicleGroupIncludedConfigurationEndpoints.SEARCH,
		model,
	);
};
