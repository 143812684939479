import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CustomerEndPoints } from './CustomerEndpoints';
import type { ICustomerSignUpResponse } from '../../../models/serviceResponse/CustomerResponse';

export interface ICustomerSignUpParams extends IServiceBaseParams {
	/**
	 * Accept privacy policy (Required)
	 */
	acceptPrivacyPolicy: boolean;
	/**
	 * I agree to receive commercial communications (Optional)
	 */
	allowCommercialCommunications?: boolean;
	/**
	 * I agree to receive personalized offers and updates. (Optional)
	 */
	allowCommercialSegmentation?: boolean;
	/**
	 * Customer valid date  (Required)
	 */
	birthDate?: string;
	/**
	 * Valid customer email (Required)
	 */
	email: string;
	/**
	 * First surname (Required)
	 */
	firstSurname: string;
	/**
	 * Customer Name (Required)
	 */
	name: string;
	/**
	 * Valid password - (Required)
	 */
	password: string;
	/**
	 * Second surname (Optional)
	 */
	secondSurname?: string;
	/**
	 * Prefers language  (Optional)
	 */
	language?: string;
	allowPendingInRegisterContract?: boolean;
	birthCity?: string;
	birthCountry?: string;
	birthProvince?: string;
	block?: string;
	blocked?: boolean;
	bookingNumber?: string;
	city?: string;
	code?: string;
	country?: string;
	customerRentType?: number;
	digitizationRegistrationDate?: string | Date;
	door?: string;
	driverLicenseAuthority?: string;
	driverLicenseCountry?: string;
	driverLicenseExpeditionDate?: string | Date;
	driverLicenseExpirationDate?: string | Date;
	driverLicenseNumber?: string;
	floor?: string;
	gender: number;
	identificationDocumentExpirationDate?: string | Date;
	isValidDigitization?: boolean;
	letter?: string;
	moreThanOneBooking?: boolean;
	number?: string;
	pec?: string;
	phone?: string;
	phoneCountry?: string;
	points?: number;
	postalCode?: string;
	preferedLanguage?: string;
	profession?: string;
	province?: string;
	roadName?: string;
	roadType?: number;
	sdiCode?: string;
	staff?: boolean;
	stair?: string;
	taxIdentificationNumber?: string;
	taxOffice?: string;
	transfer?: boolean;
	validationType?: number;
}

/**
 * Register customer
 * @param model ICustomerSignUpParams
 * @returns Promise<ServiceResponse<boolean>>
 */
export const customerSignUpService = (
	model: ICustomerSignUpParams,
): Promise<ServiceResponse<ICustomerSignUpResponse>> => {
	return Api.post<ICustomerSignUpResponse, ICustomerSignUpParams>(CustomerEndPoints.SIGN_UP, model);
};
