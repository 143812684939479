import type { FC } from 'react';

import { Link } from 'react-router-dom';

import { RenderIcon } from './RenderIcon';
import { Button } from '../../../../external/reactstrap/button';
import { checkIsActionDisabled } from '../helpers';
import type { IActionProps } from '../IList';
import { TooltipWrapper } from '../TooltipWrapper';

export const UrlAction: FC<IActionProps> = ({ action, item }) => {
	const isDisabled = checkIsActionDisabled(action, item);
	const { path, id, href, backUrl } = action;

	if (href) {
		const handleHref = () => href(item);
		return (
			<TooltipWrapper action={action} item={item}>
				<a
					aria-label={action.ariaLabel}
					className={`btn btn-${action.color} btn-sm`}
					href={handleHref()}
					rel="noreferrer"
					target="_blank"
				>
					<RenderIcon
						ariaLabel="url-action-icon"
						icon={action?.icon}
						style={{ marginRight: action?.text ? '0.25rem' : '0' }}
					/>
					<span className="d-none d-sm-inline">{action.text}</span>
				</a>
			</TooltipWrapper>
		);
	}

	let url = path as string;
	if (id.constructor === Object) {
		Object.keys(id).forEach((key) => {
			const encodedValue = encodeURIComponent(item[(id as Record<string, any>)[key]]);
			url = url.replace(new RegExp(`:${key}\\?`, 'u'), encodedValue);
			url = url.replace(new RegExp(`:${key}`, 'u'), encodedValue);
		});
	} else {
		url = url.replace(/:id/u, item[id as string]);
		url = url.replace(/:id/u, item[id as string]);
	}
	const [pathname, search] = url.split('?');
	const toUrl = { pathname, search };

	return (
		<TooltipWrapper action={action} item={item}>
			{isDisabled ? (
				<Button
					aria-label={action.ariaLabel}
					color={action.color}
					isBlock={action.block}
					isDisabled
					key={action.key}
					size={action.size || 'sm'}
				>
					<RenderIcon icon={action?.icon} style={{ marginRight: action?.text ? '0.25rem' : '0' }} />
					<span className="d-none d-sm-inline-block">{action.text}</span>
				</Button>
			) : (
				<Link
					aria-label={action.ariaLabel}
					className={`btn btn-${action.color} btn-sm`}
					key={action.key}
					state={backUrl ? { backUrl } : undefined}
					to={toUrl}
				>
					{action.icon ? (
						<RenderIcon
							ariaLabel="url-action-icon"
							icon={action?.icon}
							style={{ marginRight: action?.text ? '0.25rem' : '0' }}
						/>
					) : null}
					<span className="d-none d-sm-inline">{action.text}</span>
				</Link>
			)}
		</TooltipWrapper>
	);
};
