import type { FC } from 'react';

interface IRenderIconProps {
	icon?: any;
	ariaLabel?: string;
	style?: React.CSSProperties;
}

export const RenderIcon: FC<IRenderIconProps> = ({ icon: Icon, ariaLabel, style }) => {
	if (Icon) {
		return typeof Icon === 'string' ? (
			<i aria-label={ariaLabel} className={`fa fa-fw fa-${Icon}`} />
		) : (
			<Icon aria-label={ariaLabel} style={style} />
		);
	}
	return null;
};
