/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { FC, HTMLProps } from 'react';
import React, { useEffect, useRef } from 'react';

import classNames from 'classnames';

type TanStackTableIndeterminateCheckboxProps = { indeterminate?: boolean } & HTMLProps<HTMLInputElement>;

export const TanStackTableIndeterminateCheckbox: FC<TanStackTableIndeterminateCheckboxProps> = ({
	indeterminate,
	className = '',
	...rest
}) => {
	const ref = useRef<HTMLInputElement>(null!);

	useEffect(() => {
		if (typeof indeterminate === 'boolean' && ref.current) {
			ref.current.indeterminate = !rest.checked && indeterminate;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref, indeterminate]);

	return (
		<input
			className={classNames('cursor-pointer form-check-input', className)}
			ref={ref}
			style={{ cursor: 'pointer' }}
			type="checkbox"
			{...rest}
		/>
	);
};
