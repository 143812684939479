import type { IOccupancy } from '@crac/core/modules/pricing/occupancy/entities/Occupancy';
import { OccupancyEndpoints } from '@crac/core/modules/pricing/occupancy/services/OccupancyEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

export interface IOccupancyDeactivateParams extends IServiceBaseParams {
	ids: number[];
}

/**
 * Deactivate occupancy
 */
export const occupancyDeactivateService = (model: IOccupancyDeactivateParams) => {
	return Api.post<IOccupancy[], IOccupancyDeactivateParams>(OccupancyEndpoints.DEACTIVATE, model);
};
