import type { MessageType } from '@crac/core/models/types/MessageType';
import type { IRentRate, IRentRateRelated } from '@crac/core/modules/pricing/rentRate/entities/RentRate';
import type { IRentRateSaveParams } from '@crac/core/modules/pricing/rentRate/services/RentRateSaveService';
import { rentRateSaveService } from '@crac/core/modules/pricing/rentRate/services/RentRateSaveService';
import type { IRentRatesSearchParams } from '@crac/core/modules/pricing/rentRate/services/RentRateSearchService';
import { rentRateSearchService } from '@crac/core/modules/pricing/rentRate/services/RentRateSearchService';
import type { FromTo } from '@crac/core/modules/pricing/types/FromToType';
import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

/**
 * Searches for rent rates based on specified parameters.
 *
 * @param {IRentRate[]} payload - The rent rates to be retrieved.
 * @param {IRentRatesSearchParams} params - Parameters for the search of rent rates, such as date range, location, and other criteria.
 * @returns {Promise<void>} - A promise that resolves to an array of rent rates.
 */
export const rentRatesSearch = createAsyncAction<IRentRate[], IRentRatesSearchParams>(
	'rentRate/search',
	rentRateSearchService,
);

/**
 * Resets the state of rent rates search.
 */
export const rentRatesReset = createSyncAction('rentRate/reset');

/**
 * Saves or updates rent rates.
 *
 * @param {IRentRate[]} payload - The rent rates to be saved or updated.
 * @param {IRentRateSaveParams} params - Parameters for saving or updating rent rates.
 * @returns {Promise<void>} - A promise that resolves after the rent rates are saved or updated.
 */
export const rentRateSave = createAsyncAction<IRentRate[], IRentRateSaveParams>('rentRate/save', rentRateSaveService);

interface IRentRateRelatedSearchParams extends IServiceBaseParams {
	branch?: number;
	vehicleGroup?: string;
	rentRateTypes?: string[];
	pickUp?: FromTo<Date | string>;
	quote?: FromTo<Date | string>;
}

/**
 * Searches for rent rates related to specific criteria.
 *
 * @param {IRentRateRelated | null} payload - The related rent rates to be retrieved.
 * @param {IRentRateRelatedSearchParams} params - Parameters for the search of related rent rates, such as specific associations or dependencies.
 * @returns {Promise<void>} - A promise that resolves to an array of related rent rates.
 */
export const rentRatesRelatedSearch = createAsyncAction<IRentRateRelated | null, IRentRateRelatedSearchParams>(
	'rentRate/ratesRelatedSearch',
	async (params: IRentRateRelatedSearchParams) => {
		if (!params?.rentRateTypes?.length) {
			return new ServiceResponse<IRentRateRelated>(true, null);
		}

		const tasks: Promise<ServiceResponse<IRentRate[]>>[] = [];

		params.rentRateTypes.forEach((rentRateType) => {
			tasks.push(
				rentRateSearchService({
					rentRateType,
					pickUp: params.pickUp,
					quote: params.quote,
					branch: params.branch,
				}),
			);
		});

		const requestResponses: ServiceResponse<IRentRate[]>[] = await Promise.all(tasks);
		let responseData: IRentRateRelated | null = {};
		const responseErrors: MessageType[] = [];

		const mapResponse = (rentRates: IRentRate[]) => {
			const [{ rentRateType }] = rentRates;
			responseData = {
				...responseData,
				[rentRateType]: rentRates,
			};
		};

		requestResponses.forEach((response) => {
			if (response.ok && response.data) {
				mapResponse(response.data);
			} else {
				responseErrors.push(...response.errors);
			}
		});

		if (responseErrors.length > 0) {
			return new ServiceResponse<IRentRateRelated>(false, null, responseErrors);
		}

		return new ServiceResponse<IRentRateRelated>(true, responseData);
	},
);

/**
 * Resets the state of related rent rates search.
 */
export const rentRatesRelatedReset = createSyncAction('rentRate/ratesRelatedReset');

export const retRateReset = createSyncAction('rentRate/reset');
