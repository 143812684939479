import { EmailEndpoints } from './EmailEndpoints';
import { Api } from '../../shared/api';
import type { IServiceBaseParams } from '../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../shared/types/ServiceResponse';

export interface IEmailSendFeedBackParams extends IServiceBaseParams {
	files: File[];
	json: string;
	mailTo?: string;
}

/**
 * Send email feedBack
 *  {*} feedback
 * @example feedback
 * {
 * "json": "string",
 * "files": [],
 * "mailTo": "emai1;email2"
 * }
 */
export const emailSendFeedBackService = (model: IEmailSendFeedBackParams): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IEmailSendFeedBackParams>(EmailEndpoints.SEND_FEEDBACK, model);
};
