'use client';

import * as TabsPrimitive from '@radix-ui/react-tabs';

import { TabsContent } from './components/TabsContent';
import { TabsList } from './components/TabsList';
import { TabsTrigger } from './components/TabsTrigger';

const Tabs = TabsPrimitive.Root;

export { Tabs, TabsList, TabsTrigger, TabsContent };
