import { createSlice } from '@reduxjs/toolkit';

import {
	invoiceableServiceConfigurationClear,
	invoiceableServiceConfigurationDeactivate,
	invoiceableServiceConfigurationInsert,
	invoiceableServiceConfigurationModify,
	invoiceableServiceConfigurationRemoveDeactivated,
	invoiceableServiceConfigurationSearch,
} from '@crac/core/modules/pricing/partnerConfiguration/invoiceableServiceConfiguration/state/actions/InvoiceableServiceConfigurationActions';
import type { InvoiceableServiceConfigurationReducerState } from '@crac/core/modules/pricing/partnerConfiguration/invoiceableServiceConfiguration/state/stateType/InvoiceableServiceConfigurationReducerState';
const initialState: InvoiceableServiceConfigurationReducerState = {
	invoiceableServiceConfigurationInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	invoiceableServiceConfigurationModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	invoiceableServiceConfigurationSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	invoiceableServiceConfigurations: [],
	invoiceableServiceConfigurationDeactivateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const invoiceableServiceConfigurationSlice = createSlice({
	name: 'InvoiceableServiceConfigurationSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// SEARCH
		builder
			.addCase(invoiceableServiceConfigurationSearch.pending, (state) => {
				return {
					...state,
					invoiceableServiceConfigurationSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(invoiceableServiceConfigurationSearch.fulfilled, (state, action) => {
				return {
					...state,
					invoiceableServiceConfigurationSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					invoiceableServiceConfigurations: action.payload,
				};
			})
			.addCase(invoiceableServiceConfigurationSearch.rejected, (state, action) => {
				return {
					...state,
					invoiceableServiceConfigurationSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(invoiceableServiceConfigurationInsert.pending, (state) => {
				return {
					...state,
					invoiceableServiceConfigurationInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(invoiceableServiceConfigurationInsert.fulfilled, (state, action) => {
				return {
					...state,
					invoiceableServiceConfigurationInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					invoiceableServiceConfigurations: action.payload,
				};
			})
			.addCase(invoiceableServiceConfigurationInsert.rejected, (state, action) => {
				return {
					...state,
					invoiceableServiceConfigurationInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(invoiceableServiceConfigurationModify.pending, (state) => {
				return {
					...state,
					invoiceableServiceConfigurationModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(invoiceableServiceConfigurationModify.fulfilled, (state, action) => {
				return {
					...state,
					invoiceableServiceConfigurationModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					invoiceableServiceConfigurations: [...state.invoiceableServiceConfigurations, action.payload],
				};
			})
			.addCase(invoiceableServiceConfigurationModify.rejected, (state, action) => {
				return {
					...state,
					invoiceableServiceConfigurationModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// REMOVE DEACTIVATED ITEM FROM MODIFY
		builder.addCase(invoiceableServiceConfigurationRemoveDeactivated, (state, action) => {
			return {
				...state,
				invoiceableServiceConfigurations: state.invoiceableServiceConfigurations.filter(
					({ id }) => id !== action.payload,
				),
			};
		});
		// DEACTIVATE
		builder
			.addCase(invoiceableServiceConfigurationDeactivate.pending, (state) => {
				return {
					...state,
					invoiceableServiceConfigurationDeactivateRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(invoiceableServiceConfigurationDeactivate.fulfilled, (state, action) => {
				return {
					...state,
					invoiceableServiceConfigurationDeactivateRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					invoiceableServiceConfigurations: [
						...state.invoiceableServiceConfigurations.filter(
							({ id }) => !action.payload.map((item) => item.id).includes(id),
						),
						...action.payload,
					],
				};
			})
			.addCase(invoiceableServiceConfigurationDeactivate.rejected, (state, action) => {
				return {
					...state,
					invoiceableServiceConfigurationDeactivateRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR
		builder.addCase(invoiceableServiceConfigurationClear, () => {
			return initialState;
		});
	},
});

export const invoiceableServiceConfigurationReducer = invoiceableServiceConfigurationSlice.reducer;
