import type { PartnerType } from '@crac/core/modules/pricing/partnerConfiguration/types/PartnerType';
import type { IPreauthorizationRate } from '@crac/core/modules/pricing/preauthorizationRate/entities/PreauthorizationRate';
import { PreauthorizationRateEndpoints } from '@crac/core/modules/pricing/preauthorizationRate/services/PreauthorizationRateEndpoints';
import type { FromTo } from '@crac/core/modules/pricing/types/FromToType';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IPreauthorizationRateSearchParams extends IServiceBaseParams {
	quote?: FromTo<string | Date>;
	pickUp?: FromTo<string | Date>;
	contractTypes?: number[];
	partners?: Array<{ code: string; type: PartnerType }>;
	branches?: number[];
	vehicleGroups?: string[];
	bookingDuration?: FromTo<number>;
	unitTime?: number;
	showActive?: number;
	rentRateTypes?: string[];
	partnerCategories?: string[];
	partnerGroups?: string[];
}

/**
 * Search preauthorization rates
 * @param model IPreauthorizationRateSearchParams
 * @returns IPreauthorizationRate[]
 */
export const preauthorizationRateSearchService = (
	model: IPreauthorizationRateSearchParams,
): Promise<ServiceResponse<IPreauthorizationRate[]>> => {
	return Api.get<IPreauthorizationRate[], IPreauthorizationRateSearchParams>(
		PreauthorizationRateEndpoints.SEARCH,
		model,
	);
};
