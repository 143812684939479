import type { IMaximumBookingDuration } from '@crac/core/modules/pricing/maximumBookingDuration/entities/MaximumBookingDuration';
import { MaximumBookingDurationEndpoints } from '@crac/core/modules/pricing/maximumBookingDuration/services/MaximumBookingDurationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IMaximumBookingDurationInsertParams extends IServiceBaseParams {
	maximumBookingDurations: IMaximumBookingDuration[];
}

/**
 * Insert maximum booking durations
 */
export const maximumBookingDurationInsertService = (
	model: IMaximumBookingDurationInsertParams,
): Promise<ServiceResponse<IMaximumBookingDuration[]>> => {
	return Api.post<IMaximumBookingDuration[], IMaximumBookingDurationInsertParams>(
		MaximumBookingDurationEndpoints.INSERT,
		model,
	);
};
