export enum PackageConfigurationEndpoints {
	/**
	 * Package configuration search
	 * @host `/packageConfiguration/search`
	 */
	SEARCH = '/packageConfiguration/search',
	/**
	 * Package configuration insert
	 * @host `/packageConfiguration/insert`
	 */
	INSERT = '/packageConfiguration/insert',
	/**
	 * Package configuration modify
	 * @host `/packageConfiguration/modify`
	 */
	MODIFY = '/packageConfiguration/modify',
	/**
	 * Package configuration deactivate
	 * @host `/packageConfiguration/deactivate`
	 */
	DEACTIVATE = '/packageConfiguration/deactivate',
}
