import type { IOneWay } from '@crac/core/modules/pricing/oneWay/entities/OneWay';
import { OneWayEndpoints } from '@crac/core/modules/pricing/oneWay/services/OneWayEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IOneWayInsertParams extends IServiceBaseParams {
	oneWays: IOneWay[];
}

/**
 * Insert one ways
 * @param model IOneWayInsertParams
 * @returns IOneWay[]
 */
export const oneWayInsertService = (model: IOneWayInsertParams): Promise<ServiceResponse<IOneWay[]>> => {
	return Api.post<IOneWay[], IOneWayInsertParams>(OneWayEndpoints.INSERT, model);
};
