import type { IPartnerCategory } from '@crac/core/modules/pricing/partnerConfiguration/partnerCategory/entities/PartnerCategory';
import type { IPartnerCategoryGetAllParams } from '@crac/core/modules/pricing/partnerConfiguration/partnerCategory/services/PartnerCategoryGetAllService';
import { partnerCategoryGetAllService } from '@crac/core/modules/pricing/partnerConfiguration/partnerCategory/services/PartnerCategoryGetAllService';
import type { IPartnerCategoryGetByCodeParams } from '@crac/core/modules/pricing/partnerConfiguration/partnerCategory/services/PartnerCategoryGetByCodeService';
import { partnerCategoryGetByCodeService } from '@crac/core/modules/pricing/partnerConfiguration/partnerCategory/services/PartnerCategoryGetByCodeService';
import type { IPartnerCategoryInsertParams } from '@crac/core/modules/pricing/partnerConfiguration/partnerCategory/services/PartnerCategoryInsertService';
import { partnerCategoryInsertService } from '@crac/core/modules/pricing/partnerConfiguration/partnerCategory/services/PartnerCategoryInsertService';
import type { IPartnerCategoryModifyParams } from '@crac/core/modules/pricing/partnerConfiguration/partnerCategory/services/PartnerCategoryModifyService';
import { partnerCategoryModifyService } from '@crac/core/modules/pricing/partnerConfiguration/partnerCategory/services/PartnerCategoryModifyService';
import { partnerCategorySearchService } from '@crac/core/modules/pricing/partnerConfiguration/partnerCategory/services/PartnerCategorySearchService';
import type { IPartnerCategorySearchParams } from '@crac/core/modules/pricing/partnerConfiguration/partnerCategory/services/PartnerCategorySearchService';
import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

/**
 * Retrieves all partner categories.
 *
 * @param {IPartnerCategory[]} payload - The partner categories to be retrieved.
 * @param {IPartnerCategoryGetAllParams} params - Parameters for retrieving all partner categories.
 * @returns {Promise<void>} - A promise that resolves to an array of partner categories.
 */
export const partnerCategoryGetAll = createAsyncAction<IPartnerCategory[], IPartnerCategoryGetAllParams>(
	'partnerCategory/getAll',
	partnerCategoryGetAllService,
);

/**
 * Retrieves partner categories by their specific code.
 *
 * @param {IPartnerCategory} payload - The partner categories to be retrieved.
 * @param {IPartnerCategoryGetByCodeParams} params - Parameters to identify the partner categories by code.
 * @returns {Promise<void>} - A promise that resolves to an array of partner categories.
 */
export const partnerCategoryGetByCode = createAsyncAction<IPartnerCategory, IPartnerCategoryGetByCodeParams>(
	'partnerCategory/getByCode',
	partnerCategoryGetByCodeService,
);

/**
 * Searches for partner categories based on specified parameters.
 *
 * @param {IPartnerCategory[]} payload - The partner categories to be returned based on the search criteria.
 * @param {IPartnerCategorySearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of partner categories.
 */
export const partnerCategorySearch = createAsyncAction<IPartnerCategory[], IPartnerCategorySearchParams>(
	'partnerCategory/search',
	partnerCategorySearchService,
);

/**
 * Inserts a new partner category.
 *
 * @param {IPartnerCategory} payload - The partner category to be inserted.
 * @param {IPartnerCategoryInsertParams} params - Parameters for the insertion of the partner category.
 * @returns {Promise<void>} - A promise that resolves after the partner category is inserted.
 */
export const partnerCategoryInsert = createAsyncAction<IPartnerCategory[], IPartnerCategoryInsertParams>(
	'partnerCategory/insert',
	partnerCategoryInsertService,
);

/**
 * Modifies an existing partner category.
 *
 * @param {IPartnerCategory} payload - The partner category to be modified.
 * @param {IPartnerCategoryModifyParams} params - Parameters for the modification of the partner category.
 * @returns {Promise<void>} - A promise that resolves after the partner category is modified.
 */
export const partnerCategoryModify = createAsyncAction<IPartnerCategory, IPartnerCategoryModifyParams>(
	'partnerCategory/modify',
	partnerCategoryModifyService,
);

/**
 * Clears the partner category state.
 */
export const partnerCategoryClear = createSyncAction('partnerCategory/clear');
