import type { IRentRateFormula } from '@crac/core/modules/pricing/rentRateFormula/entities/RentRateFormula';
import { RentRateFormulaEndpoints } from '@crac/core/modules/pricing/rentRateFormula/services/RentRateFormulaEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IRentRateFormulaInsertParams extends IServiceBaseParams {
	formulas: IRentRateFormula[];
}

export const rentRateFormulaInsertService = (
	model: IRentRateFormulaInsertParams,
): Promise<ServiceResponse<IRentRateFormula[]>> => {
	return Api.post<IRentRateFormula[], IRentRateFormulaInsertParams>(RentRateFormulaEndpoints.INSERT, model);
};
