import { Api } from '@crac/core/modules/shared/api';
import type { CreationMethod } from '@crac/core/modules/shared/types/CreationMethod';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { BookingEndPoints } from './BookingEndpoints';
import type { IBooking } from '../entities/Booking';

export interface IBookingSearchParams extends IServiceBaseParams {
	agencyCode?: string;
	bookingCreationDateFrom?: string | Date;
	bookingCreationDateUntil?: string | Date;
	bookingNumber?: string;
	branch?: string;
	contractNumber?: string;
	creationMethod?: CreationMethod;
	pickUpDateFrom?: string | Date;
	pickUpDateUntil?: string | Date;
	plateNumber?: string;
	state?: number;
	vendorCode?: string;
	voucherNumber?: string;
}

/**
 * Search booking
 *  {string} string voucherNumber
 *  {string} string bookingNumber
 *  {string} string contractNumber
 *  {string} string plateNumber
 * @returns {Array<BookingPropType>} BookingPropType
 */
export const bookingSearchService = (model: IBookingSearchParams): Promise<ServiceResponse<IBooking[]>> => {
	return Api.get<IBooking[], IBookingSearchParams>(BookingEndPoints.SEARCH, model);
};
