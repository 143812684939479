import { type RefObject, useState } from 'react';

import type { SortingState, Table } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useVirtual } from 'react-virtual';

interface IUseTableVirtualizerProps {
	table: Table<any>;
	tableContainerRef: RefObject<HTMLDivElement>;
}

const DEFAULT_HEIGHT = 33;

export const useTanStackVirtualizer = ({ table, tableContainerRef }: IUseTableVirtualizerProps) => {
	const { rows } = table.getRowModel();

	const { getVirtualItems, getTotalSize } = useVirtualizer({
		count: rows.length,
		estimateSize: () => DEFAULT_HEIGHT,
		getScrollElement: () => tableContainerRef.current,
		overscan: 20,
	});

	let virtualRows = getVirtualItems();
	let totalSize = getTotalSize();

	if (!tableContainerRef.current || virtualRows.length === 0) {
		virtualRows = rows.map((_row, index) => ({
			start: index * DEFAULT_HEIGHT,
			end: (index + 1) * DEFAULT_HEIGHT,
			index,
			key: index,
			size: DEFAULT_HEIGHT,
			lane: 0,
		}));
		totalSize = rows.length * DEFAULT_HEIGHT;
	}

	const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;
	const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;

	return {
		virtualRows,
		totalSize,
		paddingBottom,
		paddingTop,
	};
};

export const useReactVirtual = ({ table, tableContainerRef }: IUseTableVirtualizerProps) => {
	const { rows } = table.getRowModel();
	const { virtualItems: virtualRows, totalSize } = useVirtual({
		parentRef: tableContainerRef,
		size: rows.length,
		overscan: 20,
	});

	const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;
	const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;

	return {
		virtualRows,
		totalSize,
		paddingBottom,
		paddingTop,
	};
};

export const useTanStackSorting = () => {
	const [sorting, setSorting] = useState<SortingState>([]);

	const onClearAll = () => setSorting([]);

	const onClearBy = (value: string) => setSorting((state) => state.filter(({ id }) => id !== value));

	const onToggleSort = (value: string) =>
		setSorting((state) => {
			const selectedSort = state.find(({ id }) => id === value);
			return selectedSort
				? state.map((sort) => (sort.id === value ? { ...selectedSort, desc: !selectedSort.desc } : sort))
				: state;
		});

	const onAddSort = (value: string) => setSorting((state) => [...state, { desc: true, id: value }]);

	return {
		sorting,
		setSorting,
		onClearAll,
		onClearBy,
		onToggleSort,
		onAddSort,
	};
};
