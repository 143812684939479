import type { IDiscountCoupon } from '@crac/core/modules/pricing/discountCoupon/entities/DiscountCoupon';
import type { IDiscountCouponDeactivateParams } from '@crac/core/modules/pricing/discountCoupon/services/DiscountCouponDeactivateService';
import { discountCouponDeactivateService } from '@crac/core/modules/pricing/discountCoupon/services/DiscountCouponDeactivateService';
import type { IDiscountCouponInsertParams } from '@crac/core/modules/pricing/discountCoupon/services/DiscountCouponInsertService';
import { discountCouponInsertService } from '@crac/core/modules/pricing/discountCoupon/services/DiscountCouponInsertService';
import type { IDiscountCouponSearchParams } from '@crac/core/modules/pricing/discountCoupon/services/DiscountCouponSearchService';
import { discountCouponSearchService } from '@crac/core/modules/pricing/discountCoupon/services/DiscountCouponSearchService';
import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

/**
 * Searches for discount coupons based on specified parameters.
 *
 * @param {IDiscountCoupon[]} payload - The discount coupons to be returned.
 * @param {IDiscountCouponSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of discount coupons.
 */
export const discountCouponSearch = createAsyncAction<IDiscountCoupon[], IDiscountCouponSearchParams>(
	'discountCoupon/search',
	discountCouponSearchService,
);

/**
 * Inserts new discount coupons into the system.
 *
 * @param {IDiscountCoupon[]} payload - The discount coupons to be inserted.
 * @param {IDiscountCouponInsertParams} params - Parameters detailing the discount coupons to be inserted.
 * @returns {Promise<void>} - A promise that resolves after the discount coupons are successfully inserted.
 */
export const discountCouponInsert = createAsyncAction<IDiscountCoupon[], IDiscountCouponInsertParams>(
	'discountCoupon/insert',
	discountCouponInsertService,
);

/**
 * Clears the discount coupon state.
 */
export const discountCouponClear = createSyncAction('discountCoupon/clear');

/**
 * Deactivates specified discount coupons.
 *
 * @param {IDiscountCoupon[]} payload - The discount coupons to be deactivated.
 * @param {IDiscountCouponDeactivateParams} params - Parameters for deactivating the discount coupons.
 * @returns {Promise<void>} - A promise that resolves after the discount coupons are deactivated.
 */
export const discountCouponDeactivate = createAsyncAction<IDiscountCoupon[], IDiscountCouponDeactivateParams>(
	'discountCoupon/deactivate',
	discountCouponDeactivateService,
);
