import type { IRentRateTypeRelation } from '@crac/core/modules/pricing/rentRateTypeRelation/entities/RentRateTypeRelation';
import { RentRateTypeRelationEndpoints } from '@crac/core/modules/pricing/rentRateTypeRelation/services/RentRateTypeRelationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

export interface IRentRateTypeRelationSearchParams extends IServiceBaseParams {
	branch?: number;
	currentRentRateType?: string;
	relatedRentRateType?: string;
}

/**
 * Search rent rate relation
 * @param model IIRentRateTypeRelationSearchParams
 * @returns IRentRateTypeRelation[]
 */
export const rentRateTypeRelationSearchService = (model: IRentRateTypeRelationSearchParams) =>
	Api.get<IRentRateTypeRelation[], IRentRateTypeRelationSearchParams>(RentRateTypeRelationEndpoints.SEARCH, model);
