import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { PaymentEndPoints } from './PaymentEntpoints';
import type { IPaymentLine } from '../entities/PaymentLine';

export interface IPaymentLineSearchParams extends IServiceBaseParams {
	creditCardNumber: string;
	authCode?: string;
}

/**
 * Search payment lines
 *  {string} creaditCardNumber
 * @returns {Promise<Array>} `Array<PaymentLine>` Payment line list
 */
export const paymentLineSearchService = (model: IPaymentLineSearchParams): Promise<ServiceResponse<IPaymentLine[]>> => {
	return Api.get<IPaymentLine[], IPaymentLineSearchParams>(PaymentEndPoints.PAYMENT_LINE_SEARCH, model);
};
