import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CouponEndPoint } from './CouponEndpoints';
import type { ICouponInsertResponse } from '../entitites/CouponInsertReponse';

export interface ICouponInsertCallBackParams extends IServiceBaseParams {
	transactionNumber: string;
	transactionId: number;
	currencyCode: string;
}

/**
 * Insert callback customer coupon
 * @param params ICouponInsertCallBackParams
 * @returns
 */
export const couponInsertCallBackService = (
	params: ICouponInsertCallBackParams,
): Promise<ServiceResponse<ICouponInsertResponse>> =>
	Api.post<ICouponInsertResponse, ICouponInsertCallBackParams>(CouponEndPoint.COUPON_INSERT_CALLBACK, params);
