import { createSlice } from '@reduxjs/toolkit';

import {
	rentRateValidationDelete,
	rentRateValidationInsert,
	rentRateValidationModify,
	rentRateValidationReset,
	rentRateValidationSearch,
} from '@crac/core/modules/pricing/rentRateValidation/state/actions/RentRateValidationActions';
import type { IRentRateValidationReducerState } from '@crac/core/modules/pricing/rentRateValidation/state/stateType/RentRateValidationReducerState';

const initialState: IRentRateValidationReducerState = {
	rentRateValidation: null,
	rentRateValidationInsertRequest: { inProgress: false, messages: [], ok: true },
	rentRateValidationModifyRequest: { inProgress: false, messages: [], ok: true },
	rentRateValidations: [],
	rentRateValidationSearchRequest: { inProgress: false, messages: [], ok: true },
	rentRateValidationDeleteRequest: { inProgress: false, messages: [], ok: true },
};

const rentRateValidationSlice = createSlice({
	name: 'rentRateValidation',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(rentRateValidationReset, () => {
			return initialState;
		});
		builder.addCase(rentRateValidationSearch.pending, (state) => {
			return {
				...state,
				rentRateValidationSearchRequest: { inProgress: true, ok: true, messages: [] },
			};
		});
		builder.addCase(rentRateValidationSearch.rejected, (state, action) => {
			return {
				...state,
				rentRateValidationSearchRequest: { inProgress: false, ok: false, messages: action.payload || [] },
			};
		});
		builder.addCase(rentRateValidationSearch.fulfilled, (state, action) => {
			return {
				...state,
				rentRateValidationSearchRequest: { inProgress: false, ok: true, messages: [] },
				rentRateValidations: action.payload,
			};
		});
		builder.addCase(rentRateValidationInsert.pending, (state) => {
			return {
				...state,
				rentRateValidationInsertRequest: { inProgress: true, ok: true, messages: [] },
			};
		});
		builder.addCase(rentRateValidationInsert.rejected, (state, action) => {
			return {
				...state,
				rentRateValidationInsertRequest: { inProgress: false, ok: false, messages: action.payload || [] },
			};
		});
		builder.addCase(rentRateValidationInsert.fulfilled, (state, action) => {
			return {
				...state,
				rentRateValidationInsertRequest: { inProgress: false, ok: true, messages: [] },
				rentRateValidations: [...state.rentRateValidations, ...action.payload],
			};
		});
		builder.addCase(rentRateValidationModify.pending, (state) => {
			return {
				...state,
				rentRateValidationModifyRequest: { inProgress: true, ok: true, messages: [] },
			};
		});
		builder.addCase(rentRateValidationModify.rejected, (state, action) => {
			return {
				...state,
				rentRateValidationModifyRequest: { inProgress: false, ok: false, messages: action.payload || [] },
			};
		});
		builder.addCase(rentRateValidationModify.fulfilled, (state, action) => {
			return {
				...state,
				rentRateValidationModifyRequest: { inProgress: false, ok: true, messages: [] },
				rentRateValidations: state.rentRateValidations.map((item) =>
					item.id === action.payload[0].id ? action.payload[0] : item,
				),
			};
		});
		builder.addCase(rentRateValidationDelete.pending, (state) => {
			return {
				...state,
				rentRateValidationDeleteRequest: { inProgress: true, ok: true, messages: [] },
			};
		});
		builder.addCase(rentRateValidationDelete.rejected, (state, action) => {
			return {
				...state,
				rentRateValidationDeleteRequest: { inProgress: false, ok: false, messages: action.payload || [] },
			};
		});
		builder.addCase(rentRateValidationDelete.fulfilled, (state, action) => {
			return {
				...state,
				rentRateValidationDeleteRequest: { inProgress: false, ok: true, messages: [] },
				rentRateValidations: state.rentRateValidations.filter(
					(validation) => validation.id !== action.payload.id,
				),
			};
		});
	},
});

export const rentRateValidationReducer = rentRateValidationSlice.reducer;
