import type { IKmRate } from '@crac/core/modules/pricing/kmRate/entities/KmRate';
import type { IKmRateDeactivateParams } from '@crac/core/modules/pricing/kmRate/services/KmRateDeactivateService';
import { kmRateDeactivateService } from '@crac/core/modules/pricing/kmRate/services/KmRateDeactivateService';
import type { IKmRateInsertParams } from '@crac/core/modules/pricing/kmRate/services/KmRateInsertService';
import { kmRateInsertService } from '@crac/core/modules/pricing/kmRate/services/KmRateInsertService';
import type { IKmRateSearchParams } from '@crac/core/modules/pricing/kmRate/services/KmRateSearchService';
import { kmRateSearchService } from '@crac/core/modules/pricing/kmRate/services/KmRateSearchService';
import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

/**
 * Searches for kilometer rates based on specified parameters.
 *
 * @param {IKmRate[]} payload - The kilometer rates to be returned.
 * @param {IKmRateSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of kilometer rates.
 */
export const kmRateSearch = createAsyncAction<IKmRate[], IKmRateSearchParams>('kmRate/search', kmRateSearchService);

/**
 * Inserts new kilometer rates into the system.
 *
 * @param {IKmRate[]} payload - The kilometer rates to be inserted.
 * @param {IKmRateInsertParams} params - Parameters detailing the kilometer rates to be inserted.
 * @returns {Promise<void>} - A promise that resolves after the kilometer rates are successfully inserted.
 */
export const kmRateInsert = createAsyncAction<IKmRate[], IKmRateInsertParams>('kmRate/insert', kmRateInsertService);

/**
 * Clears the kilometer rate state.
 */
export const kmRateClear = createSyncAction('kmRate/clear');

/**
 * Deactivates specified kilometer rates.
 *
 * @param {IKmRate[]} payload - The kilometer rates to be deactivated.
 * @param {IKmRateDeactivateParams} params - Parameters for deactivating the kilometer rates.
 * @returns {Promise<void>} - A promise that resolves after the kilometer rates are deactivated.
 */
export const kmRateDeactivate = createAsyncAction<IKmRate[], IKmRateDeactivateParams>(
	'kmRate/deactivate',
	kmRateDeactivateService,
);
