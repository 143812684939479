import type { IGroupOrderAndUpgrade } from '@crac/core/modules/pricing/vehicleGroupOrderAndUpgrade/entities/VehicleGroupOrderAndUpgrade';
import type { IVehicleGroupOrderAndUpgradeInsertParams } from '@crac/core/modules/pricing/vehicleGroupOrderAndUpgrade/services/VehicleGroupOrderAndUpgradeInsertService';
import { VehicleGroupOrderAndUpgradeEndpoints } from '@crac/core/modules/pricing/vehicleGroupOrderAndUpgrade/services/VehicleGroupOrderAndUpgradeEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

/**
 * Modify Group upgrade
 */
export const vehicleGroupOrderAndUpgradeModifyService = (
	model: IVehicleGroupOrderAndUpgradeInsertParams,
): Promise<ServiceResponse<IGroupOrderAndUpgrade[]>> => {
	return Api.post<IGroupOrderAndUpgrade[], IVehicleGroupOrderAndUpgradeInsertParams>(
		VehicleGroupOrderAndUpgradeEndpoints.MODIFY,
		model,
	);
};
