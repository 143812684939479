import type { CSSProperties, FC } from 'react';

import { SortableContext, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { type Cell, type Row, flexRender } from '@tanstack/react-table';

import { DragAlongCell } from './dragAndDrop/DragAlongCell';
import { isPinnedId } from './utils';

interface ITableBodyCell {
	row: Row<any>;
	cell: Cell<any, unknown>;
	itemId: string;
	isDnD?: boolean;
	hasSubComponent?: boolean;
	items?: string[];
}

interface CellProps {
	setNodeRef?: (node: HTMLElement | null) => void;
	style?: CSSProperties;
}

export const TableBodyCell: FC<ITableBodyCell> = ({ cell, row, itemId, isDnD, hasSubComponent, items }) => {
	const tableCell = ({ setNodeRef, style }: CellProps) => (
		<td
			className={(cell.column.columnDef.meta as any)?.className}
			key={`${itemId ?? 'cell'}_${cell.id}`}
			onClick={hasSubComponent ? row.getToggleExpandedHandler() : undefined}
			ref={setNodeRef}
			style={{
				...style,
				width: cell.column.getSize(),
				verticalAlign: 'middle',
			}}
		>
			{flexRender(cell.column.columnDef.cell, cell.getContext())}
		</td>
	);

	if (isDnD && items && !isPinnedId(cell.id)) {
		return (
			<SortableContext items={items} key={cell.id} strategy={horizontalListSortingStrategy}>
				<DragAlongCell cell={cell}>{(props) => tableCell(props)}</DragAlongCell>
			</SortableContext>
		);
	}

	return tableCell({});
};
