import type { IVehicleGroupIncludedConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/vehicleGroupIncludedConfiguration/entities/VehicleGroupIncludedConfiguration';
import { VehicleGroupIncludedConfigurationEndpoints } from '@crac/core/modules/pricing/partnerConfiguration/vehicleGroupIncludedConfiguration/services/VehicleGroupIncludedConfigurationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IVehicleGroupIncludedConfigurationInsertParams extends IServiceBaseParams {
	choosablesVehiclesGroupsConfigurations: IVehicleGroupIncludedConfiguration[];
}

/**
 * New vehicleGroupIncludedConfiguration
 */
export const vehicleGroupIncludedConfigurationInsertService = (
	model: IVehicleGroupIncludedConfigurationInsertParams,
): Promise<ServiceResponse<IVehicleGroupIncludedConfiguration[]>> => {
	return Api.post<IVehicleGroupIncludedConfiguration[], IVehicleGroupIncludedConfigurationInsertParams>(
		VehicleGroupIncludedConfigurationEndpoints.INSERT,
		model,
	);
};
