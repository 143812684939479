import type { IVehicleGroupIncludedConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/vehicleGroupIncludedConfiguration/entities/VehicleGroupIncludedConfiguration';
import type { IVehicleGroupIncludedConfigurationDeactivateParams } from '@crac/core/modules/pricing/partnerConfiguration/vehicleGroupIncludedConfiguration/services/VehicleGroupIncludedConfigurationDeactivateService';
import { vehicleGroupIncludedConfigurationDeactivateService } from '@crac/core/modules/pricing/partnerConfiguration/vehicleGroupIncludedConfiguration/services/VehicleGroupIncludedConfigurationDeactivateService';
import type { IVehicleGroupIncludedConfigurationInsertParams } from '@crac/core/modules/pricing/partnerConfiguration/vehicleGroupIncludedConfiguration/services/VehicleGroupIncludedConfigurationInsertService';
import { vehicleGroupIncludedConfigurationInsertService } from '@crac/core/modules/pricing/partnerConfiguration/vehicleGroupIncludedConfiguration/services/VehicleGroupIncludedConfigurationInsertService';
import type { IVehicleGroupIncludedConfigurationModifyParams } from '@crac/core/modules/pricing/partnerConfiguration/vehicleGroupIncludedConfiguration/services/VehicleGroupIncludedConfigurationModifyService';
import { vehicleGroupIncludedConfigurationModifyService } from '@crac/core/modules/pricing/partnerConfiguration/vehicleGroupIncludedConfiguration/services/VehicleGroupIncludedConfigurationModifyService';
import { vehicleGroupIncludedConfigurationSearchService } from '@crac/core/modules/pricing/partnerConfiguration/vehicleGroupIncludedConfiguration/services/VehicleGroupIncludedConfigurationSearchService';
import type { IVehicleGroupIncludedConfigurationSearchParams } from '@crac/core/modules/pricing/partnerConfiguration/vehicleGroupIncludedConfiguration/services/VehicleGroupIncludedConfigurationSearchService';
import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

/**
 * Searches for configurations included in vehicle groups based on specified parameters.
 *
 * @param {IVehicleGroupIncludedConfiguration[]} payload - The included configurations to be retrieved.
 * @param {IVehicleGroupIncludedConfigurationSearchParams} params - Parameters for the search of included configurations.
 * @returns {Promise<void>} - A promise that resolves to an array of vehicle group included configurations.
 */
export const vehicleGroupIncludedConfigurationSearch = createAsyncAction<
	IVehicleGroupIncludedConfiguration[],
	IVehicleGroupIncludedConfigurationSearchParams
>('vehicleGroupIncludedConfiguration/search', vehicleGroupIncludedConfigurationSearchService);

/**
 * Inserts new configurations into vehicle groups.
 *
 * @param {IVehicleGroupIncludedConfiguration[]} payload - The configurations to be inserted.
 * @param {IVehicleGroupIncludedConfigurationInsertParams} params - Parameters for the insertion of configurations.
 * @returns {Promise<void>} - A promise that resolves after the configurations are inserted.
 */
export const vehicleGroupIncludedConfigurationInsert = createAsyncAction<
	IVehicleGroupIncludedConfiguration[],
	IVehicleGroupIncludedConfigurationInsertParams
>('vehicleGroupIncludedConfiguration/insert', vehicleGroupIncludedConfigurationInsertService);

/**
 * Modifies existing configurations included in vehicle groups.
 *
 * @param {IVehicleGroupIncludedConfiguration} payload - The configuration to be modified.
 * @param {IVehicleGroupIncludedConfigurationModifyParams} params - Parameters for the modification of configurations.
 * @returns {Promise<void>} - A promise that resolves after the configuration is modified.
 */
export const vehicleGroupIncludedConfigurationModify = createAsyncAction<
	IVehicleGroupIncludedConfiguration,
	IVehicleGroupIncludedConfigurationModifyParams
>('vehicleGroupIncludedConfiguration/modify', vehicleGroupIncludedConfigurationModifyService);

/**
 * Removes deactivated configurations from vehicle groups.
 *
 * @param {number} payload - The ID of the configuration to be removed.
 */
export const vehicleGroupIncludedConfigurationRemoveDeactivated = createSyncAction<number>(
	'vehicleGroupIncludedConfiguration/removeDeactivated',
);

/**
 * Deactivates specific configurations included in vehicle groups.
 *
 * @param {IVehicleGroupIncludedConfiguration[]} payload - The configurations to be deactivated.
 * @param {IVehicleGroupIncludedConfigurationDeactivateParams} params - Parameters for the deactivation of configurations.
 * @returns {Promise<void>} - A promise that resolves after the configurations are deactivated.
 */
export const vehicleGroupIncludedConfigurationDeactivate = createAsyncAction<
	IVehicleGroupIncludedConfiguration[],
	IVehicleGroupIncludedConfigurationDeactivateParams
>('vehicleGroupIncludedConfiguration/deactivate', vehicleGroupIncludedConfigurationDeactivateService);

/**
 * Clears the vehicle group included configuration state.
 */
export const vehicleGroupIncludedConfigurationClear = createSyncAction('vehicleGroupIncludedConfiguration/clear');
