import type { IRentRateVariation } from '@crac/core/modules/pricing/rentRateVariation/entities/RentRateVariation';
import { RentRateVariationEndpoints } from '@crac/core/modules/pricing/rentRateVariation/services/RentRateVariationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IRentRateVariationInsertParams extends IServiceBaseParams {
	variations: IRentRateVariation[];
}

export const rentRateVariationInsertService = (
	model: IRentRateVariationInsertParams,
): Promise<ServiceResponse<IRentRateVariation[]>> => {
	return Api.post<IRentRateVariation[], IRentRateVariationInsertParams>(RentRateVariationEndpoints.INSERT, model);
};
