export enum VehicleGroupIncludedConfigurationEndpoints {
	/**
	 * Choosable vehicle group configuration search
	 * @host `/ChoosableVehicleGroupConfiguration/search`
	 */
	SEARCH = '/ChoosableVehicleGroupConfiguration/search',
	/**
	 * Choosable vehicle group configuration insert
	 * @host `/ChoosableVehicleGroupConfiguration/insert`
	 */
	INSERT = '/ChoosableVehicleGroupConfiguration/insert',
	/**
	 * Choosable vehicle group configuration modify
	 * @host `/ChoosableVehicleGroupConfiguration/modify`
	 */
	MODIFY = '/ChoosableVehicleGroupConfiguration/modify',
	/**
	 * Choosable vehicle group configuration deactivate
	 * @host `/ChoosableVehicleGroupConfiguration/deactivate`
	 */
	DEACTIVATE = '/ChoosableVehicleGroupConfiguration/deactivate',
}
