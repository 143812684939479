import { styled } from 'styled-components';

import { colors } from '../../../../theme/global-style';

export const SortSummaryWrapper = styled.div`
	margin-bottom: 0;
	padding: 8px 1rem;
	margin: 5px;
	display: grid;
	background: rgb(240, 243, 245);
	border-radius: 5px;

	.button-badge {
		border: dashed 1px ${colors.bgLightBorderColor};
		border-radius: 5px;
		background: #fff;
		padding: 3px 5px;

		&:hover {
			background: ${colors.bgLight};
			border: dashed 1px gray;
		}

		> .close:hover {
			color: ${colors.danger};
		}

		&-clear {
			border: dashed 1px ${colors.danger};
			color: ${colors.danger};
			border-radius: 5px;
			background: #fff;
			padding: 3px 5px;

			&:hover {
				background: ${colors.bgLight};
				border: dashed 1px ${colors.danger};
				color: ${colors.danger};
			}
		}
	}
	.disabled {
		border: dashed 1px ${colors.disabled};
		color: ${colors.disabled};

		&:hover {
			background: #fff;
			color: ${colors.disabled};
			border: dashed 1px ${colors.disabled};
		}
	}
`;
