import type { IServiceRateType } from '@crac/core/modules/pricing/serviceRateType/entities/ServiceRateType';
import type { IServiceRateTypeInsertParams } from '@crac/core/modules/pricing/serviceRateType/services/ServiceRateTypeInsertService';
import { serviceRateTypeInsertService } from '@crac/core/modules/pricing/serviceRateType/services/ServiceRateTypeInsertService';
import { serviceRateTypeModifyService } from '@crac/core/modules/pricing/serviceRateType/services/ServiceRateTypeModifyService';
import type { IServiceRateTypesSearchParams } from '@crac/core/modules/pricing/serviceRateType/services/ServiceRateTypeSearchService';
import { serviceRateTypeSearchService } from '@crac/core/modules/pricing/serviceRateType/services/ServiceRateTypeSearchService';
import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';

/**
 * Searches for service rate types based on specified parameters.
 *
 * @param {IServiceRateType[]} payload - The service rate types to be retrieved.
 * @param {IServiceRateTypesSearchParams} params - Parameters for the search of service rate types.
 * @returns {Promise<void>} - A promise that resolves to an array of service rate types.
 */
export const serviceRateTypesSearch = createAsyncAction<IServiceRateType[], IServiceRateTypesSearchParams>(
	'serviceRate/typeSearch',
	serviceRateTypeSearchService,
);

/**
 * Inserts a new service rate type.
 *
 * @param {IServiceRateType} payload - The service rate type to be inserted.
 * @param {IServiceRateTypeInsertParams} params - Parameters for the insertion of the service rate type.
 * @returns {Promise<void>} - A promise that resolves after the service rate type is inserted.
 */
export const serviceRateTypeInsert = createAsyncAction<IServiceRateType, IServiceRateTypeInsertParams>(
	'serviceRate/typeInsert',
	serviceRateTypeInsertService,
);

/**
 * Modifies an existing service rate type.
 *
 * @param {IServiceRateType} payload - The service rate type to be modified.
 * @param {IServiceRateTypeInsertParams} params - Parameters for the modification of the service rate type.
 * @returns {Promise<void>} - A promise that resolves after the service rate type is modified.
 */
export const serviceRateTypeModify = createAsyncAction<IServiceRateType, IServiceRateTypeInsertParams>(
	'serviceRate/typeModify',
	serviceRateTypeModifyService,
);

/**
 * Clears the service rate type state.
 */
export const serviceRateTypeClear = createSyncAction('serviceRate/typeClear');
