import type { FC } from 'react';
import React, { useMemo } from 'react';

import { Input } from '../../../../external/reactstrap/input';
import type { IActionProps } from '../IList';
import { ActionContainer } from '../styled';

type ICheckActionProps = IActionProps;

export const CheckAction: FC<ICheckActionProps> = ({ action, item }) => {
	const idDisabled = useMemo(() => {
		if (typeof action.disabled === 'function') {
			return action.disabled(item);
		}

		return action.disabled;
	}, [action, item]);

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (action.callback) {
			action.callback({ ...item, [action.toggleProp as string]: event.target.checked });
		}
	};

	if (action.showIf && !action.showIf(item)) {
		return null;
	}

	const isChecked = Boolean(item[action.toggleProp as string]);

	let style: React.CSSProperties = {};

	if (action.size === 'lg') {
		style = {
			width: '20px',
			height: '20px',
		};
	}

	return (
		<ActionContainer>
			<Input
				bsSize="lg"
				checked={isChecked}
				isDisabled={idDisabled}
				onChange={handleInputChange}
				style={{ ...style, cursor: 'pointer' }}
				type="checkbox"
			/>
		</ActionContainer>
	);
};
