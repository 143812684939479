import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { BookingEndPoints } from './BookingEndpoints';
import type { IBooking } from '../entities/Booking';

export interface IBookingGetByVehicleParams extends IServiceBaseParams {
	plateNumber: string;
}

/**
 * Get bookings by vehicle
 *  {string} string plateNumber
 * @returns {Array<BookingPropType>} `Array<BookingPropType>`
 */
export const bookingGetByVehicleService = (model: IBookingGetByVehicleParams): Promise<ServiceResponse<IBooking[]>> => {
	return Api.get<IBooking[], IBookingGetByVehicleParams>(BookingEndPoints.GET_BY_VEHICLE, model);
};
