import type { IMaximumExcessRate } from '@crac/core/modules/pricing/maximumExcessRate/entities/MaximumExcessRate';
import { MaximumExcessRateEndpoints } from '@crac/core/modules/pricing/maximumExcessRate/services/MaximumExcessRateEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

export interface IMaximumExcessRateInsertParams extends IServiceBaseParams {
	maximumExcessRates: IMaximumExcessRate[];
}

/**
 * Insert excess rates
 * @param model IMaximumExcessRateInsertParams
 * @returns IMaximumExcessRate[]
 */
export const maximumExcessRateInsertService = (model: IMaximumExcessRateInsertParams) =>
	Api.post<IMaximumExcessRate[], IMaximumExcessRateInsertParams>(MaximumExcessRateEndpoints.INSERT, model);
