import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CustomerEndPoints } from './CustomerEndpoints';
import type { ICustomer } from '../entities/Customer';

export interface ICustomerAcceptGdprParams extends IServiceBaseParams {
	bookingNumber: string;
	computerName: string;
	ip: string;
}

/**
 * Customer accept GDPR
 *  {Number} customerCode Customer code
 */
export const customerAcceptGdprService = (model: ICustomerAcceptGdprParams): Promise<ServiceResponse<ICustomer>> => {
	return Api.post<any, ICustomerAcceptGdprParams>(CustomerEndPoints.ACCEPT_GDPR, model);
};
