import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { CommentEndPoints } from './CommentsEndpoints';
import type { IComment } from '../entities/Comment';

export interface ICommentAddToCustomerParams extends IServiceBaseParams {
	code: string;
	message: string;
}

/**
 * Add comment to customer
 *  {string} message Comment message
 *  {string} code Customer code
 */
export const commentAddToCustomerService = (model: ICommentAddToCustomerParams): Promise<ServiceResponse<IComment>> => {
	return Api.post<IComment, ICommentAddToCustomerParams>(CommentEndPoints.ADD_TO_CUSTOMER, model);
};
