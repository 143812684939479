import type { INotAllowedMovement } from '@crac/core/modules/pricing/notAllowedMovement/entities/NotAllowedMovement';
import { NotAllowedMovementEndpoints } from '@crac/core/modules/pricing/notAllowedMovement/services/NotAllowedMovementEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface INotAllowedMovementModifyParams extends IServiceBaseParams {
	notAllowedMovement: INotAllowedMovement;
	vehicleGroupToUpdate: string;
}

export const notAllowedMovementModifyService = (
	model: INotAllowedMovementModifyParams,
): Promise<ServiceResponse<INotAllowedMovement>> => {
	return Api.post<INotAllowedMovement, INotAllowedMovementModifyParams>(NotAllowedMovementEndpoints.MODIFY, model);
};
