import type { IRentRate } from '@crac/core/modules/pricing/rentRate/entities/RentRate';
import { RentRateEndpoints } from '@crac/core/modules/pricing/rentRate/services/RentRateEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IRentRateSaveParams extends IServiceBaseParams {
	rates: IRentRate[];
}

export const rentRateSaveService = (model: IRentRateSaveParams): Promise<ServiceResponse<IRentRate[]>> => {
	return Api.post<IRentRate[], IRentRateSaveParams>(RentRateEndpoints.INSERT, model);
};
