import type { ICouponConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/couponConfiguration/entities/CouponConfiguration';
import { CouponConfigurationEndpoints } from '@crac/core/modules/pricing/partnerConfiguration/couponConfiguration/services/CouponConfigurationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

/**
 * Modify a service coupon configuration params
 */
export interface ICouponConfigurationModifyParams extends IServiceBaseParams, Partial<ICouponConfiguration> {
	id: number;
	couponCode: string;
}

/**
 * Modify coupon configuration
 * @param params `ICouponConfigurationModifyParams`
 * @returns `Promise<ServiceResponse<ICouponConfiguration>>`
 */
export const couponConfigurationModifyService = (params: ICouponConfigurationModifyParams) => {
	return Api.post<ICouponConfiguration, ICouponConfigurationModifyParams>(
		CouponConfigurationEndpoints.MODIFY,
		params,
	);
};
