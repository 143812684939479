export enum PreauthorizationRateEndpoints {
	/**
	 * Preauthorization rate search
	 * @host `/preauthorizationRate/search/`
	 */
	SEARCH = '/preauthorizationRate/search',

	/**
	 * Preauthorization rate insert
	 * @host `/preauthorizationRate/insert/`
	 */
	INSERT = '/preauthorizationRate/insert',

	/**
	 * PreAuthorization rate deactivate
	 * @host `/preauthorizationRate/deactivate`,
	 */
	DEACTIVATE = '/preauthorizationRate/deactivate',
}
