import type { IActionStatus } from '../../../types/IActions';

type functionType = (item: any) => void;

export enum ListActionType {
	url = 'url',
	action = 'action',
	toggle = 'toggle',
	spinner = 'spinner',
	check = 'check',
	dropdown = 'dropdown',
}

export interface IListColumn {
	key: number | string;
	name?: string | string[];
	separator?: string;
	title?: string;
	classNames?: string;
	verticalAlign?: 'top' | 'middle' | 'bottom' | 'baseline';
	width?: string;
	backgroundColor?: string;
	backgroundColorTitle?: string;
	renderHeader?: (column: IListColumn) => any;
	render?: (action: any, item?: any, index?: number) => any;
}

export interface IListDropdownItem {
	icon: string;
	text: string;
	id: string | object;
	path: string;
	key: number | string;
	showIf?: (item: any) => boolean;
}

export interface IListAction {
	callback?: functionType;

	/**
	 * Hidden and show columns base in bootstrap classes
	 * https://getbootstrap.com/docs/4.1/utilities/display/#hiding-elements
	 */
	classNames?: string;

	/**
	 * Color button, base in bootstrap
	 */
	color?: string;

	/**
	 * Icon name, base in FontAwesome
	 */
	icon?: any;

	/**
	 * Field id to action
	 */
	// eslint-disable-next-line @typescript-eslint/ban-types
	id: string | object;

	/**
	 * Unique key value
	 */
	key: string | number;

	/**
	 * Show or hide action
	 */
	showIf?: (item: any) => boolean;

	/**
	 * Text action
	 */
	text?: string;
	/**
	 * One of value: url, action, toggle, spinner - ListActionType
	 */
	type: keyof typeof ListActionType;
	/**
	 * ID route configure in /src/config/routes.js
	 */
	url?: number;

	path?: string;

	/**
	 * Enabled / Disabled action
	 */
	disabled?: boolean | ((item: any) => boolean);

	/**
	 * Use in actions of type toggle
	 */
	status?: IActionStatus;

	/**
	 * Set button all with
	 */
	block?: boolean;

	toggleProp?: string;

	min?: number | string;

	max?: number | string;

	value?: number | string;

	size?: 'sm' | 'lg';

	backUrl?: string;

	ariaLabel?: string;

	/**
	 * Tooltip text. Only use on CallbackAction and UrlAction
	 */
	tooltip?: (item: any) => string;

	/**
	 * Used to hide tooltip when action does not have text and tooltip is displayed automatically.
	 */
	hideTooltip?: boolean;

	/* dropdown items */
	items?: IListDropdownItem[];

	/* dropdown direction */
	direction?: 'down' | 'end' | 'start' | 'up';

	/**
	 * Used to render an external link
	 */
	href?: (item?: any) => string;
}

export interface ITableProps {
	size?: 'sm' | 'lg';
	border?: boolean;
	striped?: boolean;
	inverse?: boolean;
	hover?: boolean;
	classNames?: string;
}

export type IListActionsType = IListAction[] | ((item: any) => IListAction);
export interface IActionProps {
	action: IListAction;
	item: any;
}
