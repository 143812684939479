import type { FC } from 'react';
import React, { Suspense, useEffect, useRef } from 'react';

import { gCSwal } from '@crac/components/external/sweet-alert';
import type { IBooking } from '@crac/core/models/entities/Booking';
import type { IBranch } from '@crac/core/models/entities/Branch';
import type { IEmployee } from '@crac/core/models/entities/Employee';
import type { IRequestState } from '@crac/core/modules/shared/types/RequestStateType';
import type { emailSendFeedback } from '@crac/core/redux/actions/EmailActions';
import { Button } from '@crac/ui/button';
import { Icon } from '@crac/ui/icons/Icon';
import { Sheet, SheetContent, SheetTrigger } from '@crac/ui/sheet';

import { useComputerSelector } from '~/features/management/computer/state/selectors/computer';

import type { FeedBackFormState } from './types';

const FeedBackForm = React.lazy(() => import('~/features/shared/components/layout/feedBack/FeedBackForm'));

interface IFeedBackProps {
	booking?: IBooking | null;
	employee: IEmployee;
	isOpen: boolean;
	onToggleFeedback: (isOpen: boolean) => void;
	sendFeedBack: typeof emailSendFeedback;
	sendFeedBackRequest: IRequestState;
}

export const FeedBack: FC<IFeedBackProps> = ({
	employee,
	sendFeedBack,
	sendFeedBackRequest,
	booking,
	isOpen,
	onToggleFeedback,
}) => {
	const { hostname } = useComputerSelector();
	const sendFeedBackRequestRef = useRef(sendFeedBackRequest);

	useEffect(() => {
		sendFeedBackRequestRef.current = sendFeedBackRequest;
	}, [sendFeedBackRequest]);

	const handleClose = () => {
		onToggleFeedback(false);
	};

	const handleOpen = () => {
		onToggleFeedback(true);
	};

	const handleSubmit = async (values: FeedBackFormState): Promise<void> => {
		if (!sendFeedBackRequestRef.current.inProgress) {
			const { userName, name, allowedBranches, branchCode, token } = employee;
			const currentBranch = allowedBranches.find((branch) => branch.code === branchCode) as IBranch;
			const { files, message } = values;

			const json = JSON.stringify({
				allowedBranches: allowedBranches.map((branch) => branch.code),
				bookingNumber: booking ? booking.bookingNumber : undefined,
				branchCode,
				branchEmail: currentBranch.email,
				branchName: currentBranch.name,
				dropOffDateTime: booking ? booking.dropOffDateTime : undefined,
				dropOffFuelPercentage: booking ? booking.dropOffFuelPercentage : undefined,
				dropOffKM: booking ? booking.dropOffKM : undefined,
				message,
				name,
				token,
				url: location.href,
				userName,
				hostname,
			});

			sendFeedBack({
				files,
				json,
			});

			if (sendFeedBackRequestRef.current.ok) {
				onToggleFeedback(false);
				await gCSwal('Sent!', 'Feedback has been sent.', 'success');
			} else {
				await gCSwal('Error!', sendFeedBackRequestRef.current.messages.map((msg) => msg.message).join(', '));
			}
		}
	};

	return (
		<div className="FeedBack">
			<Sheet onOpenChange={(open) => !open && handleClose()} open={isOpen}>
				<SheetTrigger asChild>
					<Button
						className="Feedback__toggle tw-hidden sm:tw-flex tw-z-[1070] tw-fixed tw-w-14 tw-h-14 tw-overflow-hidden tw-right-2 tw-bottom-2 tw-rounded-full tw-opacity-90 tw-text-white"
						color="danger"
						onClick={handleOpen}
						tabIndex={-1}
					>
						<Icon className="tw-w-4 tw-h-4" name="FaExclamationTriangle" />
					</Button>
				</SheetTrigger>
				<SheetContent className="tw-max-w-full tw-z-[1080] tw-w-full md:tw-w-[inherit] md:tw-max-w-2xl tw-px-0 tw-pt-12 md:tw-px-4 tw-overflow-y-auto">
					<Suspense fallback={null}>
						<FeedBackForm onCancel={handleClose} onSubmit={handleSubmit} />
					</Suspense>
				</SheetContent>
			</Sheet>
		</div>
	);
};
