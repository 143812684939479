import { createAsyncThunk } from '@reduxjs/toolkit';

import { customerAcceptGdprService } from '@crac/core/modules/customer/services/CustomerAcceptGdprService';
import type { IDocument } from '@crac/core/modules/documentation/entities/Document';
import { documentGetByBookingService } from '@crac/core/modules/documentation/services/DocumentGetByBookingService';
import { createSyncAction } from '@crac/core/modules/shared/state/createAction';
import { createAsyncAction } from '@crac/core/modules/shared/state/createAsyncAction';
import type { CreationMethod } from '@crac/core/modules/shared/types/CreationMethod';
import { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { branchServiceGetAll } from '../../../../data/services/BranchService';
import { cardServiceGetByCustomer } from '../../../../data/services/CardService';
import { paymentLineServiceGetByBooking } from '../../../../data/services/PaymentLineService';
import { providerServiceGetByBranch } from '../../../../data/services/ProviderService';
import type { IBranch } from '../../../../models/entities/Branch';
import type { ICard } from '../../../../models/entities/Card';
import type { IPaymentLine } from '../../../../models/entities/PaymentLine';
import type { IProvider } from '../../../../models/entities/Provider';
import type {
	IBookingDetailResponse,
	IBookingExtendDetailResponse,
	IBookingInsertExternalResponse,
} from '../../../../models/serviceResponse/BookingResponse';
import type { MessageType } from '../../../../models/types/MessageType';
import { activeLoader, disableLoader } from '../../../../redux/actions/CommonActions';
import type { IAvailabilityAndPrice } from '../../availability/entities/AvailabilityAndPrice';
import type { IVehicleGroupAvailabilityAndPrice } from '../../availability/entities/VehicleGroupAvailabilityAndPrice';
import { bookingGetAvailabilityService } from '../../availability/services/BookingGetAvailabilityService';
import type { IBookingLine } from '../../bookingLine/entities/BookingLine';
import { bookingLineGetByBookingService } from '../../bookingLine/services/BookingLineGetByBookingService';
import type { IBooking } from '../../entities/Booking';
import type { IBookingCustomerChangeParams } from '../../modify/services/BookingCustomerChangeService';
import { bookingCustomerChangeService } from '../../modify/services/BookingCustomerChangeService';
import type { IBookingCanDoDirectPickUpParams } from '../../pickUp/services/BookingCanDoDirectPickUpService';
import { bookingCanDoDirectPickUpService } from '../../pickUp/services/BookingCanDoDirectPickUpService';
import type { IBookingDoDirectPickUpParams } from '../../pickUp/services/BookingDoDirectPickUpService';
import { bookingDoDirectPickUpService } from '../../pickUp/services/BookingDoDirectPickUpService';
import { bookingRegisterContractService } from '../../pickUp/services/BookingRegisterContractService';
import type { IBookingSignContractParams } from '../../pickUp/services/BookingSignContractService';
import { bookingSignContractService } from '../../pickUp/services/BookingSignContractService';
import type { IBookingAllowPendingParams } from '../../services/BookingAllowPendingService';
import { bookingAllowPendingService } from '../../services/BookingAllowPendingService';
import type { IBookingBillBookingParams } from '../../services/BookingBillBookingService';
import { bookingBillBookingService } from '../../services/BookingBillBookingService';
import type { IBookingGetByBookingNumberParams } from '../../services/BookingGetByBookingNumberService';
import { bookingGetByBookingNumberService } from '../../services/BookingGetByBookingNumberService';
import type { IBookingGetByCustomerParams } from '../../services/BookingGetByCustomerService';
import { bookingGetByCustomerService } from '../../services/BookingGetByCustomerService';
import type { IBookingGetByVehicleParams } from '../../services/BookingGetByVehicleService';
import { bookingGetByVehicleService } from '../../services/BookingGetByVehicleService';
import type { IBookingGetDetail } from '../../services/BookingGetDetailService';
import type { IBookingGetExtendDetail } from '../../services/BookingGetExtendDetailService';
import type { IBookingGetPendingParams } from '../../services/BookingGetPendingsService';
import { bookingGetPendingsService } from '../../services/BookingGetPendingsService';
import type { IBookingHasUpgradeParams } from '../../services/BookingHasUpgradeService';
import { bookingHasUpgradeService } from '../../services/BookingHasUpgradeService';
import type { IPaymentCallbackParams } from '../../services/BookingInsertCallBackService';
import { bookingInsertCallBackService } from '../../services/BookingInsertCallBackService';
import type { IBookingInsertExternalParams } from '../../services/BookingInsertExternalService';
import { bookingInsertExternalService } from '../../services/BookingInsertExternalService';
import type { IBookingInvoiceableChangeParams } from '../../services/BookingInvoiceableChangeService';
import { bookingInvoiceableChangeService } from '../../services/BookingInvoiceableChangeService';
import type { IBookingRefundPaymentsParams } from '../../services/BookingRefundPaymensService';
import { bookingRefundPaymentsService } from '../../services/BookingRefundPaymensService';
import type { IBookingRevertToConfirmedParams } from '../../services/BookingRevertToConfirmedService';
import { bookingRevertToConfirmedService } from '../../services/BookingRevertToConfirmedService';
import type { IBookingSearchParams } from '../../services/BookingSearchService';
import { bookingSearchService } from '../../services/BookingSearchService';
import type { IBookingSendExtendPaymentEmailParams } from '../../services/BookingSendExtendPaymentEmailService';
import { bookingSendExtendPaymentEmailService } from '../../services/BookingSendExtendPaymentEmailService';
import type { IBookingSetDropOffDataParams } from '../../services/BookingSetDropOffData';
import { bookingSetDropOffDataService } from '../../services/BookingSetDropOffData';
import type { IBookingSignAndRegisterContractParams } from '../../services/BookingSignAndRegisterContractService';
import { bookingStartContractWithSmartKeyService } from '../../services/BookingStartContractWithSmartKeyService';
import type { IBookingStartContractSmartKeyParams } from '../../services/BookingStartContractWithSmartKeyService';
import { BookingStateType } from '../../types/BookingStateType';

/**
 * Inserts a new external booking.
 *
 * @param {IBookingInsertExternalResponse} payload - The response after inserting the external booking.
 * @param {IBookingInsertExternalParams} params - Parameters for inserting an external booking.
 * @returns {Promise<void>} - A promise that resolves with the response after the booking is inserted.
 */
export const bookingInsertExternal = createAsyncAction<IBookingInsertExternalResponse, IBookingInsertExternalParams>(
	'booking/insertExternal',
	bookingInsertExternalService,
);

/**
 * Handles the callback for the booking insertion process.
 *
 * @param {IBookingInsertExternalResponse} payload - The response after processing the booking insertion callback.
 * @param {IPaymentCallbackParams} params - Parameters for the payment callback of the booking insertion.
 * @returns {Promise<void>} - A promise that resolves with the response after processing the callback.
 */
export const bookingInsertCallBack = createAsyncAction<IBookingInsertExternalResponse, IPaymentCallbackParams>(
	'booking/insertCallBack',
	bookingInsertCallBackService,
);

/**
 * Retrieves a booking by its booking number.
 *
 * @param {IBooking} payload - The booking to be retrieved.
 * @param {IBookingGetByBookingNumberParams} params - Parameters for retrieving a booking, such as the booking number.
 * @returns {Promise<void>} - A promise that resolves to the booking associated with the given number.
 */
export const bookingGetByBookingNumber = createAsyncAction<IBooking, IBookingGetByBookingNumberParams>(
	'booking/getByBookingNumber',
	bookingGetByBookingNumberService,
);

/**
 * Signs a contract for a booking.
 *
 * @param {IBooking} payload - The booking for which the contract is signed.
 * @param {IBookingSignContractParams} params - Parameters for signing the contract, such as digital signature details.
 * @returns {Promise<void>} - A promise that resolves after the contract is signed.
 */
export const bookingSignContract = createAsyncAction<IBooking, IBookingSignContractParams>(
	'booking/signContract',
	bookingSignContractService,
);

/**
 * Searches for bookings based on specified parameters.
 *
 * @param {IBooking[]} payload - The bookings that match the search criteria.
 * @param {IBookingSearchParams} params - Parameters for searching bookings, such as date range, customer ID, or vehicle ID.
 * @returns {Promise<void>} - A promise that resolves to an array of bookings matching the search criteria.
 */
export const bookingSearch = createAsyncAction<IBooking[], IBookingSearchParams>(
	'booking/search',
	bookingSearchService,
);

/**
 * Retrieves bookings associated with a specific vehicle.
 *
 * @param {IBooking[]} payload - The bookings associated with the vehicle.
 * @param {IBookingGetByVehicleParams} params - Parameters for retrieving bookings for a specific vehicle.
 * @returns {Promise<void>} - A promise that resolves to an array of bookings for the specified vehicle.
 */
export const bookingGetByVehicle = createAsyncAction<IBooking[], IBookingGetByVehicleParams>(
	'booking/getByVehicle',
	bookingGetByVehicleService,
);

/**
 * Retrieves bookings associated with a specific customer.
 *
 * @param {IBooking[]} payload - The bookings associated with the customer.
 * @param {IBookingGetByCustomerParams} params - Parameters for retrieving bookings for a specific customer.
 * @returns {Promise<void>} - A promise that resolves to an array of bookings for the specified customer.
 */
export const bookingGetByCustomer = createAsyncAction<IBooking[], IBookingGetByCustomerParams>(
	'booking/getByCustomer',
	bookingGetByCustomerService,
);

/**
 * Retrieves pending bookings.
 *
 * @param {IBooking[]} payload - The pending bookings.
 * @param {IBookingGetPendingParams} params - Parameters for retrieving pending bookings.
 * @returns {Promise<void>} - A promise that resolves to an array of pending bookings.
 */
export const bookingGetPendings = createAsyncAction<IBooking[], IBookingGetPendingParams>(
	'booking/getPendings',
	bookingGetPendingsService,
);

/**
 * Checks if a booking has an upgrade available.
 *
 * @param {boolean} payload - The result indicating whether an upgrade is available.
 * @param {IBookingHasUpgradeParams} params - Parameters for checking if a booking has an upgrade.
 * @returns {Promise<void>} - A promise that resolves with the result of whether an upgrade is available.
 */
export const bookingHasUpgrade = createAsyncAction<boolean, IBookingHasUpgradeParams>(
	'booking/hasUpgrade',
	bookingHasUpgradeService,
);

/**
 * Sends an email for extending the payment of a booking.
 *
 * @param {boolean} payload - The result indicating whether the email was successfully sent.
 * @param {IBookingSendExtendPaymentEmailParams} params - Parameters for sending the extend payment email.
 * @returns {Promise<void>} - A promise that resolves with the result of the email sending process.
 */
export const bookingSendExtendPaymentEmail = createAsyncAction<boolean, IBookingSendExtendPaymentEmailParams>(
	'booking/sendExtendPaymentEmail',
	bookingSendExtendPaymentEmailService,
);

/**
 * Resets the booking search state.
 */
export const bookingSearchReset = createSyncAction('booking/searchReset');

/**
 * Bills a specific booking.
 *
 * @param {boolean} payload - The result indicating whether the billing process was successful.
 * @param {IBookingBillBookingParams} params - Parameters for billing the booking, such as booking ID and billing details.
 * @returns {Promise<void>} - A promise that resolves with the result of the billing process.
 */
export const bookingBillBooking = createAsyncAction<boolean, IBookingBillBookingParams>(
	'booking/billBooking',
	bookingBillBookingService,
);

/**
 * Refunds payments for a specific booking.
 *
 * @param {boolean} payload - The result indicating whether the refund process was successful.
 * @param {IBookingRefundPaymentsParams} params - Parameters for refunding payments, such as booking ID and refund details.
 * @returns {Promise<void>} - A promise that resolves with the result of the refund process.
 */
export const bookingRefundPayments = createAsyncAction<boolean, IBookingRefundPaymentsParams>(
	'booking/refundPayments',
	bookingRefundPaymentsService,
);

/**
 * Checks if a direct pick-up can be performed for a specific booking.
 *
 * @param {boolean} payload - The result indicating whether a direct pick-up is possible.
 * @param {IBookingCanDoDirectPickUpParams} params - Parameters for checking the possibility of a direct pick-up, such as booking ID and related conditions.
 * @returns {Promise<void>} - A promise that resolves with the result of the check.
 */
export const bookingCanDoDirectPickUp = createAsyncAction<boolean, IBookingCanDoDirectPickUpParams>(
	'booking/canDoDirectPickUp',
	bookingCanDoDirectPickUpService,
);

/**
 * Performs a direct pick-up for a specific booking.
 *
 * @param {IBooking} payload - The booking for which the direct pick-up is performed.
 * @param {IBookingDoDirectPickUpParams} params - Parameters for performing the direct pick-up, such as booking ID and pick-up details.
 * @returns {Promise<void>} - A promise that resolves with the updated booking after the direct pick-up.
 */
export const bookingDoDirectPickUp = createAsyncAction<IBooking, IBookingDoDirectPickUpParams>(
	'booking/doDirectPickUp',
	bookingDoDirectPickUpService,
);

/**
 * Reverts a booking to its confirmed state.
 *
 * @param {IBooking} payload - The booking to be reverted.
 * @param {IBookingRevertToConfirmedParams} params - Parameters for reverting the booking, such as booking ID and related conditions.
 * @returns {Promise<void>} - A promise that resolves with the reverted booking.
 */
export const bookingRevertToConfirmed = createAsyncAction<IBooking, IBookingRevertToConfirmedParams>(
	'booking/revertToConfirmed',
	bookingRevertToConfirmedService,
);

/**
 * Allows a booking to be marked as pending.
 *
 * @param {IBooking} payload - The booking to be marked as pending.
 * @param {IBookingAllowPendingParams} params - Parameters for marking the booking as pending, such as booking ID and relevant conditions.
 * @returns {Promise<void>} - A promise that resolves with the updated booking after allowing it to be pending.
 */
export const bookingAllowPending = createAsyncAction<IBooking, IBookingAllowPendingParams>(
	'booking/allowPending',
	bookingAllowPendingService,
);

/**
 * Changes the invoiceable status of a booking.
 *
 * @param {IBooking} payload - The booking for which the invoiceable status is changed.
 * @param {IBookingInvoiceableChangeParams} params - Parameters for changing the invoiceable status, such as booking ID and new status.
 * @returns {Promise<void>} - A promise that resolves with the updated booking after the invoiceable status change.
 */
export const bookingInvoiceableChange = createAsyncAction<IBooking, IBookingInvoiceableChangeParams>(
	'booking/invoiceableChange',
	bookingInvoiceableChangeService,
);

/**
 * Changes the customer associated with a booking.
 *
 * @param {IBooking} payload - The booking for which the customer is changed.
 * @param {IBookingCustomerChangeParams} params - Parameters for changing the customer, such as booking ID and new customer details.
 * @returns {Promise<void>} - A promise that resolves with the updated booking after the customer change.
 */
export const bookingCustomerChange = createAsyncAction<IBooking, IBookingCustomerChangeParams>(
	'booking/customerChange',
	bookingCustomerChangeService,
);

/**
 * Clears the booking detail state.
 */
export const bookingDetailClear = createSyncAction('booking/detailClear');

/**
 * Clears the booking search state.
 */
export const bookingClearSearch = createSyncAction('booking/clearSearch');

type taskType =
	| ServiceResponse<IBookingLine[]>
	| ServiceResponse<IPaymentLine[]>
	| ServiceResponse<IDocument[]>
	| ServiceResponse<IBranch[]>
	| ServiceResponse<ICard[]>
	| ServiceResponse<IProvider>
	| ServiceResponse<IAvailabilityAndPrice>;

export const bookingGetDetail = createAsyncAction<IBookingDetailResponse, IBookingGetDetail>(
	'booking/getDetail',
	async (params: IBookingGetDetail) => {
		const bookingResponse = await bookingGetByBookingNumberService({ ...params });

		if (bookingResponse.ok && bookingResponse.data) {
			const tasks = [];
			tasks.push(bookingLineGetByBookingService(params));
			tasks.push(paymentLineServiceGetByBooking(params));
			tasks.push(documentGetByBookingService(params));
			tasks.push(
				branchServiceGetAll({
					creationMethod: params.creationMethod,
					locale: params.locale,
					token: params.token,
				}),
			);
			tasks.push(
				providerServiceGetByBranch({ branchCode: bookingResponse.data.pickUpBranchCode, token: params.token }),
			);
			const requestResponses = await Promise.all<taskType>(tasks);

			const responseErrors: MessageType[] = [];

			requestResponses.forEach((resp) => {
				if (!resp.ok) {
					responseErrors.push(...resp.errors);
				}
			});

			if (responseErrors.length > 0) {
				return new ServiceResponse<IBookingDetailResponse>(false, null, responseErrors);
			}

			const bookingLine: IBookingLine[] = [];
			const paymentLine: IPaymentLine[] = [];
			const bookingDocument: IDocument[] = [];
			const branches: IBranch[] = [];
			// eslint-disable-next-line init-declarations
			let provider: IProvider | undefined;
			if (requestResponses[0].ok && requestResponses[0].data) {
				bookingLine.push(...(requestResponses[0].data as IBookingLine[]));
			}
			if (requestResponses[1].ok && requestResponses[1].data) {
				paymentLine.push(...(requestResponses[1].data as IPaymentLine[]));
			}
			if (requestResponses[2].ok && requestResponses[2].data) {
				bookingDocument.push(...(requestResponses[2].data as IDocument[]));
			}
			if (requestResponses[3].ok && requestResponses[3].data) {
				branches.push(...(requestResponses[3].data as IBranch[]));
			}

			if (requestResponses[4].ok && requestResponses[4].data) {
				provider = requestResponses[4].data as IProvider;
			}
			let vehicleGroup: IVehicleGroupAvailabilityAndPrice[] = [];
			if (
				bookingResponse.data.bookingState === BookingStateType.Confirmed ||
				bookingResponse.data.bookingState === BookingStateType.OnHire
			) {
				const availaibilityResponse = await bookingGetAvailabilityService({
					agencyCode: bookingResponse.data.agencyCode,
					bookingNumber: bookingResponse.data.bookingNumber,
					creationMethod: params.creationMethod as CreationMethod,
					dropOffBranchCode: bookingResponse.data.dropOffBranchCode,
					dropOffDateTime: bookingResponse.data.dropOffDateTime as Date,
					pickUpBranchCode: bookingResponse.data.pickUpBranchCode,
					pickUpDateTime: bookingResponse.data.pickUpDateTime as Date,
					quoteDateTime: bookingResponse.data.quoteDateTime as Date,
					token: params.token,
					vehicleGroup: bookingResponse.data.vehicleGroupCodeRequested,
					vendorCode: bookingResponse.data.vendorCode,
					disableFilter: true,
					locale: params.locale,
				});
				if (availaibilityResponse.ok) {
					vehicleGroup = (availaibilityResponse.data as IAvailabilityAndPrice).vehicleGroupsAvailability;
				}
			}

			return new ServiceResponse<IBookingDetailResponse>(
				true,
				{
					booking: bookingResponse.data,
					bookingDocuments: bookingDocument,
					bookingLines: bookingLine,
					branches,
					paymentLines: paymentLine,
					provider,
					vehicleGroup,
				},
				[],
			);
		}

		return new ServiceResponse<IBookingDetailResponse>(false, null, bookingResponse.errors);
	},
);

export const bookingGetExtendDetail = createAsyncAction<IBookingExtendDetailResponse, IBookingGetExtendDetail>(
	'booking/getExtendDetail',
	async (params: IBookingGetExtendDetail) => {
		const bookingResponse = await bookingGetByBookingNumberService({ ...params });

		if (bookingResponse.ok && bookingResponse.data) {
			const tasks = [];
			tasks.push(bookingLineGetByBookingService(params));
			tasks.push(paymentLineServiceGetByBooking(params));
			tasks.push(branchServiceGetAll({ creationMethod: params.creationMethod, token: params.token }));
			tasks.push(
				cardServiceGetByCustomer({
					creationMethod: params.creationMethod,
					customerCode: bookingResponse.data.customerCode,
					token: params.token,
				}),
			);
			const requestResponses = await Promise.all<taskType>(tasks);

			const responseErrors: MessageType[] = [];

			requestResponses.forEach((resp) => {
				if (!resp.ok) {
					responseErrors.push(...resp.errors);
				}
			});

			// ERROR
			if (responseErrors.length > 0) {
				return new ServiceResponse<IBookingExtendDetailResponse>(true, null, responseErrors);
			}

			const bookingLine: IBookingLine[] = [];
			const paymentLine: IPaymentLine[] = [];
			const branches: IBranch[] = [];
			const cards: ICard[] = [];

			if (requestResponses[0].ok && requestResponses[0].data) {
				bookingLine.push(...(requestResponses[0].data as IBookingLine[]));
			}

			if (requestResponses[1].ok && requestResponses[1].data) {
				paymentLine.push(...(requestResponses[1].data as IPaymentLine[]));
			}
			if (requestResponses[2].ok && requestResponses[2].data) {
				branches.push(...(requestResponses[2].data as IBranch[]));
			}
			if (requestResponses[3].ok && requestResponses[3].data) {
				cards.push(...(requestResponses[3].data as ICard[]));
			}

			return new ServiceResponse<IBookingExtendDetailResponse>(true, {
				booking: bookingResponse.data,
				bookingLines: bookingLine,
				branches,
				cards,
				paymentLines: paymentLine,
			});
		}

		return new ServiceResponse<IBookingExtendDetailResponse>(true, null, bookingResponse.errors);
	},
);

/**
 * Clears the state related to booking insertion.
 */
export const bookingInsertClear = createSyncAction('booking/insertClear');

/**
 * Clears any errors related to booking insertion.
 */
export const bookingInsertClearErrors = createSyncAction('booking/insertClearErrors');

/**
 * Clears the state related to retrieving bookings by a customer.
 */
export const bookingGetByCustomerClear = createSyncAction('booking/getByCustomerClear');

/**
 * Sets drop-off data for a booking.
 *
 * @param {IBooking} payload - The booking to be updated.
 * @param {IBookingSetDropOffDataParams} params - Parameters for setting drop-off data for the booking.
 * @returns {Promise<void>} - A promise that resolves with the updated booking after setting the drop-off data.
 */
export const bookingSetDropOffData = createAsyncAction<IBooking, IBookingSetDropOffDataParams>(
	'booking/setDropOffData',
	bookingSetDropOffDataService,
);

/**
 * Starts a booking contract using a smart key.
 *
 * @param {boolean} payload - The result indicating whether the contract start was successful.
 * @param {IBookingStartContractSmartKeyParams} params - Parameters for starting the contract with a smart key.
 * @returns {Promise<void>} - A promise that resolves with the result of the contract start process.
 */
export const bookingSmartKeyStartContract = createAsyncAction<boolean, IBookingStartContractSmartKeyParams>(
	'booking/smartKeyStartContract',
	bookingStartContractWithSmartKeyService,
);

/**
 * Clears the state related to pending bookings.
 */
export const bookingPendingClear = createSyncAction('booking/pendingClear');

export const bookingSignAndRegisterContract = createAsyncThunk<IBooking, IBookingSignAndRegisterContractParams, any>(
	'booking/signAndRegisterContract',
	async (params: IBookingSignAndRegisterContractParams, { dispatch, rejectWithValue }) => {
		const { bookingNumber, computerName, ip, requiredSign } = params;

		try {
			if (requiredSign && computerName && ip) {
				dispatch(
					activeLoader({ icon: 'fa-pencil', message: 'Waiting for customer signature...', active: true }),
				);

				const signResponse = await bookingSignContractService({
					bookingNumber,
					computerName,
					ip,
				});

				if (!signResponse.ok) {
					dispatch(disableLoader());
					return rejectWithValue(signResponse.errors);
				}

				const customerResponse = await customerAcceptGdprService({ bookingNumber, computerName, ip });

				dispatch(disableLoader());

				if (!customerResponse.ok) {
					return rejectWithValue(customerResponse.errors);
				}
			}

			dispatch(activeLoader({ icon: 'fa-save', message: 'Awaiting register contract...', active: true }));
			const registerResponse = await bookingRegisterContractService({ bookingNumber });
			if (!registerResponse.ok || !registerResponse.data) {
				return rejectWithValue(registerResponse.errors);
			}

			return registerResponse.data;
		} finally {
			dispatch(disableLoader());
		}
	},
);
