import type { PartnerType } from '@crac/core/modules/pricing/partnerConfiguration/types/PartnerType';
import type { IRentRateVariation } from '@crac/core/modules/pricing/rentRateVariation/entities/RentRateVariation';
import { RentRateVariationEndpoints } from '@crac/core/modules/pricing/rentRateVariation/services/RentRateVariationEndpoints';
import type { FromTo } from '@crac/core/modules/pricing/types/FromToType';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IRentRateVariationsSearchParams extends IServiceBaseParams {
	branches?: number[];
	vehicleGroups?: string[];
	contractTypes?: number[];
	partners?: Array<{
		type: PartnerType;
		code: string;
	}>;
	partnerCategories?: string[];
	partnerGroups?: string[];
	rentRateTypes?: string[];
	pickUp?: FromTo<string | Date>;
	quote?: FromTo<string | Date>;
	showActive?: number;
}

export const rentRateVariationSearchService = (
	model: IRentRateVariationsSearchParams,
): Promise<ServiceResponse<IRentRateVariation[]>> => {
	return Api.get<IRentRateVariation[], IRentRateVariationsSearchParams>(RentRateVariationEndpoints.SEARCH, model);
};
