import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

import { BookingEndPoints } from './BookingEndpoints';
import type { IBooking } from '../entities/Booking';

export interface IBookingSetDropOffDataParams extends IServiceBaseParams {
	bookingNumber: string;
	vehicleLocation?: string;
	actualDropOffDateTime?: string;
	vehicleKM?: number;
}

export const bookingSetDropOffDataService = (
	model: IBookingSetDropOffDataParams,
): Promise<ServiceResponse<IBooking>> => {
	return Api.post<IBooking, IBookingSetDropOffDataParams>(BookingEndPoints.SET_DROP_OFF_DATA, model);
};
