import type { FC, ReactNode } from 'react';

import { Icon } from '@crac/ui/icons/Icon';
import { Tabs, TabsList, TabsTrigger } from '@crac/ui/tabs';

interface ILocationTabsProps {
	children: ReactNode;
}

export const LocationTabs: FC<ILocationTabsProps> = ({ children }) => {
	return (
		<Tabs defaultValue="1">
			<TabsList>
				<TabsTrigger value="1">
					<Icon className="tw-mr-1" name="FaPencil" /> Modify
				</TabsTrigger>
				<TabsTrigger value="2">
					<Icon className="tw-mr-1" name="FaMapMarkerAlt" /> Locations
				</TabsTrigger>
			</TabsList>
			{children}
		</Tabs>
	);
};
