import type { IRentRateType } from '@crac/core/modules/pricing/rentRateType/entities/RentRateType';
import { RentRateTypeEndpoints } from '@crac/core/modules/pricing/rentRateType/services/RentRateTypeEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '@crac/core/modules/shared/types/ServiceResponse';

export interface IRentRateTypeInsertParams extends IServiceBaseParams, IRentRateType {}

export const rentRateTypeInsertService = (
	model: IRentRateTypeInsertParams,
): Promise<ServiceResponse<IRentRateType>> => {
	return Api.post<IRentRateType, IRentRateTypeInsertParams>(RentRateTypeEndpoints.INSERT, model);
};
