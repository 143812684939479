import type { IInvoiceableServiceConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/invoiceableServiceConfiguration/entities/InvoiceableServiceConfiguration';
import { InvoiceableServiceConfigurationEndpoints } from '@crac/core/modules/pricing/partnerConfiguration/invoiceableServiceConfiguration/services/InvoiceableServiceConfigurationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

/**
 * Deactivate a service invoiceable configuration params
 */
export interface IInvoiceableServiceConfigurationDeactivateParams extends IServiceBaseParams {
	ids: number[];
}

/**
 * Deactivate service invoiceable configuration
 * @param params `IInvoiceableServiceConfigurationDeactivateParams`
 * @returns `Promise<ServiceResponse<IInvoiceableServiceConfiguration[]>>`
 */
export const invoiceableServiceConfigurationDeactivateService = (
	params: IInvoiceableServiceConfigurationDeactivateParams,
) => {
	return Api.post<IInvoiceableServiceConfiguration[], IInvoiceableServiceConfigurationDeactivateParams>(
		InvoiceableServiceConfigurationEndpoints.DEACTIVATE,
		params,
	);
};
