import * as React from 'react';

import * as TabsPrimitive from '@radix-ui/react-tabs';

import { cn } from '../../lib/utils/tailwindClass';

export const TabsTrigger = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.Trigger>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
	<TabsPrimitive.Trigger className={cn('tabs-trigger', className)} ref={ref} {...props} />
));

TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;
