import type { IIncludedServiceConfiguration } from '@crac/core/modules/pricing/partnerConfiguration/includedServiceConfiguration/entities/IncludedServiceConfiguration';
import { IncludedServiceConfigurationEndpoints } from '@crac/core/modules/pricing/partnerConfiguration/includedServiceConfiguration/services/IncludedServiceConfigurationEndpoints';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

/**
 * Modify a service invoiceable configuration params
 */
export interface IIncludedServiceConfigurationModifyParams
	extends IServiceBaseParams,
		Partial<IIncludedServiceConfiguration> {
	id: number;
	service: string;
}

/**
 * Modify included service configuration
 * @param params `IIncludedServiceConfigurationModifyParams`
 * @returns `Promise<ServiceResponse<IIncludedServiceConfiguration>>`
 */
export const includedServiceConfigurationModifyService = (params: IIncludedServiceConfigurationModifyParams) => {
	return Api.post<IIncludedServiceConfiguration, IIncludedServiceConfigurationModifyParams>(
		IncludedServiceConfigurationEndpoints.MODIFY,
		params,
	);
};
