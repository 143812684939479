import type { UnitTime } from '@crac/core/models/types/UnitTime';
import type { PartnerType } from '@crac/core/modules/pricing/partnerConfiguration/types/PartnerType';
import type { IStopSale } from '@crac/core/modules/pricing/stopSale/entities/StopSale';
import { StopSaleEndpoints } from '@crac/core/modules/pricing/stopSale/services/StopSaleEndpoints';
import type { FromTo } from '@crac/core/modules/pricing/types/FromToType';
import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

export interface IStopSalesSearchParams extends IServiceBaseParams {
	vehicleGroups?: string[];
	branches?: number[];
	pickUp: FromTo<string | Date>;
	contractTypes?: number[];
	partners?: Array<{
		type: PartnerType;
		code: string;
	}>;
	bookingDuration?: FromTo<number>;
	unitTime?: UnitTime;
	/**
	 * Use StopSaleState enum values
	 */
	showActive?: number;
	partnerCategories?: string[];
	partnerGroups?: string[];
}

/**
 * Search stop sales
 */
export const stopSaleServiceSearch = (model: IStopSalesSearchParams) => {
	return Api.get<IStopSale[], IStopSalesSearchParams>(StopSaleEndpoints.SEARCH, model);
};
