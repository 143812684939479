import { type FC, useMemo } from 'react';

import type { SortingState } from '@tanstack/react-table';
import classNames from 'classnames';

import { splitCamelCase } from '@crac/core/helpers/commons';

import { SortList } from './SortList';
import { SortSummaryWrapper } from './styles';
import type { ITanStackTableProps } from '..';

export type SortItemType = { id: string; desc: boolean; label: string };

interface ISortingSummary {
	sorting: SortingState;
	onClearAll: () => void;
	onClearBy: (id: string) => void;
	onToggleSort: (id: string) => void;
	columns: ITanStackTableProps['columns'];
	isSorted?: boolean;
	showOrderReset?: boolean;
	onReset?: () => void;
}

export const SortingSummary: FC<ISortingSummary> = ({
	sorting,
	onClearAll,
	onClearBy,
	onToggleSort,
	columns,
	isSorted,
	showOrderReset,
	onReset,
}) => {
	const sortColumns: SortItemType[] = useMemo(
		() =>
			sorting.length > 0
				? sorting.map((sort) => {
						const [col] = columns.filter(({ key }) => key === sort.id);
						return { ...sort, label: col.header ?? splitCamelCase(col.key) };
					})
				: [],
		[columns, sorting],
	);

	if (showOrderReset && sortColumns.length === 0) {
		return (
			<SortSummaryWrapper>
				<div className="d-flex justify-content-end">
					<button
						className={classNames('button-badge-clear', { disabled: !isSorted })}
						disabled={!isSorted}
						onClick={onReset}
						type="button"
					>
						<small>
							<i className="fa fa-fw fa-close" /> Reset order
						</small>
					</button>
				</div>
			</SortSummaryWrapper>
		);
	}

	if (sortColumns.length > 0) {
		return (
			<SortSummaryWrapper>
				<div className={classNames('d-flex flex-column justify-content-center gap-1')}>
					<div className="d-flex justify-content-between">
						<div className="d-flex align-items-center gap-1">
							<small className="fw-bold text-muted">Sort by:</small>
							<SortList
								onClear={onClearAll}
								onClearBy={onClearBy}
								onToggleSort={onToggleSort}
								sorts={sortColumns}
							/>
						</div>
						{showOrderReset ? (
							<div>
								<button
									className={classNames('button-badge-clear', { disabled: !isSorted })}
									onClick={onReset}
									type="button"
								>
									<small>
										<i className="fa fa-fw fa-close" /> Reset order
									</small>
								</button>
							</div>
						) : null}
					</div>
				</div>
			</SortSummaryWrapper>
		);
	}

	return null;
};
