import { Api } from '@crac/core/modules/shared/api';
import type { IServiceBaseParams } from '@crac/core/modules/shared/types/ServiceBaseParams';

import { CustomerEndPoints } from './CustomerEndpoints';
import type { ICustomerLoginResponse } from '../../../models/serviceResponse/CustomerResponse';

export interface ICustomerLoginParams extends IServiceBaseParams {
	email: string;
	password: string;
}

/**
 * Login customer
 * @param model ICustomerLoginParams
 * @returns Promise<ServiceResponse<ICustomerLoginResponse>>
 */
export const customerLoginService = (model: ICustomerLoginParams) => {
	return Api.post<ICustomerLoginResponse, ICustomerLoginParams>(CustomerEndPoints.LOGIN, model);
};
