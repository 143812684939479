import { VehicleEngineEndPoints } from './VehicleEngineEndPoints';
import type { TransmissionType } from '../../../../models/types/TransmissionType';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { FuelType } from '../../types/FuelType';
import type { IVehicleEngine } from '../entities/VehicleEngine';

export interface IVehicleEngineInsertParams extends IServiceBaseParams {
	metaModelId: number;
	name: string;
	horsepower?: number;
	fuelType?: FuelType;
	cylinderCapacity?: number;
	transmissionType?: TransmissionType;
}

export const vehicleEngineServiceInsert = (params: IVehicleEngineInsertParams) => {
	return Api.post<IVehicleEngine, IVehicleEngineInsertParams>(VehicleEngineEndPoints.INSERT, params);
};
