import { styled } from 'styled-components';

import { colors } from '../../../theme/global-style';

export const StylesToolBar = styled.div`
	border: 1px solid ${colors.gbLightBorder};
	background: ${colors.bgLight};
	&-title {
		display: flex;
		justify-content: space-between;
		align-items: center;

		&-text {
		}

		&-buttons {
		}
	}
`;
